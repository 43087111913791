import React from 'react';
import { styled } from 'goober';

interface Props {
  rating?: number;
  size?: number;
  onChange: (newValue: number) => void;
}

const RatingContainer = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  svg {
    cursor: pointer;
  }
`;

export const Rating: React.FC<Props> = ({ rating, size = 13, onChange }) => {
  if (rating === undefined) return null;

  const RenderStar = (value: number) => {
    if (value <= rating) {
      return (
        <svg
          width={size}
          height={size}
          onClick={() => onChange(value)}
          viewBox='0 0 13 13'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M6.5 0L8.10465 4.29138L12.6819 4.49139L9.09638 7.34362L10.3206 11.7586L6.5 9.23L2.6794 11.7586L3.90362 7.34362L0.318133 4.49139L4.89535 4.29138L6.5 0Z'
            fill='#bf90f1'
          />
        </svg>
      );
    }
    return (
      <svg
        width={size}
        height={size}
        onClick={() => onChange(value)}
        viewBox='0 0 13 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.5 0L8.10465 4.29138L12.6819 4.49139L9.09638 7.34362L10.3206 11.7586L6.5 9.23L2.6794 11.7586L3.90362 7.34362L0.318133 4.49139L4.89535 4.29138L6.5 0Z'
          fill='#e5cdfe'
        />
      </svg>
    );
  };

  return (
    <RatingContainer>
      {RenderStar(1)}
      {RenderStar(2)}
      {RenderStar(3)}
      {RenderStar(4)}
      {RenderStar(5)}
    </RatingContainer>
  );
};
