import { ProfileMenuActionButton } from './ProfileActionButtons';
import React from 'react';
import { User } from '../../types/GlobalTypes';
import { customPageAnimationVertically } from '../../utils/pageTransition';
import { isBlockedByUser } from '../../utils/helper-functions/isBlockedByUser';
import { peopleCircleOutline } from 'ionicons/icons';
import { useIonRouter } from '@ionic/react';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';

type Props = {
  user: User;
  onClick?: () => void;
};

export const ViewFriendsButton: React.FC<Props> = ({ user, onClick }) => {
  const { loggedInUser } = useLoggedInUser();

  const ionRouter = useIonRouter();

  if (!loggedInUser?.id) return null;
  if (loggedInUser.id === user.id) return null;

  if (isBlockedByUser(user, loggedInUser)) return null;
  if (isBlockedByUser(loggedInUser, user)) return null;

  const goToFollowers = () => {
    onClick?.();
    ionRouter.push(
      `/user/${user?.id}/followers`,
      'forward',
      'push',
      {},
      customPageAnimationVertically
    );
  };

  return (
    <>
      <ProfileMenuActionButton
        clear
        color='medium'
        iconLeft={peopleCircleOutline}
        onClick={goToFollowers}
      >
        View Friends
      </ProfileMenuActionButton>
    </>
  );
};
