import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import React, { useContext, useRef, useState } from 'react';
import { UserWishlist, WishlistItem } from '../types/GlobalTypes';

import { Footer } from '../components/layout/Footer';
import { StyledIonOuterToolbar } from '../components/layout/StyledOuterIonToolbar';
import { WishList } from '../components/WishList';
import { WishlistItemModalContext } from '../contexts/WishlistItemModalContext';
import { analytics } from '../firebase';
import { getArchivedItemsObservable } from '../data-fetching/getArchivedItems';
import { logEvent } from 'firebase/analytics';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';
import { useParams } from 'react-router';

const ArchivedItems: React.FC = () => {
  const { loggedInUser, loggedInUserWishlists } = useLoggedInUser();
  const { openWishlistItemModal } = useContext(WishlistItemModalContext);

  const [archivedWishlist, setArchivedWishlist] = useState<UserWishlist>();

  const pageRef = useRef();
  const params = useParams<any>();

  const selectedWishlist = loggedInUserWishlists.find((wishlist) =>
    !params.wishlistId
      ? wishlist.id === 'original'
      : wishlist.id === params.wishlistId
  );

  const _openWishlistItemModal = (wishlistItem: WishlistItem | null) => {
    const wishListItemToOpen =
      archivedWishlist && wishlistItem?.id
        ? archivedWishlist[wishlistItem.id]
        : undefined;

    if (wishlistItem?.id && wishListItemToOpen) {
      logEvent(analytics, 'archived_wishlist_item_clicked', {
        itemId: wishlistItem.id,
      });

      openWishlistItemModal({
        currentWishlist: selectedWishlist,
        isSuggestion: false,
        pageRef,
        isViewingArchivedItem: true,
        readonly: false,
        userId: loggedInUser?.id,
        item: wishListItemToOpen,
      });
    }
  };

  useIonViewWillEnter(() => {
    let unsubscribeFromSnapshotWatch: () => void;
    if (loggedInUser?.id) {
      unsubscribeFromSnapshotWatch = getArchivedItemsObservable(
        loggedInUser.id,
        params.wishlistId || '',
        setArchivedWishlist
      );
    }

    return () => {
      unsubscribeFromSnapshotWatch && unsubscribeFromSnapshotWatch();
    };
  }, [loggedInUser, params]);

  return (
    <IonPage ref={pageRef}>
      <IonHeader mode='ios' translucent={true}>
        <StyledIonOuterToolbar>
          <IonButtons slot='start'>
            <IonBackButton
              defaultHref={
                !loggedInUser?.id
                  ? `/`
                  : `/user/${loggedInUser?.id}${
                      selectedWishlist?.id && selectedWishlist.id !== 'original'
                        ? `/wishlists/${selectedWishlist.id}`
                        : ''
                    }`
              }
              color='dark'
            />
          </IonButtons>
          <IonTitle>Archived Items</IonTitle>
        </StyledIonOuterToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <IonHeader mode='ios' collapse='condense'>
          <IonToolbar>
            <IonTitle size='large'>Archived Items</IonTitle>
          </IonToolbar>
        </IonHeader>

        <WishList
          showingArchivedItems
          userId={loggedInUser?.id}
          wishlist={archivedWishlist}
          selectedWishlist={selectedWishlist}
          isLoggedInUsersProfile={true}
          onSelectWishlistItem={_openWishlistItemModal}
        />
        <Footer type='full' />
      </IonContent>
    </IonPage>
  );
};

export default ArchivedItems;
