import React, { useRef } from 'react';

import { InputContainer } from './BaseUI/InputContainer';
import { checkPriceIsValid } from '../utils/helper-functions/checkPriceIsValid';
import { removeNonDigits } from '../utils/helper-functions/removeNonDigits';
import { styled } from 'goober';

interface Props {
  price: string;
  setPrice: (price: Props['price']) => void;
  readonly?: boolean;
}

const PriceInputInnerContainer = styled('div')`
  display: flex;
  align-items: center;
  color: var(--ion-color-dark);
`;

const Input = styled('input', React.forwardRef)<{ size: number }>`
  background: none;
  outline: none;

  letter-spacing: 0.6px;
  outline: none;
  border: none;
  border-radius: 9px;
  flex: none;
  color: var(--ion-color-dark);
  text-align: right;
  position: relative;

  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 4px;
  margin-right: -9px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0;
  padding-right: 8px;

  min-width: ${({ size }) => size * 10.2}px;
`;

const PriceInputContainer = styled(InputContainer)`
  margin-bottom: 12px;
`;

export const PriceInput: React.FC<Props> = ({ price, setPrice, readonly }) => {
  const priceInputRef = useRef<any>();

  const formatPriceValue = (value: string) => {
    if (value === '0') {
      setPrice('');
      return;
    }
    if (checkPriceIsValid(value)) {
      // prevent more than 2 decimals
      if (value.split('.').length > 1 && value.split('.')[1].length > 2) return;
      setPrice(
        String(
          value.endsWith('.') || value.endsWith('0') ? value : Number(value)
        )
      );
    } else {
      setPrice(value === '.' ? '0.' : removeNonDigits(value));
    }
  };

  return (
    <PriceInputContainer
      onClick={() => !readonly && priceInputRef.current?.focus()}
    >
      <p>Price</p>
      <PriceInputInnerContainer>
        <p>$</p>
        <Input
          size={
            price ? (price.length < 3 ? price.length : price.length + 1) : 1
          }
          ref={priceInputRef}
          inputMode='numeric'
          pattern='[0-9.]*'
          readOnly={readonly}
          onChange={(e: any) => formatPriceValue(e.target.value)}
          value={
            !price || price === '0'
              ? ''
              : price.endsWith('.') || price.endsWith('0')
              ? price
              : String(Number(price))
          }
        />
      </PriceInputInnerContainer>
    </PriceInputContainer>
  );
};
