import React, { useContext } from 'react';

import { WishListItem } from './WishListItem';
import { WishlistItem } from '../types/GlobalTypes';
import { WishlistItemModalContext } from '../contexts/WishlistItemModalContext';
import { styled } from 'goober';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';
import { useProfilePageContext } from '../contexts/ProfilePageContext';

const SuggestionListContainer = styled('div')`
  min-width: calc(100% - 24px);
  border: 2px dashed var(--ion-color-medium);
  margin: 12px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.02);
  box-shadow: inset 0px 2px 32px rgba(0, 0, 0, 0.05),
    inset 0px 2px 8px rgba(0, 0, 0, 0.05);
  > h4 {
    padding: 18px 22px;
    padding-bottom: 0;
    font-weight: 700;
    font-family: 'Nunito Sans', sans-serif;
  }
`;

const InnerContainer = styled('div')`
  overflow-x: scroll;
  padding: 12px;
  min-width: 100%;
  white-space: nowrap;
  > div {
    display: inline-block;
    width: 180px;
    height: 180px;
  }
`;

interface Props {}

export const SuggestionList: React.FC<Props> = () => {
  const { loggedInUser } = useLoggedInUser();
  const { openWishlistItemModal } = useContext(WishlistItemModalContext);
  const { suggestionList } = useProfilePageContext();
  const { profilePageRef } = useProfilePageContext();

  if (
    !loggedInUser?.id ||
    !suggestionList ||
    Object.keys(suggestionList).length === 0
  )
    return null;

  const onSelectSuggestionListItem = (item: WishlistItem) => {
    openWishlistItemModal({
      currentWishlist: item.suggested_list_id,
      isSuggestion: true,
      pageRef: profilePageRef,
      readonly: false,
      userId: loggedInUser.id,
      item,
    });
  };

  return (
    <SuggestionListContainer>
      <h4>Suggested by your friends</h4>
      <InnerContainer>
        {Object.entries(suggestionList).map(([itemId, item]) => (
          <WishListItem
            key={itemId}
            userId={loggedInUser.id}
            isLoggedInUsersItem={true}
            purchased={undefined}
            wishlistItem={item}
            onClick={() => onSelectSuggestionListItem(item)}
          />
        ))}
      </InnerContainer>
    </SuggestionListContainer>
  );
};
