import { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { styled } from 'goober';

export const PseudoH1 = styled(motion.h2)`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 900;
  color: var(--ion-color-dark);
  letter-spacing: 0px;
  font-size: 30px;
  @media (min-width: 378px) {
    font-size: 34px;
  }
  @media (min-width: 668px) {
    font-size: 50px;
  }
  @media (min-width: 968px) {
    font-size: 64px;
  }
`;

export const H2 = styled(motion.h2)`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 900;
  color: var(--ion-color-dark);
  letter-spacing: 0px;
  font-size: 28px;
  @media (min-width: 368px) {
    font-size: 30px;
  }
  @media (min-width: 668px) {
    font-size: 40px;
  }
  @media (min-width: 968px) {
    font-size: 50px;
  }
`;

export const H3 = styled(motion.h3)`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 900;
  color: var(--ion-color-dark);

  font-size: 18px;
  @media (min-width: 368px) {
    font-size: 20px;
  }
  @media (min-width: 668px) {
    font-size: 30px;
  }
  @media (min-width: 968px) {
    font-size: 40px;
  }
`;

export const H4 = styled('h4')`
  font-family: 'Nunito Sans', sans-serif;
  white-space: nowrap;
  font-size: 12px;
  letter-spacing: 0px;
  @media (min-width: 368px) {
    font-size: 14px;
  }
  @media (min-width: 668px) {
    font-size: 20px;
  }
  @media (min-width: 968px) {
    font-size: 28px;
  }
  font-weight: 900;
  color: var(--ion-color-dark);
`;

export const DescriptionText = styled(motion.p)`
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  font-weight: 700;
  max-width: 480px;
  margin-top: 8px;
  letter-spacing: 0px;
  @media (min-width: 368px) {
    font-size: 13px;
  }
  @media (min-width: 668px) {
    font-size: 15px;
  }
  @media (min-width: 968px) {
    font-size: 16px;
  }
  color: var(--ion-color-medium);
  span {
    color: var(--ion-color-dark);
  }
`;

export const FeatureItemBoxContainer = styled('div', forwardRef)`
  border: 2px solid #e9e9e9;
  border-left: 0;
  flex: 1;
  display: flex;
  align-items: stretch;
  &.privacy-section {
    border: 2px solid #e9e9e9;
  }
  &:last-of-type {
    border-right: 0;
  }
`;

export const SmallFeatureBoxRowContainer = styled('div')`
  display: flex;
  align-items: stretch;
  width: 100%;
  flex: 1;
`;

export const HalfFeatureBoxRowContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  flex: 1;
  margin-bottom: 80px;

  @media (max-width: 767px) {
    margin-bottom: 30px;
    > div {
      border-right: 0px;
    }
    > div:first-of-type {
      border-bottom: 0px;
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
  }

  &.snug {
    margin-bottom: -2px;
  }
`;

export const FeatureBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const SmallFeatureBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  flex: 1;

  @media (min-width: 468px) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  @media (min-width: 668px) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  svg {
    font-size: 20px;
    margin-bottom: 4px;
    @media (min-width: 368px) {
      font-size: 26px;
    }
    @media (min-width: 668px) {
      font-size: 28px;
      margin-bottom: 10px;
    }
    @media (min-width: 968px) {
      font-size: 40px;
    }
    opacity: 0.7;
    color: var(--ion-color-medium);
    transition: all 0.2s ease;
  }

  &:hover {
    svg {
      opacity: 1;
      transform: scale(1.1);
    }
  }
`;

export const HalfFeatureBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  flex: 1;

  padding-top: 42px;
  padding-bottom: 42px;

  @media (min-width: 768px) {
    padding-top: 56px;
    padding-bottom: 56px;
    padding-left: 48px;
    padding-right: 48px;
  }

  @media (min-width: 968px) {
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 68px;
    padding-right: 68px;
  }
`;
