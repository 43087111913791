import React, { useContext, useEffect } from 'react';

import { Filesystem } from '@capacitor/filesystem';
import { SendIntent } from 'send-intent';
import { WishlistItemModalContext } from '../contexts/WishlistItemModalContext';
import { analytics } from '../firebase';
import { extractURLFromString } from '../utils/helper-functions/extractURLFromString';
import { logEvent } from 'firebase/analytics';
import { useIonRouter } from '@ionic/react';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

export type FormattedIntent = {
  image?: string;
  title?: string;
  price?: string;
  url?: string;
  userId?: string;
  wishlistId?: string;
};

export const ShareIntentListener: React.FC = () => {
  const ionRouter = useIonRouter();
  const [intent, setIntent] = React.useState<FormattedIntent | undefined>();

  const { openWishlistItemModal } = useContext(WishlistItemModalContext);

  const { loggedInUserWishlists, loggedInUser } = useLoggedInUser();

  useEffect(() => {
    if (loggedInUser?.id && intent) {
      logEvent(analytics, 'share_intent');
      openWishlistItemModal({
        currentWishlist: undefined,
        isSuggestion: false,
        userId: intent.userId || loggedInUser.id,
        item: {
          title: intent.title || '',
          link: intent.url || '',
          deletedAt: null,
          purchased: null,
          image: intent.image || '',
          updatedAt: null,
          price: intent.price || '',
        },
        readonly: false,
        pageRef: undefined,
      });
      setIntent(undefined);
    }
  }, [
    loggedInUser,
    intent,
    openWishlistItemModal,
    ionRouter,
    loggedInUserWishlists,
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('shareIntent')) {
      const newIntent: FormattedIntent = {
        title: searchParams.get('title') || undefined,
        url: searchParams.get('url') || undefined,
        image: searchParams.get('image') || undefined,
        price: searchParams.get('price') || undefined,
        userId: searchParams.get('userId') || undefined,
        wishlistId: searchParams.get('wishlistId') || undefined,
      };

      setIntent(newIntent);
      if (newIntent.userId) {
        ionRouter.push('/user/' + newIntent.userId);
      } else if (loggedInUser?.id) {
        ionRouter.push('/user/' + loggedInUser.id);
      } else {
        ionRouter.push('/friends');
      }
    }
  }, [ionRouter, loggedInUser]);

  useEffect(() => {
    const checkSendIntent = async () => {
      try {
        const result = await SendIntent.checkSendIntentReceived();
        if (result && (result.title || result.url)) {
          const intent: FormattedIntent = {};

          const type = result.type
            ? decodeURIComponent(result.type)
            : undefined;
          if (type && type.startsWith('image/')) {
            if (result.url) {
              const imagePath = decodeURIComponent(result.url);
              const content = await Filesystem.readFile({ path: imagePath });
              intent.image = `data:${type};base64,${content.data}`;
            }
          } else {
            // we assume everything else is just text
            if (result.description) {
              intent.title = decodeURIComponent(result.description);
            }

            if (result.title) {
              const title = decodeURIComponent(result.title);
              const attemptedURLExtractedFromString =
                extractURLFromString(title);
              if (attemptedURLExtractedFromString) {
                intent.url = attemptedURLExtractedFromString;
                intent.title = title.replace(
                  attemptedURLExtractedFromString,
                  ''
                );
              } else {
                if (title.startsWith('http') || title.startsWith('www')) {
                  intent.url = title;
                } else {
                  intent.title = title;
                }
              }
            }

            if (result.url) {
              intent.url = decodeURIComponent(result.url);
            }
          }

          if (intent.title || intent.url || intent.image) {
            setIntent(intent);
          }
        }
      } catch (err) {
        console.error(err);
      }
    };

    window.addEventListener('sendIntentReceived', checkSendIntent);

    // run initial check in case it occurred before app load.
    checkSendIntent();

    return () => {
      window.removeEventListener('sendIntentReceived', checkSendIntent);
    };
  }, []);

  return null;
};
