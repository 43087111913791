import { QuerySnapshot, collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { UserWishlist, WishlistItem } from '../types/GlobalTypes';

import { db } from '../firebase';
import { log } from '../utils/log';

export const getArchivedItemsObservable = (
  userId: string,
  wishlistId: string,
  setFn: (value: React.SetStateAction<UserWishlist | undefined>) => void
) => {
  log('read', `[READ from Server] getArchivedItems`);

  const collectionPath = `users/${userId}/${!wishlistId || wishlistId === 'original' ? 'wishlist' : `wishlists/${wishlistId}/items`}`;
  return onSnapshot(query(collection(db, collectionPath), where('deletedAt', '!=', null), orderBy('deletedAt', 'desc')), (snapshot) => {
    const newArchivedWishlist: UserWishlist = {};
    (snapshot as QuerySnapshot<WishlistItem>).docs.map((item) => {
      newArchivedWishlist[item.id] = { ...item.data(), id: item.id };
      return null;
    });
    setFn(newArchivedWishlist);
  });
};
