import React from 'react';

interface Props {
  size?: number;
  className?: string;
  emoji: string;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ['em-emoji']: any;
    }
  }
}

export const Emoji: React.FC<Props> = ({ size, className, emoji }) => {
  return <em-emoji shortcodes={emoji} size={size} className={className} />;
};
