import { IonIcon, IonSpinner } from '@ionic/react';

import React from 'react';
import { Sparkles } from '../Sparkles';
import { isPlatform } from '@ionic/react';
import { styled } from 'goober';

interface Props {
  color: string;
  iconLeft?: string | React.ReactNode;
  iconRight?: string | React.ReactNode;
  onClick?: (e: any) => void;
  clear?: boolean;
  uppercase?: boolean;
  disabled?: boolean;
  small?: boolean;
  className?: string;
  href?: string;
  target?: string;
  isLoading?: boolean;
  type?: 'submit' | 'button';
  slot?: string;
  children?: React.ReactNode;
  sparkles?: boolean;
  maxStars?: number;
}

const TextContainer = styled('div')<{ $uppercase: boolean }>`
  pointer-events: none;
  font-size: 15px;
  display: flex;
  ${({ $uppercase }) =>
    $uppercase ? `text-transform: uppercase; letter-spacing: 1.5px;` : ''}
`;

type IconContainerProps = {
  $iconOnly: boolean;
  $iconSide: 'left' | 'right';
};

const IconContainer = styled('div')<IconContainerProps>`
  pointer-events: none;
  font-size: 20px;
  display: flex;
  ${({ $iconOnly, $iconSide }) =>
    $iconOnly
      ? `font-size: 28px;`
      : $iconSide === 'left'
      ? `padding-right: 6px;`
      : `padding-left: 6px;`}
`;

const Container = styled('div')<any>`
  display: flex;
  margin: 0;
  padding: ${({ $icon, $small, $iconOnly }) => {
    let yPadding = 6;
    let xPadding = $iconOnly ? 6 : 12;

    if ($icon) {
      yPadding = yPadding / 2;
      xPadding = xPadding / 2;
    }

    if (!$small) {
      yPadding = yPadding * 2;
      xPadding = xPadding + 4;
    }

    return `${yPadding}px ${xPadding}px`;
  }};
  outline: none;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  line-height: 15px;
  cursor: pointer;
  text-decoration: none;
  background: ${({ color, $clear }) =>
    $clear ? 'transparent' : `var(--ion-color-${color})`};
  --sparkle-bg: ${({ color, $clear }) =>
    $clear ? 'white' : `var(--ion-color-${color})`};
  .btn-text {
    color: ${({ color, $clear }) =>
      `var(--ion-color-${color + ($clear ? '' : '-contrast')})`};
  }

  &${isPlatform('desktop') ? ':hover' : ':active'} {
    background: ${({ color, $clear }) =>
      $clear ? `rgba(0,0,0,.05)` : `var(--ion-color-${color}-tint)`};

    --sparkle-bg: ${({ color, $clear }) =>
      $clear ? 'white' : `var(--ion-color-${color}-tint)`};
    .btn-text,
    .btn-icon {
      opacity: 0.8;
    }
  }

  ion-icon,
  ion-spinner {
    pointer-events: none;
    ${({ $small }) => $small && `font-size: 20px;`}
    color: ${({ color, $clear }) =>
      `var(--ion-color-${$clear ? color : color + '-contrast'})`};
  }
`;

const LoadingContainer = styled('div')`
  ion-spinner {
    height: 18px;
  }
  display: flex;
  margin-right: 8px;
  margin-top: -1px;
  margin-bottom: -1px;
`;

export const Button: React.FC<Props> = ({
  small = false,
  children,
  disabled,
  iconLeft,
  iconRight,
  href,
  color,
  clear = false,
  onClick,
  uppercase = false,
  className,
  target,
  isLoading,
  type = 'button',
  sparkles,
  maxStars,
  ...rest
}) => {
  const renderIcon = (side: 'left' | 'right') => {
    const icon = side === 'left' ? iconLeft : iconRight;
    if (!icon) return undefined;
    return isLoading ? null : typeof icon === 'string' ? (
      <IconContainer
        $iconSide={side}
        className='btn-icon'
        $iconOnly={!children ? true : false}
      >
        <IonIcon icon={icon} />
      </IconContainer>
    ) : (
      icon
    );
  };

  return (
    <Container
      as={href ? 'a' : 'button'}
      $small={small}
      $icon={Boolean(iconLeft || iconRight)}
      href={href}
      target={target}
      disabled={disabled}
      onClick={(e: any) => (onClick ? onClick(e) : undefined)}
      $clear={clear}
      color={color}
      className={className}
      $iconOnly={!children ? true : false}
      type={type}
      {...rest}
    >
      {sparkles && <Sparkles maxStars={maxStars} />}
      {renderIcon('left')}
      {isLoading && (
        <LoadingContainer>
          <IonSpinner name='crescent' color='light' />
        </LoadingContainer>
      )}
      <TextContainer className='btn-text' $uppercase={uppercase}>
        {children}
      </TextContainer>
      {renderIcon('right')}
    </Container>
  );
};
