import { User, WishlistConfig } from '../../types/GlobalTypes';

import { apiFunctionFetch } from '../apiFunctionFetch';

export const scheduleActivityNotification = async (loggedInUser: User, wishlistConfig: WishlistConfig) => {
    try {
        if (!loggedInUser || !wishlistConfig) throw new Error('Invalid parameters');
        if (!wishlistConfig.notificationSubscribers || wishlistConfig.notificationSubscribers.length === 0) return;
        await apiFunctionFetch('scheduleActivityNotification', {
            wishlistId: wishlistConfig.id,
            wishlistName: wishlistConfig.name,
            actorName: loggedInUser.formattedName,
            actorId: loggedInUser.id,
            notificationSubscribers: wishlistConfig.notificationSubscribers,
        });
    } catch (err) {
        console.error(err);
    }
}