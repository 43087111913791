export const RELATIONSHIP_DEFINITIONS = {
  mutual: {
    emoji: '💛',
    alt: 'heart',
    definition: 'Mutual friends',
  },
  'user-follows': {
    emoji: '👻',
    alt: 'ghost',
    definition: 'You follow this user but they do not follow you back',
  },
  'friend-follows': {
    emoji: '🚷',
    alt: 'doesnt-follow',
    definition: 'This user follows you but you do not follow them',
  },
};
