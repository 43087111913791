import { analytics, auth } from '../firebase';

import { io } from 'socket.io-client';
import { log } from './log';
import { logEvent } from 'firebase/analytics';

export const socket = io(
    'https://api.ifancy.app'
    , {
        autoConnect: false,
    });


export const connectToSocketServer = async () => {
    try {
        logEvent(analytics, 'socket_connect_attempt');
        log('serverlessFunction', 'Connecting to socket server...');
        const idToken = await auth?.currentUser?.getIdToken();
        if (!idToken) {
            console.error('No user is logged in');
            logEvent(analytics, 'socket_connect_failed_no_user');
            return;
        }
        socket.auth = { token: idToken };
        socket.connect();
    } catch (error) {
        logEvent(analytics, 'socket_connect_failed');
        console.error('Error connecting to socket server:', error);
    }
};