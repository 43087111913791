import { FeedbackModal, FeedbackType } from '../../modals/FeedbackModal';
import React, { useEffect, useState } from 'react';
import {
  bugOutline,
  bulbOutline,
  chatbubbleEllipsesOutline,
} from 'ionicons/icons';
import {
  getDaysSinceLastChat,
  handleOpenTidioChat,
} from '../../hooks/useTidioControls';

import { Button } from '../BaseUI/Button';
import { LiveButtonDot } from '../BaseUI/LiveButtonDot';
import LogoAndTitle from '../LogoAndTitle';
import { analytics } from '../../firebase';
import { customPageAnimationHorizontally } from '../../utils/pageTransition';
import { logEvent } from 'firebase/analytics';
import packageJson from '../../../package.json';
import { styled } from 'goober';
import { useIonRouter } from '@ionic/react';

interface Props {
  type: 'full' | 'minimal';
  hideDisclaimer?: boolean;
}

const Badge = styled('div')`
  border-radius: 6px;
  padding: 1px 4px;
  background: var(--ion-color-primary);
  font-size: 10px;
  color: var(--ion-color-primary-contrast);
  white-space: nowrap;
  margin-top: 12px;
`;

const Container = styled('div')`
  width: 100%;
  padding-top: 20px;
  padding-bottom: calc(80px + var(--ion-safe-area-bottom)) !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
`;

const FeedbackButtonsContainer = styled('div')`
  padding: 12px;
  padding-bottom: 0px;
  padding-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SupportButton = styled(Button as any)`
  margin-top: 10px;
  padding: 8px 16px;
  .btn-icon {
    font-size: 19px;
  }
  .btn-text {
    font-size: 17px;
  }
`;

const FeedbackButton = styled(Button as any)`
  .btn-icon {
    font-size: 15px;
  }

  .btn-text {
    font-size: 10px;
  }
`;

const NeedHelpButton = styled(Button as any)`
  .btn-text {
    font-size: 12px;
  }
  margin-top: 10px;
`;

const FeedbackButtonsDivider = styled('div')`
  margin-left: 12px;
  margin-right: 12px;
  @media (max-width: 650px) {
    margin-left: 3px;
    margin-right: 3px;
  }
  width: 1px;
  height: 100%;
  background: black;
  opacity: 0.1;
`;

const DisclaimerText = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;

  p {
    text-align: justify;
    line-height: 110%;
    max-width: 600px;
    color: var(--ion-color-medium);
    font-size: 9px;
    letter-spacing: 0;
  }
`;

const TermsLink = styled('a')`
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
  &:hover,
  :active {
    opacity: 0.5;
  }
`;

export const Footer: React.FC<Props> = ({ type, hideDisclaimer }) => {
  const [feedbackType, setFeedbackType] = useState<FeedbackType | undefined>();
  const [showChatButton, setShowChatButton] = useState(false);

  const ionRouter = useIonRouter();

  useEffect(() => {
    const diffInDays = getDaysSinceLastChat();

    if (diffInDays && diffInDays < 5) {
      setShowChatButton(true);
    }
  }, []);

  const handleChatInit = async (type: FeedbackType) => {
    try {
      setShowChatButton(true);
      handleOpenTidioChat(type);
    } catch (error) {
      console.error('Error initializing chat:', error);
      setFeedbackType(type);
    }
  };

  useEffect(() => {
    function onTidioChatApiReady() {
      window.tidioChatApi?.on('messageFromOperator', () => {
        setShowChatButton(true);
      });
    }

    if (window?.tidioChatApi) {
      window.tidioChatApi.on('ready', onTidioChatApiReady);
    } else {
      document.addEventListener('tidioChat-ready', onTidioChatApiReady);
    }

    return () => {
      document.removeEventListener('tidioChat-ready', onTidioChatApiReady);
    };
  }, []);

  const openChatSupport = () => {
    logEvent(analytics, 'chat_opened');
    if (!window.tidioChatApi) {
      logEvent(analytics, 'chat_open_unavailable');
      return;
    }
    window.tidioChatApi.show();
    window.tidioChatApi.open();
  };

  const goToTermsOfUse = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    ionRouter.push(
      `/terms-of-use`,
      'forward',
      'push',
      {},
      customPageAnimationHorizontally
    );
  };

  const goToPrivacyPolicy = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    ionRouter.push(
      `/privacy-policy`,
      'forward',
      'push',
      {},
      customPageAnimationHorizontally
    );
  };

  const goToAffiliateDisclosure = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    ionRouter.push(
      `/affiliate-disclosure`,
      'forward',
      'push',
      {},
      customPageAnimationHorizontally
    );
  };

  return (
    <Container className='footer'>
      {type === 'full' && <LogoAndTitle />}
      <Badge>v{packageJson.version}</Badge>
      {showChatButton && (
        <SupportButton
          iconLeft={<LiveButtonDot className='green' />}
          iconRight={chatbubbleEllipsesOutline}
          color='primary'
          onClick={openChatSupport}
        >
          Show Support Chat
        </SupportButton>
      )}

      {type === 'full' ? (
        <FeedbackButtonsContainer>
          <FeedbackButton
            color='medium'
            clear
            onClick={() => handleChatInit('general')}
            iconLeft={chatbubbleEllipsesOutline}
          >
            Provide Feedback
          </FeedbackButton>
          <FeedbackButtonsDivider />
          <FeedbackButton
            color='medium'
            clear
            onClick={() => handleChatInit('bug')}
            iconLeft={bugOutline}
          >
            Report Bug
          </FeedbackButton>
          <FeedbackButtonsDivider />
          <FeedbackButton
            color='medium'
            clear
            onClick={() => handleChatInit('feature')}
            iconLeft={bulbOutline}
          >
            Request Feature
          </FeedbackButton>
        </FeedbackButtonsContainer>
      ) : (
        <NeedHelpButton
          small
          color='medium'
          clear
          onClick={() => handleChatInit('general')}
        >
          Need Help?
        </NeedHelpButton>
      )}

      {!hideDisclaimer && (
        <DisclaimerText>
          <p>
            © {new Date().getFullYear()} iFancy | A simple wishlist app. By
            creating an account, using the platform, or otherwise utilizing
            iFancy services, you agree to our{' '}
            <TermsLink href='/terms-of-use' onClick={goToTermsOfUse}>
              Terms of Use
            </TermsLink>
            ,{' '}
            <TermsLink
              href='/affiliate-disclosure'
              onClick={goToAffiliateDisclosure}
            >
              Affiliate Disclosure
            </TermsLink>
            , and{' '}
            <TermsLink href='/privacy-policy' onClick={goToPrivacyPolicy}>
              Privacy Policy
            </TermsLink>
            . iFancy is not responsible for ensuring people purchase your gifts.
            If any distasteful behavior is witnessed, please report the user
            immediately and we will take appropriate measures to ensure the
            issue is resolved swiftly and with proper justice. iFancy remains a
            free-to-use app because we utilize affiliate links to keep the
            lights on. As an Amazon Associate we earn from qualifying purchases.{' '}
            <TermsLink
              href='/affiliate-disclosure'
              onClick={goToAffiliateDisclosure}
            >
              Learn More about Affiliate Disclosure
            </TermsLink>
          </p>
        </DisclaimerText>
      )}

      <FeedbackModal
        isOpen={feedbackType ? true : false}
        initFeedbackType={feedbackType}
        onDidDismiss={() => setFeedbackType(undefined)}
      />
    </Container>
  );
};
