import {
  Notification,
  NotificationEventName,
  PNIDDoc,
} from '../types/GlobalTypes';
import {
  PermissionStatus,
  PushNotifications,
} from '@capacitor/push-notifications';
import {
  PushNotificationLocalStorageData,
  PushNotificationsLocalStorageKey,
} from '../components/PushNotificationCTABox';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Timestamp, analytics, db, functions } from '../firebase';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
} from 'firebase/firestore';
import { getPlatforms, useIonRouter } from '@ionic/react';

import { AuthenticationContext } from './AuthenticationContext';
import { Capacitor } from '@capacitor/core';
import { handleCatchError } from '../utils/handleCatchError';
import { httpsCallable } from 'firebase/functions';
import { logEvent } from 'firebase/analytics';
import toast from 'react-hot-toast';
import { useLoggedInUser } from './LoggedInUserContext';

type NotificationsProps = {
  isLoading: boolean;
  reachedEndOfNotifications: boolean;
  notifications: Notification[] | undefined;
  setNotifications: (notifications: Notification[]) => void;
  markRead: (notificationId: Notification) => void;
  fetchNotifications: (forceRefresh: boolean, manualClick: boolean) => void;
  triggerNotification: (
    actorUserId: string,
    subjectUserId: string,
    notificationEventName: NotificationEventName
  ) => Promise<void>;
  permissionStatus: PermissionStatus | null;
  setPermissionStatus: (permissionStatus: PermissionStatus) => void;
  handleAllow: () => void;
};

export const NotificationsContext = React.createContext<NotificationsProps>(
  {} as NotificationsProps
);

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider = ({ children }: any) => {
  const { loggedInUserId, authHasLoadedAndLoggedInUserLoaded } = useContext(
    AuthenticationContext
  );
  const [notifications, setNotifications] = useState<
    Notification[] | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [reachedEndOfNotifications, setReachedEndOfNotifications] =
    useState(false);
  const [permissionStatus, setPermissionStatus] =
    useState<PermissionStatus | null>(null);
  const ionRouter = useIonRouter();
  const { loggedInUser, pushToken, setPushToken } = useLoggedInUser();

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;
    if (loggedInUser && !pushToken) {
      const checkPermissionStatus = async () => {
        try {
          const permStatus = await PushNotifications.checkPermissions();
          setPermissionStatus(permStatus);
        } catch (err) {
          console.error(err);
          handleCatchError(err);
        }
      };

      checkPermissionStatus();
    }
  }, [loggedInUser, pushToken]);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;
    const addListeners = async () => {
      await PushNotifications.addListener('registration', async (token) => {
        if (!loggedInUser?.id) return;

        try {
          const pnidRef = doc(
            db,
            `users/${loggedInUser.id}/pnids/${token.value}`
          );
          const pnidDoc = await getDoc(pnidRef);

          if (pnidDoc.exists()) {
            setPushToken({ ...pnidDoc.data(), id: pnidDoc.id } as PNIDDoc);
          } else {
            const newPNIDDoc: PNIDDoc = {
              id: token.value,
              ignoreNotificationTypes: [],
              capacitorPlatform: Capacitor.getPlatform(),
              platforms: getPlatforms(),
              createdAt: Timestamp.fromDate(new Date()),
              userAgent: window.navigator.userAgent,
            };
            setDoc(pnidRef, newPNIDDoc);
            setPushToken(newPNIDDoc);

            const apiCall = httpsCallable(
              functions,
              'subscribeToGlobalNotifications'
            );
            await apiCall({ token: token.value });
          }
        } catch (err) {
          console.error(err);
          handleCatchError(err);
        }
      });

      await PushNotifications.addListener('registrationError', (err) => {
        console.error('Registration error: ', err.error);
        handleCatchError(err.error);
      });

      await PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
          console.log('Push notification received: ', notification);
        }
      );

      await PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification) => {
          if (notification?.notification?.data?.path) {
            ionRouter.push(notification.notification.data.path);
          }
        }
      );
    };

    addListeners();

    return () => {
      PushNotifications.removeAllListeners();
    };
  }, [ionRouter, loggedInUser, setPushToken]);

  useEffect(() => {
    if (!permissionStatus) return;

    const registerIfGranted = async () => {
      try {
        if (permissionStatus.receive === 'granted') {
          await PushNotifications.register();
        }
      } catch (err) {
        handleCatchError(err);
        console.error(err);
      }
    };

    registerIfGranted();
  }, [permissionStatus]);

  const markRead = useCallback(
    async (notification: Notification) => {
      if (notification.read) return;
      if (!loggedInUserId) return;
      const readDate = Timestamp.fromDate(new Date());
      setNotifications(
        notifications?.map((item) => {
          if (item.id === notification.id) {
            item.read = readDate;
          }
          return item;
        })
      );
      updateDoc(
        doc(db, `users/${loggedInUserId}/notifications/${notification.id}`),
        { read: readDate }
      );
    },
    [loggedInUserId, notifications]
  );

  useEffect(() => {
    let unsubscribe = () => {};
    if (loggedInUserId && !isLoading && authHasLoadedAndLoggedInUserLoaded) {
      unsubscribe = onSnapshot(
        query(
          collection(db, `users/${loggedInUserId}/notifications`),
          orderBy('createdAt', 'desc'),
          limit(1)
        ),
        (snapshot) => {
          if (snapshot.docs.length && snapshot.docs[0].exists()) {
            const notificationDoc = snapshot.docs[0];
            if (
              !notifications?.find((item) => item.id === notificationDoc.id)
            ) {
              setNotifications([
                {
                  ...notificationDoc.data(),
                  id: notificationDoc.id,
                } as Notification,
                ...(notifications || []),
              ]);
            }
          }
        }
      );
    }

    return () => {
      unsubscribe();
    };
  }, [
    isLoading,
    authHasLoadedAndLoggedInUserLoaded,
    loggedInUserId,
    notifications,
    setNotifications,
  ]);

  const fetchNotifications = useCallback(
    async (forceRefresh: boolean, manualClick: boolean) => {
      try {
        if (!loggedInUserId) return;
        setIsLoading(true);

        const queryConstraints: any[] = [orderBy('createdAt', 'desc')];

        if (!forceRefresh && notifications && notifications.length) {
          queryConstraints.push(
            startAfter(notifications[notifications.length - 1].createdAt)
          );
        }

        queryConstraints.push(limit(20));
        const queryRes = await getDocs(
          query(
            collection(db, 'users', loggedInUserId, 'notifications'),
            ...queryConstraints
          )
        );

        if (!queryRes.docs.length || queryRes.docs.length < 20) {
          setReachedEndOfNotifications(true);
        } else {
          setReachedEndOfNotifications(false);
        }
        const notificationsToSet = [
          ...(forceRefresh ? [] : notifications || []),
          ...queryRes.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                id: doc.id,
              } as Notification)
          ),
        ];
        setNotifications(notificationsToSet);
        if (manualClick) {
          toast.success('Notifications refreshed');
        }
      } catch (err) {
        handleCatchError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [loggedInUserId, notifications]
  );

  useEffect(() => {
    if (loggedInUserId && !notifications) {
      fetchNotifications(true, false);
    }
  }, [fetchNotifications, loggedInUserId, notifications]);

  const triggerNotification = useCallback(
    async (
      actorUserId: string,
      subjectUserId: string,
      notificationEventName: NotificationEventName
    ) => {
      try {
        const apiCall = httpsCallable(functions, 'triggerNotification');
        await apiCall({ actorUserId, subjectUserId, notificationEventName });
      } catch (err) {
        handleCatchError(err);
      }
    },
    []
  );

  const handleAllow = useCallback(async () => {
    try {
      logEvent(analytics, 'push_notification_allowed');
      const data: PushNotificationLocalStorageData = {
        lastShown: new Date().toISOString(),
        response: 'granted',
      };
      localStorage.setItem(
        PushNotificationsLocalStorageKey,
        JSON.stringify(data)
      );

      const permStatus = await PushNotifications.requestPermissions();

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }
      setPermissionStatus(permStatus);
    } catch (err) {
      handleCatchError(err);
    }
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        triggerNotification,
        markRead,
        fetchNotifications,
        notifications,
        isLoading,
        setNotifications,
        reachedEndOfNotifications,
        permissionStatus,
        setPermissionStatus,
        handleAllow,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
