import { Redirect, Route } from 'react-router';

import { AffiliateDisclosure } from '../pages/AffiliateDisclosure';
import { AmazonAdminPanel } from '../pages/AmazonAdminPanel';
import Authentication from '../pages/Authentication';
import BirthdaySMSReminders from '../pages/BirthdaySMSReminders';
import { Friends } from '../pages/Friends';
import Home from '../pages/Home';
import { NotificationsPage } from '../pages/Notifications';
import OpenInApp from '../pages/OpenInApp';
import { PrivacyPolicy } from '../pages/PrivacyPolicy';
import React from 'react';
import { Search } from '../pages/Search';
import Settings from '../pages/Settings';
import { TermsOfUse } from '../pages/TermsOfUse';
import { UserProfile } from '../pages/UserProfile';
import { UsersFriends } from '../pages/UsersFriends';

export const PublicRoutes: JSX.Element[] = [
  <Route key='open-in-app' path='/open-in-app'>
    <OpenInApp />
  </Route>,
  <Route key='home' path='/home'>
    <Home />
  </Route>,
  <Route key='privacy-policy' path='/privacy-policy'>
    <PrivacyPolicy />
  </Route>,
  <Route key='notifications' path='/notifications'>
    <NotificationsPage />
  </Route>,
  <Route key='terms-of-use' path='/terms-of-use'>
    <TermsOfUse />
  </Route>,
  <Route key='affiliate-disclosure' path='/affiliate-disclosure'>
    <AffiliateDisclosure />
  </Route>,
  <Route key='auth' exact strict path='/auth'>
    <Authentication />
  </Route>,

  <Route key='search' exact strict path='/search'>
    <Search />
  </Route>,
  <Route
    key='users'
    exact
    strict
    path={['/user/:userId', '/user/:userId/wishlists/:wishlistId']}
  >
    <UserProfile />
  </Route>,
  <Route key='friends' exact strict path='/friends'>
    <Friends />
  </Route>,
  <Route key='user-friends' exact strict path='/user/:userId/followers'>
    <UsersFriends />
  </Route>,
  <Route key='settings' exact strict path='/settings'>
    <Settings />
  </Route>,
  <Route key='empty-profile' exact strict path='/empty-profile'>
    <UserProfile isNonAuthedProfile />
  </Route>,
  <Redirect key='redirect-to-home' exact strict from='/' to='/home' />,
];

export const PrivateRoutes: JSX.Element[] = [
  <Route key='amazon-admin-panel' exact strict path='/amazon-admin-panel'>
    <AmazonAdminPanel />
  </Route>,
  <Route
    key='birthday-sms-reminders'
    exact
    strict
    path='/birthday-sms-reminders'
  >
    <BirthdaySMSReminders />
  </Route>,

  <Redirect key='redirect-to-friends' exact strict from='/' to='/friends' />,
];
