import { Button } from './BaseUI/Button';
import React from 'react';
import { add } from 'ionicons/icons';
import { styled } from 'goober';

interface Props {
  isLoggedInUsersProfile: boolean;
  onClick?: () => void;
}

const AddItemButton = styled(Button as any)`
  flex-direction: column;
  height: 244px;
  margin: 8px;
  ion-icon {
    font-size: 32px;
    opacity: 0.5;
    margin-bottom: 10px;
  }
  .btn-text {
    font-weight: 700;
    font-size: 17px;
    opacity: 0.5;
  }
`;

export const AddWishlistItemButton: React.FC<Props> = ({
  isLoggedInUsersProfile,
  onClick,
}) => {
  return (
    <AddItemButton color='grey' iconLeft={add} onClick={onClick}>
      {isLoggedInUsersProfile ? 'Add Item' : 'Suggest Item'}
    </AddItemButton>
  );
};
