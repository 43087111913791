import React, { useState } from 'react';
import { ellipsisHorizontalSharp, settingsOutline } from 'ionicons/icons';

import { EditNameButton } from './EditNameButton';
import { EditProfileColorButton } from './EditProfileColorButton';
import { IonPopover } from '@ionic/react';
import { ProfileActionButton } from './ProfileActionButtons';
import { ProfileAvatar } from '../ProfileAvatar';
import { ReportUserButton } from './ReportUserButton';
import { SettingsButton } from './SettingsButton';
import { ShareProfileButton } from './ShareProfileButton';
import { ToggleBlockUserButtons } from './ToggleBlockUserButtons';
import { ToggleFriendButton } from './ToggleFriendButton';
import { User } from '../../types/GlobalTypes';
import { ViewFriendsButton } from './ViewFriendsButton';
import { styled } from 'goober';
import { useProfilePageContext } from '../../contexts/ProfilePageContext';

interface Props {
  user?: User;
  profileColorValue: string;
  setProfileColorValue: React.Dispatch<React.SetStateAction<string>>;
}

const ProfileHeaderContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 36px;
  @media (max-width: 850px) {
    padding: 0px 20px;
  }
`;

const ProfileActionButtonsEndContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-right: -4px;
  @media (max-width: 468px) {
    margin-right: -6px;
  }
  @media (max-width: 368px) {
    margin-right: -8px;
  }
`;

const AddFriendButtonContainer = styled('div')`
  margin-left: 10px;
  margin-right: 8px;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  button {
    padding: 9px 10px;
  }
  .btn-icon {
    font-size: 14px;
  }
`;

export const ProfileAvatarAndActions: React.FC<Props> = ({
  user,
  profileColorValue,
  setProfileColorValue,
}) => {
  const { isLoggedInUsersProfile, isNonAuthedProfile } =
    useProfilePageContext();
  const [profileMoreOptionsEvent, setProfileMoreOptionsEvent] = useState<any>();

  const handleOpenProfileMoreOptions = (e: any) => {
    e.persist();
    setProfileMoreOptionsEvent(e);
  };

  const handleProfileMoreOptionsPopoverDismiss = () => {
    setProfileMoreOptionsEvent(undefined);
  };

  return (
    <ProfileHeaderContainer>
      <ProfileAvatar
        image={user?.avatarImg}
        isOnLoggedInUsersProfile={isLoggedInUsersProfile}
      />

      {((user && user.id) || isNonAuthedProfile) && (
        <>
          {user && user.id && (
            <AddFriendButtonContainer>
              <ToggleFriendButton showOnly='follow' user={user} />
            </AddFriendButtonContainer>
          )}

          <ProfileActionButtonsEndContainer>
            {user && user.id && <ShareProfileButton user={user} />}
            <IonPopover
              showBackdrop={true}
              mode='ios'
              event={profileMoreOptionsEvent}
              isOpen={profileMoreOptionsEvent ? true : false}
              onDidDismiss={handleProfileMoreOptionsPopoverDismiss}
            >
              <EditProfileColorButton
                user={user}
                profileColorValue={profileColorValue}
                setProfileColorValue={setProfileColorValue}
              />
              <EditNameButton
                onClick={handleProfileMoreOptionsPopoverDismiss}
                user={user}
              />
              {user && user.id && (
                <>
                  <ViewFriendsButton
                    onClick={handleProfileMoreOptionsPopoverDismiss}
                    user={user}
                  />
                  <ToggleFriendButton
                    onClick={handleProfileMoreOptionsPopoverDismiss}
                    showOnly='unfollow'
                    user={user}
                  />
                  <ToggleBlockUserButtons
                    onClick={handleProfileMoreOptionsPopoverDismiss}
                    user={user}
                  />
                  <ReportUserButton
                    onClick={handleProfileMoreOptionsPopoverDismiss}
                    user={user}
                  />
                </>
              )}
              <SettingsButton
                onClick={handleProfileMoreOptionsPopoverDismiss}
              />
            </IonPopover>
            <ProfileActionButton
              iconLeft={
                isLoggedInUsersProfile
                  ? settingsOutline
                  : ellipsisHorizontalSharp
              }
              color='grey'
              onClick={handleOpenProfileMoreOptions}
            />
          </ProfileActionButtonsEndContainer>
        </>
      )}
    </ProfileHeaderContainer>
  );
};
