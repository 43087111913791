import { AnimatePresence, motion } from 'framer-motion';
import { FeedbackModal, FeedbackType } from '../modals/FeedbackModal';
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithCredential,
  signInWithRedirect,
  updateProfile,
} from 'firebase/auth';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonPage,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { PrivateUserData, User } from '../types/GlobalTypes';
import React, { useEffect, useRef, useState } from 'react';
import { analytics, auth, db } from '../firebase';
import { call, chevronBack, chevronBackOutline } from 'ionicons/icons';
import { doc, setDoc } from 'firebase/firestore';

import AppleLogo from '../assets/imgs/AppleIcon.svg';
import { Button } from '../components/BaseUI/Button';
import { Capacitor } from '@capacitor/core';
import { Emoji } from '../components/Emoji';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import GoogleLogo from '../assets/imgs/GoogleLogo.svg';
import { Helmet } from 'react-helmet-async';
import { Keyboard } from '@capacitor/keyboard';
import LogoAndTitle from '../components/LogoAndTitle';
import { apiFunctionFetch } from '../utils/apiFunctionFetch';
import { customPageAnimationHorizontally } from '../utils/pageTransition';
import { handleCatchError } from '../utils/handleCatchError';
import { handleOpenTidioChat } from '../hooks/useTidioControls';
import { logEvent } from 'firebase/analytics';
import packageJson from '../../package.json';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { useAuth } from '../contexts/AuthenticationContext';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';
import { useMaskito } from '@maskito/react';
import { useRoutedAppContext } from '../navigation/RoutedAppContext';

declare const rdt: any;

const StyledIonContent = styled(IonContent, React.forwardRef)`
  --background: url('/assets/images/BackgroundSwirls.webp') center center /
    170px;
`;

const DetailsContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  @media (min-width: 769px) {
    flex: 1;
    position: relative;
  }
`;

const Presents = styled('img')`
  width: 100%;
  max-width: min(80vh, 900px);
  display: flex;
  margin-top: 25vh;
  position: relative;
  @media (min-width: 769px) {
    margin-top: 0;
  }
`;

const StyledIonInput = styled(IonInput, React.forwardRef)``;

const AuthCard = styled('div')`
  border-radius: 12px;
  box-shadow: var(--box-shadow-lg);
  background: rgba(255, 255, 255, 0.91);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  padding: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 480px;
  width: 350px;
  button {
    width: 100%;
  }
  input {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  form {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  @media (max-width: 800px) {
    min-height: 280px;
    input {
      background: white;
    }
  }

  @media (max-width: 700px) {
    padding: 16px;
  }
`;

const VerifyNewAccountButton = styled(Button as any)`
  margin-top: 12px;
`;

const SubmitButtonContainer = styled(motion.div)`
  width: 100%;
  button {
    width: 100%;
  }
`;

const CardTitle = styled('h2')`
  font-size: 18px;
  font-weight: 700;
  padding: 10% 0;
  text-transform: none;
  text-align: center;
  font-family: 'Nunito', sans-serif;
  img {
    margin-bottom: -2px;
  }
`;

const BackButton = styled(Button as any)`
  width: auto !important;
  align-self: flex-start;
  margin-left: -10px;
  font-weight: 700;
  margin-bottom: 16px;
  .btn-icon {
    padding-right: 0px;
    margin-top: -2px;
    margin-left: -6px;
  }

  &.header-back-button {
    margin-left: 0px;
    margin-bottom: 0px;
    align-self: center;
  }
`;

const AppleLogoImage = styled('img')`
  width: 20px;
  margin-bottom: -3px;
  margin-top: -6px;
  margin-right: 12px;
`;

const GoogleLogoImage = styled('img')`
  width: 20px;
  margin-bottom: -5px;
  margin-top: -2px;
  margin-right: 12px;
`;

const AuthCardContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  position: relative;
  margin-right: 0px;
  width: 100%;
  @media (max-width: 768px) {
    flex: 1;
  }
  @media (min-width: 769px) {
    margin-right: 30px;
    width: 320px;
  }
  @media (min-width: 899px) {
    width: 380px;
  }
  @media (min-width: 999px) {
    margin-right: 100px;
    width: 450px;
  }
`;

const NeedHelpButton = styled(Button as any)`
  .btn-text {
    font-size: 12px;
  }
  margin-top: 10px;
`;

const AbsoluteContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  width: 100%;
  padding-bottom: 10vh;
  @media (min-width: 769px) {
    padding-bottom: 0;
  }
`;

const LayoutContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1300px;
  margin: auto;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 48px;
  padding-top: 140px;
  @media (min-width: 769px) {
    padding-top: 48px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    min-height: 100%;
  }

  @media (max-width: 360px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }
`;

const OrText = styled('p')`
  text-align: center;
  margin-bottom: 18px;
`;

const AuthProviderButton = styled(Button as any)`
  margin: 5px;
`;

const DisclaimerText = styled('div')`
  flex: 1;
  display: flex;
  align-items: flex-end;
  padding-top: 8px;
  p {
    color: var(--ion-color-medium);
    font-size: 12px;
  }
`;

const TermsLink = styled('a')`
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
  &:hover,
  :active {
    opacity: 0.5;
  }
`;

type AuthStages =
  | 'method-prompt'
  | 'verify-sms-code'
  | 'verify-new-account'
  | 'name-input';

type CustomMotionState = {
  initialGoBack: boolean;
  exitGoBack: boolean;
};

const basicMotion = {
  initial: ({ initialGoBack }: CustomMotionState) => ({
    opacity: 0,
    x: initialGoBack ? -136 : 136,
    transition: { type: 'tween' },
  }),
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.4,
      type: 'spring',
    },
  },
  exit: ({ exitGoBack }: CustomMotionState) => ({
    opacity: 0,
    x: exitGoBack ? 136 : -136,
    transition: { type: 'tween' },
  }),
};

const Authentication: React.FC = () => {
  const [stage, setStage] = useState<AuthStages>('method-prompt');
  const [feedbackType, setFeedbackType] = useState<FeedbackType | undefined>();

  const [phoneNumber, setPhoneNumber] = useState('');

  const [firstName, setFirstName] = useState('');

  const [lastName, setLastName] = useState('');

  const [smsCode, setSmsCode] = useState('');

  const ionRouter = useIonRouter();

  const { hasAddedPrivateRoutes } = useRoutedAppContext();

  const [isLoading, setIsLoading] = useState<
    'sms' | 'google' | 'apple' | true | false
  >(false);
  const [animationDirection, setAnimationDirection] = useState<
    'forward' | 'backward'
  >('forward');

  const {
    sendSMSCode,
    logoutUser,
    authHasLoadedAndLoggedInUserLoaded,
    verifySMSCode,
    loggedInUserId,
  } = useAuth();

  const { loggedInUser } = useLoggedInUser();

  const ionContentRef = useRef<any>();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardWillShow', () => {
        ionContentRef.current?.scrollToBottom(500);
      });

      Keyboard.addListener('keyboardWillHide', () => {
        ionContentRef.current?.scrollToTop(200);
      });
    }

    return () => {
      if (Capacitor.isNativePlatform()) {
        Keyboard.removeAllListeners();
      }
    };
  }, []);

  const inputRef = useRef<HTMLIonInputElement | null>(null);

  useEffect(() => {
    if (inputRef?.current?.setFocus) {
      setTimeout(() => {
        inputRef?.current?.setFocus();
      }, 300);
    }
  }, [stage]);

  useEffect(() => {
    if (
      ionRouter.routeInfo.pathname === '/auth' &&
      hasAddedPrivateRoutes &&
      authHasLoadedAndLoggedInUserLoaded &&
      loggedInUser?.first_name &&
      loggedInUser?.last_name &&
      loggedInUserId
    ) {
      resetState();
      ionRouter.push(`/user/${loggedInUserId}`, 'root', 'push');
      setIsLoading(false);
    }
  }, [
    authHasLoadedAndLoggedInUserLoaded,
    ionRouter,
    loggedInUserId,
    loggedInUser,
    hasAddedPrivateRoutes,
  ]);

  const _sendSMSCode = async (e: any) => {
    try {
      e.preventDefault();
      await logoutUser();
      setIsLoading('sms');
      setAnimationDirection('forward');

      const phoneValidationRegEx = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

      const reformattedPhoneNumber = phoneNumber
        .replace('+1 (', '')
        .replace(') ', '-');

      if (!phoneValidationRegEx.test(reformattedPhoneNumber))
        throw new Error('Invalid Number');

      const successfulSend = await sendSMSCode(
        String('+1' + reformattedPhoneNumber)
      );
      if (successfulSend) {
        setStage('verify-sms-code');
      }
    } catch (err) {
      handleCatchError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const _verifySMSCode = async (e: any) => {
    try {
      e.preventDefault();
      setAnimationDirection('forward');
      if (smsCode.length !== 6) {
        throw new Error('Invalid Code');
      }
      setIsLoading(true);
      setAnimationDirection('forward');

      await verifySMSCode(smsCode);
    } catch (err) {
      handleCatchError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const resetState = () => {
    setStage('method-prompt');
    setPhoneNumber('');
    setFirstName('');
    setLastName('');
    setSmsCode('');
    setIsLoading(false);
  };

  const saveUser = async (e: any | undefined) => {
    try {
      e?.preventDefault();
      setAnimationDirection('forward');
      setIsLoading(true);
      if (firstName.trim().length < 2) {
        throw new Error('Must be longer');
      }

      if (lastName.trim().length < 2) {
        throw new Error('Must be longer');
      }

      if (!loggedInUserId) throw new Error('No User ID - AUTH ERROR 4');

      localStorage.setItem('ifancy-nuc', new Date().toISOString());

      const newUserForDatabase: User = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        friends: [],
      };

      const privateUserData: PrivateUserData = {
        ...(auth.currentUser?.email && { email: auth.currentUser?.email }),
        ...(auth.currentUser?.phoneNumber && {
          phone_number: auth.currentUser?.phoneNumber,
        }),
      };

      try {
        logEvent(analytics, 'sign_up');
        rdt('track', 'SignUp');
      } catch (err) {
        console.error(err);
      }

      await setDoc(doc(db, `users/${loggedInUserId}`), newUserForDatabase, {
        merge: true,
      });

      await setDoc(
        doc(db, `users-private/${loggedInUserId}`),
        privateUserData,
        {
          merge: true,
        }
      );

      toast.success('Account Updated!');

      resetState();
      if (ionRouter.routeInfo.pathname === '/auth' && hasAddedPrivateRoutes) {
        ionRouter.push(`/user/${loggedInUserId}`, 'none', 'replace');
      } else {
        console.error(
          "Couldn't navigate to user's profile - Auth Setup Save User Error 2"
        );
      }
    } catch (err) {
      handleCatchError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (stage === 'name-input') {
      setFirstName(
        auth.currentUser?.displayName &&
          auth.currentUser?.displayName?.split(' ').length > 0
          ? auth.currentUser.displayName.split(' ')[0]
          : ''
      );
      setLastName(
        auth.currentUser?.displayName &&
          auth.currentUser?.displayName?.split(' ').length > 1
          ? auth.currentUser.displayName.split(' ')[1]
          : ''
      );
    }
  }, [stage]);

  useEffect(() => {
    if (loggedInUser && (!loggedInUser.first_name || !loggedInUser.last_name)) {
      setStage('verify-new-account');
    }
  }, [loggedInUser]);

  const handleNeedHelp = async () => {
    try {
      handleOpenTidioChat('auth-help');
    } catch (error) {
      console.error('Error initializing chat:', error);
      setFeedbackType('general');
    }
  };

  const signInWithApple = async () => {
    try {
      await logoutUser();
      if (Capacitor?.isNativePlatform()) {
        setIsLoading('apple');
        const result = await FirebaseAuthentication.signInWithApple({
          skipNativeAuth: true,
        });

        const user = result?.user;

        const firstTimeAppleDisplayName = user?.displayName;

        const provider = new OAuthProvider('apple.com');
        const credential = provider.credential({
          idToken: result.credential?.idToken,
          rawNonce: result.credential?.nonce,
        });
        const credentialFinal = await signInWithCredential(auth, credential);

        const uid = credentialFinal.user?.uid;

        if (!uid) {
          throw new Error('No User ID');
        }

        if (firstTimeAppleDisplayName) {
          await updateProfile(credentialFinal.user, {
            displayName: firstTimeAppleDisplayName,
          });
        }
      } else {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('name');
        provider.addScope('email');
        signInWithRedirect(auth, provider);
      }
    } catch (err) {
      if (
        err &&
        !(err as any).errorMessage?.includes('Code=1001') &&
        !(err as any).errorMessage?.includes('error 1001')
      ) {
        handleCatchError(err);
      }
      setIsLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    try {
      await logoutUser();
      if (Capacitor?.isNativePlatform()) {
        setIsLoading('google');

        const result = await FirebaseAuthentication.signInWithGoogle();

        const credential = GoogleAuthProvider.credential(
          result.credential?.idToken
        );

        const auth = getAuth();
        await signInWithCredential(auth, credential);
      } else {
        const provider = new GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        await signInWithRedirect(auth, provider);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleBack = async () => {
    setAnimationDirection('backward');
    if (stage === 'verify-sms-code') {
      setStage('method-prompt');
    }

    if (stage === 'name-input') {
      setStage('verify-new-account');
    }

    if (stage === 'verify-new-account') {
      try {
        setIsLoading(true);
        apiFunctionFetch('requestDeleteAccountFromAccidentalLogin', {
          userId: loggedInUserId,
        });
        await logoutUser();
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
        setStage('method-prompt');
      }
    }
  };

  const goToTermsOfUse = () => {
    ionRouter.push(
      `/terms-of-use`,
      'forward',
      'push',
      {},
      customPageAnimationHorizontally
    );
  };

  const goToPrivacyPolicy = () => {
    ionRouter.push(
      `/privacy-policy`,
      'forward',
      'push',
      {},
      customPageAnimationHorizontally
    );
  };

  const phoneMask = useMaskito({
    options: {
      mask: [
        '+',
        '1',
        ' ',
        '(',
        /\d/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
    },
  });

  const goBack = () => {
    if (stage === 'method-prompt') {
      if (ionRouter.canGoBack()) {
        ionRouter.goBack(customPageAnimationHorizontally);
      } else {
        ionRouter.push(
          `/`,
          'forward',
          'push',
          {},
          customPageAnimationHorizontally
        );
      }
    } else {
      handleBack();
    }
  };

  return (
    <IonPage>
      <Helmet prioritizeSeoTags>
        <title>iFancy | Login Or Create An Account</title>
        <meta
          property='twitter:title'
          content={'iFancy | Login Or Create An Account'}
        />

        <meta
          property='og:title'
          content={'iFancy | Login Or Create An Account'}
        />
      </Helmet>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <BackButton
              color='dark'
              clear
              iconLeft={chevronBack}
              onClick={goBack}
              className='header-back-button'
            >
              Back
            </BackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <StyledIonContent ref={ionContentRef} fullscreen={true}>
        <LayoutContainer>
          <DetailsContainer>
            <LogoAndTitle />

            <Presents src={'assets/images/Presents.png'} alt='Presents' />
          </DetailsContainer>

          <AuthCardContainer>
            <AnimatePresence>
              {stage === 'method-prompt' && (
                <AbsoluteContainer
                  custom={{
                    initialGoBack: animationDirection === 'backward',
                    exitGoBack: false,
                  }}
                  initial='initial'
                  animate='animate'
                  exit='exit'
                  variants={basicMotion}
                  key={'method-prompt-auth-card'}
                >
                  <AuthCard>
                    <CardTitle>
                      <Emoji emoji=':lock:' size={19} /> Login/Sign up
                    </CardTitle>
                    <form onSubmit={_sendSMSCode}>
                      <StyledIonInput
                        autocomplete='tel'
                        name='tel'
                        type='tel'
                        onIonInput={(e) =>
                          setPhoneNumber(String(e.target.value || ''))
                        }
                        value={phoneNumber}
                        ref={async (phoneInput) => {
                          inputRef.current = phoneInput;
                          if (phoneInput) {
                            const input = await phoneInput.getInputElement();
                            phoneMask(input);
                          }
                        }}
                        placeholder='+1 (xxx) xxx-xxxx'
                      >
                        <IonIcon slot='start' icon={call} aria-hidden='true' />
                      </StyledIonInput>
                      {phoneNumber?.length ? (
                        <SubmitButtonContainer
                          key='send-sms-code-button'
                          initial={{ opacity: 0, y: -10 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: {
                              type: 'spring',
                            },
                          }}
                          exit={{
                            opacity: 0,
                            y: -10,
                            transition: { duration: 0.95 },
                          }}
                          transition={{ duration: 0.4, delay: 0 }}
                        >
                          <Button
                            type='submit'
                            isLoading={isLoading === 'sms'}
                            disabled={isLoading === 'sms'}
                            color='primary'
                          >
                            Send Code
                          </Button>
                        </SubmitButtonContainer>
                      ) : null}
                    </form>

                    <OrText>Or</OrText>
                    <AuthProviderButton
                      isLoading={isLoading === 'google'}
                      disabled={isLoading === 'google'}
                      onClick={signInWithGoogle}
                      color='dark'
                    >
                      <GoogleLogoImage src={GoogleLogo} alt='Google Logo' />
                      Sign In With Google
                    </AuthProviderButton>
                    <AuthProviderButton
                      isLoading={isLoading === 'apple'}
                      disabled={isLoading === 'apple'}
                      onClick={signInWithApple}
                      color='dark'
                    >
                      <AppleLogoImage src={AppleLogo} alt='Apple Logo' />
                      Sign In With Apple
                    </AuthProviderButton>
                    <DisclaimerText>
                      <p>
                        By creating an account and using iFancy, you agree to
                        our{' '}
                        <TermsLink onClick={goToTermsOfUse}>
                          Terms of Use
                        </TermsLink>{' '}
                        and{' '}
                        <TermsLink onClick={goToPrivacyPolicy}>
                          Privacy Policy
                        </TermsLink>{' '}
                        App Version: {packageJson.version}
                      </p>
                    </DisclaimerText>
                    <NeedHelpButton
                      small
                      color='medium'
                      clear
                      onClick={handleNeedHelp}
                    >
                      Need Help?
                    </NeedHelpButton>
                  </AuthCard>
                </AbsoluteContainer>
              )}
              {stage === 'verify-sms-code' && (
                <AbsoluteContainer
                  custom={{
                    initialGoBack: animationDirection === 'backward',
                    exitGoBack: animationDirection === 'forward',
                  }}
                  initial='initial'
                  animate='animate'
                  exit='exit'
                  variants={basicMotion}
                  key={'verify-sms-auth-card'}
                >
                  <AuthCard>
                    <BackButton
                      onClick={handleBack}
                      clear
                      color='dark'
                      iconLeft={chevronBackOutline}
                    >
                      Back
                    </BackButton>
                    <p>A one-time code has been sent to your phone number.</p>
                    <form onSubmit={_verifySMSCode}>
                      <StyledIonInput
                        autocomplete='one-time-code'
                        name='one-time-code'
                        ref={inputRef}
                        onIonInput={(e) =>
                          setSmsCode(String(e.target.value || ''))
                        }
                        type='tel'
                        value={smsCode}
                        placeholder='Enter Verification Code'
                      />
                      {smsCode?.length ? (
                        <SubmitButtonContainer
                          key='verify-sms-code-button'
                          initial={{ opacity: 0, y: -10 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: {
                              type: 'spring',
                            },
                          }}
                          exit={{
                            opacity: 0,
                            y: -10,
                            transition: { duration: 0.95 },
                          }}
                          transition={{ duration: 0.4, delay: 0 }}
                        >
                          <Button
                            isLoading={Boolean(isLoading)}
                            disabled={Boolean(isLoading)}
                            type='submit'
                            color='primary'
                          >
                            Verify Code
                          </Button>
                        </SubmitButtonContainer>
                      ) : null}
                    </form>
                  </AuthCard>
                </AbsoluteContainer>
              )}
              {stage === 'name-input' && (
                <AbsoluteContainer
                  custom={{
                    initialGoBack: animationDirection === 'backward',
                    exitGoBack: animationDirection === 'forward',
                  }}
                  initial='initial'
                  animate='animate'
                  exit='exit'
                  variants={basicMotion}
                  key={'name-input'}
                >
                  <AuthCard>
                    <BackButton
                      onClick={handleBack}
                      clear
                      color='dark'
                      iconLeft={chevronBackOutline}
                    >
                      Back
                    </BackButton>
                    <p>New Account Details</p>
                    <form onSubmit={saveUser}>
                      <StyledIonInput
                        autocomplete='given-name'
                        name='fname'
                        onIonInput={(e) =>
                          setFirstName(String(e.target.value || ''))
                        }
                        value={firstName}
                        placeholder='First Name'
                      />
                      <StyledIonInput
                        autocomplete='family-name'
                        name='lname'
                        onIonInput={(e) =>
                          setLastName(String(e.target.value || ''))
                        }
                        value={lastName}
                        placeholder='Last Name'
                      />
                      {firstName?.length || lastName?.length ? (
                        <SubmitButtonContainer
                          key='submit-name-button'
                          initial={{ opacity: 0, y: -10 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: {
                              type: 'spring',
                            },
                          }}
                          exit={{
                            opacity: 0,
                            y: -10,
                            transition: { duration: 0.95 },
                          }}
                          transition={{ duration: 0.4, delay: 0 }}
                        >
                          <Button
                            isLoading={Boolean(isLoading)}
                            disabled={Boolean(isLoading)}
                            type='submit'
                            color='primary'
                          >
                            Save
                          </Button>
                        </SubmitButtonContainer>
                      ) : null}
                    </form>
                  </AuthCard>
                </AbsoluteContainer>
              )}
              {stage === 'verify-new-account' && (
                <AbsoluteContainer
                  custom={{
                    initialGoBack: animationDirection === 'backward',
                    exitGoBack: animationDirection === 'forward',
                  }}
                  initial='initial'
                  animate='animate'
                  exit='exit'
                  variants={basicMotion}
                  key={'verify-new-account'}
                >
                  <AuthCard>
                    <p>Looks like you are trying to create a new account?</p>
                    <VerifyNewAccountButton
                      color='light'
                      onClick={() => setStage('name-input')}
                    >
                      Yes, Continue.
                    </VerifyNewAccountButton>
                    <VerifyNewAccountButton color='light' onClick={handleBack}>
                      No, I should already have an account. Go back to try a
                      different login method.
                    </VerifyNewAccountButton>
                  </AuthCard>
                </AbsoluteContainer>
              )}
            </AnimatePresence>
          </AuthCardContainer>
        </LayoutContainer>
      </StyledIonContent>
      <FeedbackModal
        isOpen={feedbackType ? true : false}
        initFeedbackType={feedbackType}
        onDidDismiss={() => setFeedbackType(undefined)}
      />
    </IonPage>
  );
};

export default Authentication;
