import { UserWishlist, WishlistConfig } from '../types/GlobalTypes';
import { useEffect, useState } from 'react';

import { wishlistCollectionItemsObservable } from '../data-fetching/wishlistCollectionItemsObservable';

type UseGetWishlistItemsProps = {
  userId: string | undefined;
  wishlist: WishlistConfig | undefined;
  isThisAPrivateProfileAndHidden: boolean;
  isLoggedInUserBlockedByUser: boolean;
  isLoggedInUsersProfile: boolean;
  isLoggedInUserFollowing: boolean;
  forceWishlistRefresh: number;
};

export function useGetWishlistItems({
  userId,
  wishlist,
  isThisAPrivateProfileAndHidden,
  isLoggedInUserBlockedByUser,
  isLoggedInUserFollowing,
  isLoggedInUsersProfile,
  forceWishlistRefresh
}: UseGetWishlistItemsProps) {
  const [wishlistItems, setWishlistItems] = useState<UserWishlist>();


  useEffect(() => {
    setWishlistItems(undefined);
  }, [userId]);

  useEffect(() => {
    // skip if trying to get OGIMAGE screenshot so that the products look to be always loading
    const showFakeProducts = new URLSearchParams(window?.location?.search).has(
      'ogimage'
    );
    if (showFakeProducts) return;

    let unsubscribeFromWishlistSnapshotWatch: () => void;
    if (
      wishlist &&
      userId &&
      !isThisAPrivateProfileAndHidden &&
      !isLoggedInUserBlockedByUser
    ) {

      // Now check for wishlist privacy settings
      if (
        isLoggedInUsersProfile ||
        wishlist.visibility === 'public' ||
        (wishlist.visibility === 'friends-only' &&
          isLoggedInUserFollowing)
      ) {
        unsubscribeFromWishlistSnapshotWatch =
          wishlistCollectionItemsObservable(
            userId,
            wishlist,
            setWishlistItems
          );
      }
    }


    return () => {
      unsubscribeFromWishlistSnapshotWatch &&
        unsubscribeFromWishlistSnapshotWatch();
    };
  }, [
    forceWishlistRefresh,
    isLoggedInUsersProfile,
    wishlist,
    userId,
    isLoggedInUserBlockedByUser,
    isThisAPrivateProfileAndHidden,
    isLoggedInUserFollowing,
  ]);

  return wishlistItems;
}
