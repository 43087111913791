import React, { useEffect } from 'react';

import { Capacitor } from '@capacitor/core';
import { RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../firebase';

declare const window: any;

export const RecaptchaElement: React.FC = () => {
  const setupRecaptchaContainer = () => {
    if (!Capacitor.isNativePlatform()) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        'reCaptcha-container',
        {
          size: 'invisible',
        }
      );

      window.recaptchaVerifier.render().then(function (widgetId: any) {
        window.recaptchaWidgetId = widgetId;
      });
    }
  };

  useEffect(() => {
    if (window.recaptchaVerifier) return;
    setupRecaptchaContainer();
  }, []);

  return <div id='reCaptcha-container' />;
};
