export const homepageReviews = [
    {
        name: "Anita W",
        review: "This app makes it super easy to share my wish lists with friends and family. I love that I can create multiple lists for different occasions, and the customization options for my profile are a nice touch."
    },
    {
        name: "Sydney C",
        review: "I’ve never had an easier time organizing my wish lists! The privacy controls give me peace of mind, and I love how it doesn’t spoil surprises by notifying me when items are purchased."
    },
    {
        name: "Karen M",
        review: "The quick link fill is a game changer! The app makes it so simple to organize multiple wish lists, and I love that my friends can suggest gift ideas for me—such a helpful feature!"
    },
    {
        name: "Nicole H",
        review: "I absolutely love how easy it is to share my lists, but the best part is that I never know what’s been purchased until the big day! The surprise stays intact, which is perfect."
    },
    {
        name: "Grant G",
        review: "This wish list app has completely changed how I plan for gifts. It's user-friendly and makes organizing my ideas a breeze"
    },
    {
        name: "Ellie S",
        review: "I love that the app allows you to suggest items to people. It’s also great that my family doesn’t know what I’ve already bought, so surprises stay surprises."
    },
    {
        name: "Alan R",
        review: "The privacy settings are fantastic. I can control who sees my lists and which items. Overall the app is great and my whole family uses it"
    },
    {
        name: "Charlotte E",
        review: "It’s great being able to make different lists for birthdays, holidays, and other events. Sharing is a breeze, and the fact that purchased items are hidden is a nice feature."
    },
    {
        name: "Savannah Y",
        review: "Thanks to this wish list app, my kids have been getting gifts that are more aligned with their needs. It helps me organize their lists and share them with others, which makes gift-giving a bit more straightforward."
    },
    {
        name: "Trey D",
        review: "I’m impressed with the app’s privacy features. I feel good knowing that I can keep my wish lists private until I’m ready to share. Definitely recommend the app"
    },
    {
        name: "Megan W",
        review: "This wish list app has transformed my gift-giving experience! I can easily see what my friends and family want, making it so much easier to find the perfect gift. No more guessing or awkward surprises"
    },
    {
        name: "Andrew T",
        review: "Setting up multiple wish lists was super easy, and I like how I can customize each one for different occasions. Sharing with family and friends is seamless too!"
    },
    {
        name: "Annabeth H",
        review: "This wish list app has made it easier to ensure my kids receive gifts they actually need. I can share their lists with family, which helps avoid duplicates or items they won’t use"
    },
    {
        name: "Lisa P",
        review: "Using this wish list app has made gift-giving a breeze! I love being able to see my friends' and family's preferences all in one place. It’s so convenient and helps me choose gifts that I know they'll love!"
    }
];
