import { useEffect, useState } from 'react';

import { WishlistConfig } from '../types/GlobalTypes';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';
import { wishlistCollectionObservable } from '../data-fetching/wishlistCollectionObservable';

export function useGetWishlists({ userId, isLoggedInUsersProfile }: { userId: string | undefined; isLoggedInUsersProfile: boolean; }) {
  const [wishlists, setWishlists] = useState<WishlistConfig[]>([]);
  const { loggedInUserWishlists, setLoggedInUserWishlists } = useLoggedInUser();


  useEffect(() => {
    if (Boolean(setLoggedInUserWishlists) && isLoggedInUsersProfile) return;
    let unsubscribeFromWishlistsCollectionSnapshotWatch: () => void;

    if (!userId) {
      setWishlists([])
    } else {
      unsubscribeFromWishlistsCollectionSnapshotWatch =
        wishlistCollectionObservable(userId, isLoggedInUsersProfile, setWishlists);
    }

    return () => {
      unsubscribeFromWishlistsCollectionSnapshotWatch &&
        unsubscribeFromWishlistsCollectionSnapshotWatch();
    };
  }, [
    userId, isLoggedInUsersProfile, setLoggedInUserWishlists
  ]);

  return { wishlists: isLoggedInUsersProfile && loggedInUserWishlists ? loggedInUserWishlists : wishlists, setWishlists: isLoggedInUsersProfile && loggedInUserWishlists ? setLoggedInUserWishlists : setWishlists };
}
