import { IonIcon, IonProgressBar } from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { addSeconds, differenceInDays } from 'date-fns';
import { checkmarkCircle, radioButtonOffOutline } from 'ionicons/icons';

import { Button } from './BaseUI/Button';
import { ConfettiContext } from '../contexts/ConfettiContext';
import { DEFAULT_PROFILE_COLOR } from '../constants/constants';
import { Emoji } from './Emoji';
import { ProgressBar } from './BaseUI/ProgressBar';
import { Timestamp } from 'firebase/firestore';
import { User } from '../types/GlobalTypes';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';
import { useProfilePageContext } from '../contexts/ProfilePageContext';

const Container = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: calc(100% - 40px);
  padding: 20px;
  margin: 20px;
  padding-top: 10px;
  background: #eef9ff;
  border: 2px dashed #acf4ff;
  border-radius: 10px;
  max-width: 600px;
  @media (max-width: 460px) {
    width: calc(100% - 24px);
    margin: 20px 12px;
    padding: 14px;
  }
`;

const StyledH4 = styled('h4')`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
`;

const ChecklistText = styled('p')`
  flex-shrink: 0;
`;

const CloseButton = styled(Button as any)`
  font-weight: 700;
  margin-right: -12px;
`;

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ChecklistItem = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 400px;
  ion-progress-bar {
    border-radius: 10px;
    overflow: hidden;
    margin-top: 2px;
    margin-left: 8px;
  }
  ion-icon {
    flex-shrink: 0;
    font-size: 22px;
    margin-right: 6px;
    margin-top: 1px;
  }
`;

interface Props {
  profileColor: string;
}

const MinimumItemsForCompleteProfile = 3;
const DaysAlertWillBeHidden = 3;
const localStorageKey = 'ifancy-profileSetupChecklistLastHidden';

export const ProfileSetupChecklist: React.FC<Props> = ({ profileColor }) => {
  const [show, setShow] = useState(true);
  const [initHasCompletedChecklist, setInitHasCompletedChecklist] = useState<
    boolean | undefined
  >(undefined);
  const [progressBarEndTime, setProgressBarEndTime] = useState(0);

  const { isLoggedInUsersProfile, wishlistItems } = useProfilePageContext();

  const { loggedInUser, updateLoggedInUser } = useLoggedInUser();

  const { triggerConfetti } = useContext(ConfettiContext);

  const abortRef = useRef<boolean>(false);

  const handleProgressEnd = () => {
    if (abortRef.current) return;
    setShow(false);
    setProgressBarEndTime(0);
  };

  useEffect(() => {
    abortRef.current = false;
    return () => {
      abortRef.current = true;
    };
  }, []);

  useEffect(() => {
    if (!isLoggedInUsersProfile || !wishlistItems || !show || !loggedInUser) {
      setInitHasCompletedChecklist(undefined);
      return;
    }
    if (initHasCompletedChecklist === undefined) {
      if (
        Object.keys(wishlistItems).length >= MinimumItemsForCompleteProfile &&
        DEFAULT_PROFILE_COLOR !== profileColor &&
        loggedInUser?.avatarImg
      ) {
        setInitHasCompletedChecklist(true);
        close();
      } else {
        setInitHasCompletedChecklist(false);
      }
    }
  }, [
    initHasCompletedChecklist,
    profileColor,
    show,
    wishlistItems,
    isLoggedInUsersProfile,
    loggedInUser,
  ]);

  useEffect(() => {
    if (
      initHasCompletedChecklist === false &&
      wishlistItems &&
      Object.keys(wishlistItems).length >= MinimumItemsForCompleteProfile &&
      DEFAULT_PROFILE_COLOR !== profileColor &&
      loggedInUser?.avatarImg &&
      !loggedInUser?.featureAwareness?.completedProfileSetup
    ) {
      // now we have a successful checklist completion
      toast.success(
        'Congratulations! You have completed your profile setup checklist.'
      );
      triggerConfetti([profileColor, '#2dd36f']);
      setProgressBarEndTime(new Date().getTime() + 19 * 1000);
      localStorage.setItem(
        localStorageKey,
        addSeconds(new Date(), 19).toISOString()
      );
      const featureAwareness: User['featureAwareness'] = {
        ...(loggedInUser.featureAwareness || {}),
        completedProfileSetup: Timestamp.fromDate(new Date()),
      };
      updateLoggedInUser({ featureAwareness });
    }
  }, [
    initHasCompletedChecklist,
    profileColor,
    triggerConfetti,
    wishlistItems,
    loggedInUser,
    updateLoggedInUser,
  ]);

  useEffect(() => {
    const lastClosed = localStorage.getItem(localStorageKey);

    if (lastClosed) {
      const lastClosedDate = new Date(lastClosed);
      if (lastClosedDate.getTime() > new Date().getTime()) {
        return;
      }
      if (
        Math.abs(differenceInDays(lastClosedDate, new Date())) <
        DaysAlertWillBeHidden
      ) {
        setShow(false);
      }
    }
  }, []);

  const close = () => {
    localStorage.setItem(localStorageKey, new Date().toISOString());
    setShow(false);
  };

  if (!isLoggedInUsersProfile || !wishlistItems || !show || !loggedInUser) {
    return null;
  }

  if (
    loggedInUser.featureAwareness?.completedProfileSetup &&
    loggedInUser.featureAwareness.completedProfileSetup.toDate().getTime() +
      20 * 1000 <
      new Date().getTime()
  ) {
    return null;
  }

  const needsMoreItems =
    Object.keys(wishlistItems).length < MinimumItemsForCompleteProfile;

  return (
    <>
      <Container>
        <HeaderContainer>
          <StyledH4>Profile Checklist</StyledH4>
          <CloseButton type='button' clear onClick={close} color='medium'>
            Hide
          </CloseButton>
        </HeaderContainer>
        {loggedInUser?.featureAwareness?.completedProfileSetup ? (
          <>
            <ChecklistText>
              Awesome!{' '}
              <span role='img' aria-label='party popper'>
                🎉
              </span>{' '}
              You&apos;ve completed your profile setup. Don&apos;t forget to
              routinely update your wishlist to keep it fresh!
            </ChecklistText>
            <ProgressBar
              onProgressEnd={handleProgressEnd}
              endTime={progressBarEndTime}
            />
          </>
        ) : (
          <>
            <ChecklistItem>
              <IonIcon
                color={
                  DEFAULT_PROFILE_COLOR !== profileColor ? 'success' : 'medium'
                }
                icon={
                  DEFAULT_PROFILE_COLOR === profileColor
                    ? radioButtonOffOutline
                    : checkmarkCircle
                }
              />
              <ChecklistText>
                Change your profile color{' '}
                <span role='img' aria-label='color'>
                  🎨
                </span>
              </ChecklistText>
            </ChecklistItem>
            <ChecklistItem>
              <IonIcon
                color={loggedInUser.avatarImg ? 'success' : 'medium'}
                icon={
                  !loggedInUser.avatarImg
                    ? radioButtonOffOutline
                    : checkmarkCircle
                }
              />
              <ChecklistText>
                Upload a profile image <Emoji emoji=':camera:' size={19} />
              </ChecklistText>
            </ChecklistItem>
            <ChecklistItem>
              <IonIcon
                color={!needsMoreItems ? 'success' : 'medium'}
                icon={needsMoreItems ? radioButtonOffOutline : checkmarkCircle}
              />
              <ChecklistText>
                Add at least {MinimumItemsForCompleteProfile} items
              </ChecklistText>
              <IonProgressBar
                mode='ios'
                color='primary'
                value={
                  Object.keys(wishlistItems).length /
                  MinimumItemsForCompleteProfile
                }
              />
            </ChecklistItem>
          </>
        )}
      </Container>
    </>
  );
};
