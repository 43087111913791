import { QuerySnapshot, collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { UserWishlist, WishlistItem } from '../types/GlobalTypes';

import { db } from '../firebase';

export const suggestionListCollectionObservable = (
  user_id: string,
  setFn: (value: React.SetStateAction<UserWishlist | undefined>) => void
) => {
  return onSnapshot(query(collection(db, `users/${user_id}/wishlist-suggestions`), where('deletedAt', '==', null), orderBy('createdAt', 'desc')), (snapshot) => {
    const newWishlist: UserWishlist = {};
    (snapshot as QuerySnapshot<WishlistItem>).docs.map((item) => {
      newWishlist[item.id] = { ...item.data(), id: item.id };
      return null;
    });
    setFn(newWishlist);
  });
};

