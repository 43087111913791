import { Capacitor } from '@capacitor/core';
import { Clipboard } from '@capacitor/clipboard';
import { ProfileActionButton } from './ProfileActionButtons';
import React from 'react';
import { User } from '../../types/GlobalTypes';
import { analytics } from '../../firebase';
import { handleCatchError } from '../../utils/handleCatchError';
import { logEvent } from 'firebase/analytics';
import { shareOutline } from 'ionicons/icons';
import toast from 'react-hot-toast';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';
import { useProfilePageContext } from '../../contexts/ProfilePageContext';

interface Props {
  user: User;
}

export const ShareProfileButton: React.FC<Props> = ({ user }) => {
  const { loggedInUser } = useLoggedInUser();
  const { selectedWishlist } = useProfilePageContext();

  const copyUserURLToClipBoard = async () => {
    logEvent(analytics, 'profile_shared', { userId: user.id });
    try {
      const url = `https://ifancy.app/user/${user?.id}`;
      if ('share' in navigator) {
        await (navigator as any).share({
          url,
          text:
            loggedInUser?.id && loggedInUser.id === user.id
              ? `Check out my profile on iFancy`
              : `${user?.formattedName}'s profile on iFancy`,
        });
      } else {
        if (
          Capacitor.getPlatform() !== 'web' &&
          Capacitor.isPluginAvailable('Clipboard')
        ) {
          await Clipboard.write({
            string: url,
          });
        } else {
          await window?.navigator.clipboard.writeText(url);
        }
        toast.success(
          `Profile link ${
            selectedWishlist ? `[to ${selectedWishlist.name}]` : ''
          } copied to clipboard!`
        );
      }
    } catch (err: any) {
      if (
        err?.message?.includes('Abort due to cancellation') ||
        err?.message?.includes('Share canceled') ||
        err?.message?.includes('An earlier share has not yet completed.')
      )
        return;
      handleCatchError(err);
    }
  };

  return (
    <ProfileActionButton
      color='grey'
      iconLeft={shareOutline}
      onClick={copyUserURLToClipBoard}
    ></ProfileActionButton>
  );
};
