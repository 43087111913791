import {
  IonContent,
  IonHeader,
  IonInput,
  IonModal,
  IonSpinner,
  IonTextarea,
  IonToolbar,
  getPlatforms,
} from '@ionic/react';
import {
  ModalCancelButton,
  ModalFooterButtonsContainer,
  ModalMessageContainer,
  PrimaryModalActionButton,
  StyledModalIonFooter,
  StyledModalTitle,
} from './StyledModalComponents';
import React, { useEffect, useState } from 'react';

import { Button } from '../components/BaseUI/Button';
import { Capacitor } from '@capacitor/core';
import { MdFeedback } from 'react-icons/md';
import { handleCatchError } from '../utils/handleCatchError';
import packageJson from '../../package.json';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

const AllFeedbackTypesContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 4px;
  margin-left: -8px;
`;

const StyledIonModal = styled(IonModal)`
  --width: calc(100vw - 20px);
  --max-width: 640px;
  --height: 100%;
  --max-height: 490px;
  --border-radius: 12px;
  @media (max-width: 650px) {
    --max-height: 480px;
  }
  @media (max-width: 460px) {
    --height: calc(
      100% - 20px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
    );
    --max-height: 100%;
  }
`;

const FeedbackOptionButton = styled(Button as any)`
  letter-spacing: 0px;
  text-transform: none;
  margin: 3px;
`;

const StyledOuterIonToolbar = styled(IonToolbar)`
  --border-color: rgba(0, 0, 0, 0.04);
  --min-height: 56px;
  --background: transparent;
  padding-top: 0 !important;
`;

const StyledIonTextarea = styled(IonTextarea)`
  background: rgba(0, 0, 0, 0.03);
  width: 100%;
  flex: 0;
  border-radius: 12px;
  --padding-start: 12px;
  margin-top: 8px;
`;

const Container = styled('div')`
  width: 100%;
  padding: 20px;
`;

const StyledH4 = styled('h4')`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  align-self: flex-start;
`;

const Message = styled('p')``;

export type FeedbackType = 'bug' | 'improvement' | 'feature' | 'general';
const feedbackTypes: { [key: string]: string } = {
  general: 'General Contact',
  bug: 'Bug Report',
  improvement: 'Suggest Improvement',
  feature: 'Request Feature',
};

interface Props {
  isOpen: boolean;
  onDidDismiss: () => void;
  initFeedbackType?: FeedbackType;
}

export const FeedbackModal: React.FC<Props> = ({
  isOpen,
  initFeedbackType = 'feature',
  onDidDismiss,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackType, setFeedbackType] = useState(initFeedbackType);
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  const { loggedInUser } = useLoggedInUser();

  const submitFeedback = async () => {
    setIsLoading(true);
    try {
      if (!description) {
        throw new Error('Description missing');
      }

      const body = JSON.stringify({
        title: 'Web App Ticket',
        description,
        ticketLabels: [feedbackType],
        user_info: {
          id: loggedInUser?.id || 'Not logged in',
          name: loggedInUser
            ? loggedInUser.first_name + ' ' + loggedInUser.last_name
            : 'Not logged in',
          profile_page: loggedInUser
            ? '[https://www.ifancy.app/user/' + loggedInUser.id + '](link)'
            : 'Not logged in',
          email: contactEmail || 'No email provided',
        },
        device_info: {
          userAgent: window.navigator.userAgent,
          version: packageJson.version,
          isNativePlatform: Capacitor.isNativePlatform(),
          nativePlatform: Capacitor.getPlatform(),
          platforms: getPlatforms().toString(),
        },
      });
      const response = await fetch(
        'https://ifancy-linear-help-center.netlify.app/.netlify/functions/submitTicket',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body,
        }
      );
      const parsedResponse = await response.json();

      if (!parsedResponse) {
        throw new Error('Response not parsed');
      }
      if (!contactEmail) {
        toast.error(
          'Feedback Received but we cannot contact you without an email'
        );
        toast.error('Submit your email in the feedback form to be contacted');
      }
      setMessage(parsedResponse.msg);
      setTimeout(() => {
        if (parsedResponse.success) {
          closeModal();
        } else {
          resetState();
        }
      }, 2000);
    } catch (err) {
      handleCatchError(err);
    }

    setIsLoading(false);
  };

  const resetState = () => {
    setDescription('');
    setMessage('');
  };

  const closeModal = () => {
    resetState();
    onDidDismiss();
  };

  useEffect(() => {
    setFeedbackType(initFeedbackType);
  }, [initFeedbackType]);

  return (
    <StyledIonModal
      onDidDismiss={onDidDismiss}
      className='custom-modal'
      isOpen={isOpen}
    >
      <IonHeader>
        <StyledOuterIonToolbar mode='md'>
          <StyledModalTitle>
            <p>Feedback</p> <MdFeedback />
          </StyledModalTitle>
        </StyledOuterIonToolbar>
      </IonHeader>
      <IonContent>
        <Container>
          {message ? (
            <ModalMessageContainer>
              <Message>{message}</Message>
            </ModalMessageContainer>
          ) : (
            <>
              <StyledH4>Select Type:</StyledH4>
              <AllFeedbackTypesContainer>
                {Object.keys(feedbackTypes).map((feedbackTypeKey, i) => (
                  <FeedbackOptionButton
                    onClick={() =>
                      setFeedbackType(feedbackTypeKey as FeedbackType)
                    }
                    clear={feedbackType !== feedbackTypeKey}
                    color='dark'
                    small
                    key={i}
                  >
                    {feedbackTypes[feedbackTypeKey]}
                  </FeedbackOptionButton>
                ))}
              </AllFeedbackTypesContainer>
              <IonInput
                value={contactEmail}
                onIonInput={(e) => setContactEmail(e?.detail?.value || '')}
                placeholder='Email to contact you'
              />
              <StyledIonTextarea
                value={description}
                onIonInput={(e: any) => setDescription(e?.detail?.value)}
                rows={8}
                placeholder="We'd love to hear how we can improve your experience"
              />
            </>
          )}
        </Container>
      </IonContent>
      <StyledModalIonFooter>
        <ModalFooterButtonsContainer>
          <ModalCancelButton onClick={closeModal} color='medium'>
            Cancel
          </ModalCancelButton>
          <PrimaryModalActionButton onClick={submitFeedback} color='primary'>
            {isLoading ? <IonSpinner /> : 'Send Feedback'}
          </PrimaryModalActionButton>
        </ModalFooterButtonsContainer>
      </StyledModalIonFooter>
    </StyledIonModal>
  );
};
