import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import {
  NextButton,
  PrevButton,
  usePrevNextButtons,
} from './EmblaCarouselArrowButton';
import React, { useCallback } from 'react';

import Autoplay from 'embla-carousel-autoplay';
import { EmblaCarouselType } from 'embla-carousel';
import { FaStar } from 'react-icons/fa';
import { homepageReviews } from '../../constants/homepageReviews';
import { styled } from 'goober';
import useEmblaCarousel from 'embla-carousel-react';

interface Props {}

const Container = styled('div')`
  width: 100%;
  padding-top: 110px;
  padding-bottom: 30px;
  margin-top: -214px;
  position: relative;
  z-index: 4;
  background: linear-gradient(to top, #ffffff 20%, rgba(255, 255, 255, 0.5));
  border-top: 2px solid #f5f5f5;
  border-bottom: 2px solid #f5f5f5;
`;

const Embla = styled('section')`
  max-width: 100%;
  margin: auto;
  --slide-height: 210px;
  @media (min-width: 368px) {
    --slide-height: 190px;
  }
  --slide-spacing: 12px;
`;

const EmblaViewPort = styled('div', React.forwardRef)`
  overflow: hidden;
`;

const EmblaContainer = styled('div')`
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
`;

const EmblaSlide = styled('div')`
  transform: translate3d(0, 0, 0);
  min-width: 0;
  padding-left: var(--slide-spacing);
  flex: 0 0 auto;
  min-width: 0;
  max-width: min(380px, 80%);
`;

const EmblaSlideInner = styled<any>('div')`
  border-radius: 17px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: var(--slide-height);
  user-select: none;
  border: 2px solid #f5f5f5;
  padding: 20px;
  width: ${(props) => props.$width + 100}px;
  max-width: 100%;
  background: white;
`;

const ReviewStars = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: #f1de76;
  font-size: 20px;
  svg {
    margin-right: 1px;
  }
`;

const ReviewMessage = styled('p')`
  font-size: 14px;
  margin-bottom: 10px;
  color: #767676;
  line-height: 1.15;
  flex: 1;
`;

const ReviewName = styled('p')`
  font-size: 14px;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;
  color: var(--ion-color-dark);
`;

const EmblaControls = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 30px;
  @media (max-width: 468px) {
    margin-top: 12px;
  }
  padding: 0 14px;
`;

const EmblaButtons = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
`;

const EmblaDots = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ReviewsSection: React.FC<Props> = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { dragFree: true, loop: true, duration: 50 },
    [Autoplay({ delay: 2000 })]
  );

  const onNavButtonClick = useCallback((emblaApi: EmblaCarouselType) => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;

    const resetOrStop =
      autoplay.options.stopOnInteraction === false
        ? autoplay.reset
        : autoplay.stop;

    resetOrStop();
  }, []);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onNavButtonClick
  );

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi, onNavButtonClick);

  return (
    <Container>
      <Embla className='embla'>
        <EmblaViewPort className='embla__viewport' ref={emblaRef}>
          <EmblaContainer className='embla__container'>
            {homepageReviews.map((review, index) => (
              <EmblaSlide className='embla__slide' key={index}>
                <EmblaSlideInner $width={review.review.length}>
                  <ReviewStars>
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </ReviewStars>
                  <ReviewMessage>{review.review}</ReviewMessage>
                  <ReviewName>{review.name}</ReviewName>
                </EmblaSlideInner>
              </EmblaSlide>
            ))}
          </EmblaContainer>
        </EmblaViewPort>

        <EmblaControls>
          <EmblaButtons>
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </EmblaButtons>

          <EmblaDots>
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={index === selectedIndex ? 'selected' : ''}
              />
            ))}
          </EmblaDots>
        </EmblaControls>
      </Embla>
    </Container>
  );
};
