import React, { useContext, useState } from 'react';

import { Button } from './BaseUI/Button';
import FriendsModal from '../modals/FriendsModal';
import { UserBankContext } from '../contexts/UserBank';
import { styled } from 'goober';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

interface Props {
  userId: string;
  setUserId: (userId: Props['userId']) => void;
  modalRef: any;
}

const SelectUserButton = styled(Button as any)`
  flex-shrink: 0;
  margin-bottom: 4px;
  align-self: flex-start;
`;

const Container = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  padding-bottom: 8px;
  padding-left: 4px;
  button {
    font-weight: 700;
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

export const SelectFriendForItemAddButton: React.FC<Props> = ({
  userId,
  setUserId,
  modalRef,
}) => {
  const { userBank } = useContext(UserBankContext);
  const { loggedInUser } = useLoggedInUser();
  const [isFriendsModalOpen, setIsFriendsModalOpen] = useState(false);

  const handleVisibleToModalDismiss = (selectedUsers: string[] | undefined) => {
    if (selectedUsers?.length) {
      setUserId(selectedUsers[0]);
    }
    setIsFriendsModalOpen(false);
  };

  return (
    <>
      <Container>
        <p>
          {userId === loggedInUser?.id
            ? `Adding to My Profile`
            : `Suggesting to ${
                userBank[userId]?.user
                  ? userBank[userId].user?.formattedName
                  : 'Unknown User'
              }`}
        </p>

        <SelectUserButton
          color='light-100'
          onClick={() => setIsFriendsModalOpen(true)}
        >
          Change
        </SelectUserButton>
      </Container>
      <FriendsModal
        tipBoxText='Adding an item to a friends list will only suggest the item to them. They can choose to add it to their list or not.'
        selectedFriends={[userId]}
        isOpen={isFriendsModalOpen}
        onDidDismiss={handleVisibleToModalDismiss}
        pageRef={modalRef}
        limit={1}
        required={1}
        showSelf={true}
        showFollowers={false}
      />
    </>
  );
};
