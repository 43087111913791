import { Capacitor } from '@capacitor/core';
import { auth } from '../firebase';
import { log } from './log';

const getLocalRouteDomainForAPI = (functionName: string) => {
  // return `http://localhost:3007/api/${functionName}`;
  return `https://api.ifancy.app/api/${functionName}`;
};

export const apiFunctionFetch = async (functionName: string, body?: any) => {
  log('serverlessFunction', '[API Function] ' + functionName);

  const url = getLocalRouteDomainForAPI(functionName);

  const headers: [string, string][] = [['Content-Type', 'application/json']];

  if (Capacitor.isNativePlatform()) {
    headers.push(['x-capacitor-dev', 'true']);
  }

  const options: RequestInit = {
    method: body ? 'POST' : 'GET',
    headers,
  };

  if (body) {
    const idToken = await auth.currentUser?.getIdToken();
    options.body = JSON.stringify({ ...body, idToken });
  }

  const response = await fetch(url, options);
  return await response.json();
};
