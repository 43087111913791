import { IonInput, isPlatform } from '@ionic/react';

import React from 'react';
import { styled } from 'goober';

export const InputContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  border-radius: 12px;
  flex-wrap: wrap;
  position: relative;
  color: rgba(var(--ion-color-dark-rgb), 0.5);
  border: 1px solid var(--color-idk-light);
  min-height: 42px;
  background: transparent;
  &${isPlatform('desktop') ? ':hover' : ':active'} {
    background: var(--color-extra-light);
  }
  &:focus-within {
    background: var(--color-extra-light);
  }

  font-size: 16px;
  font-weight: 400;
  ion-input {
    width: auto;
    flex: 1;
    --padding-start: 0;
    --padding-end: 0;
  }
  p {
    padding: 10px 0;
  }
`;

export const Input = styled(IonInput, React.forwardRef)`
  letter-spacing: 0.6px;
  outline: none;
  border: none;
  border-radius: 12px;
  --padding-start: 16px;
  --padding-end: 16px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  flex: none;
  color: var(--ion-color-dark);
  min-height: 42px;
  --background: transparent !important;
  background: transparent !important;
  .input-wrapper {
    --background: transparent !important;
  }
  input {
    --background: transparent !important;
  }

  font-size: 16px;
  font-weight: 400;
`;
