import React, { useState } from 'react';

import { IonAlert } from '@ionic/react';
import { ProfileMenuActionButton } from './ProfileActionButtons';
import { User } from '../../types/GlobalTypes';
import { handleCatchError } from '../../utils/handleCatchError';
import { pencilOutline } from 'ionicons/icons';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';
import { useProfilePageContext } from '../../contexts/ProfilePageContext';

interface Props {
  user: User | undefined;
  onClick?: () => void;
}

export const EditNameButton: React.FC<Props> = ({ user, onClick }) => {
  const [isChangeNameAlertOpen, setIsChangeNameAlertOpen] = useState(false);
  const { loggedInUser, updateLoggedInUser } = useLoggedInUser();
  const { isLoggedInUsersProfile, isNonAuthedProfile } =
    useProfilePageContext();

  if (!loggedInUser?.id && !isNonAuthedProfile) return null;
  if (!isLoggedInUsersProfile) return null;

  const saveName = async (first_name: string, last_name: string) => {
    try {
      if (!user?.id) throw new Error('No user ID');
      if (user.id !== loggedInUser?.id) throw new Error('Not Allowed');
      if (!first_name) throw new Error('First name required');
      if (!last_name) throw new Error('Last name required');
      setIsChangeNameAlertOpen(false);
      await updateLoggedInUser({ first_name, last_name });
    } catch (err) {
      handleCatchError(err);
    }
    onClick && onClick();
  };

  return (
    <>
      <IonAlert
        mode='ios'
        inputs={[
          {
            name: 'firstName',
            type: 'text',
            placeholder: 'First Name',
            value: user?.first_name,
            attributes: { autoFocus: true },
          },
          {
            name: 'lastName',
            type: 'text',
            value: user?.last_name,
            placeholder: 'Last Name',
          },
        ]}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setIsChangeNameAlertOpen(false);
            },
          },
          {
            text: 'Save',
            handler: (data) => {
              saveName(data.firstName, data.lastName);
            },
          },
        ]}
        header={'Edit Name'}
        isOpen={isChangeNameAlertOpen}
        onDidDismiss={() => setIsChangeNameAlertOpen(false)}
      />
      <ProfileMenuActionButton
        clear
        color='medium'
        disabled={!user}
        iconLeft={pencilOutline}
        onClick={() => setIsChangeNameAlertOpen(true)}
      >
        Edit Name
      </ProfileMenuActionButton>
    </>
  );
};
