import {
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { Timestamp, analytics, db } from '../firebase';
import { doc, setDoc, updateDoc } from 'firebase/firestore';

import { Button } from '../components/BaseUI/Button';
import { DeleteWishlistButton } from '../components/ProfileActionButtons/DeleteWishlistButton';
import { Emoji } from '../components/Emoji';
import { EmojiPickerModal } from './EmojiPickerModal';
import { IoAddCircleOutline } from 'react-icons/io5';
import { SelectPopoverButton } from '../components/BaseUI/SelectPopoverButton';
import { TbPencil } from 'react-icons/tb';
import { WISHLIST_VISIBILITY_OPTIONS } from '../constants/constants';
import { WishlistConfig } from '../types/GlobalTypes';
import { format } from 'date-fns';
import { handleCatchError } from '../utils/handleCatchError';
import { logEvent } from 'firebase/analytics';
import { nanoid } from 'nanoid';
import { styled } from 'goober';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';
import { useProfilePageContext } from '../contexts/ProfilePageContext';

const FooterButtonsContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 20px 20px;
  @media (max-width: 440px) {
    padding: 10px;
  }
  button:first-child {
    margin-right: 10px;
    @media (max-width: 440px) {
      margin-right: 5px;
    }
  }
  button:last-child {
    margin-left: 10px;
    @media (max-width: 440px) {
      margin-left: 5px;
    }
  }
`;

const StyledIonFooter = styled(IonFooter)`
  margin: 0;
`;

const SubmitButton = styled(Button as any)`
  flex: 1;
  ion-spinner {
    margin: -5px 0;
    height: 23px;
    width: 23px;
  }
`;

const CancelButton = styled(Button as any)`
  flex: 1;
`;

const StyledIonModal = styled(IonModal)`
  --width: calc(100vw - 20px);
  --max-width: 540px;
  --height: 100%;
  --max-height: 600px;
  --border-radius: 20px;
  @media (max-width: 460px) {
    --height: calc(
      100% - 20px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
    );
    --max-height: 600px;
  }
  --background: var(--ion-color-light);
  --ion-background-color: var(--ion-color-light);
`;

const Container = styled('div')`
  width: 100%;
  padding: 30px 30px;
  @media (max-width: 580px) {
    padding: 30px;
  }
  @media (max-width: 440px) {
    padding: 20px;
  }
`;

const InputTitle = styled('h4')`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const StyledOuterIonToolbar = styled(IonToolbar)`
  --border-color: rgba(0, 0, 0, 0.04);
  --min-height: 56px;
  --background: transparent;
  padding-top: 0 !important;
`;

const TitleIcon = styled('div')`
  margin-right: 10px;
  font-size: 28px;
  background: var(--ion-color-primary);
  border-radius: 14px;
  width: 38px;
  height: 38px;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const TitleInner = styled('div')`
  p {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 24px;
    @media (max-width: 360px) {
      font-size: 20px;
    }
  }
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const StyledIonTitle = styled(IonTitle)`
  padding: 16px 20px;
`;

const StyledInput = styled('input', React.forwardRef)`
  background: var(--ion-color-light-100);
  &:hover,
  :active,
  :focus-within {
    background: var(--ion-color-light-100-tint);
  }
  margin-bottom: 18px;
  width: 100%;
`;

const EmojiSelectedButton = styled(Button as any)`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
`;

const EmojiWrapper = styled('span')`
  margin-bottom: -12px;
`;

const StyledSelectPopoverButton = styled(SelectPopoverButton as any)`
  margin-bottom: 38px;
`;

const DangerAreaContainer = styled('div')`
  border-radius: 12px;
  padding: 12px;
  border: 2px dashed #f5dddd;
  h4 {
    color: var(--ion-color-danger-shade);
  }
`;

const CollaboratorsText = styled('p')``;

interface Props {
  isOpen: boolean;
  onDidDismiss: (
    action?: 'deleted' | 'created' | 'demo',
    newWishlist?: WishlistConfig
  ) => void;
  wishlistToEdit?: WishlistConfig;
  isDemo?: boolean;
}

export const CreateOrEditWishlistModal: React.FC<Props> = ({
  isOpen,
  onDidDismiss,
  wishlistToEdit,
  isDemo,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [name, setName] = useState<string>(
    wishlistToEdit
      ? wishlistToEdit.name
      : `New Wishlist - ${format(new Date(), 'P')}`
  );
  const [emoji, setEmoji] = useState<string>(
    wishlistToEdit ? wishlistToEdit.emoji : ':gift:'
  );
  const [visibility, setVisibility] = useState<WishlistConfig['visibility']>(
    wishlistToEdit ? wishlistToEdit.visibility : 'public'
  );
  const inputRef = useRef<any>();
  const { loggedInUser } = useLoggedInUser();
  const { wishlists, setWishlists, updateSelectedWishlist } =
    useProfilePageContext();

  const submit = async () => {
    if (isDemo) {
      onDidDismiss('demo');
      return;
    }
    setIsLoading(true);
    try {
      if (
        wishlists.length > 25 ||
        wishlists.filter((i) => !i.deletedAt).length >= 10
      ) {
        throw new Error('You cannot have more than 10 wishlists');
      }
      if (name.length < 2) {
        throw new Error('Needs More Characters in Name');
      }

      if (wishlistToEdit) {
        const fieldsToEdit: Partial<WishlistConfig> = {
          name,
          emoji,
          visibility,
          updatedAt: Timestamp.fromDate(new Date()),
        };

        await updateDoc(
          doc(db, `users/${loggedInUser?.id}/wishlists/${wishlistToEdit.id}`),
          fieldsToEdit
        );

        const newWishlists = wishlists.map((wishlist) => {
          if (wishlist.id === wishlistToEdit.id) {
            return { ...wishlist, ...fieldsToEdit };
          }
          return wishlist;
        });
        setWishlists(newWishlists);
        updateSelectedWishlist(
          newWishlists.find((wishlist) => wishlist.id === wishlistToEdit.id)
        );
        logEvent(analytics, 'wishlist_edited');
        onDidDismiss();
      } else {
        const id = nanoid(15);
        const newWishlist: WishlistConfig = {
          id,
          name,
          emoji,
          visibility,
          createdAt: Timestamp.fromDate(new Date()),
          updatedAt: Timestamp.fromDate(new Date()),
          deletedAt: null,
        };

        await setDoc(
          doc(db, `users/${loggedInUser?.id}/wishlists/${id}`),
          newWishlist
        );
        logEvent(analytics, 'wishlist_created');
        onDidDismiss('created', newWishlist);
      }
    } catch (err) {
      handleCatchError(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (wishlistToEdit) {
      setName(wishlistToEdit.name);
      setEmoji(wishlistToEdit.emoji);
      setVisibility(wishlistToEdit.visibility);
    } else if (!isOpen) {
      setIsLoading(false);
      setEmoji(':gift:');
      setName(`New Wishlist - ${format(new Date(), 'P')}`);
      setVisibility('public');
    }
  }, [isOpen, wishlistToEdit]);

  const closeModal = () => {
    setTimeout(() => {
      onDidDismiss();
    }, 170);
  };

  const handleEmojiPickerOpen = () => {
    setEmojiPickerOpen(true);
  };

  const handleEmojiPickerDismiss = (emoji: string | undefined) => {
    if (emoji) {
      setEmoji(emoji);
    }
    setEmojiPickerOpen(false);
  };

  return (
    <>
      <StyledIonModal
        onDidDismiss={closeModal}
        className='custom-modal'
        isOpen={isOpen}
        backdropDismiss={false}
      >
        <IonHeader mode='ios'>
          <StyledOuterIonToolbar mode='md'>
            <StyledIonTitle>
              <TitleInner>
                <TitleIcon>
                  {wishlistToEdit ? <TbPencil /> : <IoAddCircleOutline />}
                </TitleIcon>
                <p>{wishlistToEdit ? 'Edit' : 'Create New'} Wishlist</p>
              </TitleInner>
            </StyledIonTitle>
          </StyledOuterIonToolbar>
        </IonHeader>
        <IonContent>
          <Container>
            <InputTitle>Name</InputTitle>
            <StyledInput
              ref={inputRef}
              name='wishlist-name'
              onChange={(e) => setName(e.target.value)}
              value={name}
              placeholder='Wishlist Name'
            />
            <InputTitle>Icon</InputTitle>
            <EmojiSelectedButton
              onClick={handleEmojiPickerOpen}
              color='light-100'
              iconLeft={
                <EmojiWrapper>
                  <Emoji emoji={emoji} size={42} />
                </EmojiWrapper>
              }
            />

            <InputTitle>Visibility</InputTitle>
            <StyledSelectPopoverButton
              value={visibility}
              onChange={(e: any) => setVisibility(e.value)}
              options={WISHLIST_VISIBILITY_OPTIONS}
            />
            {wishlistToEdit ? (
              <DangerAreaContainer>
                <InputTitle>Danger Area</InputTitle>
                {wishlistToEdit.id === 'original' ? (
                  <p>You cannot delete your original list.</p>
                ) : (
                  <DeleteWishlistButton onClick={onDidDismiss} />
                )}
              </DangerAreaContainer>
            ) : (
              <CollaboratorsText>
                {/* You can add collaborators later by clicking on the actions
                button */}
                {/* @TODO add collaborators */}
              </CollaboratorsText>
            )}
          </Container>
        </IonContent>
        <StyledIonFooter mode='ios'>
          <FooterButtonsContainer>
            <CancelButton onClick={closeModal} color='medium'>
              Cancel
            </CancelButton>
            <SubmitButton onClick={submit} color='primary'>
              {isLoading ? (
                <IonSpinner name='crescent' />
              ) : wishlistToEdit ? (
                'Save'
              ) : (
                'Create Wishlist'
              )}
            </SubmitButton>
          </FooterButtonsContainer>
        </StyledIonFooter>
      </StyledIonModal>
      <EmojiPickerModal
        isOpen={emojiPickerOpen}
        onDidDismiss={handleEmojiPickerDismiss}
      />
    </>
  );
};
