import * as Sentry from '@sentry/react';

import {
    AppTrackingTransparency,
} from 'capacitor-plugin-app-tracking-transparency';
import { Capacitor } from '@capacitor/core';
import { analytics } from '../firebase';
import { setUserId } from 'firebase/analytics';
import { useEffect } from 'react';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

export const useGetTrackingPermission = () => {
    const { loggedInUser } = useLoggedInUser();

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            if (loggedInUser) {

                const doChecks = async () => {
                    let iOSTrackingResponse = await AppTrackingTransparency.getStatus();
                    if (iOSTrackingResponse.status === 'notDetermined') {
                        iOSTrackingResponse = await AppTrackingTransparency.requestPermission();
                    }

                    if (iOSTrackingResponse.status === 'denied') {
                        setUserId(analytics, '');
                    } else {
                        setUserId(analytics, loggedInUser?.id || '');
                        Sentry.setUser({
                            id: loggedInUser?.id || '',
                            username: loggedInUser?.first_name + ' ' + loggedInUser?.last_name,
                        });
                    }
                };

                doChecks();
            }
        } else {
            setUserId(analytics, loggedInUser?.id || '');
            Sentry.setUser({
                id: loggedInUser?.id || '',
                username: loggedInUser?.first_name + ' ' + loggedInUser?.last_name,
            });
        }
    }, [loggedInUser]);

};