import React, { useState } from 'react';
import {
  UseIonRouterResult,
  createAnimation,
  useIonRouter,
} from '@ionic/react';

import { CreateOrEditWishlistModal } from '../../modals/CreateOrEditWishlistModal';
import { ProfileMenuActionButton } from './ProfileActionButtons';
import { WishlistConfig } from '../../types/GlobalTypes';
import { pencilOutline } from 'ionicons/icons';
import { useProfilePageContext } from '../../contexts/ProfilePageContext';

interface Props {
  onClick?: () => void;
}

export const handleCreateOrEditWishlistModalDismiss = ({
  userId,
  action,
  ionRouter,
  newWishlist,
}: {
  action?: 'deleted' | 'created' | 'demo';
  newWishlist?: WishlistConfig;
  userId: string;
  ionRouter: UseIonRouterResult;
}) => {
  if (action === 'deleted') {
    ionRouter.routeInfo.routeDirection = 'none';
    ionRouter.push(`/user/${userId}`, 'none', 'replace', {}, () =>
      createAnimation()
    );
  } else if (action === 'created' && newWishlist) {
    ionRouter.routeInfo.routeDirection = 'none';
    ionRouter.push(
      `/user/${userId}${
        newWishlist.id === 'original' ? '' : `/wishlists/${newWishlist?.id}`
      }`,
      'none',
      'replace',
      {},
      () => createAnimation()
    );
  }
};

export const EditWishlistButton: React.FC<Props> = ({ onClick }) => {
  const [showModal, setShowModal] = useState(false);
  const { userId, selectedWishlist } = useProfilePageContext();
  const ionRouter = useIonRouter();

  const handleEditWishlist = () => {
    setShowModal(true);
  };

  const handleCreateNewWishlistModalDismiss = (
    action?: 'deleted' | 'created' | 'demo',
    newWishlist?: WishlistConfig
  ) => {
    setShowModal(false);
    if (!userId) throw new Error('No user ID');
    handleCreateOrEditWishlistModalDismiss({
      userId,
      action,
      newWishlist,
      ionRouter,
    });
    onClick?.();
  };

  return (
    <>
      <CreateOrEditWishlistModal
        isOpen={showModal}
        wishlistToEdit={selectedWishlist}
        onDidDismiss={handleCreateNewWishlistModalDismiss}
      />
      <ProfileMenuActionButton
        color='medium'
        clear
        iconLeft={pencilOutline}
        onClick={handleEditWishlist}
      >
        Edit Wishlist
      </ProfileMenuActionButton>
    </>
  );
};
