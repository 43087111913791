import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import React, { useContext, useEffect } from 'react';

import { Button } from '../components/BaseUI/Button';
import { Capacitor } from '@capacitor/core';
import { FriendsList } from '../components/FriendsList';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StyledIonOuterToolbar } from '../components/layout/StyledOuterIonToolbar';
import { UserBankContext } from '../contexts/UserBank';
import { customPageAnimationVertically } from '../utils/pageTransition';
import { styled } from 'goober';
import { useRouteMatch } from 'react-router';

const StyledIonTitle = styled(IonTitle)``;

const CloseButton = styled(Button as any)`
  padding: 6px 16px;
  .btn-text {
    font-size: 19px;
    font-family: 'Nunito Sans', sans-serif;
    letter-spacing: 0.3px;
  }
  &.hide {
    display: none;
  }
`;

export const UsersFriends: React.FC = () => {
  const { userBank, getUser } = useContext(UserBankContext);
  const ionRouter = useIonRouter();
  const match = useRouteMatch<{ userId: string }>();

  const user =
    (match?.params?.userId && userBank[match.params.userId]?.user) || undefined;

  useEffect(() => {
    if (match?.params?.userId) {
      getUser(match.params.userId);
    }
  }, [getUser, match]);

  const goBack = () => {
    ionRouter.goBack(customPageAnimationVertically);
  };

  const title = user ? `${user?.first_name}'s Friends` : '';

  return (
    <IonPage>
      <IonHeader mode='ios' translucent={true}>
        <StyledIonOuterToolbar>
          <IonButtons slot='end'>
            <CloseButton
              className={Capacitor.isNativePlatform() ? '' : 'hide'}
              color='dark'
              clear
              onClick={goBack}
            >
              Close
            </CloseButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </StyledIonOuterToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <PageWrapper footerType='minimal' hideDisclaimer>
          <IonHeader mode='ios' collapse='condense'>
            <IonToolbar>
              <StyledIonTitle size='large'>{title}</StyledIonTitle>
            </IonToolbar>
          </IonHeader>
          <FriendsList isViewingUserFriends userId={user?.id} />
        </PageWrapper>
      </IonContent>
    </IonPage>
  );
};
