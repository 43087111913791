import { IonItem, IonLabel } from '@ionic/react';
import React, { useContext, useEffect } from 'react';
import { radioButtonOff, radioButtonOn } from 'ionicons/icons';

import { Button } from './BaseUI/Button';
import { RELATIONSHIP_DEFINITIONS } from '../constants/relationshipDefinitions';
import { User } from '../types/GlobalTypes';
import { UserBankContext } from '../contexts/UserBank';
import { UserListAvatar } from './UserListAvatar';
import { isUserAFollowingUserB } from '../utils/helper-functions/isUserAFollowingUserB';
import { styled } from 'goober';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

interface Props {
  userOrUserId: string | undefined | User;
  onClick: (user: User) => void;
  selected?: string[];
  showRelationship?: boolean;
  hideDetail?: boolean;
}

const UserRelationshipEmoji = styled('span')`
  margin-left: 8px;
`;

const StyledIonItem = styled(IonItem)`
  --min-height: 56px;
  --padding-start: 0;
  overflow: visible;
`;

export const UserListItem: React.FC<Props> = ({
  userOrUserId,
  onClick,
  selected,
  showRelationship,
  hideDetail,
}) => {
  const { userBank, getUser } = useContext(UserBankContext);
  const { loggedInUser } = useLoggedInUser();

  const user =
    typeof userOrUserId === 'string'
      ? userBank[userOrUserId]?.user
      : userOrUserId;

  useEffect(() => {
    if (
      userOrUserId &&
      typeof userOrUserId === 'string' &&
      !userBank[userOrUserId]
    ) {
      getUser(userOrUserId);
    }
  }, [getUser, userBank, userOrUserId]);

  if (!user?.id) return null;

  const isUserSelected = Boolean(selected?.includes(user.id));

  const friendFollows = showRelationship
    ? isUserAFollowingUserB(user, loggedInUser)
    : undefined;
  const isFollowing = showRelationship
    ? isUserAFollowingUserB(loggedInUser, user)
    : undefined;

  const relationship: keyof typeof RELATIONSHIP_DEFINITIONS | undefined =
    isFollowing && friendFollows
      ? 'mutual'
      : friendFollows
      ? 'friend-follows'
      : isFollowing
      ? 'user-follows'
      : undefined;

  return (
    <StyledIonItem
      button={true}
      onClick={() => onClick(user)}
      detail={hideDetail ? false : selected ? false : true}
    >
      {selected && (
        <Button
          clear
          iconLeft={isUserSelected ? radioButtonOn : radioButtonOff}
          color='dark'
        />
      )}
      <UserListAvatar isOnLoggedInUsersProfile={false} image={user.avatarImg} />
      <IonLabel class='ion-text-nowrap'>
        {user.formattedName}
        {relationship && (
          <UserRelationshipEmoji
            role='img'
            aria-label={RELATIONSHIP_DEFINITIONS[relationship].alt}
          >
            {RELATIONSHIP_DEFINITIONS[relationship].emoji}
          </UserRelationshipEmoji>
        )}
      </IonLabel>
    </StyledIonItem>
  );
};
