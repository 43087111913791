import { useEffect, useState } from 'react';

import { UserWishlist } from '../types/GlobalTypes';
import { suggestionListCollectionObservable } from '../data-fetching/suggestionListCollectionObservable';

type UseGetWishlistItemsProps = {
  userId: string | undefined;
  isLoggedInUsersProfile: boolean;
};

export function useGetWishlistSuggestions({
  userId,
  isLoggedInUsersProfile,
}: UseGetWishlistItemsProps) {
  const [suggestionList, setSuggestionList] = useState<UserWishlist>();


  useEffect(() => {
    setSuggestionList(undefined);
  }, [userId]);

  useEffect(() => {

    let unsubscribeFromSuggestionListSnapshotWatch: () => void;


    if (userId && isLoggedInUsersProfile) {
      unsubscribeFromSuggestionListSnapshotWatch =
        suggestionListCollectionObservable(userId, setSuggestionList);
    }


    return () => {
      unsubscribeFromSuggestionListSnapshotWatch &&
        unsubscribeFromSuggestionListSnapshotWatch();
    };
  }, [
    isLoggedInUsersProfile,
    userId,
  ]);

  return suggestionList;
}
