import * as React from 'react';

import { IonIcon } from '@ionic/react';
import { getColorContrast } from '../../utils/contrastColor';
import { isPlatform } from '@ionic/react';
import { styled } from 'goober';

interface Props {
  color: string;
  icon?: string;
  onClick?: () => void;
  clear?: boolean;
  uppercase?: boolean;
  disabled?: boolean;
  small?: boolean;
  className?: any;
  href?: string;
  target?: string;
  children?: React.ReactNode;
}

const TextContainer = styled('div')`
  font-size: 15px;
  color: inherit;
`;

const IconContainer = styled('div')<{ $iconOnly: boolean }>`
  font-size: 20px;
  display: flex;
  ${({ $iconOnly }) => ($iconOnly ? `font-size: 28px;` : `padding-right: 6px;`)}
`;

const Container = styled('button')<any>`
  display: flex;
  margin: 0;
  padding: ${({ $icon, $small, $iconOnly }) => {
    let yPadding = 6;
    let xPadding = $iconOnly ? 6 : 12;

    if ($icon) {
      yPadding = yPadding / 2;
      xPadding = xPadding / 2;
    }

    if (!$small) {
      yPadding = yPadding * 2;
      xPadding = xPadding + 4;
    }

    return `${yPadding}px ${xPadding}px`;
  }};
  outline: none;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  line-height: 15px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background: ${({ $color, $clear }) => ($clear ? 'transparent' : $color)};
  color: ${({ $color, $clear }) =>
    $clear ? $color : getColorContrast($color)};

  &${isPlatform('desktop') ? ':hover' : ':active'} {
    ${({ $clear }) => ($clear ? `background: rgba(0,0,0,.05)` : `opacity: .9`)};
  }

  ion-icon {
    color: ${({ $color, $clear }) =>
      $clear ? $color : getColorContrast($color)};
  }
`;

export const ImageUploadButton: React.FC<Props> = ({
  small = false,
  children,
  disabled,
  icon,
  color,
  clear = false,
  onClick,
  className,
}) => {
  return (
    <Container
      $small={small}
      $icon={Boolean(icon)}
      disabled={disabled}
      onClick={() => (onClick ? onClick() : undefined)}
      $clear={clear}
      $color={color}
      $iconOnly={!children ? true : false}
      className={className}
    >
      {icon && (
        <IconContainer $iconOnly={!children ? true : false}>
          <IonIcon icon={icon} />
        </IconContainer>
      )}
      <TextContainer>{children}</TextContainer>
    </Container>
  );
};
