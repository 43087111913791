import { analytics } from '../../firebase';
import { isKnownAmazonLink } from './isKnownAmazonLink';
import { logEvent } from 'firebase/analytics';

export const scrapeOGTags = (htmlString: string, url: string) => {

    const knownAmazonLink = isKnownAmazonLink(url);
    if (knownAmazonLink) {
        logEvent(analytics, 'amazon_link_scraped');
    }
    const response: { [key: string]: string[]; } = {};
    const metaTagStrings = htmlString.split("<meta").map(i => i.replaceAll("\\", "").split(">")[0]);
    metaTagStrings.shift();

    metaTagStrings.map(i => {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString("<meta" + i + ">", 'text/html');
        const reParsedMetaTag = htmlDoc.querySelector("meta");
        const key = reParsedMetaTag?.getAttribute("property") || reParsedMetaTag?.getAttribute("name");
        const value = reParsedMetaTag?.getAttribute("content") || reParsedMetaTag?.getAttribute("value");
        if (!key || !value) return;
        if (!response[key]) {
            response[key] = [value];
        } else {
            response[key].push(value);
        }
    })

    try {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(htmlString, 'text/html');
        const title = htmlDoc.querySelector("title")?.textContent;
        if (!response["title"] && title) {
            response["title"] = [title];
        }
        // get images 
        try {
            const imageContainers = [...htmlDoc.querySelectorAll("img")].filter(Boolean);
            for (const imageEl of imageContainers) {
                const imageSrc = imageEl.getAttribute("src");

                if (imageSrc) {
                    // Filters
                    if (imageSrc?.startsWith("//fls-na")) continue;
                    if (imageEl.style.display === "none") continue;

                    if (!response["image"]) {
                        response["image"] = [imageSrc];
                    } else {
                        response["image"].push(imageSrc);
                    }
                }
            }
        } catch (err) {
            console.log(err);
            console.log("error getting images")
        }

        try {
            // get prices
            let foundConfidentPrice = false;

            // try to get amazon json data price object
            if (htmlString.includes(`{"mobile_buybox_group_1"`)) {
                try {
                    const amazonJsonData = htmlString.split(`{"mobile_buybox_group_1"`)[1].split("</")[0];
                    const amazonJsonDataParsed = JSON.parse(`{"mobile_buybox_group_1"` + amazonJsonData);
                    if (amazonJsonDataParsed?.mobile_buybox_group_1?.length && amazonJsonDataParsed.mobile_buybox_group_1[0].priceAmount) {
                        if (!response["price"]) {
                            response["price"] = [amazonJsonDataParsed.mobile_buybox_group_1[0].priceAmount];
                        } else {
                            response["price"].unshift(amazonJsonDataParsed.mobile_buybox_group_1[0].priceAmount);
                        }
                        foundConfidentPrice = true;
                    }
                } catch (err) {
                    console.log(err, "Unsuccessful in getting amazon json data");
                }
            }



            if (!foundConfidentPrice) {
                const priceContainers = [...htmlDoc.querySelectorAll(".a-price-whole"), ...htmlDoc.querySelectorAll(".price")].filter(Boolean);

                for (let i = 0; i < priceContainers.length; i++) {
                    const priceString = priceContainers[i].textContent;
                    const priceNumber = priceString?.replace(/[^0-9.]+/g, "");

                    if (priceNumber && !isNaN(Number(priceNumber))) {
                        if (!response["price"]) {
                            response["price"] = [priceNumber];
                        } else {
                            response["price"].push(priceNumber);
                        }
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }

    } catch (err) {
        console.log(err);
    }



    return response;
}