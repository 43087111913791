import { IonText, useIonRouter } from '@ionic/react';

import { Button } from './BaseUI/Button';
import React from 'react';
import { customPageAnimationVertically } from '../utils/pageTransition';
import { styled } from 'goober';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

interface Props {
  isNonAuthedProfile?: boolean;
}

const LoginPromptContainer = styled('div')`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 8px;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
`;

const FooterInnerContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 32px;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0px -4px 29px rgba(0, 0, 0, 0.05);
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  backdrop-filter: blur(6px) saturate(1.4);
  -webkit-backdrop-filter: blur(6px) saturate(1.4);

  padding-bottom: calc(var(--ion-safe-area-bottom) + 90px);
  @media (min-width: 768px) {
    padding-bottom: calc(var(--ion-safe-area-bottom) + 30px);
  }
  button {
    margin: 4px 0;
    width: 100%;
    max-width: 300px;
  }
  ion-text {
    padding: 24px;
    @media (max-width: 550px) {
      padding: 4px;
      padding-bottom: 8px;
      padding-top: 0px;
    }
  }
`;

export const LoginPromptFooter: React.FC<Props> = ({ isNonAuthedProfile }) => {
  const { loggedInUser } = useLoggedInUser();
  const ionRouter = useIonRouter();

  if (loggedInUser?.id || isNonAuthedProfile) return null;

  const goToAuth = () => {
    ionRouter.push(
      `/auth`,
      'forward',
      'push',
      {},
      customPageAnimationVertically
    );
  };

  const gettingOGImage = new URLSearchParams(window?.location?.search).has(
    'ogimage'
  );

  if (gettingOGImage) return null;

  return (
    <LoginPromptContainer>
      <FooterInnerContainer>
        {!loggedInUser?.id && (
          <>
            <IonText color='danger'>
              Login to see which items have already been purchased!
            </IonText>
            <Button onClick={goToAuth} color='primary'>
              Login // Sign Up
            </Button>
          </>
        )}
      </FooterInnerContainer>
    </LoginPromptContainer>
  );
};
