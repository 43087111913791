import { getPlatforms, useIonRouter } from '@ionic/react';

import { Button } from './BaseUI/Button';
import { Capacitor } from '@capacitor/core';
import React from 'react';
import { exitOutline } from 'ionicons/icons';

interface Props {
  className?: string;
}

export const SwitchToAppButton: React.FC<Props> = ({ className }) => {
  const ionRouter = useIonRouter();
  const isOnMobileIOS =
    getPlatforms().includes('ios') && getPlatforms().includes('mobileweb');
  const shouldShowAppRedirect = isOnMobileIOS && !Capacitor.isNativePlatform();

  if (!shouldShowAppRedirect) return null;
  const href =
    'https://ifancy.app/open-in-app?pathname=' +
    encodeURIComponent(ionRouter.routeInfo.pathname);

  return (
    <Button
      className={className}
      href={href}
      color='dark'
      iconRight={exitOutline}
    >
      Switch to the App
    </Button>
  );
};
