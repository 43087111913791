import { Button } from '../BaseUI/Button';
import { styled } from 'goober';

export const ProfileActionButton = styled(Button as any)`
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  opacity: .4;
  margin: 0 8px;
  @media (max-width: 468px) {
  margin: 0 6px;
  }
  @media (max-width: 368px) {
  margin: 0 4px;
  }
  &:hover {
    opacity: 1;
  }
  .btn-icon {
    font-size: 20px;
    padding: 0;
  }
  .btn-text {
    display: none;
  }
`;


export const ProfileMenuActionButton = styled(Button as any)`
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--ion-color-light);
  width: 100%;
  
  &[disabled] {
    opacity: .5;
    &:hover {
      background: initial;
    }
  }

  .btn-icon {
    font-size: 21px;
    padding-right: 10px;
  }
  .btn-text{ 
    color: black;
    opacity: .6;
  }
  &:last-child {
    border-bottom: none;
  }
`;


export const WishlistOptionButton = styled(Button as any)`
 && {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 10px;
    width: 100%;
    border-radius: 0;
   > span:first-of-type {
      transform: scale(0.85);
      margin-top: -4px;
      margin-bottom: -7px;
      margin-right: 5px;
    }
    .btn-text {
      text-align: left;
      font-weight: 600;
      font-family: 'Nunito', sans-serif;
      font-size: 22px;
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow-x: clip;
      line-height: 100%;
      max-width: calc(100% - 59px);
    }
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 8px;
      .btn-text {
      max-width: calc(100% - 56px);
      font-size: 17px;
      }
    }
    @media (max-width: 368px) {
      .btn-text {
        font-size: 15px;
      }
    }
    svg:last-of-type {
      pointer-events: none;
      margin-right: 6px;
      width: 17px;
      height: 17px;
      margin-top: -4px;
      margin-bottom: -4px;
      opacity: 0.4;
      color: black;
    }
    border-bottom: 1px solid var(--ion-color-light-100-shade);
    &:last-of-type {
      border-bottom: none;
  }
  }
`;


export const WishlistOptionButtonSmall = styled(WishlistOptionButton)`
  && {
   
    .btn-text {
      font-size: 17px;
    }
    @media (max-width: 768px) {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 10px;
      padding-right: 8px;
      .btn-text {
        font-size: 16px;
        max-width: calc(100% - 50px);
      }
    }
    @media (max-width: 368px) {
      .btn-text {
        font-size: 15px;
      }
    }
    svg:last-of-type {
      margin-right: 6px;
      width: 17px;
      height: 17px;
      margin-top: -4px;
      margin-bottom: -4px;
      color: black;
    }
  }
`;
