import { IonImg, IonSpinner } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';

import NoImage from '../../assets/imgs/NoImage.jpg';
import { storage } from '../../firebase';
import { styled } from 'goober';

interface Props {
  className?: string;
  src: string | undefined;
  userId: string | undefined;
}

const Container = styled('div')`
  z-index: 0;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
`;

const StyledIonImg = styled(IonImg)<{ hide: boolean }>`
  width: 100%;
  object-position: center;
  object-fit: cover;
  height: 100%;

  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 0.4s ease;
`;

const LoadingContainer = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ion-color-light);
`;

export const CustomImage: React.FC<Props> = ({ src, userId, className }) => {
  const [imageUrl, setImageUrl] = useState<
    { imagePath: string; downloadUrl: string } | undefined
  >();
  const [imageLoading, setImageLoading] = useState<boolean>(true);

  useEffect(() => {
    let abort = false;
    if (src) {
      if (src.startsWith('public/' + userId)) {
        if (!imageUrl || imageUrl.imagePath !== src) {
          const getImageDownloadUrl = async () => {
            try {
              setImageLoading(true);
              if (!src) throw new Error('No image');
              const downloadUrl = await getDownloadURL(ref(storage, src));
              if (abort) return;

              setImageUrl({ imagePath: src, downloadUrl });
            } catch (err) {
              console.log(err);
            } finally {
              if (!abort) {
                setImageLoading(false);
              }
            }
          };
          getImageDownloadUrl();
        }
      } else {
        setImageLoading(false);
      }
    } else {
      setImageUrl(undefined);
      setImageLoading(false);
    }

    return () => {
      abort = true;
    };
  }, [imageUrl, userId, src]);

  const modifiedSrc = src
    ? src.startsWith('//')
      ? 'https:' + src
      : src.startsWith('http:')
      ? src.replace('http:', 'https:')
      : src
    : undefined;

  return (
    <Container className={className}>
      {imageLoading && (
        <LoadingContainer>
          <IonSpinner name='crescent' color='medium' />
        </LoadingContainer>
      )}
      <StyledIonImg
        hide={imageLoading}
        alt=''
        src={imageUrl?.downloadUrl || modifiedSrc || NoImage}
      />
    </Container>
  );
};
