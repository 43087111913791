import React, { useEffect, useState } from 'react';

import { IonProgressBar } from '@ionic/react';
import { styled } from 'goober';

interface Props {
  endTime?: number;
  onProgressEnd?: () => void;
}

const StyledIonProgressBar = styled(IonProgressBar)`
  margin-bottom: 4px;
  margin-top: 4px;
`;

export const ProgressBar: React.FC<Props> = ({ endTime, onProgressEnd }) => {
  const [value, setValue] = useState(0);
  const [startTime, setStartTime] = useState(0);

  useEffect(() => {
    if (endTime) {
      setStartTime(new Date().getTime());
    } else {
      setStartTime(0);
    }
  }, [endTime]);

  useEffect(() => {
    let interval: any = null;
    if (endTime && startTime) {
      interval = setInterval(() => {
        const remainingTime = endTime - new Date().getTime();
        const totalTime = endTime - startTime;

        if (remainingTime / totalTime < 0) {
          onProgressEnd && onProgressEnd();
        } else {
          setValue(remainingTime / totalTime);
        }
      }, 100);
    } else if (!endTime && value !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [endTime, onProgressEnd, startTime, value]);

  return <StyledIonProgressBar value={value} />;
};
