import { useEffect, useState } from 'react';

export function useDelayMount(isMounted: boolean, delayTime: number) {
  const [shouldRender, setShouldRender] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);

  useEffect(() => {
    setTimeout(() => {
      if (shouldRender !== shouldAnimate) {
        if (shouldRender) {
          setShouldAnimate(true);
        } else {
          setShouldAnimate(false);
        }
      }
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRender]);

  return { shouldRender, shouldAnimate };
}
