import { PNIDDoc, User, WishlistConfig } from '../types/GlobalTypes';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Unsubscribe,
  deleteField,
  doc,
  onSnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore';

import { db } from '../firebase';
import { log } from '../utils/log';
import { transformUser } from '../utils/transformers/transformUser';
import { useAuth } from './AuthenticationContext';
import { useGetWishlists } from '../hooks/useGetWishlists';

type LoggedInUserContextProps = {
  pushToken: PNIDDoc | undefined;
  setPushToken: (token: PNIDDoc | undefined) => void;
  loggedInUser: User | undefined;
  updateLoggedInUser: (newFields: Partial<User>) => Promise<void>;
  loggedInUserWishlists: WishlistConfig[];
  setLoggedInUserWishlists: React.Dispatch<
    React.SetStateAction<WishlistConfig[]>
  >;
};

export const LoggedInUserContext =
  React.createContext<LoggedInUserContextProps>({} as LoggedInUserContextProps);

export const useLoggedInUser = () => useContext(LoggedInUserContext);

export const LoggedInUserProvider = ({ children }: any) => {
  const { loggedInUserId, setAuthHasLoadedAndLoggedInUserLoaded } = useAuth();

  const [loggedInUser, setLoggedInUser] = useState<User | undefined>(
    localStorage.getItem('iliuotisnvsbidc')
      ? JSON.parse(localStorage.getItem('iliuotisnvsbidc') || '')
      : undefined
  );

  const [pushToken, setPushToken] = useState<PNIDDoc | undefined>();

  const updateLoggedInUser = useCallback(
    async (newFields: Partial<User>) => {
      log('write', '[Write] LoggedInUser Update');

      // if any field is undefined, the assumed intention is to remove it from DB
      for (const key in newFields) {
        if (newFields[key as keyof User] === undefined) {
          newFields[key as keyof User] = deleteField() as any;
        }
      }

      await updateDoc(doc(db, `users/${loggedInUserId}`), newFields);
    },
    [loggedInUserId]
  );

  const {
    wishlists: loggedInUserWishlists,
    setWishlists: setLoggedInUserWishlists,
  } = useGetWishlists({
    userId: loggedInUserId,
    isLoggedInUsersProfile: true,
  });

  useEffect(() => {
    let listenerUnsubscribe: Unsubscribe;
    if (!loggedInUserId) {
      setLoggedInUser(undefined);
    } else {
      listenerUnsubscribe = onSnapshot(
        doc(db, `users/${loggedInUserId}`),
        async (rawDoc) => {
          let user: User | undefined | { doesNotExist: true };
          if (!rawDoc.exists()) {
            await setDoc(
              doc(db, `users/${loggedInUserId}`),
              {},
              { merge: true }
            );
          } else {
            user = await transformUser(rawDoc);

            log('read', 'LoggedInUser updated');
            setLoggedInUser(user);
            setAuthHasLoadedAndLoggedInUserLoaded(true);
          }
        },
        console.error
      );
    }
    return () => {
      listenerUnsubscribe?.();
    };
  }, [loggedInUserId, setAuthHasLoadedAndLoggedInUserLoaded]);

  useEffect(() => {
    if (!loggedInUserId) {
      setPushToken(undefined);
    }
  }, [loggedInUserId]);

  useEffect(() => {
    localStorage.setItem('iliuotisnvsbidc', JSON.stringify(loggedInUser || ''));
  }, [loggedInUser]);

  return (
    <LoggedInUserContext.Provider
      value={{
        pushToken,
        setPushToken,
        loggedInUser,
        loggedInUserWishlists,
        setLoggedInUserWishlists,
        updateLoggedInUser,
      }}
    >
      {children}
    </LoggedInUserContext.Provider>
  );
};
