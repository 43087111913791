import { ProfileMenuActionButton } from './ProfileActionButtons';
import React from 'react';
import { customPageAnimationVertically } from '../../utils/pageTransition';
import { settingsOutline } from 'ionicons/icons';
import { useIonRouter } from '@ionic/react';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';
import { useProfilePageContext } from '../../contexts/ProfilePageContext';

interface Props {
  onClick?: () => void;
}

export const SettingsButton: React.FC<Props> = ({ onClick }) => {
  const { loggedInUser } = useLoggedInUser();
  const { isLoggedInUsersProfile, isNonAuthedProfile } =
    useProfilePageContext();
  const ionRouter = useIonRouter();

  if ((!loggedInUser?.id && !isNonAuthedProfile) || !isLoggedInUsersProfile)
    return null;

  const handleClick = () => {
    onClick?.();
    ionRouter.push(
      `/settings`,
      'forward',
      'push',
      {},
      customPageAnimationVertically
    );
  };

  return (
    <ProfileMenuActionButton
      clear
      color='medium'
      iconLeft={settingsOutline}
      onClick={handleClick}
    >
      More Settings
    </ProfileMenuActionButton>
  );
};
