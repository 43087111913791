export const getImageDimensions = async (src: string) =>
  new Promise<{ width: string; height: string }>((resolve) => {
    const img = new Image();

    img.onload = () => {
      const height = String(img.height);
      const width = String(img.width);
      resolve({ width, height });
    };

    img.src = src;
  });
