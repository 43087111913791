import React, { useEffect, useState } from 'react';
import { chevronDown, giftOutline } from 'ionicons/icons';

import { Button } from '../BaseUI/Button';
// @ts-expect-error image import
import DesktopScreenshot from '../../assets/imgs/DesktopScreenshot.png?w=300;600;900;1200&format=avif;webp;png&as=picture';
import { Emoji } from '../Emoji';
import { Logo } from '../Logo';
// @ts-expect-error image import
import MobileScreenshotGroup from '../../assets/imgs/iphone-screenshot-collection.png?w=300;600;900;1200&format=avif;webp;png&as=picture';
import { Picture } from '../BaseUI/Picture';
import { customPageAnimationHorizontally } from '../../utils/pageTransition';
import { motion } from 'framer-motion';
import { styled } from 'goober';
import { useIonRouter } from '@ionic/react';

interface Props {}

const defaultViewPortOptions: any = {
  once: true,
};

const StyledLogo = styled(Logo as any)`
  display: inline-flex;
  margin-bottom: -40%;
  margin-top: -40%;
  transform: translate(6px, 30%);
  img {
    width: 110px;

    @media (min-width: 358px) {
      width: 125px;
    }
    @media (min-width: 468px) {
      width: 170px;
    }
    @media (min-width: 568px) {
      width: 215px;
    }
    @media (min-width: 768px) {
      width: 270px;
    }
    @media (min-width: 968px) {
      width: 290px;
    }
  }
`;

const MainTitle = styled(motion.h1)`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 900;
  color: var(--ion-color-primary);
  letter-spacing: 0px;
  font-size: 30px;
  margin-top: 22vh;
  text-align: center;
  @media (min-width: 358px) {
    font-size: 33px;
  }
  @media (min-width: 468px) {
    font-size: 45px;
  }
  @media (min-width: 568px) {
    font-size: 56px;
    margin-top: 4px;
    text-align: left;
  }
  @media (min-width: 768px) {
    margin-top: 10px;
    font-size: 77px;
  }
  @media (min-width: 968px) {
    font-size: 82px;
  }
`;

const Subheader = styled(motion.p)`
  font-size: 15px;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  color: #202020;
  margin-bottom: 7vh;
  text-align: center;
  span {
    font-weight: 700;
    color: white;
    background: var(--ion-color-primary);
    border-radius: 14px;
    padding: 0px 12px;
  }
  @media (min-width: 368px) {
    font-size: 17px;
  }
  @media (min-width: 468px) {
    font-size: 20px;
    margin-bottom: 30px;
  }
  @media (min-width: 568px) {
    text-align: left;
  }
  @media (min-width: 768px) {
    font-size: 24px;
  }

  @media (min-width: 968px) {
    font-size: 27px;
  }
`;

const SubSubHeader = styled(motion.p)`
  font-size: 13px;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  color: #6c6c6c;
  margin-top: 25vh;
  margin-bottom: 60px;
  text-align: center;
  @media (min-width: 468px) {
    font-size: 14px;
    margin-bottom: 50px;
  }
  @media (min-width: 768px) {
    font-size: 15px;
  }
  @media (min-width: 968px) {
    text-align: left;
    font-size: 16px;
    margin-bottom: 0px;
  }
`;

const ButtonContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  align-self: center;
  gap: 4px;
  @media (min-width: 468px) {
    gap: 12px;
  }
  @media (min-width: 568px) {
    align-self: flex-start;
  }
  @media (min-width: 768px) {
    gap: 24px;
  }
  button {
    color: white;
    padding: 12px 18px;
    .btn-text {
      margin-top: 1px;
      font-size: 17px;
      font-weight: 700;
      white-space: nowrap;
    }

    @media (max-width: 668px) {
      padding: 8px 10px;
      .btn-text {
        font-size: 14px;
      }
    }

    @media (max-width: 368px) {
      padding: 6px 8px;
      .btn-text {
        font-size: 13px;
      }
    }
  }
`;

const OccasionsContainer = styled(motion.div)`
  border-radius: 11px;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #f8f8f8;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.04);
  padding: 0px 10px;

  align-self: center;
  display: flex;
  align-items: center;

  @media (min-width: 368px) {
    padding-left: 12px;
    padding-right: 12px;
  }
  @media (min-width: 468px) {
    padding-left: 18px;
    padding-right: 18px;
  }
  @media (min-width: 568px) {
    align-self: flex-start;
  }
`;

const OccasionItem = styled('div')`
  display: flex;
  align-items: center;
  border-right: 1px solid #f5f5f5;
  margin-right: 10px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  &:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
  p {
    max-width: 0px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    margin-bottom: -1px;
    white-space: nowrap;
    color: #808080;
    font-size: inherit;
    font-weight: 600;
    transition: max-width 0.5s ease, margin 0.5s ease;
    transition-delay: 0s;
    margin-left: 0px;
  }
  font-size: 10px;
  @media (min-width: 368px) {
    font-size: 12px;
  }
  &.open p {
    max-width: 200px;
    margin-left: 4px;
    transition-delay: 0.18s;
  }
  @media (min-width: 668px) {
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 14px;
    &.next-to-open p {
      max-width: 200px;
      margin-left: 4px;
      transition-delay: 0.18s;
    }
  }
  @media (min-width: 968px) {
    &.next-next-to-open p {
      max-width: 200px;
      margin-left: 4px;
      transition-delay: 0.18s;
    }
  }
`;

const Container = styled('div')`
  width: 100%;
  padding-top: calc(6vh + env(safe-area-inset-top));
  padding-bottom: 120px;
  padding-left: 14px;
  padding-right: 14px;
  background: url('/assets/images/BackgroundSwirls.webp');
  background-size: 170px 170px;
  @media (min-width: 368px) {
    padding-left: 18px;
    padding-right: 18px;
  }
  @media (min-width: 468px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (min-width: 568px) {
    padding-top: 150px;
  }

  @media (min-width: 968px) {
    padding-bottom: 230px;
  }
`;

const MaxWidthContainer = styled('div')`
  margin: auto;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 768px) {
    padding-top: 5vh;
  }
`;

const DesktopScreenshotPicture = styled('picture')`
  display: flex;
  max-width: 700px;
  border: 6px solid var(--ion-color-dark);

  border-bottom: 0;
  overflow: hidden;
  z-index: 2;
  mask-image: linear-gradient(to bottom, black 80%, transparent 100%);

  width: 96%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  bottom: 50px;

  @media (min-width: 468px) {
    bottom: 30px;
  }

  @media (min-width: 968px) {
    position: absolute;
    width: 56%;
    right: 0%;
    bottom: -110px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }
  @media (min-width: 1150px) {
    right: -10%;
    width: 700px;
  }
`;

const MobileScreenshotPicture = styled(motion.picture)`
  max-width: 560px;
  display: flex;
  position: absolute;
  right: 4%;
  border-bottom: 0;
  overflow: hidden;
  z-index: 14;

  width: 80%;
  max-width: 500px;
  bottom: -20px;
  @media (min-width: 468px) {
    width: 67%;
  }
  @media (min-width: 968px) {
    max-width: 560px;
    width: 46%;
    bottom: -204px;
    right: 0%;
  }
  @media (min-width: 1150px) {
    right: -8%;
    width: 530px;
  }
  @media (min-width: 1350px) {
    right: -13%;
    width: 560px;
  }
`;

const Occasions = [
  {
    emoji: ':christmas_tree:',
    occasion: 'Christmas',
  },
  {
    emoji: ':birthday:',
    occasion: 'Birthdays',
  },
  {
    emoji: ':wedding:',
    occasion: 'Weddings',
  },
  {
    emoji: ':mortar_board:',
    occasion: 'Graduations',
  },
  {
    emoji: ':baby_bottle:',
    occasion: 'Baby Showers',
  },
  {
    emoji: ':tada:',
    occasion: 'Holidays',
  },
  {
    emoji: ':gift:',
    occasion: 'Year Round',
  },
];

export const HeaderSection: React.FC<Props> = () => {
  const router = useIonRouter();

  const [openOccasions, setOpenOccasions] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setOpenOccasions((prev) => (prev + 1) % Occasions.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const goToApp = () => {
    router.push(
      '/auth',
      'forward',
      'push',
      {},
      customPageAnimationHorizontally
    );
  };

  const goToFeatures = () => {
    const featuresEl = document.getElementById('features');
    if (featuresEl) {
      featuresEl.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Container>
      <MaxWidthContainer>
        <OccasionsContainer
          initial={{
            opacity: 0,
            filter: 'blur(14px)',
          }}
          whileInView={{
            opacity: 1,
            filter: 'blur(0px)',
            transition: {
              duration: 0.7,
              delay: 0,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          {Occasions.map((occasion, index) => (
            <OccasionItem
              key={index}
              className={
                (index === openOccasions ? 'open' : '') +
                (index === (openOccasions + 1) % Occasions.length
                  ? ' next-to-open'
                  : '') +
                (index === (openOccasions + 2) % Occasions.length
                  ? ' next-next-to-open'
                  : '')
              }
            >
              <Emoji size={14} emoji={occasion.emoji} />
              <p>{occasion.occasion}</p>
            </OccasionItem>
          ))}
        </OccasionsContainer>
        <MainTitle
          initial={{
            opacity: 0,
            y: 24,
            filter: 'blur(14px)',
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            filter: 'blur(0px)',
            transition: {
              duration: 0.8,
              delay: 0.1,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          Make gift planning <br />
          easy with <StyledLogo />
        </MainTitle>
        <Subheader
          initial={{
            opacity: 0,
            y: 24,
            filter: 'blur(14px)',
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            filter: 'blur(0px)',
            transition: {
              duration: 0.8,
              delay: 0.2,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          A modern <span>wishlist</span> experience
        </Subheader>

        <ButtonContainer
          initial={{
            opacity: 0,
            x: 24,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
              delay: 0.2,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          <Button color='dark' onClick={goToApp} iconRight={giftOutline}>
            Make a Wish List
          </Button>
          <Button
            color='dark'
            clear
            onClick={goToFeatures}
            iconRight={chevronDown}
          >
            See the features
          </Button>
        </ButtonContainer>

        <SubSubHeader
          initial={{
            opacity: 0,
            y: 24,
            filter: 'blur(14px)',
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            filter: 'blur(0px)',
            transition: {
              duration: 0.8,
              delay: 0.3,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          Available on Web & iOS App Store
        </SubSubHeader>
        <DesktopScreenshotPicture>
          <Picture
            picture={DesktopScreenshot}
            alt='Desktop platform of wishlist app iFancy'
          />
        </DesktopScreenshotPicture>

        <MobileScreenshotPicture>
          <Picture
            picture={MobileScreenshotGroup}
            alt='mobile and ios screenshot of wishlist app iFancy'
          />
        </MobileScreenshotPicture>
      </MaxWidthContainer>
    </Container>
  );
};
