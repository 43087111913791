import { Capacitor, CapacitorHttp, HttpResponse } from '@capacitor/core';

import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';
import { prioritizeOGTagToUse } from './prioritizeOGTagToUse';
import { scrapeOGTags } from './scrapeOGTags';
import { socket } from '../socket';

export const fetchLinkWithCapacitorHttps = async (url: string) => {

    if (!Capacitor.isNativePlatform()) return;

    logEvent(analytics, 'capacitor_scrape_attempt');
    const scrapeStartTime = new Date().getTime();

    const options = {
        url,
        headers: {
            'User-Agent': window.navigator.userAgent,
        },
    };

    try {
        const htmlRes: HttpResponse = await CapacitorHttp.get(options);

        const openGraphResults = scrapeOGTags(htmlRes.data, url);

        if (!openGraphResults) throw new Error('No OG tags found');
        const results = prioritizeOGTagToUse(openGraphResults);

        if (!results) throw new Error('No results found');

        const scrapeTime = new Date().getTime() - scrapeStartTime;
        socket.emit('capacitor-scrape', { link: url, scrapeTime, results });


    } catch (err: any) {
        const scrapeTime = new Date().getTime() - scrapeStartTime;
        socket.emit('capacitor-scrape', { link: url, scrapeTime, results: { titles: [], images: [], prices: [] }, error: err?.message || 'Unknown Error' });
    }



}