import { Timestamp, analytics, db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';

import { handleCatchError } from './handleCatchError';
import { logEvent } from 'firebase/analytics';
import toast from 'react-hot-toast';

export const restoreItem = async (loggedInUserId: string, wishlistItemId: string, wishlistId: string, setIsLoading?: (isLoading: boolean) => void) => {
  try {
    if (!loggedInUserId) throw new Error('Unauthenticated');
    setIsLoading && setIsLoading(true);
    const updatedAt = Timestamp.fromDate(new Date());
    if (!wishlistId) throw new Error('Unable to determine which wishlist to save');
    const collectionPath = wishlistId === 'original' ? 'wishlist' : `wishlists/${wishlistId}/items`;

    await updateDoc(doc(db, `users/${loggedInUserId}/${collectionPath}/${wishlistItemId}`), { deletedAt: null, updatedAt });

    setIsLoading && setIsLoading(false);
    logEvent(analytics, 'wishlist_item_unarchived', { itemId: wishlistItemId });
    toast.success('Item Restored & Un-Marked as Purchased');
  } catch (err) {
    handleCatchError(err);
  }
};
