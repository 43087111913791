import { IonPopover, IonSpinner } from '@ionic/react';
import React, { useState } from 'react';

import { BsChevronExpand } from 'react-icons/bs';
import { Button } from './Button';
import { styled } from 'goober';

export type SelectOption = {
  value: any;
  name: string | React.ReactNode;
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
};

const LoadingContainer = styled('div')`
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectionButton = styled(Button as any)`
  width: 100%;
  min-width: 160px;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--ion-color-light-100-tint);
  svg {
    pointer-events: none;
    margin-right: 6px;
    width: 17px;
    height: 17px;
    margin-top: -4px;
    margin-bottom: -4px;
    opacity: 0.6;
    color: black;
  }
  .btn-icon {
    font-size: 21px;
    padding-right: 10px;
  }
  .btn-text {
    color: black;
    opacity: 0.6;
  }
  &:last-of-type {
    border-bottom: none;
  }
`;

const ExpandIconContainer = styled('span')`
  font-size: 17px;
  margin-left: 10px;
  opacity: 0.3;
  margin-top: 2px;
  margin-bottom: 2px;
  pointer-events: none;
  color: black;
  @media (max-width: 468px) {
    margin-left: 8px;
    font-size: 14px;
  }
`;

const SelectButton = styled(Button as any)`
  min-width: 160px;
  svg {
    margin-right: 6px;
    width: 17px;
    height: 17px;
    margin-top: -4px;
    margin-bottom: -4px;
    opacity: 0.6;
    pointer-events: none;
    color: black;
  }
  .btn-text {
    text-align: left;
    flex: 1;
  }
`;

// prettier-ignore
const StyledIonPopover = styled(IonPopover)<{ sWidth: number; offsetX: number; }>`
  --width: ${({ sWidth }) => sWidth}px;
  --max-width: ${({ sWidth }) => sWidth}px;
  --min-width: ${({ sWidth }) => sWidth}px;
  --offset-x: ${({ offsetX }) => offsetX}px;
`;

interface Props {
  onChange: (newValue: SelectOption) => void;
  options: SelectOption[];
  Button?: React.ReactElement;
  value?: SelectOption | string;
  isLoading?: boolean;
  onOpen?: () => void;
  disabled?: boolean;
  showBackdrop?: boolean;
  CustomStyledSelectButton?: any;
  CustomStyledSelectOptionButton?: any;
  AddNewButton?: React.ReactElement;
  className?: string;
}

export const SelectPopoverButton: React.FC<Props> = ({
  Button,
  disabled,
  isLoading,
  onChange,
  onOpen,
  options,
  value: _value,
  showBackdrop = false,
  CustomStyledSelectButton,
  CustomStyledSelectOptionButton,
  AddNewButton,
  className,
}) => {
  const [popoverEvent, setPopoverEvent] = useState<any>();
  const [sWidth, setSWidth] = useState(260);
  const [offsetX, setOffsetX] = useState<number | undefined>(undefined);

  const value =
    typeof _value === 'string'
      ? options.find((i) => i.value === _value)
      : _value;

  const openPopover = (e: any) => {
    onOpen && onOpen();
    e.persist();
    const xOffset = Number(e?.target?.getBoundingClientRect()?.x);
    const xOffsetToSet = xOffset > 5 && xOffset < 25 ? xOffset - 5 : 0;
    setOffsetX(xOffsetToSet);
    const screenWidth = window.innerWidth;
    if (screenWidth < 550) {
      setSWidth(screenWidth - (xOffset || 0) * 2);
    } else {
      setSWidth(e?.target?.clientWidth || 260);
    }
    setPopoverEvent(e);
  };

  const _onChange = (newValue: SelectOption) => {
    setPopoverEvent(undefined);
    if (newValue.value !== value?.value) {
      onChange(newValue);
    }
  };

  const SelectButtonComponent = CustomStyledSelectButton || SelectButton;
  const SelectOptionButtonComponent =
    CustomStyledSelectOptionButton || SelectionButton;

  return (
    <>
      {Button ? (
        React.cloneElement(Button, { onClick: openPopover })
      ) : (
        <SelectButtonComponent
          className={className}
          disabled={disabled}
          slot='end'
          color='light-100'
          iconLeft={value?.icon}
          styles={{ '--offset-x': offsetX }}
          iconRight={
            <ExpandIconContainer>
              <BsChevronExpand />
            </ExpandIconContainer>
          }
          onClick={openPopover}
        >
          {value?.name || 'Select'}
        </SelectButtonComponent>
      )}
      <StyledIonPopover
        sWidth={sWidth}
        onDidDismiss={() => setPopoverEvent(undefined)}
        isOpen={Boolean(popoverEvent)}
        event={popoverEvent}
        className='simple-list-popover'
        offsetX={offsetX || 0}
        mode='ios'
        showBackdrop={showBackdrop}
      >
        {isLoading && (
          <LoadingContainer>
            <IonSpinner name='crescent' />
          </LoadingContainer>
        )}
        {options.map((option) => (
          <SelectOptionButtonComponent
            color='light-100'
            key={option.value}
            onClick={() => _onChange(option)}
            iconLeft={option.icon}
            iconRight={option.iconRight}
          >
            {option.name}
          </SelectOptionButtonComponent>
        ))}
        {AddNewButton
          ? React.cloneElement(AddNewButton, {
              onClick: () => setPopoverEvent(undefined),
            })
          : undefined}
      </StyledIonPopover>
    </>
  );
};
