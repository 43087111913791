import { Footer } from './Footer';
import React from 'react';
import { styled } from 'goober';

interface Props {
  footerType: 'full' | 'minimal';
  className?: string;
  hideDisclaimer?: boolean;
  children: React.ReactNode;
  maxWidth?: boolean;
}

const Container = styled('div')`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MaxWidthContainer = styled('div')`
  margin: 0 auto;
  width: 100%;
  &.max-width {
    max-width: 1200px;
  }
`;

export const PageWrapper: React.FC<Props> = ({
  footerType,
  children,
  className,
  hideDisclaimer = false,
  maxWidth = false,
}) => {
  return (
    <Container className={className}>
      <MaxWidthContainer className={maxWidth ? 'max-width' : ''}>
        {children}
      </MaxWidthContainer>
      <Footer type={footerType} hideDisclaimer={hideDisclaimer} />
    </Container>
  );
};
