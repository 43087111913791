import { IoEyeOffOutline } from 'react-icons/io5';
import { IoMdGlobe } from 'react-icons/io';
import { LiaUserFriendsSolid } from 'react-icons/lia';
import React from 'react';
import { WishlistConfig } from '../types/GlobalTypes';

export const DEFAULT_PROFILE_COLOR = '#222';

export const DEFAULT_WISHLIST_CONFIG: Omit<
  WishlistConfig,
  'createdAt' | 'updatedAt'
> = {
  name: 'Wishlist',
  visibility: 'public',
  emoji: ':gift:',
  id: 'original',
  deletedAt: null,
};

export const WISHLIST_VISIBILITY_OPTIONS = [
  { value: 'public', name: 'Public', icon: <IoMdGlobe /> },
  {
    value: 'friends-only',
    name: 'Friends Only',
    icon: <LiaUserFriendsSolid />,
  },
  { value: 'private', name: 'Private', icon: <IoEyeOffOutline /> },
];
