import {
  IonContent,
  IonPage,
  IonSkeletonText,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  customPageAnimationHorizontally,
  customPageAnimationVertically,
} from '../utils/pageTransition';
import { matchPath, useHistory, useParams } from 'react-router';

// import { BirthdayRequestBox } from '../components/BirthdayRequestBox';
import { Button } from '../components/BaseUI/Button';
import Color from 'color';
import { DEFAULT_PROFILE_COLOR } from '../constants/constants';
import { ErrorMessageText } from '../components/ErrorMessageText';
import { Footer } from '../components/layout/Footer';
import { Helmet } from 'react-helmet-async';
import { LoginCTABox } from '../components/LoginCTABox';
import { LoginPromptFooter } from '../components/LoginPromptFooter';
import { ProfileAvatarAndActions } from '../components/ProfileActionButtons/ProfileAvatarAndActions';
import { ProfilePageContextProvider } from '../contexts/ProfilePageContext';
import { ProfileSetupChecklist } from '../components/ProfileSetupChecklist';
import { PushNotificationCTABox } from '../components/PushNotificationCTABox';
import { StaleProfileReminderAlert } from '../components/StaleProfileReminderAlert';
import { SuggestionList } from '../components/SuggestionList';
import { UserBankContext } from '../contexts/UserBank';
import { WishList } from '../components/WishList';
import { chevronBack } from 'ionicons/icons';
import { formatDistanceToNowStrict } from 'date-fns';
import { isBlockedByUser } from '../utils/helper-functions/isBlockedByUser';
import { isPrivateProfileAndHidden } from '../utils/helper-functions/isPrivateProfileAndHidden';
import { isUserAFollowingUserB } from '../utils/helper-functions/isUserAFollowingUserB';
import { styled } from 'goober';
import { useAuth } from '../contexts/AuthenticationContext';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

const StyledIonContent = styled(IonContent)`
  --background: transparent;
  z-index: 1;
  &.extra-bottom-padding {
    --padding-bottom: 170px;
  }
  @media (min-width: 768px) {
    &.extra-bottom-padding {
      --padding-bottom: 100px;
    }
  }
`;

const ProfileName = styled('h1')`
  text-align: left;
  font-weight: 900;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 62px;
  letter-spacing: 0.3px;
  padding: 0px 36px;
  margin-top: 6px;
  @media (max-width: 850px) {
    font-size: 42px;
    padding: 0px 20px;
  }

  @media (max-width: 650px) {
    font-size: 32px;
  }
`;

const MinHeightContainer = styled('div')`
  min-height: calc(100% - 140px + var(--ion-safe-area-top));
  margin-top: calc(140px + var(--ion-safe-area-top));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--ion-color-light);
  box-shadow: 0 -8px 27px rgba(20, 21, 21, 0.12),
    0px -1px 4px rgba(20, 21, 21, 0.14);
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  position: relative;
  z-index: 12;
  padding-top: 16px;
  @media (min-width: 768px) {
    margin-top: calc(206px + var(--ion-safe-area-top));
  }
  @media (min-width: 850px) {
    margin-left: 32px;
    margin-right: 32px;
  }

  @media (min-width: 1150px) {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
`;

const ProfileToolbar = styled('div')<any>`
  display: flex;
  width: 100%;
  padding: 8px;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  top: var(--ion-safe-area-top);
  @media (min-width: 768px) {
    top: calc(66px + var(--ion-safe-area-top, 0px));
  }
  @media (min-width: 850px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (min-width: 1150px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`;

type HeaderBackgroundProps = {
  $profileColor: string;
  $opacity: number;
};

const HeaderBackground = styled('div', React.forwardRef)<HeaderBackgroundProps>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: rgba(${({ $profileColor }) => $profileColor}, 1);
  height: 280px;
  opacity: 1;
  &:after {
    z-index: 1;
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: url('/assets/images/BackgroundSwirls2.png');
    background-size: 170px 170px;
    opacity: ${({ $opacity }) => $opacity};
  }
`;

const BackgroundColor = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  background: var(--ion-color-light);
`;

const BackButton = styled(Button as any)`
  .btn-text {
    font-size: 19px;
    font-weight: 700;
    font-family: 'Nunito Sans', sans-serif;
    letter-spacing: 0.3px;
  }
  .btn-icon {
    font-size: 26px;
    padding-right: 0px;
    margin-top: -4px;
    margin-bottom: -3px;
    margin-left: -8px;
  }
`;

const LastActivityText = styled('p')`
  margin-top: -12px;
  margin-bottom: 8px;
  letter-spacing: 0.2px;
  padding: 0px 36px;
  font-size: 14px;
  color: var(--ion-color-medium);
  font-weight: 600;
  .muted {
    opacity: 0.2;
  }
  @media (max-width: 850px) {
    padding: 0px 20px;
  }
  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

type Props = {
  isLoggedInUsersProfile?: boolean;
  isNonAuthedProfile?: boolean;
};

export const UserProfile: React.FC<Props> = ({
  isLoggedInUsersProfile,
  isNonAuthedProfile,
}) => {
  const { loggedInUser } = useLoggedInUser();
  const { loggedInUserId } = useAuth();
  const { getUser, userBank } = useContext(UserBankContext);

  const [profileColorValue, setProfileColorValue] = useState(
    DEFAULT_PROFILE_COLOR
  );

  const [prevPage, setPrevPage] = useState<string>('My Friends');

  const ionRouter = useIonRouter();
  const history = useHistory();
  const pageRef = useRef<any>();
  const params = useParams<{ userId: string }>();

  const userId = isLoggedInUsersProfile ? loggedInUserId : params.userId;

  const user = isLoggedInUsersProfile
    ? loggedInUser
    : userId
    ? userBank[userId]?.user
    : undefined;

  const isLoggedInUserFollowing =
    !isLoggedInUsersProfile && isUserAFollowingUserB(loggedInUser, user)
      ? true
      : false;
  const isThisAPrivateProfileAndHidden = isPrivateProfileAndHidden(
    user,
    loggedInUser
  );

  const isLoggedInUserBlockedByUser = isBlockedByUser(loggedInUser, user);
  const userDoesNotExist = Boolean(
    userId && userBank[userId] && !userBank[userId].user
  );

  const profileColor = Color(profileColorValue);
  const profileHeaderOverlayOpacity = Math.max(
    (1 -
      profileColor.contrast(Color('white')) / 25 +
      Math.sqrt(profileColor.luminosity())) /
      5,
    0.08
  );

  useIonViewWillEnter(() => {
    if (history.action === 'REPLACE' || history.action === 'POP') {
      return;
    }
    setPrevPage(ionRouter.routeInfo?.routeOptions?.prevPage || 'My Friends');
  }, [setPrevPage, ionRouter]);

  useIonViewWillLeave(() => {
    if (
      matchPath(history.location.pathname, {
        path: '/user/:userId/followers',
        exact: true,
        strict: true,
      }) ||
      matchPath(history.location.pathname, {
        path: '/settings',
        exact: true,
        strict: true,
      })
    ) {
      return;
    }
  });

  useEffect(() => {
    if (userId && !userBank[userId]) {
      getUser(userId);
    }
  }, [getUser, userBank, userId]);

  useEffect(() => {
    setProfileColorValue(user?.profileColor || DEFAULT_PROFILE_COLOR);
  }, [setProfileColorValue, user]);

  const goBack = () => {
    if (prevPage?.toLowerCase() === 'friends') {
      ionRouter.push(
        `/friends`,
        'back',
        'pop',
        {},
        customPageAnimationHorizontally
      );
    } else if (prevPage?.toLowerCase() === 'search') {
      ionRouter.push(
        `/search`,
        'back',
        'pop',
        {},
        customPageAnimationHorizontally
      );
    } else {
      if (ionRouter.canGoBack()) {
        ionRouter.goBack(customPageAnimationHorizontally);
      } else {
        ionRouter.push('/', 'back', 'pop', {}, customPageAnimationHorizontally);
      }
    }
  };

  const goToHome = () => {
    ionRouter.push(`/`, 'forward', 'push', {}, customPageAnimationVertically);
  };

  const ogImageURI = `https://firebasestorage.googleapis.com/v0/b/wishroll-373cb.appspot.com/o/public%2F${user?.id}%2Fogimage.png?alt=media`;

  return (
    <IonPage ref={pageRef}>
      <Helmet prioritizeSeoTags>
        <title>
          iFancy |{' '}
          {user
            ? `${user?.first_name} ${user?.last_name}'s Wishlist`
            : 'A Simple Wishlist App'}
        </title>
        <meta
          property='twitter:title'
          content={
            'iFancy | ' +
            user?.first_name +
            ' ' +
            user?.last_name +
            "'s Wishlist"
          }
        />

        <meta
          property='og:title'
          content={
            'iFancy | ' +
            user?.first_name +
            ' ' +
            user?.last_name +
            "'s Wishlist"
          }
        />
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='og:type' content='profile' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta property='og:image' content={ogImageURI} />
        <meta property='twitter:image' content={ogImageURI} />
      </Helmet>

      <StyledIonContent
        fullscreen={true}
        className={
          !isNonAuthedProfile && !loggedInUser ? 'extra-bottom-padding' : ''
        }
      >
        <BackgroundColor slot='fixed'>
          <HeaderBackground
            $profileColor={`${profileColor.object().r}, ${
              profileColor.object().g
            }, ${profileColor.object().b}`}
            $opacity={profileHeaderOverlayOpacity}
          ></HeaderBackground>
        </BackgroundColor>
        <ProfilePageContextProvider
          isNonAuthedProfile={isNonAuthedProfile}
          pageRef={pageRef}
          userId={user ? userId : undefined}
          isLoggedInUserFollowing={isLoggedInUserFollowing}
          isLoggedInUsersProfile={Boolean(
            isNonAuthedProfile ? true : isLoggedInUsersProfile
          )}
          isThisAPrivateProfileAndHidden={isThisAPrivateProfileAndHidden}
          isLoggedInUserBlockedByUser={isLoggedInUserBlockedByUser}
        >
          <ProfileToolbar>
            {loggedInUser?.id &&
              (loggedInUser?.id !== user?.id ? (
                <BackButton
                  color={profileColor.isDark() ? 'light' : 'dark'}
                  clear
                  iconLeft={chevronBack}
                  onClick={goBack}
                >
                  {prevPage}
                </BackButton>
              ) : null)}
          </ProfileToolbar>

          <MinHeightContainer>
            <div>
              <ProfileAvatarAndActions
                user={user}
                profileColorValue={profileColorValue}
                setProfileColorValue={setProfileColorValue}
              />
              <ProfileName>
                {user?.first_name ? (
                  user?.first_name + ' ' + user?.last_name
                ) : (
                  <IonSkeletonText
                    style={{
                      width: 200,
                      height: 40,
                      display: 'inline-block',
                      borderRadius: '8px',
                    }}
                  />
                )}
              </ProfileName>
              {isLoggedInUserFollowing && (
                <LastActivityText>
                  Last Login:{' '}
                  {user?.lastLogin?.toDate
                    ? formatDistanceToNowStrict(user?.lastLogin?.toDate(), {
                        addSuffix: true,
                      })
                    : 'Unknown'}
                </LastActivityText>
              )}
              <PushNotificationCTABox />
              {isNonAuthedProfile ? (
                <>
                  <LoginCTABox />
                  <WishList userId={userId} isLoggedInUsersProfile={true} />
                </>
              ) : userDoesNotExist ? (
                <ErrorMessageText>
                  <p>
                    <span role='img' aria-label='lock'>
                      🤔
                    </span>{' '}
                    Hmm... This user doesn&apos;t seem to exist. If that cannot
                    possibly be true, contact us!
                  </p>
                  <Button color='medium' onClick={goToHome}>
                    Back to Safety
                  </Button>
                </ErrorMessageText>
              ) : isLoggedInUserBlockedByUser ? (
                <ErrorMessageText>
                  <p>
                    You have been blocked from this profile{' '}
                    <span role='img' aria-label='lock'>
                      🔒
                    </span>
                  </p>
                </ErrorMessageText>
              ) : isThisAPrivateProfileAndHidden ? (
                <ErrorMessageText>
                  <p>
                    This profile is private{' '}
                    <span role='img' aria-label='lock'>
                      🔒
                    </span>
                    . Add {user?.first_name} as a friend to view the items they
                    fancy.
                  </p>
                </ErrorMessageText>
              ) : (
                <>
                  {!isLoggedInUsersProfile && user && (
                    <StaleProfileReminderAlert
                      user={user}
                      lastLogin={
                        user?.lastLogin &&
                        user.lastLogin.toDate &&
                        user.lastLogin.toDate()
                      }
                    />
                  )}
                  <ProfileSetupChecklist profileColor={profileColorValue} />
                  {/* <BirthdayRequestBox /> */}
                  {isLoggedInUsersProfile && <SuggestionList />}
                  <WishList
                    userId={userId}
                    isLoggedInUsersProfile={Boolean(isLoggedInUsersProfile)}
                  />
                </>
              )}
            </div>
          </MinHeightContainer>
          <Footer type='minimal' />
        </ProfilePageContextProvider>
      </StyledIonContent>
      <LoginPromptFooter isNonAuthedProfile={isNonAuthedProfile} />
    </IonPage>
  );
};
