import * as Sentry from '@sentry/react';

import { PrivateRoutes, PublicRoutes } from './Routes';
import React, { useContext, useEffect, useState } from 'react';

import { AppUrlListener } from './AppURLDeepLinkListener';
import ArchivedItems from '../pages/ArchivedItems';
import { Capacitor } from '@capacitor/core';
import { IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router';
import { ShareIntentListener } from '../components/ShareIntentListener';
import Tabbar from '../components/layout/Tabbar';
import { UserProfile } from '../pages/UserProfile';
import { useAuth } from '../contexts/AuthenticationContext';
import { useGetTrackingPermission } from '../hooks/useGetTrackingPermission';
import { useTidioControls } from '../hooks/useTidioControls';

type RoutedAppContextProps = {
  hasAddedPrivateRoutes: boolean;
};

export const RoutedAppContext = React.createContext<RoutedAppContextProps>(
  {} as RoutedAppContextProps
);

export const useRoutedAppContext = () => useContext(RoutedAppContext);

let Routes: JSX.Element[] = [...PublicRoutes];

export const RoutedAppContextProvider: React.FC = () => {
  const [hasAddedPrivateRoutes, setHasAddedPrivateRoutes] = useState(false);
  const { loggedInUserId } = useAuth();

  useGetTrackingPermission();
  useTidioControls();

  useEffect(() => {
    Sentry.setTag('Capacitor', Capacitor.isNativePlatform());
  }, []);

  useEffect(() => {
    const alreadyAdded = Routes.find(
      (route) => route.key === 'loggedInUser-profile'
    );

    if (loggedInUserId) {
      if (!alreadyAdded) {
        Routes = PublicRoutes.concat(PrivateRoutes);
        Routes.push(
          <Route
            key='loggedInUser-profile'
            exact
            strict
            path={[
              `/user/${loggedInUserId}/wishlists/:wishlistId`,
              `/user/${loggedInUserId}`,
            ]}
          >
            <UserProfile isLoggedInUsersProfile />
          </Route>
        );
        Routes.push(
          <Route
            key='loggedInUser-archive'
            exact
            strict
            path={[
              `/user/${loggedInUserId}/wishlists/:wishlistId/archive`,
              `/user/${loggedInUserId}/archive`,
            ]}
          >
            <ArchivedItems />
          </Route>
        );
        setHasAddedPrivateRoutes(true);
      }
    } else {
      if (alreadyAdded) {
        Routes = [...PublicRoutes];
        setHasAddedPrivateRoutes(false);
      }
    }
  }, [loggedInUserId]);

  if (loggedInUserId && !hasAddedPrivateRoutes) return <div />;

  return (
    <RoutedAppContext.Provider
      value={{
        hasAddedPrivateRoutes,
      }}
    >
      <IonRouterOutlet>{Routes}</IonRouterOutlet>
      <Tabbar />
      <ShareIntentListener />
      <AppUrlListener />
    </RoutedAppContext.Provider>
  );
};
