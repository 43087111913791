import { IonPopover, IonProgressBar, useIonRouter } from '@ionic/react';
import React, { useState } from 'react';

import { Button } from '../BaseUI/Button';
import { EditWishlistButton } from './EditWishlistButton';
import { IoNotificationsOutline } from 'react-icons/io5';
import { LiveButtonDot } from '../BaseUI/LiveButtonDot';
import { ReorderWishlist } from './ReorderWishlist';
import { SubscribeToWishlistUpdatesButton } from './SubscribeToWishlistUpdatesButton';
import { ViewArchivedItems } from './ViewArchivedItems';
import { WISHLIST_VISIBILITY_OPTIONS } from '../../constants/constants';
import { WishlistConfig } from '../../types/GlobalTypes';
import { WishlistsSelectButton } from './WishlistsSelectButton';
import { chevronDownCircleOutline } from 'ionicons/icons';
import { filterWishlistsInSelectButton } from '../../utils/helper-functions/filterWishlistsInSelectButton';
import { formatDistanceToNowStrict } from 'date-fns';
import { styled } from 'goober';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';
import { useProfilePageContext } from '../../contexts/ProfilePageContext';

interface Props {
  handleShowListViewClick: () => void;
}

const WishlistHeaderContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WishlistActionButton = styled(Button as any)``;

const PurchasedText = styled('p')`
  font-size: 14px;
  margin: 0;
  color: var(--ion-color-primary);
  @media (max-width: 398px) {
    font-size: 12px;
  }
`;

const CompletionProgressBar = styled('div')`
  flex: 1;
  padding: 0 8px;
  ion-progress-bar {
    border-radius: 9px;
    height: 5px;
  }
`;

const PercentPurchasedContainer = styled('div')`
  display: flex;
  align-items: center;
  padding: 10px 4px;
  border-bottom: 1px solid var(--ion-color-light);
`;

const PurchasedRatioText = styled('p')`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: var(--ion-color-medium);
`;

const Divider = styled('div')`
  display: block;
  height: 1px;
  width: 100%;
  background: var(--ion-color-medium);
  opacity: 0.2;
  border-radius: 4px;
  margin-bottom: 20px;
  @media (max-width: 468px) {
    margin-top: 10px;
  }
`;

const WishlistActionsContainer = styled('div')`
  display: block;
  width: 100%;
`;

const WishlistMetadataContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 8px;
`;

const VisibilityContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    font-size: inherit;
    margin-right: 4px;
    color: var(--ion-color-medium);
  }
  p {
    font-size: inherit;
    font-weight: 600;
    color: var(--ion-color-medium);
  }
  @media (max-width: 468px) {
    font-size: 14px;
  }
`;

const LastItemUpdatedAt = styled('p')`
  color: var(--ion-color-medium);
  letter-spacing: 0px;
  .muted {
    opacity: 0.2;
  }
  @media (max-width: 468px) {
    font-size: 14px;
  }
`;

const SignInToSeePurchasedText = styled('p')`
  font-size: 12px;
  color: var(--ion-color-danger);
  margin: 0;
  padding-top: 8px;
  line-height: 1.1;
`;

const SubscribedToNotifications = styled('div')`
  align-self: center;
  align-items: center;
  background: #96eec9;
  font-weight: 600;
  color: #235440;
  border-radius: 8px;
  padding: 4px 12px 4px 8px;
  border: 1px dashed #7bd2ae;
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
  line-height: 1;
  margin-top: 5px;
  display: inline-flex;
  svg {
    margin-right: 4px;
  }
  @media (max-width: 468px) {
    font-size: 13px;
  }
`;

export const WishlistActionsSection: React.FC<Props> = ({
  handleShowListViewClick,
}) => {
  const { loggedInUser, updateLoggedInUser } = useLoggedInUser();

  const [wishlistMoreOptionsEvent, setWishlistMoreOptionsEvent] =
    useState<any>();
  const {
    selectedWishlist,
    wishlistItems,
    wishlists,
    isLoggedInUserFollowing,
    userId,
    isLoggedInUsersProfile,
    isNonAuthedProfile,
    isLoggedInUserBlockedByUser,
  } = useProfilePageContext();

  const ionRouter = useIonRouter();

  if (!userId && !isNonAuthedProfile) return null;

  const handleOpenProfileMoreOptions = (e: any) => {
    e.persist();
    setWishlistMoreOptionsEvent(e);
  };

  const canSeeSubscribeToWishlistUpdates =
    !isLoggedInUsersProfile &&
    !isNonAuthedProfile &&
    loggedInUser &&
    isLoggedInUserFollowing &&
    !isLoggedInUserBlockedByUser &&
    selectedWishlist &&
    (selectedWishlist.visibility === 'public' ||
      selectedWishlist.visibility === 'friends-only');

  const handleWishlistMoreOptionsPopoverDismiss = () => {
    setWishlistMoreOptionsEvent(undefined);
    if (
      canSeeSubscribeToWishlistUpdates &&
      !loggedInUser.featureAwareness?.subscribeToWishlistUpdates
    ) {
      updateLoggedInUser({
        featureAwareness: {
          ...(loggedInUser.featureAwareness || {}),
          subscribeToWishlistUpdates: true,
        },
      });
    }
  };

  const handleWishlistSelected = (wishlist: WishlistConfig) => {
    ionRouter.push(
      `/user/${userId}${
        wishlist.id === 'original' ? '' : `/wishlists/${wishlist?.id}`
      }`,
      'none',
      'push'
    );
  };

  const wishlistOfVisibleToUserItems = wishlistItems
    ? Object.values(wishlistItems)
        .map((item) => item)
        .filter((item) => {
          if (
            isLoggedInUsersProfile ||
            !item.visible_to ||
            (loggedInUser?.id && item.visible_to.includes(loggedInUser?.id))
          )
            return true;
          return false;
        })
    : 0;

  const itemsPurchased =
    isNonAuthedProfile ||
    (!isLoggedInUsersProfile && (!loggedInUser || !isLoggedInUserFollowing))
      ? '?'
      : wishlistOfVisibleToUserItems
      ? wishlistOfVisibleToUserItems.filter((item) =>
          Boolean(item.purchased?.user_id)
        ).length
      : 0;

  const percentOfItemsPurchased =
    itemsPurchased === '?'
      ? 0
      : wishlistOfVisibleToUserItems
      ? itemsPurchased / wishlistOfVisibleToUserItems.length
      : 0;

  const selectedWishlistVisibility = WISHLIST_VISIBILITY_OPTIONS.find(
    (i) => i.value === selectedWishlist?.visibility
  );

  const lastWishlistItemAddedOn =
    wishlistItems &&
    Object.values(wishlistItems)
      .map((i) => i.createdAt?.toDate && i.createdAt.toDate())
      .sort((a, b) => (a && b ? b.getTime() - a.getTime() : 0))
      .shift();

  return (
    <WishlistActionsContainer>
      <Divider />

      <WishlistHeaderContainer>
        <WishlistsSelectButton
          large
          wishlists={filterWishlistsInSelectButton(
            wishlists,
            isLoggedInUsersProfile,
            isLoggedInUserFollowing
          )}
          showAddNewButton={isLoggedInUsersProfile}
          onSelect={handleWishlistSelected}
          selectedWishlist={selectedWishlist}
        />

        <IonPopover
          showBackdrop={true}
          mode='ios'
          event={wishlistMoreOptionsEvent}
          isOpen={wishlistMoreOptionsEvent ? true : false}
          onDidDismiss={handleWishlistMoreOptionsPopoverDismiss}
        >
          <PercentPurchasedContainer>
            <CompletionProgressBar>
              <PurchasedRatioText>
                {itemsPurchased}&nbsp;/&nbsp;
                {wishlistOfVisibleToUserItems
                  ? wishlistOfVisibleToUserItems.length
                  : '-'}
              </PurchasedRatioText>
              <PurchasedText>Purchased</PurchasedText>
              <IonProgressBar
                color='primary'
                value={percentOfItemsPurchased}
              ></IonProgressBar>
              {itemsPurchased === '?' ? (
                <SignInToSeePurchasedText>
                  {loggedInUser
                    ? 'You must be following this user to see the purchased count'
                    : 'Login to see the count of purchased items'}
                </SignInToSeePurchasedText>
              ) : undefined}
            </CompletionProgressBar>
          </PercentPurchasedContainer>

          {canSeeSubscribeToWishlistUpdates && (
            <SubscribeToWishlistUpdatesButton
              onClick={handleWishlistMoreOptionsPopoverDismiss}
            />
          )}
          {isLoggedInUsersProfile && !isNonAuthedProfile && (
            <>
              <ReorderWishlist
                onClick={() => {
                  handleWishlistMoreOptionsPopoverDismiss();
                  handleShowListViewClick();
                }}
              />
              <EditWishlistButton
                onClick={handleWishlistMoreOptionsPopoverDismiss}
              />
              <ViewArchivedItems
                onClick={handleWishlistMoreOptionsPopoverDismiss}
                userId={userId || ''}
              />
            </>
          )}
        </IonPopover>
        <WishlistActionButton
          iconRight={chevronDownCircleOutline}
          color='primary'
          onClick={handleOpenProfileMoreOptions}
          iconLeft={
            canSeeSubscribeToWishlistUpdates &&
            !loggedInUser.featureAwareness?.subscribeToWishlistUpdates ? (
              <LiveButtonDot />
            ) : undefined
          }
        >
          Actions
        </WishlistActionButton>
      </WishlistHeaderContainer>
      <WishlistMetadataContainer>
        <VisibilityContainer>
          {selectedWishlistVisibility?.icon}
          <p>{selectedWishlistVisibility?.name}</p>
        </VisibilityContainer>
        {lastWishlistItemAddedOn && (
          <LastItemUpdatedAt>
            <span className='muted'>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span>
              Last Item Added:{' '}
              {formatDistanceToNowStrict(lastWishlistItemAddedOn, {
                addSuffix: true,
              })}
            </span>
          </LastItemUpdatedAt>
        )}
      </WishlistMetadataContainer>
      {loggedInUser?.id &&
        selectedWishlist?.notificationSubscribers?.includes(
          loggedInUser.id
        ) && (
          <SubscribedToNotifications>
            <IoNotificationsOutline /> Subscribed to Updates
          </SubscribedToNotifications>
        )}
    </WishlistActionsContainer>
  );
};
