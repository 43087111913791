import { IonIcon, useIonRouter } from '@ionic/react';
import React, { useContext } from 'react';
import { chevronForward, close } from 'ionicons/icons';

import { Notification } from '../types/GlobalTypes';
import { NotificationsContext } from '../contexts/NotificationsContext';
import { customPageAnimationTabLeft } from '../utils/pageTransition';
import { formatDistanceToNowStrict } from 'date-fns';
import { isUserProfileRoot } from './layout/Tabbar';
import { styled } from 'goober';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

interface Props {
  notification: Notification;
}

const Icon = styled('p')`
  font-size: 36px;
`;

const UnreadDot = styled('div')`
  width: 13px;
  height: 13px;
  border-radius: 13px;
  background: var(--ion-color-danger);
  margin: 6px;
  box-shadow: var(--box-shadow-md);
  opacity: 1;
  transition: opacity 0.1s ease;
`;

const TitleText = styled('p')`
  color: var(--ion-color-dark);
  font-size: 700;
  opacity: 0.8;
  font-family: 'Nunito Sans', sans-serif;
  margin: -1px 0;
`;

const InfoContainer = styled('div')`
  flex: 1;
  padding: 0 16px;
`;

const DatetimeText = styled('p')`
  opacity: 0.6;
  color: var(--ion-color-dark);
  font-size: 14px;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const Description = styled('p')`
  text-align: left;
  width: 100%;
  margin: -1px 0;
  color: var(--ion-color-dark);
  line-height: 115%;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

const ActionContainer = styled('div')`
  position: relative;
  display: flex;
`;

const ActionText = styled('p')`
  position: absolute;
  font-size: 15px;
  font-weight: 700;
  top: 50%;
  left: -4px;
  transform: translate(-70%, -54%);
  opacity: 0;
  transition: all 0.2s ease;
  color: var(--ion-color-dark);
`;

const ActionIcon = styled(IonIcon)`
  font-size: 19px;
  opacity: 0.3;
`;

const Container = styled('div')`
  display: flex;
  padding: 20px;
  border-radius: 19px;
  background: rgba(255, 255, 255, 0.45);
  margin: 10px 20px;
  transition: opacity 0.3s ease;
  cursor: pointer;
  align-items: center;
  @media (max-width: 500px) {
    padding: 10px 15px;
    margin: 8px 10px;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.55);
    box-shadow: var(--box-shadow-sm);
    .if-unread-dot {
      opacity: 0.3;
    }
    .if-action-text {
      opacity: 1;
      transform: translate(-100%, -54%);
    }
    .if-action-icon {
      opacity: 1;
    }
    .if-info-container {
      mask-image: -webkit-gradient(
        linear,
        right top,
        left bottom,
        from(rgba(0, 0, 0, 0.2)),
        to(rgba(0, 0, 0, 1))
      );
      -webkit-mask-image: -webkit-gradient(
        linear,
        right top,
        left bottom,
        from(rgba(0, 0, 0, 0.2)),
        to(rgba(0, 0, 0, 1))
      );
    }
  }
`;

export const NotificationItem: React.FC<Props> = ({ notification }) => {
  const ionRouter = useIonRouter();
  const { loggedInUser } = useLoggedInUser();
  const { markRead } = useContext(NotificationsContext);

  const handleClick = () => {
    if (!loggedInUser?.id) return;
    if (notification.path) {
      const tabStack =
        isUserProfileRoot(notification.path) &&
        !notification.path.includes(loggedInUser.id)
          ? 'friends'
          : undefined;
      ionRouter.push(
        notification.path,
        'forward',
        'push',
        { tabStack } as any,
        customPageAnimationTabLeft
      );
    }
    markRead(notification);
  };

  return (
    <Container onClick={handleClick}>
      <Icon>{notification.icon}</Icon>
      <InfoContainer className='if-info-container'>
        <DatetimeText>
          {notification.createdAt?.toDate
            ? formatDistanceToNowStrict(notification.createdAt?.toDate(), {
                addSuffix: true,
              })
            : ''}
        </DatetimeText>
        {notification.title && <TitleText>{notification.title}</TitleText>}
        <Description>{notification.body}</Description>
        <div />
      </InfoContainer>
      {!notification.read && <UnreadDot className='if-unread-dot' />}
      <ActionContainer>
        <ActionText className='if-action-text'>
          {notification.path ? 'View' : notification.read ? null : 'Dismiss'}
        </ActionText>
        {notification.path ? (
          <ActionIcon
            className='if-action-icon'
            color='dark'
            icon={chevronForward}
          />
        ) : notification.read ? null : (
          <ActionIcon className='if-action-icon' color='dark' icon={close} />
        )}
      </ActionContainer>
    </Container>
  );
};
