import {
  ImageUploadContext,
  ImageUploadSuccess,
} from '../../contexts/ImageUploadContext';
import React, { useContext, useEffect, useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';

import { FaFileUpload } from 'react-icons/fa';
import { FaUserAstronaut } from 'react-icons/fa6';
import { IonSpinner } from '@ionic/react';
import { storage } from '../../firebase';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { useDelayMount } from '../../hooks/useDelayMount';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';

interface Props {
  image?: string;
  isOnLoggedInUsersProfile: boolean;
  className?: string;
}

const AvatarContainer = styled('div')`
  width: calc(var(--image-size) + var(--image-border) + var(--image-border));
  height: calc(var(--image-size) + var(--image-border) + var(--image-border));
  position: relative;
  padding: calc(var(--image-border) * 2);
  background: white;
  border-radius: calc(var(--border-radius) * 1.25);
  box-shadow: var(--box-shadow-lg);

  img {
    margin: calc(var(--image-border) * -1);
    width: var(--image-size);
    height: var(--image-size);
    max-width: none;
    object-fit: cover;
    border-radius: var(--border-radius);
  }
`;

const NoUserContainer = styled('div')`
  width: var(--image-size);
  height: var(--image-size);
  margin: calc(var(--image-border) * -1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  background: var(--ion-color-grey);
  color: var(--ion-color-medium);
  svg {
    font-size: calc(var(--image-size) / 2);
  }
`;

const UploadPhotoButton = styled('button')`
  position: absolute;
  cursor: pointer;
  top: calc(var(--image-border) * 2);
  right: calc(var(--image-border) * 2);
  bottom: calc(var(--image-border) * 2);
  left: calc(var(--image-border) * 2);
  width: var(--image-size);
  height: var(--image-size);
  margin: calc(var(--image-border) * -1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  background: var(--ion-color-grey);
  color: var(--ion-color-medium);
  opacity: 0;
  transition: opacity 0.1s ease;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 1;
    }
  }
  &.has-image {
    opacity: 0;
  }
  @media (hover: hover) and (pointer: fine) {
    &.has-image:hover {
      opacity: 0.92;
    }
  }

  svg {
    font-size: 36px;
    @media (max-width: 650px) {
      font-size: 26px;
    }
  }
`;

const LoadingContainer = styled('div')`
  position: absolute;
  z-index: 2;
  top: calc(var(--image-border) * 2);
  right: calc(var(--image-border) * 2);
  bottom: calc(var(--image-border) * 2);
  left: calc(var(--image-border) * 2);
  width: var(--image-size);
  height: var(--image-size);
  margin: calc(var(--image-border) * -1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  background: var(--ion-color-light);
  color: var(--ion-color-medium);
  opacity: 1;
  transition: opacity 0s ease;
  &.hide {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
`;

export const BaseAvatar: React.FC<Props> = ({
  image,
  isOnLoggedInUsersProfile,
  className,
}) => {
  const [imageUrl, setImageUrl] = useState<
    { imagePath: string; downloadUrl: string } | undefined
  >();
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [imageReloadLoading, setImageReloadLoading] = useState<boolean>(false);
  const { loggedInUser, updateLoggedInUser } = useLoggedInUser();
  const { open } = useContext(ImageUploadContext);

  useEffect(() => {
    let abort = false;
    if (image) {
      if (!imageUrl || imageUrl.imagePath !== image) {
        const getImageDownloadUrl = async () => {
          try {
            setImageLoading(true);
            if (!image) throw new Error('No image');
            const downloadUrl = await getDownloadURL(ref(storage, image));
            if (abort) return;

            setImageUrl({ imagePath: image, downloadUrl });
          } catch (err) {
            console.log(err);
            setImageLoading(false);
          } finally {
            if (!abort) {
              setImageReloadLoading(false);
            }
          }
        };
        getImageDownloadUrl();
      }
    } else {
      setImageUrl(undefined);
      setImageLoading(false);
    }

    return () => {
      abort = true;
    };
  }, [imageUrl, image]);

  const imageChangeCallback = async (
    imageRes?: ImageUploadSuccess | undefined
  ) => {
    if (imageRes && loggedInUser?.id) {
      setImageLoading(true);
      setImageReloadLoading(true);
      await new Promise((r) => setTimeout(r, 5000));
      await updateLoggedInUser({
        avatarImg: imageRes.fullPath.replace(
          'avatar.',
          'resized/avatar_200x200.'
        ),
      });
      setImageUrl(undefined);
    }
  };

  const handleImageUploadError = (err: Error) => {
    toast.error(err.message);
  };

  const handleImageUploadButtonClick = () => {
    if (!loggedInUser?.id) {
      toast.error('You must be logged in to upload an image');
      return;
    }
    open(imageChangeCallback, handleImageUploadError, {
      pathPrefix: `public/${loggedInUser.id}/avatars/`,
      imageFileName: 'avatar',
    });
  };

  const {
    shouldRender: shouldRenderLoading,
    shouldAnimate: shouldAnimateLoading,
  } = useDelayMount(imageLoading, 300);

  return (
    <AvatarContainer className={className}>
      {shouldRenderLoading && (
        <LoadingContainer
          className={imageLoading && shouldAnimateLoading ? '' : 'hide'}
        >
          {imageReloadLoading && <IonSpinner name='crescent' color='medium' />}
        </LoadingContainer>
      )}
      {isOnLoggedInUsersProfile && loggedInUser?.id && (
        <UploadPhotoButton
          className={image ? 'has-image' : ''}
          onClick={handleImageUploadButtonClick}
        >
          <FaFileUpload />
        </UploadPhotoButton>
      )}
      {imageUrl ? (
        <img
          src={imageUrl?.downloadUrl}
          onLoad={() => setImageLoading(false)}
          alt='avatar'
        />
      ) : (
        <NoUserContainer>
          <FaUserAstronaut />
        </NoUserContainer>
      )}
    </AvatarContainer>
  );
};
