import {
  Contacts,
  PhonePayload as ContactsAPIPhoneNumber,
} from '@capacitor-community/contacts';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { Timestamp, db } from '../firebase';
import {
  addYears,
  format,
  formatDistanceToNow,
  isPast,
  isSameDay,
  isSameYear,
  isToday,
  parseISO,
} from 'date-fns';
import { close, shuffleOutline } from 'ionicons/icons';
import { doc, getDoc, setDoc } from 'firebase/firestore';

import { Button } from '../components/BaseUI/Button';
import { Helmet } from 'react-helmet-async';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StyledIonOuterToolbar } from '../components/layout/StyledOuterIonToolbar';
import { customPageAnimationHorizontally } from '../utils/pageTransition';
import { handleCatchError } from '../utils/handleCatchError';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

const StyledIonTitle = styled(IonTitle)``;

const BirthdateInToolbar = styled('p')`
  font-size: 18px;
  letter-spacing: 0;
  align-self: flex-end;
`;

const CloseButton = styled(Button as any)`
  padding: 4px;
  .btn-icon {
    font-size: 38px;
  }
`;

const BirthdayOnItemText = styled('p')`
  text-align: right;
  line-height: 95%;
`;

const BirthdayInText = styled('small')`
  font-size: 12px;
`;

const BirthdayInLargeText = styled('p')`
  font-size: 15px;
  font-weight: 700;
  font-family: 'Nunito Sans', sans-serif;
  color: var(--ion-color-primary);
  margin-top: 36px;
`;

const FlexContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 36px;
`;

const StyledShuffleButton = styled(Button as any)`
  margin-left: 8px;
  padding: 6px 12px;
  .btn-text {
    font-size: 32px;
  }
`;

const SendToButton = styled(Button as any)`
  margin-bottom: 8px;
`;

const StyledH4 = styled('h4')`
  font-weight: 700;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 19px;
  margin-top: 24px;
`;

const PreviouslySentMessage = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 12px;
  align-items: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const DateText = styled('p')`
  font-size: 13px;
  opacity: 0.8;
  color: black;
  letter-spacing: 0;
  flex-shrink: 0;
`;

const SentToText = styled('p')`
  font-size: 11px;
  letter-spacing: 0;
  opacity: 0.6;
  color: black;
  flex-shrink: 0;
`;

const MessageBubble = styled('p')`
  border-radius: 12px;
  padding: 6px 12px;
  background: #1982fc;
  color: white;
`;

const MessageBubbleContainer = styled('div')`
  flex: 1;
  margin-left: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ModalIonContent = styled(IonContent)`
  --padding-start: 16px;
  --padding-end: 16px;
`;

const StyledIonInput = styled(IonTextarea)`
  border: 1px solid rgba(0, 0, 0, 0.3);
  --padding-start: 12px;
`;

type BirthdayContact = {
  contactId: string;
  displayName: string;
  phoneNumbers: ContactsAPIPhoneNumber[];
  birthday: string;
  previousSends: PreviousMessage[];
};

type PreviousMessage = {
  date: Timestamp;
  body: string;
  number: string;
};

type ContactsSavedInDB = {
  contacts: BirthdayContact[];
};

type SuggestedBirthdayMessage = {
  text: string;
};

type MessageTemplateResponse = {
  messages: SuggestedBirthdayMessage[];
  emojis: string;
};

const giveDateYearOfNextOccurrence = (date: Date): Date => {
  const thisYearsOccurrence = new Date(
    date.setFullYear(new Date().getFullYear())
  );
  if (!isToday(thisYearsOccurrence) && isPast(thisYearsOccurrence)) {
    return addYears(thisYearsOccurrence, 1);
  } else {
    return thisYearsOccurrence;
  }
};

const BirthdaySMSReminders: React.FC = () => {
  const { loggedInUser } = useLoggedInUser();

  const [savedContacts, setSavedContacts] = useState<BirthdayContact[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [suggestedBirthdayMessages, setSuggestedBirthdayMessages] =
    useState<MessageTemplateResponse>();
  const [happyBirthdayMessageBody, setHappyBirthdayMessageBody] = useState('');
  const [selectedContact, setSelectedContact] = useState<
    BirthdayContact | undefined
  >();

  const pageRef = useRef<any>();

  useEffect(() => {
    setHappyBirthdayMessageBody(getRandomBirthdayMessageSuggestion());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact]);

  const getRandomBirthdayMessageSuggestion = (): string => {
    if (!suggestedBirthdayMessages) return 'No suggestions available!!!!';
    const baseMessage =
      suggestedBirthdayMessages.messages[
        Math.floor(Math.random() * suggestedBirthdayMessages.messages.length)
      ].text;

    const getRandomEmoji = () => {
      const randomEmoji = suggestedBirthdayMessages.emojis
        .codePointAt(
          Math.floor(
            (Math.random() * suggestedBirthdayMessages.emojis.length) / 2
          ) * 2
        )
        ?.toString(16);
      return String.fromCodePoint(('0x' + randomEmoji) as any);
    };

    const filledMessage = baseMessage
      .split('#')
      .map((i) => (i === '' ? getRandomEmoji() : i))
      .join('')
      .normalize();

    return filledMessage;
  };

  useEffect(() => {
    const getContactsFromDB = async () => {
      try {
        if (!loggedInUser?.id) {
          setIsLoading(false);
          return toast.error(
            'You do not have permission to access this feature'
          );
        }

        const { record } = await (
          await fetch(
            'https://api.jsonbin.io/v3/b/61e9c1a16c4a232f9d863521/1',
            {
              headers: {
                'Content-Type': 'application/json',
                'X-Master-Key':
                  '$2b$10$soYPWBq7e9UQpzYFCjtHOOEknE/KnF1xS94WQ1go4Y1.DTaE1FFEe',
              },
            }
          )
        ).json();

        setSuggestedBirthdayMessages(record);

        const contactDoc = await getDoc(
          doc(
            db,
            `users/${loggedInUser.id}/contact-birthdays/${loggedInUser.id}`
          )
        );

        const { contacts } = contactDoc.data() as ContactsSavedInDB;

        setSavedContacts(contacts || []);

        setIsLoading(false);
      } catch (err) {
        handleCatchError(err);
        setIsLoading(false);
      }
    };
    getContactsFromDB();
  }, [loggedInUser]);

  const syncContacts = async () => {
    if (isLoading) {
      return toast.error(
        'Wait until contacts are loaded please and thank you.'
      );
    }

    if (!savedContacts) {
      return toast.error('Appears data corruption from loading failure');
    }

    if (!loggedInUser?.id) {
      return toast.error('Well somebody needs to be logged in for this.');
    }

    setIsLoading(true);
    try {
      const result = await Contacts.getContacts({
        projection: {
          name: true,
          phones: true,
          birthday: true,
        },
      });
      const contactsToSave = [];
      for (const contact of result.contacts) {
        if (!contact.name?.display) continue;
        if (!contact.birthday) continue;
        if (!contact.phones?.length) continue;
        const contactToSave: BirthdayContact = {
          displayName: contact.name.display,
          contactId: contact.contactId,
          birthday: `${contact.birthday.year}-${contact.birthday.month}-${contact.birthday.day}`,
          phoneNumbers: contact.phones,
          previousSends:
            savedContacts.find((item) => item.contactId === contact.contactId)
              ?.previousSends || [],
        };

        contactsToSave.push(contactToSave);
      }

      const newSavedContacts: ContactsSavedInDB = {
        contacts: contactsToSave,
      };

      await setDoc(
        doc(
          db,
          `users/${loggedInUser.id}/contact-birthdays/${loggedInUser.id}`
        ),
        newSavedContacts
      );

      setSavedContacts(contactsToSave);
      setIsLoading(false);
    } catch (err) {
      handleCatchError(err);
      setIsLoading(false);
    }
  };

  const shuffleSuggestion = () => {
    if (!suggestedBirthdayMessages?.messages?.length)
      return toast.error("Wasn't able to load suggestions");
    setHappyBirthdayMessageBody(getRandomBirthdayMessageSuggestion());
  };

  const sendMessage = async (
    contact: BirthdayContact,
    phoneNumber: ContactsAPIPhoneNumber
  ) => {
    try {
      if (!loggedInUser?.id) throw new Error('Not logged in');
      if (!savedContacts) throw new Error('Data missing');

      const newPreviousMessage: PreviousMessage = {
        date: Timestamp.fromDate(new Date()),
        body: happyBirthdayMessageBody,
        number: phoneNumber.number || 'Unknown',
      };

      const newContactsToSave: ContactsSavedInDB = {
        contacts: [...savedContacts],
      };

      newContactsToSave.contacts
        .find((item) => item.contactId === contact.contactId)
        ?.previousSends.push(newPreviousMessage);

      await setDoc(
        doc(
          db,
          `users/${loggedInUser.id}/contact-birthdays/${loggedInUser.id}`
        ),
        newContactsToSave
      );

      setSavedContacts(newContactsToSave.contacts);

      window.location.href = `sms:${phoneNumber.number}&body=${happyBirthdayMessageBody}`;
    } catch (err) {
      handleCatchError(err);
    }
  };

  return (
    <>
      <IonPage ref={pageRef}>
        <Helmet prioritizeSeoTags>
          <meta name='robots' content='noindex' />
        </Helmet>
        <IonHeader mode='ios' translucent={true}>
          <StyledIonOuterToolbar>
            <IonButtons slot='start'>
              <IonBackButton
                defaultHref='/friends'
                routerAnimation={customPageAnimationHorizontally}
                color='dark'
              />
            </IonButtons>
            <IonTitle>Birthday SMS Reminders</IonTitle>
          </StyledIonOuterToolbar>
        </IonHeader>
        <IonContent fullscreen={true}>
          <PageWrapper footerType='minimal' hideDisclaimer>
            <IonHeader mode='ios' collapse='condense'>
              <IonToolbar>
                <StyledIonTitle size='large'>
                  Birthday SMS Reminders
                </StyledIonTitle>
              </IonToolbar>
            </IonHeader>
            <Button color='primary' onClick={syncContacts}>
              Sync Contacts
            </Button>
            {isLoading && <IonSpinner name='crescent' color='dark' />}
            <IonList>
              {savedContacts?.length
                ? savedContacts
                    .sort((a, b) => {
                      return (
                        +giveDateYearOfNextOccurrence(parseISO(a.birthday)) -
                        +giveDateYearOfNextOccurrence(parseISO(b.birthday))
                      );
                    })
                    .map((contact) => {
                      const nextBdayDate = giveDateYearOfNextOccurrence(
                        parseISO(contact.birthday)
                      );
                      return (
                        <React.Fragment key={contact.contactId}>
                          <IonItem
                            color={
                              isSameDay(nextBdayDate, new Date())
                                ? 'success'
                                : isSameYear(nextBdayDate, new Date())
                                ? undefined
                                : 'light'
                            }
                            onClick={() => setSelectedContact(contact)}
                          >
                            <IonLabel>{contact.displayName}</IonLabel>
                            <BirthdayOnItemText slot='end'>
                              {format(nextBdayDate, 'MMM do')}

                              <br />
                              <BirthdayInText>
                                {isToday(nextBdayDate)
                                  ? 'Today!'
                                  : formatDistanceToNow(nextBdayDate, {
                                      addSuffix: true,
                                    })}
                              </BirthdayInText>
                            </BirthdayOnItemText>
                          </IonItem>
                          <IonModal
                            presentingElement={pageRef.current}
                            isOpen={
                              selectedContact?.contactId === contact.contactId
                            }
                            onDidDismiss={() => setSelectedContact(undefined)}
                          >
                            <IonHeader mode='ios' translucent={true}>
                              <IonToolbar>
                                <IonTitle>{contact.displayName}</IonTitle>
                                <IonButtons slot='end'>
                                  <CloseButton
                                    clear
                                    color='dark'
                                    iconLeft={close}
                                    onClick={() =>
                                      setSelectedContact(undefined)
                                    }
                                  />
                                </IonButtons>
                              </IonToolbar>
                            </IonHeader>
                            <ModalIonContent>
                              <IonHeader mode='ios' collapse='condense'>
                                <IonToolbar>
                                  <StyledIonTitle size='large'>
                                    {contact.displayName}
                                  </StyledIonTitle>
                                  <BirthdateInToolbar slot='end'>
                                    {format(nextBdayDate, 'MMM do')}
                                  </BirthdateInToolbar>
                                </IonToolbar>
                              </IonHeader>
                              <BirthdayInLargeText>
                                Birthday{' '}
                                {isToday(nextBdayDate)
                                  ? 'Today!'
                                  : formatDistanceToNow(nextBdayDate, {
                                      addSuffix: true,
                                    })}
                              </BirthdayInLargeText>
                              <FlexContainer>
                                <StyledIonInput
                                  rows={4}
                                  value={happyBirthdayMessageBody}
                                  onIonInput={(e) =>
                                    setHappyBirthdayMessageBody(e.detail.value!)
                                  }
                                />
                                <StyledShuffleButton
                                  onClick={shuffleSuggestion}
                                  clear
                                  color='dark'
                                >
                                  <IonIcon color='dark' icon={shuffleOutline} />
                                </StyledShuffleButton>
                              </FlexContainer>
                              {contact.phoneNumbers.map((phoneNumber) => (
                                <SendToButton
                                  onClick={() =>
                                    sendMessage(contact, phoneNumber)
                                  }
                                  color='dark'
                                  key={phoneNumber.number}
                                >
                                  Send to {phoneNumber.label}{' '}
                                  {phoneNumber.number}
                                </SendToButton>
                              ))}
                              <StyledH4>Previously Sent Messages</StyledH4>
                              {contact.previousSends
                                .sort((a, b) => {
                                  return (
                                    +new Date(b.date.toDate()) -
                                    +new Date(a.date.toDate())
                                  );
                                })
                                .map((message) => (
                                  <PreviouslySentMessage
                                    key={message.date.seconds}
                                  >
                                    <DateText>
                                      {format(message.date.toDate(), 'PP')}
                                      <br />
                                      {format(message.date.toDate(), 'p')}
                                    </DateText>
                                    <MessageBubbleContainer>
                                      <MessageBubble>
                                        {message.body}
                                      </MessageBubble>
                                      <SentToText>
                                        Sent to {message.number}
                                      </SentToText>
                                    </MessageBubbleContainer>
                                  </PreviouslySentMessage>
                                ))}
                            </ModalIonContent>
                          </IonModal>
                        </React.Fragment>
                      );
                    })
                : undefined}
            </IonList>
          </PageWrapper>
        </IonContent>
      </IonPage>
    </>
  );
};

export default BirthdaySMSReminders;
