import { IonSpinner, IonTextarea } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import { Button } from './BaseUI/Button';
import { Capacitor } from '@capacitor/core';
import { RateApp } from 'capacitor-rate-app';
import { Rating } from './Rating';
import { analytics } from '../firebase';
import { differenceInDays } from 'date-fns';
import { handleCatchError } from '../utils/handleCatchError';
import { logEvent } from 'firebase/analytics';
import packageJson from '../../package.json';
import { styled } from 'goober';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

const SendFeedbackButton = styled(Button as any)`
  flex: 1;
  margin-top: 12px;
  ion-spinner {
    margin: -5px 0;
    height: 23px;
    width: 23px;
  }
`;

const StyledIonTextarea = styled(IonTextarea)`
  background: rgba(0, 0, 0, 0.03);
  width: 100%;
  flex: 0;
  border-radius: 12px;
  --padding-start: 12px;
`;

const Container = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: calc(100% - 40px);
  padding: 20px;
  padding-top: 10px;
  margin: 20px;
  background: #f6eeff;
  border: 2px dashed #d4acff;
  border-radius: 10px;
  @media (max-width: 460px) {
    width: calc(100% - 24px);
    margin: 20px 12px;
    padding: 14px;
    padding-top: 8px;
  }
`;

const CloseButton = styled(Button as any)`
  font-weight: 700;
  margin-right: -12px;
`;

const StyledH4 = styled('h4')`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
`;

const Message = styled('p')``;

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

interface Props {}

export const FeedbackInlineBox: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(true);
  const [rating, setRating] = useState(0);

  const { loggedInUser } = useLoggedInUser();

  useEffect(() => {
    const lastClosed = localStorage.getItem(
      'ifancy-feedbackInlineBoxLastShown'
    );

    if (lastClosed) {
      const lastClosedDate = new Date(lastClosed);

      if (Math.abs(differenceInDays(lastClosedDate, new Date())) < 30) {
        setShow(false);
      }
    }
  }, []);

  const submitFeedback = async () => {
    setIsLoading(true);
    try {
      if (!description) {
        throw new Error('Description missing');
      }
      const body = JSON.stringify({
        title: 'Feedback',
        description: 'Rating: ' + rating + ' - ' + description,
        ticketLabels: ['general'],
        user_info: {
          id: loggedInUser?.id || 'Not logged in',
          name: loggedInUser
            ? loggedInUser.first_name + ' ' + loggedInUser.last_name
            : 'Not logged in',
          profile_page: loggedInUser
            ? '[https://www.ifancy.app/user/' + loggedInUser.id + '](link)'
            : 'Not logged in',
        },
        device_info: {
          userAgent: window.navigator.userAgent,
          version: packageJson.version,
        },
      });
      const response = await fetch(
        'https://ifancy-linear-help-center.netlify.app/.netlify/functions/submitTicket',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body,
        }
      );
      const parsedResponse = await response.json();

      if (!parsedResponse) {
        throw new Error('Response not parsed');
      }

      setMessage(parsedResponse.msg);
      if (parsedResponse.success) {
        setTimeout(() => {
          close();
        }, 6000);
      }
    } catch (err) {
      handleCatchError(err);
    }

    setIsLoading(false);
  };

  const resetState = () => {
    setDescription('');
    setMessage('');
  };

  const close = () => {
    localStorage.setItem(
      'ifancy-feedbackInlineBoxLastShown',
      new Date().toISOString()
    );
    setShow(false);
    resetState();
  };

  useEffect(() => {
    if (rating) {
      logEvent(analytics, 'feedback_rating_' + rating);
      const savedDateString = localStorage.getItem('ifancy-askedforreview');

      if (savedDateString) {
        const savedDate = new Date(savedDateString);

        if (Math.abs(differenceInDays(savedDate, new Date())) < 30) {
          return;
        }
      }
      localStorage.setItem('ifancy-askedforreview', new Date().toISOString());
    }
    if (rating === 5 && Capacitor.isNativePlatform()) {
      RateApp.requestReview();
    }
  }, [rating]);

  if (!show) return null;

  return (
    <Container>
      <HeaderContainer>
        <StyledH4>How are we doing?</StyledH4>
        <CloseButton type='button' clear onClick={close} color='medium'>
          Hide
        </CloseButton>
      </HeaderContainer>
      <Rating
        rating={rating}
        size={31}
        onChange={(newRating) => setRating(newRating)}
      />
      {message ? (
        <Message>{message}</Message>
      ) : rating ? (
        <StyledIonTextarea
          value={description}
          onIonInput={(e: any) => setDescription(e?.detail?.value)}
          rows={3}
          autoGrow
          placeholder='Suggest a feature, report a bug, or simply tell us how we can improve!'
        />
      ) : null}
      {description && !message && (
        <SendFeedbackButton onClick={submitFeedback} color='primary'>
          {isLoading ? <IonSpinner name='crescent' /> : 'Send Feedback'}
        </SendFeedbackButton>
      )}
    </Container>
  );
};
