import {
  PhoneAuthProvider,
  signInWithCredential,
  signInWithPhoneNumber,
} from 'firebase/auth';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Capacitor } from '@capacitor/core';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { SplashScreen } from '@capacitor/splash-screen';
import { User } from '../types/GlobalTypes';
import { auth } from '../firebase';
import { fadeOutDelayInMilliseconds } from '../navigation/LoadingScreen';
import { handleCatchError } from '../utils/handleCatchError';
import { log } from '../utils/log';
import { useSaveLoginTS } from '../hooks/useSaveLoginTS';

declare const window: any;

type AuthenticationProps = {
  loggedInUserId: string | undefined;
  sendSMSCode: (phoneNumber: string) => Promise<boolean>;
  verifySMSCode: (code: string) => Promise<void>;
  logoutUser: () => Promise<void>;
  authHasLoadedAndLoggedInUserLoaded: boolean;
  setAuthHasLoadedAndLoggedInUserLoaded: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

export type LoginEvent = {
  user?: User;
  isNewUser: boolean;
} | null;

export const AuthenticationContext = React.createContext<AuthenticationProps>(
  {} as AuthenticationProps
);

export const useAuth = () => useContext(AuthenticationContext);

export const AuthenticationProvider = ({ children }: any) => {
  const [loggedInUserId, setLoggedInUserId] = useState<string | undefined>(
    localStorage.getItem('iFancy-loggedInUserId') || undefined
  );
  const [
    authHasLoadedAndLoggedInUserLoaded,
    setAuthHasLoadedAndLoggedInUserLoaded,
  ] = useState(false);

  const [smsVerificationId, setSmsVerificationId] = useState('');

  useSaveLoginTS(authHasLoadedAndLoggedInUserLoaded, loggedInUserId);

  useEffect(() => {
    if (authHasLoadedAndLoggedInUserLoaded && Capacitor.isNativePlatform()) {
      setTimeout(() => {
        SplashScreen.hide();
      }, fadeOutDelayInMilliseconds);
    }
  }, [authHasLoadedAndLoggedInUserLoaded]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      log('green', 'Auth Changed');
      if (authUser?.uid) {
        log('green', 'User logged in');
        log('green', 'Auth Listener Enabled');

        setLoggedInUserId(authUser.uid);

        localStorage.setItem('iFancy-loggedInUserId', authUser.uid);
      } else {
        log('green', 'No User, setting loggedInUser to undefined');
        setAuthHasLoadedAndLoggedInUserLoaded(true);
        setLoggedInUserId(undefined);
        localStorage.setItem('iFancy-loggedInUserId', '');
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      FirebaseAuthentication.addListener('phoneCodeSent', async (event) => {
        if (!event.verificationId) throw new Error('No verification ID');
        setSmsVerificationId(event.verificationId);
      });
    }
  }, []);

  const sendSMSCode = useCallback(async (phoneNumber: string) => {
    try {
      if (Capacitor.isNativePlatform()) {
        await FirebaseAuthentication.signInWithPhoneNumber({
          phoneNumber: phoneNumber,
        });
      } else {
        const appVerifier = window.recaptchaVerifier;
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          appVerifier
        );
        window.confirmationResult = confirmationResult;
      }
      return true;
    } catch (err) {
      handleCatchError(err);
      return false;
    }
  }, []);

  const verifySMSCode = useCallback(
    async (code: string) => {
      try {
        if (Capacitor.isNativePlatform()) {
          const credential = PhoneAuthProvider.credential(
            smsVerificationId,
            code
          );

          await signInWithCredential(auth, credential);
        } else {
          await window.confirmationResult.confirm(code);
        }
      } catch (err) {
        handleCatchError(err);
      }
    },
    [smsVerificationId]
  );

  const logoutUser = useCallback(async () => {
    try {
      await auth.signOut();
      if (Capacitor.isNativePlatform()) {
        await FirebaseAuthentication.signOut();
      }
      localStorage.clear();
    } catch (err) {
      handleCatchError(err);
    } finally {
      setLoggedInUserId(undefined);
    }
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        verifySMSCode,
        loggedInUserId,
        logoutUser,
        sendSMSCode,
        authHasLoadedAndLoggedInUserLoaded,
        setAuthHasLoadedAndLoggedInUserLoaded,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
