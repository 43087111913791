import React from 'react';
import logo from '../assets/imgs/logo_new.svg';
import { styled } from 'goober';

interface Props {
  className?: string;
}

const Container = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  img {
    width: 120px;
    height: auto;
  }
`;

const ImageWrapper = styled('div')`
  position: relative;
  display: flex;

  .blur {
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(2px);
    transform: scale(0.95) translateY(4px);
    transform-origin: center;
    opacity: 0.18;
  }
`;

export const Logo: React.FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <ImageWrapper>
        <img width={120} height={49} src={logo} alt='iFancy app logo' />
        <img
          className='blur'
          width={120}
          height={49}
          src={logo}
          alt='iFancy app logo-blurred'
        />
      </ImageWrapper>
    </Container>
  );
};
