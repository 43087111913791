import { cleanImagesFn } from './cleanImagesFn';
import { cleanPricesFn } from './cleanPricesFn';
import { cleanTitlesFn } from './cleanTitlesFn';

export type CapacitorLinkScrapeResults = {
    titles?: string[];
    images?: string[];
    prices?: string[];
};

export const prioritizeOGTagToUse = (ogTags: { [key: string]: any }): CapacitorLinkScrapeResults => {
    const titles = [...new Set(['og:title', 'twitter:title', 'title'].map((key) => ogTags[key]).flat().map(cleanTitlesFn).filter(Boolean))].slice(0, 4);
    const images = [...new Set(['og:image', 'twitter:image', 'og:image:secure_url', 'image'].map((key) => ogTags[key]).flat().map(cleanImagesFn).filter(Boolean))].slice(0, 40);
    const prices = [...new Set(['og:price:amount', 'price'].map((key) => ogTags[key]).flat().map(cleanPricesFn).filter(Boolean))].slice(0, 4);
    const results: CapacitorLinkScrapeResults = {
        titles: titles.length ? titles : [],
        images: images.length ? images : [],
        prices: prices.length ? prices : [],
    };

    return results;
}
