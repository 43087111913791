import { User } from '../../types/GlobalTypes';

export const isBlockedByUser = (loggedInUser: User | undefined, user: User | undefined) =>
  Boolean(
    user?.id &&
    user?.blocked &&
    loggedInUser?.id &&
    loggedInUser?.id !== user.id &&
    user.blocked?.includes(loggedInUser.id)
  );
