import React, { useState } from 'react';
import { Timestamp, analytics, db } from '../../firebase';
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';

import { Button } from '../BaseUI/Button';
import { IonAlert } from '@ionic/react';
import { handleCatchError } from '../../utils/handleCatchError';
import { logEvent } from 'firebase/analytics';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { trashOutline } from 'ionicons/icons';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';
import { useProfilePageContext } from '../../contexts/ProfilePageContext';

interface Props {
  onClick: (action?: 'deleted') => void;
}

const StyledButton = styled(Button as any)`
  color: var(--ion-color-medium-shade);
  .btn-icon {
    ion-icon {
      color: inherit;
    }
    font-size: 17px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .btn-text {
    color: inherit;
  }
`;

export const DeleteWishlistButton: React.FC<Props> = ({ onClick }) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { loggedInUser } = useLoggedInUser();
  const { selectedWishlist, wishlists, setWishlists } = useProfilePageContext();

  const deleteWishlist = async () => {
    try {
      if (!selectedWishlist) throw new Error('No wishlist selected');
      const wishlistToDelete = { ...selectedWishlist };
      if (!loggedInUser?.id)
        throw new Error('You must be logged in to delete a wishlist');
      if (!wishlistToDelete?.id)
        throw new Error('Wishlist missing an ID uh oh');
      if (wishlistToDelete.id === 'original')
        throw new Error('Sorry, you cannot delete your original wishlist');

      const doAction = async () => {
        const deletedAt = Timestamp.fromDate(new Date());

        const allItemsSnapshot = await getDocs(
          query(
            collection(
              db,
              `users/${loggedInUser.id}/wishlists/${wishlistToDelete.id}/items`
            ),
            where('deletedAt', '==', null)
          )
        );

        allItemsSnapshot.forEach(async (doc) => {
          await updateDoc(doc.ref, { deletedAt, updatedAt: deletedAt });
        });

        await updateDoc(
          doc(db, `users/${loggedInUser.id}/wishlists/${wishlistToDelete.id}`),
          { deletedAt, updatedAt: deletedAt }
        );

        const updatedWishlists = wishlists.filter(
          (wishlist) => wishlist.id !== wishlistToDelete.id
        );
        setWishlists(updatedWishlists);

        logEvent(analytics, 'wishlist_deleted');
        return true;
      };

      await toast.promise<boolean>(doAction(), {
        loading: 'Deleting...',
        success: `${wishlistToDelete.name} deleted`,
        error: (err) => err.message,
      });
      onClick('deleted');
    } catch (err) {
      handleCatchError(err);
    }
  };

  if (selectedWishlist?.id === 'original') return null;

  return (
    <>
      <IonAlert
        mode='ios'
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setIsAlertOpen(false);
            },
          },
          {
            text: 'Delete',
            role: 'destructive',
            handler: () => {
              deleteWishlist();
            },
          },
        ]}
        header={'Delete this wishlist?'}
        message={
          'This will archive all of your wishlist items in this list and delete the list.'
        }
        isOpen={isAlertOpen}
        onDidDismiss={() => setIsAlertOpen(false)}
      />
      <StyledButton
        color='light-100'
        iconLeft={trashOutline}
        onClick={() => setIsAlertOpen(true)}
      >
        Delete Wishlist
      </StyledButton>
    </>
  );
};
