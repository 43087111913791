import React, { useEffect, useState } from 'react';
import { css, styled } from 'goober';

import { IonSpinner } from '@ionic/react';
import LogoAndTitle from '../components/LogoAndTitle';

interface Props {
  hide?: boolean;
}

export const fadeOutDelayInMilliseconds = 250;

const fadeOutTimeInMilliseconds = 300;

const Container = styled('div')<{ $hide: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  position: absolute;
  opacity: 1;
  pointer-events: auto;
  transition: ${fadeOutTimeInMilliseconds}ms ease;
  z-index: 10000;
  flex-direction: column;
  background: url('/assets/images/BackgroundSwirls.webp');
  background-size: 170px 170px;
  ${({ $hide }) =>
    $hide &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

const StyledLogoAndTitle = styled(LogoAndTitle)`
  padding-bottom: 10vh;
`;

export const LoadingScreen: React.FC<Props> = ({ hide }) => {
  const [isHidden, setIsHidden] = useState(hide);
  const [isRemovedFromDOM, setIsRemovedFromDOM] = useState(false);

  useEffect(() => {
    if (hide) {
      setTimeout(() => {
        setIsHidden(true);
        setTimeout(() => {
          setIsRemovedFromDOM(true);
        }, fadeOutTimeInMilliseconds + 100);
      }, fadeOutDelayInMilliseconds);
    }
  }, [hide]);

  return (
    <Container $hide={Boolean(isHidden)}>
      {!isRemovedFromDOM && (
        <>
          <StyledLogoAndTitle />
          <IonSpinner color='dark' />
        </>
      )}
    </Container>
  );
};
