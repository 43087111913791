import { User } from '../../types/GlobalTypes';
import { getFormattedName } from './getFormattedName';

export const transformUser = async (rawDoc: any) => {
    const userObj: User = {
        id: rawDoc.id,
        ...rawDoc.data(),
    };

    userObj.formattedName = getFormattedName(
        userObj?.first_name,
        userObj?.last_name
    );

    return userObj;
};


