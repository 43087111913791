import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import {
  collectionGroup,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';

import { LinkInput } from '../components/LinkInput';
import { StyledIonOuterToolbar } from '../components/layout/StyledOuterIonToolbar';
import { WishlistItem } from '../types/GlobalTypes';
import { db } from '../firebase';
import { handleCatchError } from '../utils/handleCatchError';
import { styled } from 'goober';
import toast from 'react-hot-toast';

const Item = styled('div')`
  margin: 10px;
  border-radius: 8px;
  border: 1px solid var(--ion-color-light-100);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const LinkText = styled('p')`
  font-size: 8px;
`;

export const AmazonAdminPanel: React.FC = () => {
  const [amazonLinkItems, setAmazonLinkItems] = useState<WishlistItem[]>([]);

  const getMoreAmazonLinks = async () => {
    try {
      const args: any[] = [
        where('isAmazonLink', '==', true),
        orderBy('createdAt', 'desc'),
        limit(10),
      ];
      if (amazonLinkItems.length > 0) {
        args.push(
          startAfter(amazonLinkItems[amazonLinkItems.length - 1].createdAt)
        );
      }
      const querySnap = await getDocs(
        query(collectionGroup(db, 'wishlist'), ...args)
      );

      const items: WishlistItem[] = querySnap.docs.map((doc) => ({
        ...(doc.data() as WishlistItem),
        id: doc.id,
      }));

      setAmazonLinkItems([...amazonLinkItems, ...items]);
    } catch (err) {
      console.log(err);
      handleCatchError(err);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <StyledIonOuterToolbar>
          <IonButtons slot='start'>
            <IonBackButton defaultHref='/friends' />
          </IonButtons>
          <IonTitle>Amazon Admin Panel</IonTitle>
        </StyledIonOuterToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse='condense'>
          <IonToolbar>
            <IonTitle size='large'>Amazon Admin Panel</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          {amazonLinkItems.map((item) => (
            <Item key={item.id}>
              <p>
                <strong>{item.title}</strong>
              </p>
              <LinkText>{item.link}</LinkText>
              <LinkInput
                price=''
                adminCheck
                linkDomain={item.link || ''}
                readonly={false}
                setLink={(url: string) => {
                  toast.success(url);
                  console.log(url);
                }}
                setImage={(url: string) => {
                  toast.success(url);
                  console.log(url);
                }}
                setPrice={() => {}}
                uniqueOpenId={undefined}
                image=''
                itemId={item.id}
                setTitle={(url: string) => {
                  toast.success(url);
                  console.log(url);
                }}
                key={item.id}
                title=''
                link={item.link || ''}
                onClickOpenLinkInfoModal={() => {}}
                isLinkFetchLoading={false}
                setIsLinkFetchLoading={() => {}}
              />
            </Item>
          ))}
          <IonButton onClick={getMoreAmazonLinks}>Load More</IonButton>
        </IonList>
      </IonContent>
    </IonPage>
  );
};
