import { IonAlert, IonItem } from '@ionic/react';
import React, { useState } from 'react';

import { apiFunctionFetch } from '../../utils/apiFunctionFetch';
import { differenceInMinutes } from 'date-fns';
import { useAuth } from '../../contexts/AuthenticationContext';
import { useHistory } from 'react-router';

interface Props {}

const checkIfJustCreatedAccount = () => {
  const newUserCreated = localStorage.getItem('ifancy-nuc');

  if (newUserCreated) {
    const newUserCreatedDate = new Date(newUserCreated);

    if (Math.abs(differenceInMinutes(newUserCreatedDate, new Date())) < 10) {
      return true;
    }
  }
  return false;
};

const LogoutButton: React.FC<Props> = () => {
  const history = useHistory();
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const [showLogoutAndDeleteAccountAlert, setShowLogoutAndDeleteAccountAlert] =
    useState(false);

  const { logoutUser, loggedInUserId } = useAuth();

  const _logoutUser = async (deleteAccount: boolean) => {
    if (deleteAccount) {
      localStorage.removeItem('ifancy-nuc');
      apiFunctionFetch('requestDeleteAccountFromAccidentalLogin', {
        userId: loggedInUserId,
      });
    }
    await logoutUser();
    history.replace('/auth');
  };

  const determineWhichPromptToShow = () => {
    const recentlyCreatedAccount = checkIfJustCreatedAccount();
    if (recentlyCreatedAccount) {
      setShowLogoutAndDeleteAccountAlert(true);
    } else {
      setShowLogoutAlert(true);
    }
  };

  return (
    <IonItem button onClick={determineWhichPromptToShow}>
      <IonAlert
        isOpen={showLogoutAlert}
        onDidDismiss={() => setShowLogoutAlert(false)}
        header={'Are you sure?'}
        message='This will log you out.'
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {},
          },
          {
            text: 'Logout',
            handler: () => {
              _logoutUser(false);
            },
          },
        ]}
      />
      <IonAlert
        isOpen={showLogoutAndDeleteAccountAlert}
        onDidDismiss={() => setShowLogoutAndDeleteAccountAlert(false)}
        header={'Did you accidentally create an account?'}
        message="If you didn't mean to create a new account, should we delete it?"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {},
          },
          {
            text: 'Delete the account and logout',
            handler: () => {
              _logoutUser(true);
            },
          },
          {
            text: 'Keep the account and logout',
            handler: () => {
              _logoutUser(false);
            },
          },
        ]}
      />
      Logout
    </IonItem>
  );
};

export default LogoutButton;
