import React, { useState } from 'react';
import { analytics, functions } from '../../firebase';

import { IonAlert } from '@ionic/react';
import { ProfileMenuActionButton } from './ProfileActionButtons';
import { User } from '../../types/GlobalTypes';
import { flagOutline } from 'ionicons/icons';
import { handleCatchError } from '../../utils/handleCatchError';
import { httpsCallable } from 'firebase/functions';
import { logEvent } from 'firebase/analytics';
import toast from 'react-hot-toast';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';

interface Props {
  user: User;
  onClick?: () => void;
}

export const ReportUserButton: React.FC<Props> = ({ user, onClick }) => {
  const [showAlert, setShowAlert] = useState(false);
  const { loggedInUser } = useLoggedInUser();

  if (loggedInUser?.id && loggedInUser?.id === user.id) return null;

  const reportUser = async (reason: string) => {
    try {
      if (!user?.id) return;

      const doAction = async () => {
        logEvent(analytics, 'user_reported', { userId: user.id });
        const apiCall = httpsCallable(functions, 'reportUser');
        const response = await apiCall({ userId: user.id, reason });
        const { success, msg } = response.data as any;

        if (!success) throw new Error(msg);

        return true;
      };

      await toast.promise<boolean>(doAction(), {
        loading: 'Sending...',
        success: `Thank you. We will investigate the issue.`,
        error: (err) => err.message,
      });
    } catch (err) {
      handleCatchError(err);
    }
    onClick && onClick();
  };

  return (
    <>
      <ProfileMenuActionButton
        clear
        color='medium'
        iconLeft={flagOutline}
        onClick={() => setShowAlert(true)}
      >
        Report User
      </ProfileMenuActionButton>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Please describe the issue'}
        mode='ios'
        message='We will address the issue ASAP'
        inputs={[
          {
            name: 'reason',
            type: 'text',
            placeholder: 'Reason',
            value: '',
            attributes: { autoFocus: true },
          },
        ]}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {},
          },
          {
            text: 'Send',
            handler: ({ reason }) => {
              reportUser(reason);
            },
          },
        ]}
      />
    </>
  );
};
