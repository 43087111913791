import { ProfileMenuActionButton } from './ProfileActionButtons';
import React from 'react';
import { listOutline } from 'ionicons/icons';

interface Props {
  onClick: () => void;
}

export const ReorderWishlist: React.FC<Props> = ({ onClick }) => {
  return (
    <ProfileMenuActionButton
      clear
      color='medium'
      iconLeft={listOutline}
      onClick={onClick}
    >
      Reorder Wishlist
    </ProfileMenuActionButton>
  );
};
