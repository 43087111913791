import { IonContent, IonFooter } from '@ionic/react';

import { Button } from '../components/BaseUI/Button';
import { styled } from 'goober';

export const ModalFooterButtonsContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 20px 20px;
  @media (max-width: 440px) {
    padding: 10px;
  }
`;

export const TransparentIonContent = styled(IonContent)`
  --ion-background-color: transparent;
`;

export const StyledModalTitle = styled('div')`
  opacity: 0.73;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1;
  padding-left: 14px;
  padding-right: 14px;
  p {
    font-size: 19px;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }
  svg {
    width: 26px;
    height: 26px;
  }
`;

export const StyledModalIonFooter = styled(IonFooter)`
  margin: 0;
`;

export const ModalMessageContainer = styled('div')`
  padding: 20px;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PrimaryModalActionButton = styled(Button as any)`
  flex: 1;
  margin-left: 10px;
  @media (max-width: 440px) {
    margin-left: 5px;
  }
  ion-spinner {
    margin: -5px 0;
    height: 23px;
    width: 23px;
  }
`;

export const ModalCancelButton = styled(Button as any)`
  flex: 1;
  margin-right: 10px;
  @media (max-width: 440px) {
    margin-right: 5px;
  }
`;
