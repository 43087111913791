import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics';
import {
  ImageUploadContext,
  ImageUploadSuccess,
} from '../contexts/ImageUploadContext';
import {
  IonActionSheet,
  IonAlert,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonModal,
  IonSpinner,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Timestamp, analytics, db, storage } from '../firebase';
import { WishlistConfig, WishlistItem } from '../types/GlobalTypes';
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import {
  archiveOutline,
  arrowUndoOutline,
  checkmark,
  clipboardOutline,
  close,
  closeSharp,
  imageOutline,
  openOutline,
  shareOutline,
  thumbsDownSharp,
  thumbsUpSharp,
} from 'ionicons/icons';
import { deleteObject, ref } from 'firebase/storage';
import { format, isPast } from 'date-fns';

import { Button } from '../components/BaseUI/Button';
import { Capacitor } from '@capacitor/core';
import { Clipboard } from '@capacitor/clipboard';
import { ConfettiContext } from '../contexts/ConfettiContext';
import { IoGiftOutline } from 'react-icons/io5';
import { LinkInput } from '../components/LinkInput';
import LinkParsingInfoModal from './LinkParsingInfoModal';
import { MarkPurchasedModal } from './MarkPurchasedModal';
import { NotificationsContext } from '../contexts/NotificationsContext';
import { PriceInput } from '../components/PriceInput';
import { SearchImageResults } from '../components/SearchImageResults';
import { SelectFriendForItemAddButton } from '../components/SelectFriendForItemAddButton';
import { UserBankContext } from '../contexts/UserBank';
import { VisibleToInput } from '../components/VisibleToInput';
import { WishListItem } from '../components/WishListItem';
import { WishlistsSelectButton } from '../components/ProfileActionButtons/WishlistsSelectButton';
import { apiFunctionFetch } from '../utils/apiFunctionFetch';
import { buildRedirectLink } from '../utils/helper-functions/buildRedirectLink';
import { checkPriceIsValid } from '../utils/helper-functions/checkPriceIsValid';
import { filterWishlistsInSelectButton } from '../utils/helper-functions/filterWishlistsInSelectButton';
import { handleCatchError } from '../utils/handleCatchError';
import { isUserAFollowingUserB } from '../utils/helper-functions/isUserAFollowingUserB';
import { isValidURL } from '../utils/helper-functions/isValidURL';
import { logEvent } from 'firebase/analytics';
import { nanoid } from 'nanoid';
import { restoreItem } from '../utils/restoreItem';
import { scheduleActivityNotification } from '../utils/helper-functions/scheduleActivityNotification';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { uploadImageToFirebase } from '../utils/uploadImage';
import { useGetWishlists } from '../hooks/useGetWishlists';
import { useHistory } from 'react-router';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

const AcceptButton = styled(Button as any)`
  padding: 0px 12px;
  margin-right: 4px;
  border-radius: 16px;
  .btn-icon {
    font-size: 35px;
  }
`;

const CloseButton = styled(Button as any)`
  padding: 4px;
  .btn-icon {
    font-size: 38px;
  }
`;

const SectionContainer = styled('div')`
  padding: 24px;
  min-width: 400px;
  @media (max-width: 650px) {
    padding: 0px;
    min-width: 300px;
    padding-bottom: 16px;
  }
  @media (max-width: 450px) {
    padding: 0px;
    min-width: 260px;
    padding-bottom: 16px;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const LargeTitleInnerContainer = styled('div')`
  display: flex;
  position: relative;
  max-height: 9999px;
  overflow: hidden;
  width: 100%;
  &.shrink {
    @media (max-width: 669px) {
      max-height: 90px;
      .shrink-item {
        display: initial;
      }
    }
  }
`;

const ShowMoreTitleButton = styled(Button as any)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  z-index: 2;
  display: none;
  .btn-text {
    font-size: 12px;
    font-weight: 700;
  }
  &.show {
    @media (max-width: 669px) {
      display: initial;
    }
  }
`;

const LargeTitleContainer = styled('div')`
  display: flex;
  position: relative;
  border-radius: 12px;
  margin-top: 12px;
  width: 100%;
  border: 1px solid var(--color-idk-light);
  &.readonly {
    margin-top: 0;
  }
  .shrink-item {
    display: none;
  }
  &.extra-margin-bottom {
    @media (max-width: 669px) {
      margin-bottom: 8px;
    }
  }
`;

const LargeTitleFade = styled('div')`
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  z-index: 1;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: linear-gradient(to top, white, transparent);
`;

const Textarea = styled(IonTextarea)`
  letter-spacing: 0.2px;
  outline: none;
  border-radius: 12px;
  padding: 4px 16px;
  flex: none;
  color: var(--ion-color-dark);
  min-height: 42px;
  border: 1px solid var(--color-idk-light);
  textarea {
    min-height: 42px;
  }
  &${isPlatform('desktop') ? ':hover' : ':active'} {
    background: var(--color-extra-light);
  }
  &:focus-within {
    background: var(--color-extra-light);
  }
  &.title {
    font-size: 20px;
    font-weight: 700;
    border: none;
    z-index: 0;
    @media (min-width: 769px) {
      font-size: 24px;
    }
  }
  &.notes {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
    margin-top: 12px;
  }
  &.empty {
    display: none;
  }
`;

const AnimatedBorder = styled('i')`
  margin: 0;
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  padding: 2px;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
  border-radius: 14px;
  &:before {
    content: '';
    display: block;
    background: conic-gradient(
      from 180deg at 50% 50%,
      #5d5fa8 0deg,
      #c13232 228.75deg,
      rgba(42, 138, 246, 0) 360deg
    );
    width: calc(100% * 1.41421356237);
    padding-bottom: calc(100% * 1.41421356237);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    z-index: -2;
    animation: borderSpin 5s linear infinite;
  }
`;

const PurchasedByDisclosureText = styled('p')`
  margin: 5px;
  padding: 8px 14px;
  font-weight: 700;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  transform: translateZ(0);
  svg {
    margin-right: 8px;
    margin-bottom: -5px;
    margin-top: -2px;
    width: 22px;
    height: 22px;
  }
`;

const PaddingContainer = styled('div')`
  margin-bottom: var(--ion-safe-area-bottom);
  padding: 24px;
  @media (max-width: 360px) {
    padding: 15px;
  }
  min-height: 99%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  @media (max-width: 969px) {
    flex-direction: column;
  }
`;

const StyledIonModal = styled(IonModal, React.forwardRef)`
  @media (min-width: 767px) {
    --min-height: 96vh;
    --min-width: 100vw;
    padding-top: 4vh;
  }
  @media (min-width: 1000px) {
    --min-height: 90vh;
    --min-width: 92vw;
  }
`;

const StyledWishListItem = styled(WishListItem as any)`
  width: 100%;
  height: 100%;
  @media (max-width: 969px) {
    width: 320px;
    height: 320px;
  }
  @media (max-width: 469px) {
    width: 100%;
    height: 300px;
  }
`;

const ImageOptionsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

const ProductContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  width: 80%;
  max-width: 460px;
  height: 80%;
  max-height: 460px;
  min-height: 260px;
  min-width: 260px;
  &.fullscreen {
    width: 100%;
    max-width: 460px;
    height: 100%;
    min-height: 260px;
  }

  @media (max-width: 1269px) {
    max-height: 400px;
  }
  @media (max-width: 1069px) {
    max-height: 360px;
  }
`;

const DeleteButton = styled(Button as any)`
  margin-top: 22px;
  margin-bottom: 52px;
`;

const OnlyVisibleToYouText = styled('p')`
  color: var(--ion-color-primary);
  margin-top: -8px;
  padding-left: 16px;
  font-size: 14px;
`;

const SuggestedByText = styled(IonText)`
  width: 100%;
  text-align: left;
`;

const SuggestionButtonsContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  @media (max-width: 569px) {
    width: 100%;
  }
  @media (max-width: 469px) {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
    padding: 0 8px;
  }
  button {
    .btn-icon {
      font-size: 15px;
      padding-right: 0;
      padding-left: 6px;
      @media (max-width: 469px) {
        padding-left: 0px;
      }
    }
    .btn-text {
      font-weight: 700;
      font-family: 'Nunito Sans', sans-serif;
      flex-direction: column;
      align-items: center;
      flex: 1;
    }
    min-height: 48px;
    margin: 12px 5px;
    flex: 1;
    @media (max-width: 469px) {
      margin: 12px 2px;
    }
  }
`;

const VisitLinkButton = styled(Button as any)`
  .btn-text {
    white-space: nowrap;
  }
`;

const LinkActionButton = styled(Button as any)`
  .btn-icon {
    font-size: 20px;
  }
`;

const WarningText = styled('p')`
  color: var(--ion-color-danger);
  font-weight: 700;
`;

const ButtonActionsContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: max(24px, var(--ion-safe-area-bottom) + 10px);
  padding-left: 24px;
  padding-right: 24px;
  pointer-events: none;
  button,
  a {
    pointer-events: auto;
  }
  @media (max-width: 669px) {
    padding-bottom: max(18px, var(--ion-safe-area-bottom) + 10px);
    padding-left: 18px;
    padding-right: 18px;
  }

  @media (max-width: 569px) {
    padding-bottom: max(16px, var(--ion-safe-area-bottom) + 4px);
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 469px) {
    padding-bottom: max(16px, var(--ion-safe-area-bottom) + 4px);
    padding-left: 4px;
    padding-right: 4px;
  }

  button,
  a {
    border-radius: 16px;
    margin: 8px;
    padding: 18px 48px;
    .btn-text {
      font-size: 18px;
    }
    @media (max-width: 669px) {
      border-radius: 14px;
      margin: 6px;
      padding: 16px 38px;
      .btn-text {
        font-size: 16px;
      }
    }

    @media (max-width: 569px) {
      border-radius: 13px;
      margin: 5px;
      padding: 14px 28px;
      .btn-text {
        font-size: 15px;
      }
    }

    @media (max-width: 469px) {
      flex: 1;
      border-radius: 10px;
      margin: 4px;
      padding: 12px 16px;
      .btn-text {
        font-size: 14px;
      }
    }
  }
`;

const PurchasedButton = styled(Button as any)`
  .btn-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const PurchasedByText = styled('p')`
  font-weight: 700;
  font-size: 12px;
  margin-top: 3px;
`;

const LastUpdatedAt = styled('p')`
  font-size: 13px;
  color: var(--ion-color-medium);
  margin-top: 18px;
  text-align: left;
  width: 100%;
  padding: 0 16px;
  @media (max-width: 969px) {
    flex: 1;
  }
  &.not-readonly {
    flex: 1;
  }
`;

const TitleRequiredError = styled('p')`
  color: var(--ion-color-danger);
  font-weight: 700;
  width: 100%;
  padding-top: 8px;
  font-size: 12px;
  padding-bottom: 0;
  margin-bottom: -6px;
`;

const WishlistsSelectButtonContainer = styled<{ $newItem: boolean }>('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.$newItem
      ? `background: var(--color-extra-light); border: 1px dashed var(--ion-color-light-100);`
      : ''}
  padding: 8px;
  border-radius: 12px;

  > button {
    width: 100%;
    max-width: none;
  }
  justify-content: flex-start;
`;

type Props = {
  isOpen: boolean;
  wishlistItem?: WishlistItem | null;
  pageRef?: any;
  readonly?: boolean;
  userId?: string;
  onDidDismiss: () => void;
  isViewingArchivedItem?: boolean;
  isSuggestion: boolean;
  currentWishlist: WishlistConfig | string | undefined;
};

const WishlistItemModal: React.FC<Props> = ({
  isOpen,
  wishlistItem,
  currentWishlist,
  pageRef,
  readonly = true,
  userId: _userId,
  onDidDismiss,
  isSuggestion: _isSuggestion,
  isViewingArchivedItem,
}) => {
  const isEditing = wishlistItem?.id && !readonly ? true : false;

  const history = useHistory();
  const modalRef = useRef<any>();

  const { triggerNotification } = useContext(NotificationsContext);
  const { loggedInUser } = useLoggedInUser();
  const { userBank, getUser } = useContext(UserBankContext);
  const { triggerConfetti } = useContext(ConfettiContext);

  const [showActionSheet, setShowActionSheet] = useState(false);

  const { open, isOpen: isImageUploadOverlayOpen } =
    useContext(ImageUploadContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showSuccessfulSuggestionAlert, setShowSuccessfulSuggestionAlert] =
    useState(false);
  const [isLinkParsingInfoModalOpen, setIsLinkParsingInfoModalOpen] =
    useState(false);
  const [isMarkPurchasedModalOpen, setIsMarkPurchasedModalOpen] =
    useState(false);
  const [showDeleteImageConfirmAlert, setShowDeleteImageConfirmAlert] =
    useState(false);

  const [revealTitle, setRevealTitle] = useState(false);
  const [isLinkFetchLoading, setIsLinkFetchLoading] = useState(false);

  const [image, setImage] = useState(wishlistItem?.image || '');
  const [title, setTitle] = useState(wishlistItem?.title || '');
  const [titleError, setTitleError] = useState('');
  const [price, setPrice] = useState(wishlistItem?.price || '');
  const [link, setLink] = useState(wishlistItem?.link || '');
  const [userId, setUserId] = useState('');
  const [linkDomain, setLinkDomain] = useState('');
  const [wishlist, setWishlist] = useState<WishlistConfig | undefined>(
    undefined
  );
  const [uniqueOpenID, setUniqueOpenID] = useState<string | undefined>(
    undefined
  );
  const [attemptToMoveItemToNewWishlist, setAttemptToMoveItemToNewWishlist] =
    useState<WishlistConfig>();

  const isLoggedInUsersProfile = loggedInUser?.id === userId;
  const isSuggestion = wishlistItem?.id
    ? _isSuggestion
    : loggedInUser?.id !== userId;

  const user =
    userId && userBank && userBank[userId]?.user
      ? userBank[userId]?.user
      : undefined;

  const { wishlists: _wishlists } = useGetWishlists({
    userId,
    isLoggedInUsersProfile,
  });

  const wishlists = filterWishlistsInSelectButton(
    _wishlists,
    isLoggedInUsersProfile,
    isUserAFollowingUserB(loggedInUser, user)
  );

  useEffect(() => {
    setWishlist(undefined);
  }, [userId]);

  useEffect(() => {
    if (!isOpen) {
      setWishlist(undefined);
    } else if (!wishlist) {
      if (!_wishlists) return;
      if (userId === _userId) {
        const foundWishlist = _wishlists.find((wl) =>
          typeof currentWishlist === 'string'
            ? wl.id === currentWishlist
            : currentWishlist?.id
            ? wl.id === currentWishlist.id
            : wl.id === 'original'
        );
        if (foundWishlist) {
          setWishlist(foundWishlist);
        }
      } else {
        setWishlist(_wishlists.find((i) => i.id === 'original'));
      }
    }
  }, [_wishlists, wishlist, currentWishlist, isOpen, userId, _userId]);

  const [notes, setNotes] = useState(wishlistItem?.notes || '');
  const [visibleTo, setVisibleTo] = useState<string[] | undefined>(
    wishlistItem?.visible_to
  );

  const [hasBeenPurchased, setHasBeenPurchased] = useState<string | undefined>(
    wishlistItem?.purchased?.user_id
      ? wishlistItem?.purchased.user_id
      : undefined
  );
  const [canDisclose, setCanDisclose] = useState(false);

  const displayOnlyVisibleToUserMessage =
    !isLoggedInUsersProfile &&
    loggedInUser?.id &&
    visibleTo &&
    visibleTo.includes(loggedInUser?.id);

  const pageTitle = readonly
    ? ''
    : isEditing
    ? 'Edit'
    : isLoggedInUsersProfile
    ? 'Create'
    : 'Suggest';

  useEffect(() => {
    // could optimize this probably but rarely might we see a user who is not already loaded from the friends list i suppose.
    if (hasBeenPurchased) {
      getUser(hasBeenPurchased as string);
    }
  }, [hasBeenPurchased, getUser]);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Haptics.impact({ style: ImpactStyle.Medium });
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      wishlistItem?.suggested_by &&
      loggedInUser?.friends?.includes(wishlistItem?.suggested_by) &&
      !userBank[wishlistItem?.suggested_by]
    ) {
      getUser(wishlistItem?.suggested_by);
    }
  }, [getUser, wishlistItem, loggedInUser, userBank]);

  const cancelNewItem = async () => {
    if (!isEditing && !readonly) {
      await deleteImage();
    }
    onDidDismiss();
  };

  const validateForm = () => {
    let success = true;
    if (!title) {
      setTitleError('Title Required');
      success = false;
      toast.error('Title Required');
    } else {
      setTitleError('');
    }

    if (price && !checkPriceIsValid(price)) {
      success = false;
      toast.error('Invalid Price');
    }

    if (link && !isValidURL(link)) {
      toast.error('Invalid URL');
      success = false;
    }

    if (!isSuggestion && !wishlist) {
      toast.error('Select a wishlist for this item');
      success = false;
    }

    return success;
  };

  const addWishListItem = async (wishlistItem: WishlistItem) => {
    try {
      if (!loggedInUser?.id) throw new Error('Unauthenticated');
      if (!wishlist?.id) throw new Error('Select a wishlist for this item');
      setIsLoading(true);
      const imageURI = wishlistItem.image || image;

      // force https for images
      if (imageURI.indexOf('http:') !== -1) {
        imageURI.replace('http:', 'https:');
      }

      const collectionPathForSave = isSuggestion
        ? 'wishlist-suggestions'
        : wishlist?.id
        ? wishlist.id === 'original'
          ? 'wishlist'
          : `wishlists/${wishlist.id}/items`
        : undefined;

      if (!collectionPathForSave)
        throw new Error('Unable to determine where to save this item');

      await addDoc(
        collection(
          db,
          `users/${
            isSuggestion ? userId : loggedInUser.id
          }/${collectionPathForSave}`
        ),
        { ...wishlistItem, image: imageURI }
      );

      if (!isSuggestion) {
        scheduleActivityNotification(loggedInUser, wishlist);
      }

      setIsLoading(false);
      return true;
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  const togglePurchased = async (discloseAt?: string) => {
    const preEagerUpdate = wishlistItem?.purchased?.user_id || undefined;
    try {
      if (!loggedInUser?.id) {
        history.push('/auth');
        onDidDismiss();
        throw new Error('You must be logged in first.');
      }

      if (!wishlistItem?.id) throw new Error('No item found');
      if (!user) throw new Error('No user');
      if (!isUserAFollowingUserB(loggedInUser, user)) {
        throw new Error(`Add ${user.first_name} as a friend first`);
      }

      try {
        const doAction = async () => {
          const response = await apiFunctionFetch('togglePurchased', {
            userId: user.id,
            itemId: wishlistItem.id,
            wishlistId: wishlist?.id,
            discloseAt,
          });
          const { success, msg } = response;

          if (!success) throw new Error(msg);

          return msg;
        };

        // eagerly load results
        if (hasBeenPurchased) {
          toast.success('Item marked as [not] purchased!');
          logEvent(analytics, 'wishlist_item_marked_unpurchased', {
            itemId: wishlistItem?.id,
          });
          if (wishlistItem.isAmazonLink) {
            logEvent(analytics, 'amazon_product_unpurchased', {
              itemId: wishlistItem?.id,
            });
          }
          setHasBeenPurchased(undefined);
          if (Capacitor.isNativePlatform()) {
            await Haptics.notification({ type: NotificationType.Success });
          }
        } else {
          toast.success('Item marked as purchased!');
          logEvent(analytics, 'wishlist_item_marked_purchased', {
            itemId: wishlistItem?.id,
          });
          if (wishlistItem.isAmazonLink) {
            logEvent(analytics, 'amazon_product_purchased', {
              itemId: wishlistItem?.id,
            });
          }
          setHasBeenPurchased(loggedInUser.id);
          triggerConfetti(['#5d5fa8']);
        }

        await doAction();
      } catch (err) {
        toast.error('Uh oh wait... there was an error...');
        handleCatchError(err);

        // undo eager UI
        setHasBeenPurchased(preEagerUpdate);
      }
    } catch (err) {
      handleCatchError(err);
    }
  };

  const saveExistingWishlistItem = async (item: Partial<WishlistItem>) => {
    if (!loggedInUser?.id) throw new Error('Unauthenticated');
    if (item.image) {
      setImage(item.image);
    }
    if (wishlistItem) {
      setIsLoading(true);
      if (!wishlist?.id) throw new Error('No wishlist id');
      const itemCollectionPath = isSuggestion
        ? 'wishlist-suggestions'
        : wishlist.id === 'original'
        ? 'wishlist'
        : `wishlists/${wishlist.id}/items`;

      await updateDoc(
        doc(
          db,
          `users/${
            isSuggestion ? userId : loggedInUser.id
          }/${itemCollectionPath}/${wishlistItem.id}`
        ),
        {
          ...item,
          updatedAt: Timestamp.fromDate(new Date()),
        }
      );

      if (!isSuggestion) {
        scheduleActivityNotification(loggedInUser, wishlist);
      }

      setIsLoading(false);
    }
    return true;
  };

  const imageChangeCallback = async (
    imageRes?: ImageUploadSuccess | undefined
  ) => {
    if (imageRes) {
      await saveExistingWishlistItem({ image: imageRes.fullPath });
    }
  };

  const handleImageUploadError = (err: Error) => {
    toast.error(err.message);
  };

  const handleImagePreSave = async () => {
    if (!loggedInUser?.id) throw new Error('Unauthenticated');
    let imageURIToSave = image;

    if (image && image.startsWith('data:image')) {
      // need to upload image real quick
      setIsLoading(true);
      const successfulUploadObj = await uploadImageToFirebase({
        image,
        uploadOptions: {
          pathPrefix: `public/${isSuggestion ? userId : loggedInUser.id}/`,
        },
      });
      setImage(successfulUploadObj.fullPath);
      imageURIToSave = successfulUploadObj.fullPath;
    }
    return imageURIToSave;
  };

  const save = async () => {
    try {
      if (!validateForm()) {
        return;
      }
      if (!loggedInUser?.id) throw new Error('Unauthenticated');
      if (!wishlist?.id) throw new Error('Select a wishlist for this item');

      const imageURIToSave = await handleImagePreSave();
      if (isEditing && wishlistItem?.id) {
        // wishlistitem already exists, we need to update it.
        const updatedWishListItem: Partial<WishlistItem> = {
          image: imageURIToSave,
          title,
          price,
          link,
          notes,
          id: wishlistItem.id,
          visible_to: visibleTo || (deleteField() as any),
          updatedAt: Timestamp.fromDate(new Date()),
        };

        // TODO check that the wishlist location for this item has not changed!

        const success = await saveExistingWishlistItem(updatedWishListItem);
        if (success) {
          logEvent(analytics, 'wishlist_item_updated', {
            itemId: wishlistItem?.id,
          });
          onDidDismiss();
        } else {
          toast.error('Something went wrong 😢');
        }
      } else {
        // we are creating a new one.
        const newWishListItem: WishlistItem = {
          image: imageURIToSave,
          title,
          price,
          link,
          notes,
          purchased: null,
          createdAt: Timestamp.fromDate(new Date()),
          ...(isSuggestion && {
            suggested_by: loggedInUser.id,
            suggested_list_id: wishlist.id,
          }),
          ...(visibleTo && { visible_to: visibleTo }),
          deletedAt: null,
          updatedAt: Timestamp.fromDate(new Date()),
        };
        if (!userId && isSuggestion) throw new Error('No user to suggest to');
        const success = await addWishListItem(newWishListItem);
        if (success) {
          if (isSuggestion) {
            if (!userId) throw new Error('No user to suggest to');
            logEvent(analytics, 'wishlist_item_suggestion_created', {
              itemId: wishlistItem?.id,
            });
            triggerNotification(loggedInUser.id, userId, 'item-suggested');

            setShowSuccessfulSuggestionAlert(true);
          } else {
            logEvent(analytics, 'wishlist_item_created', {
              itemId: wishlistItem?.id,
            });
            onDidDismiss();
          }
        } else {
          toast.error('Something went wrong 😢');
        }
      }
    } catch (err) {
      handleCatchError(err);
      setIsLoading(false);
    }
  };

  const deleteImage = async () => {
    if (image && image.startsWith('public/' + userId)) {
      try {
        await deleteObject(ref(storage, image));
      } catch (err) {
        // dont show an error if it is a 404 error
        if (
          err &&
          typeof err === 'object' &&
          'code' in err &&
          (err as any).code === 'storage/object-not-found'
        ) {
          return;
        }

        handleCatchError(err);
      }
    }
  };

  const handleConfirmClearImage = async () => {
    setImage('');
    await saveExistingWishlistItem({ image: '' });
    await deleteImage();
  };

  const deleteItem = async () => {
    try {
      if (!loggedInUser?.id) throw new Error('Unauthenticated');
      if (isSuggestion) throw new Error('Cannot delete a suggestion');
      setIsLoading(true);
      if (!wishlist?.id) throw new Error('No wishlist id');
      const itemCollectionPath =
        wishlist.id === 'original'
          ? 'wishlist'
          : `wishlists/${wishlist.id}/items`;
      const deletedAt = Timestamp.fromDate(new Date());
      await updateDoc(
        doc(
          db,
          `users/${loggedInUser.id}/${itemCollectionPath}/${wishlistItem?.id}`
        ),
        {
          deletedAt,
          updatedAt: deletedAt,
        }
      );

      setIsLoading(false);
      logEvent(analytics, 'wishlist_item_deleted', {
        itemId: wishlistItem?.id,
      });
      onDidDismiss();
    } catch (err) {
      handleCatchError(err);
    }
  };

  useEffect(() => {
    setImage(wishlistItem?.image || '');
    setTitle(wishlistItem?.title || '');
    setPrice(wishlistItem?.price || '');
    setLink(wishlistItem?.link || '');
    setNotes(wishlistItem?.notes || '');
    setVisibleTo(wishlistItem?.visible_to);
    setTitleError('');
    setRevealTitle(false);

    setHasBeenPurchased(
      wishlistItem?.purchased?.user_id
        ? wishlistItem?.purchased.user_id
        : undefined
    );
  }, [wishlistItem, isOpen, currentWishlist]);

  useEffect(() => {
    setUniqueOpenID(isOpen ? nanoid() : undefined);
  }, [isOpen]);

  useEffect(() => {
    setUserId(_userId || loggedInUser?.id || '');
  }, [_userId, loggedInUser, isOpen]);

  useEffect(() => {
    if (
      wishlistItem?.suggested_by &&
      wishlistItem?.suggested_by !== loggedInUser?.id &&
      !userBank[wishlistItem.suggested_by]?.user
    ) {
      getUser(wishlistItem.suggested_by);
    }
  }, [wishlistItem, userBank, loggedInUser, getUser]);

  useEffect(() => {
    setCanDisclose(
      Boolean(
        loggedInUser &&
          isLoggedInUsersProfile &&
          hasBeenPurchased &&
          wishlistItem?.purchased?.disclose_at &&
          isPast(wishlistItem.purchased.disclose_at.toDate())
      )
    );
  }, [hasBeenPurchased, isLoggedInUsersProfile, loggedInUser, wishlistItem]);

  const acceptSuggestion = async () => {
    try {
      if (!validateForm()) {
        return;
      }
      if (!loggedInUser?.id) throw new Error('Unauthenticated');
      if (!wishlist?.id) throw new Error('Select a wishlist for this item');

      const imageURIToSave = await handleImagePreSave();

      if (!loggedInUser?.id) throw new Error('Unauthenticated');
      if (!wishlistItem?.id)
        throw new Error('Select a wishlist to save this item to');
      if (!wishlistItem?.suggested_by)
        throw new Error("Hmm item wasn't suggested by anyone");
      setIsLoading(true);
      if (!wishlist?.id) throw new Error('No wishlist id');
      const itemCollectionPath =
        wishlist.id === 'original'
          ? 'wishlist'
          : `wishlists/${wishlist.id}/items`;

      const newWishlistItem = {
        ...wishlistItem,
        image: imageURIToSave,
        title,
        price,
        link,
        notes,
        updatedAt: Timestamp.fromDate(new Date()),
      };

      if (visibleTo) {
        newWishlistItem.visible_to = visibleTo;
      }

      await setDoc(
        doc(
          db,
          `users/${loggedInUser.id}/${itemCollectionPath}/${wishlistItem.id}`
        ),
        newWishlistItem
      );
      logEvent(analytics, 'wishlist_item_suggestion_accepted', {
        itemId: wishlistItem?.id,
      });

      await deleteDoc(
        doc(
          db,
          `users/${loggedInUser.id}/wishlist-suggestions/${wishlistItem.id}`
        )
      );
      triggerNotification(
        loggedInUser.id,
        wishlistItem.suggested_by,
        'accepted-suggestion'
      );
      toast.success('Item added to wishlist!');
      setIsLoading(false);
      onDidDismiss();
    } catch (err) {
      setIsLoading(false);
      handleCatchError(err);
    }
  };

  const declineSuggestion = async () => {
    try {
      if (!loggedInUser?.id) throw new Error('Unauthenticated');
      if (!wishlistItem?.id) throw new Error('No wishlist item');
      setIsLoading(true);

      await updateDoc(
        doc(
          db,
          `users/${loggedInUser.id}/wishlist-suggestions/${wishlistItem.id}`
        ),
        {
          deletedAt: Timestamp.fromDate(new Date()),
        }
      );

      setIsLoading(false);
      logEvent(analytics, 'wishlist_item_suggestion_declined', {
        itemId: wishlistItem?.id,
      });
      toast.success('Suggestion declined');
      setIsLoading(false);
      onDidDismiss();
    } catch (err) {
      setIsLoading(false);
      handleCatchError(err);
    }
  };

  const copyLink = async () => {
    const linkToCopy = buildRedirectLink(link, wishlistItem?.id || 'new');
    logEvent(analytics, 'wishlist_item_link_copied', {
      itemId: wishlistItem?.id,
    });
    try {
      if (
        Capacitor.getPlatform() !== 'web' &&
        Capacitor.isPluginAvailable('Clipboard')
      ) {
        await Clipboard.write({
          string: linkToCopy,
        });
      } else {
        await navigator.clipboard.writeText(linkToCopy || link);
      }
      toast.success('Copied to clipboard!');
    } catch (err) {
      handleCatchError(err);
    }
  };

  const shareLink = async () => {
    logEvent(analytics, 'wishlist_item_shared', { itemId: wishlistItem?.id });
    const linkToShare = buildRedirectLink(link, wishlistItem?.id || 'new');

    try {
      if ('share' in navigator) {
        await navigator.share({
          url: linkToShare,
        });
      }
    } catch (err) {
      if ((err as any)?.message?.includes('Abort due to cancellation of share'))
        return;
      if ((err as any)?.message?.includes('Share canceled')) return;
      handleCatchError(err);
    }
  };

  const triggerHrefEvent = () => {
    // @TODO merge this with the other one
    logEvent(analytics, 'wishlist_item_link_clicked', {
      itemId: wishlistItem?.id,
    });
  };

  const unArchiveItem = async () => {
    if (!wishlist?.id) return toast.error('No wishlist id');
    await restoreItem(
      loggedInUser?.id || '',
      wishlistItem?.id || '',
      wishlist.id,
      setIsLoading
    );
    onDidDismiss();
  };

  const handleWishlistChange = async (wishlistSelected: WishlistConfig) => {
    if (isEditing) {
      if (isSuggestion) {
        setWishlist(wishlistSelected);
      } else if (wishlistSelected.id !== wishlist?.id) {
        setAttemptToMoveItemToNewWishlist(wishlistSelected);
      }
    } else {
      setWishlist(wishlistSelected);
    }
  };

  const moveItem = async () => {
    try {
      const doAction = async () => {
        await save();

        if (!wishlist?.id) throw new Error('No wishlist id');
        if (!wishlistItem?.id) throw new Error('No wishlist item id');
        const itemCollectionPath =
          wishlist.id === 'original'
            ? 'wishlist'
            : `wishlists/${wishlist.id}/items`;
        const newItemCollectionPath =
          attemptToMoveItemToNewWishlist?.id === 'original'
            ? 'wishlist'
            : `wishlists/${attemptToMoveItemToNewWishlist?.id}/items`;
        const freshDoc = await getDoc(
          doc(
            db,
            `users/${loggedInUser?.id}/${itemCollectionPath}/${wishlistItem?.id}`
          )
        );

        const freshData = freshDoc.data();
        if (!freshData) throw new Error('No data found');
        await setDoc(
          doc(
            db,
            `users/${loggedInUser?.id}/${newItemCollectionPath}/${wishlistItem?.id}`
          ),
          freshData
        );

        await deleteDoc(
          doc(
            db,
            `users/${loggedInUser?.id}/${itemCollectionPath}/${wishlistItem?.id}`
          )
        );
        setWishlist(attemptToMoveItemToNewWishlist);
        setAttemptToMoveItemToNewWishlist(undefined);
      };

      await toast.promise(doAction(), {
        loading: 'Moving item...',
        success: 'Item moved!',
        error: (err) => err.message,
      });
    } catch (err) {
      handleCatchError(err);
    }
  };

  useEffect(() => {
    try {
      const linkDomain = link
        ? new URL(link).hostname.replace('www.', '') || 'Link'
        : 'Link';

      setLinkDomain(linkDomain);
    } catch (err) {
      setLinkDomain('');
      //ignore
    }
  }, [link]);

  return (
    <StyledIonModal
      ref={modalRef}
      mode='ios'
      presentingElement={pageRef?.current}
      onDidDismiss={() => {
        if (isOpen) {
          cancelNewItem();
        }
      }}
      isOpen={isOpen}
      backdropDismiss={!isImageUploadOverlayOpen}
    >
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        buttons={[
          {
            text: 'Share Link',
            icon: shareOutline,
            handler: () => {
              shareLink();
            },
          },
          {
            text: 'Copy Link',
            icon: clipboardOutline,
            handler: () => {
              copyLink();
            },
          },
          {
            text: 'Cancel',
            icon: close,
            role: 'cancel',
          },
        ]}
      />
      <LinkParsingInfoModal
        pageRef={modalRef}
        isOpen={isLinkParsingInfoModalOpen}
        onDidDismiss={() => setIsLinkParsingInfoModalOpen(false)}
      />
      <MarkPurchasedModal
        togglePurchased={togglePurchased}
        isOpen={isMarkPurchasedModalOpen}
        onDidDismiss={() => setIsMarkPurchasedModalOpen(false)}
        userId={userId}
      />
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        header={'Are you sure?'}
        mode='ios'
        message='You will be able to view & restore this item in the archive.'
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {},
          },
          {
            text: 'Archive',
            handler: () => {
              deleteItem();
            },
          },
        ]}
      />

      <IonAlert
        isOpen={Boolean(attemptToMoveItemToNewWishlist)}
        onDidDismiss={() => setAttemptToMoveItemToNewWishlist(undefined)}
        header={'Move this item?'}
        mode='ios'
        message={`Move this item from ${wishlist?.name} to ${attemptToMoveItemToNewWishlist?.name}?`}
        buttons={[
          {
            text: "Don't Move",
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {},
          },
          {
            text: 'Move',
            handler: () => {
              moveItem();
            },
          },
        ]}
      />

      <IonAlert
        isOpen={showSuccessfulSuggestionAlert}
        onDidDismiss={() => setShowSuccessfulSuggestionAlert(false)}
        header={'Suggestion Sent!'}
        mode='ios'
        message={`Your suggestion has been sent to ${user?.first_name} for them to approve or decline.`}
        buttons={[
          {
            text: 'Okay',
            handler: () => {
              setShowSuccessfulSuggestionAlert(false);
              onDidDismiss();
            },
          },
        ]}
      />

      <IonAlert
        isOpen={showDeleteImageConfirmAlert}
        onDidDismiss={() => setShowDeleteImageConfirmAlert(false)}
        header={'Delete Image?'}
        mode='ios'
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {},
          },
          {
            text: 'Delete',
            handler: () => {
              handleConfirmClearImage();
            },
          },
        ]}
      />

      <IonHeader className='ion-no-border' mode='ios' translucent={true}>
        <IonToolbar>
          {!readonly && !isViewingArchivedItem && (
            <IonButtons slot='start'>
              <CloseButton
                clear
                color='dark'
                iconLeft={close}
                onClick={cancelNewItem}
              />
            </IonButtons>
          )}

          <IonButtons slot='end'>
            {(readonly || isViewingArchivedItem) && (
              <CloseButton
                clear
                color='dark'
                iconLeft={close}
                onClick={onDidDismiss}
              />
            )}
            {isLoading ? (
              <IonSpinner />
            ) : (
              !readonly &&
              !isViewingArchivedItem && (
                <AcceptButton
                  iconLeft={checkmark}
                  color='success'
                  onClick={save}
                ></AcceptButton>
              )
            )}
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <PaddingContainer>
          <SectionContainer>
            <ProductContainer className={readonly ? 'fullscreen' : ''}>
              <StyledWishListItem
                userId={userId}
                purchased={
                  loggedInUser &&
                  (!isLoggedInUsersProfile || canDisclose) &&
                  hasBeenPurchased
                    ? String(hasBeenPurchased)
                    : undefined
                }
                wishlistItem={{
                  title,
                  image,
                  link,
                  price,
                  visible_to: visibleTo,
                  deletedAt: null,
                  purchased: null,
                }}
              />
            </ProductContainer>

            {!isViewingArchivedItem &&
              !readonly &&
              loggedInUser?.id &&
              !canDisclose && (
                <ImageOptionsContainer>
                  <Button
                    iconLeft={imageOutline}
                    clear={image ? true : false}
                    color={image ? 'medium' : 'primary'}
                    onClick={() =>
                      open(imageChangeCallback, handleImageUploadError, {
                        pathPrefix: `public/${
                          isSuggestion ? userId : loggedInUser.id
                        }/`,
                      })
                    }
                  >
                    Upload Image
                  </Button>
                  {image && (
                    <Button
                      iconLeft={closeSharp}
                      clear={true}
                      color={'medium'}
                      onClick={() => setShowDeleteImageConfirmAlert(true)}
                    >
                      Clear Image
                    </Button>
                  )}
                </ImageOptionsContainer>
              )}
            {!readonly && !canDisclose && (
              <SearchImageResults
                image={image}
                uniqueOpenID={uniqueOpenID}
                isLinkFetchLoading={isLinkFetchLoading}
                searchTerm={title}
                onSearchImageSelected={setImage}
              />
            )}
            {!loggedInUser && (
              <WarningText>
                This item might already be purchased. Login to see more details.
              </WarningText>
            )}
            {canDisclose && (
              <PurchasedByDisclosureText>
                <AnimatedBorder />
                <IoGiftOutline />
                Purchased By{' '}
                {(hasBeenPurchased &&
                  userBank[hasBeenPurchased]?.user?.formattedName) ||
                  'Unknown'}
              </PurchasedByDisclosureText>
            )}
            {!isSuggestion &&
              !isViewingArchivedItem &&
              readonly &&
              loggedInUser?.id &&
              hasBeenPurchased &&
              hasBeenPurchased === loggedInUser.id && (
                <Button onClick={() => togglePurchased()} color='primary'>
                  Un-Mark as Purchased
                </Button>
              )}
            {!isSuggestion &&
              !isViewingArchivedItem &&
              readonly &&
              loggedInUser?.id &&
              hasBeenPurchased &&
              hasBeenPurchased !== loggedInUser.id && (
                <PurchasedButton disabled={true} color='primary'>
                  <p>Already Purchased</p>
                  {loggedInUser?.id && (
                    <PurchasedByText>
                      Purchased by{' '}
                      {hasBeenPurchased === loggedInUser.id
                        ? 'You'
                        : userBank[hasBeenPurchased]?.user?.formattedName ||
                          'Unknown'}
                    </PurchasedByText>
                  )}
                </PurchasedButton>
              )}
            {!isSuggestion &&
              !isViewingArchivedItem &&
              readonly &&
              !hasBeenPurchased && (
                <Button
                  onClick={() => setIsMarkPurchasedModalOpen(true)}
                  color='primary'
                >
                  Mark as Purchased
                </Button>
              )}
          </SectionContainer>

          <SectionContainer>
            {wishlistItem?.suggested_by && wishlistItem?.id && (
              <>
                {isSuggestion && (
                  <SuggestionButtonsContainer>
                    <Button
                      color='success'
                      onClick={acceptSuggestion}
                      iconLeft={thumbsUpSharp}
                    >
                      Accept
                      <small>Add to Wishlist</small>
                    </Button>
                    <Button
                      color='danger'
                      onClick={declineSuggestion}
                      iconLeft={thumbsDownSharp}
                    >
                      Decline
                      <small>Delete Suggestion</small>
                    </Button>
                  </SuggestionButtonsContainer>
                )}

                <SuggestedByText color='primary'>
                  Suggested by{' '}
                  {userBank[wishlistItem.suggested_by] &&
                  userBank[wishlistItem.suggested_by].user
                    ? userBank[wishlistItem.suggested_by].user?.formattedName
                    : 'a friend'}
                </SuggestedByText>
              </>
            )}

            {wishlists &&
              !isViewingArchivedItem &&
              (!wishlistItem?.id || isLoggedInUsersProfile) && (
                <WishlistsSelectButtonContainer
                  $newItem={Boolean(!wishlistItem?.id)}
                >
                  {wishlistItem?.id ? null : (
                    <SelectFriendForItemAddButton
                      setUserId={setUserId}
                      userId={userId || loggedInUser?.id || ''}
                      modalRef={modalRef}
                    />
                  )}

                  <WishlistsSelectButton
                    wishlists={wishlists}
                    onSelect={handleWishlistChange}
                    selectedWishlist={wishlist}
                  />
                </WishlistsSelectButtonContainer>
              )}
            {titleError && (
              <TitleRequiredError color='danger'>
                {titleError}
              </TitleRequiredError>
            )}
            <LargeTitleContainer
              className={
                (readonly ? 'readonly' : '') +
                (readonly && title.length > 80 ? ' extra-margin-bottom' : '')
              }
            >
              <LargeTitleInnerContainer
                className={
                  readonly && title.length > 80 && !revealTitle ? ' shrink' : ''
                }
              >
                <Textarea
                  className={'title' + (readonly ? ' readonly' : '')}
                  placeholder='Title'
                  readonly={isViewingArchivedItem || readonly}
                  onIonInput={(e: any) => setTitle(e.detail.value)}
                  value={title}
                  rows={1}
                  autoGrow
                />
                <LargeTitleFade className='shrink-item' />
              </LargeTitleInnerContainer>
              <ShowMoreTitleButton
                className={readonly && title.length > 80 ? 'show' : ''}
                color='light'
                small
                onClick={() => setRevealTitle(!revealTitle)}
              >
                Show {revealTitle ? 'Less' : 'More'}
              </ShowMoreTitleButton>
            </LargeTitleContainer>
            {displayOnlyVisibleToUserMessage && (
              <OnlyVisibleToYouText>
                **{user?.first_name} has made this item visible to only specific
                users, including you!
              </OnlyVisibleToYouText>
            )}
            <Textarea
              className={'notes' + (readonly && !notes ? ' empty' : '')}
              placeholder={readonly ? '' : 'Notes'}
              readonly={isViewingArchivedItem || readonly}
              onIonInput={(e: any) => setNotes(e.detail.value)}
              value={notes}
              rows={4}
            />

            <LinkInput
              itemId={wishlistItem?.id}
              link={link}
              linkDomain={linkDomain}
              setLink={setLink}
              title={title}
              setTitle={setTitle}
              price={price}
              setPrice={setPrice}
              image={image}
              setImage={setImage}
              readonly={isViewingArchivedItem || readonly}
              onClickOpenLinkInfoModal={() =>
                setIsLinkParsingInfoModalOpen(true)
              }
              uniqueOpenId={uniqueOpenID}
              isLinkFetchLoading={isLinkFetchLoading}
              setIsLinkFetchLoading={setIsLinkFetchLoading}
            />

            {!readonly && (
              <PriceInput
                readonly={isViewingArchivedItem}
                price={price}
                setPrice={setPrice}
              />
            )}
            {!isSuggestion && !isViewingArchivedItem && !readonly && (
              <VisibleToInput
                modalRef={modalRef}
                visibleTo={visibleTo}
                setVisibleTo={setVisibleTo}
              />
            )}

            {wishlistItem?.createdAt?.toDate && (
              <LastUpdatedAt className={readonly ? '' : 'not-readonly'}>
                Added On: {format(wishlistItem?.createdAt?.toDate(), 'P')}
              </LastUpdatedAt>
            )}

            {!isSuggestion && !isViewingArchivedItem && !readonly && (
              <>
                {isEditing && (
                  <DeleteButton
                    iconLeft={archiveOutline}
                    clear
                    color='medium'
                    uppercase
                    onClick={() => setShowDeleteAlert(true)}
                  >
                    Archive Item
                  </DeleteButton>
                )}
              </>
            )}

            {!isSuggestion && isViewingArchivedItem && (
              <DeleteButton
                iconLeft={arrowUndoOutline}
                clear
                color='medium'
                onClick={unArchiveItem}
              >
                Restore Item
              </DeleteButton>
            )}
          </SectionContainer>
        </PaddingContainer>
      </IonContent>
      {Boolean(link) && (
        <IonFooter>
          <ButtonActionsContainer>
            <VisitLinkButton
              onClick={triggerHrefEvent}
              href={buildRedirectLink(link, wishlistItem?.id || 'new')}
              target='_blank'
              color='dark'
              iconRight={openOutline}
            >
              Visit {linkDomain}
            </VisitLinkButton>
            {'share' in navigator ? (
              <LinkActionButton
                color='medium'
                iconRight={shareOutline}
                onClick={() => setShowActionSheet(true)}
              >
                {linkDomain.length > 14 ? '' : 'Share Link'}
              </LinkActionButton>
            ) : (
              <LinkActionButton
                color='medium'
                onClick={copyLink}
                iconRight={clipboardOutline}
              >
                {linkDomain.length > 14 ? '' : 'Copy Link'}
              </LinkActionButton>
            )}
          </ButtonActionsContainer>
        </IonFooter>
      )}
    </StyledIonModal>
  );
};

export default WishlistItemModal;
