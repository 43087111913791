import { IonContent, IonModal } from '@ionic/react';

import Picker from '@emoji-mart/react';
import React from 'react';
import data from '@emoji-mart/data';
import { styled } from 'goober';

const StyledIonModal = styled(IonModal)`
  --width: 352px;
  --max-width: 352px;
  --height: 435px;
  --max-height: 435px;
  --border-radius: 12px;
`;

interface Props {
  isOpen: boolean;
  onDidDismiss: (emoji: string | undefined) => void;
}

export const EmojiPickerModal: React.FC<Props> = ({ isOpen, onDidDismiss }) => {
  return (
    <StyledIonModal
      mode='md'
      onDidDismiss={() => onDidDismiss(undefined)}
      className='custom-modal'
      isOpen={isOpen}
    >
      <IonContent>
        <Picker
          data={data}
          theme='light'
          onEmojiSelect={(emojiObject: any) =>
            onDidDismiss(emojiObject.shortcodes)
          }
        />
      </IonContent>
    </StyledIonModal>
  );
};
