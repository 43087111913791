import { styled } from 'goober';

export const ErrorMessageText = styled('div')`
  min-height: max(250px, 50vh);
  width: 100%;
  padding: 30px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgba(var(--ion-color-dark-rgb), 0.7);

  display: flex;
  button {
    margin-top: 20px;
  }
`;
