import { UserWishlist } from '../../types/GlobalTypes';

export const mapWishlistToSortedArray = (wishlist: UserWishlist | undefined, isLoggedInUsersProfile: boolean, isArchiveList: boolean) => {
    return wishlist
        ? Object.values(wishlist)
            .map((item) => item)
            .sort((itemA, itemB) => {
                if (isArchiveList) {
                    if (itemA.deletedAt && itemB.deletedAt) {
                        return itemB.deletedAt.seconds - itemA.deletedAt.seconds;
                    }
                    // if (itemA.deletedAt) {
                    //     return -1;
                    // }
                }
                if (!isLoggedInUsersProfile) {
                    if (itemA.purchased && !itemB.purchased) {
                        return 1;
                    } else if (!itemA.purchased && itemB.purchased) {
                        return -1;
                    }
                }
                // neither item has an order number so sort it by created at
                if (itemA.order === undefined && itemB.order === undefined) {
                    if (itemA.createdAt && itemB.createdAt) {
                        return itemB.createdAt.seconds - itemA.createdAt.seconds;
                    }

                    if (itemA.createdAt) {
                        return -1;
                    }

                    return 1;
                }

                // both items have an order number so sort by that
                if (itemA.order !== undefined && itemB.order !== undefined) {
                    return itemA.order - itemB.order;
                }

                // only item B has an order number so put that in the back - we assume items with no order number are newer
                if (itemA.order === undefined) {
                    return -1;
                }

                // only item A has an order number so put that in the front - we assume items with no order number are newer
                return 1;
            })
        : undefined;
};
