import React, { useEffect, useState } from 'react';

import { Button } from './BaseUI/Button';
import { Capacitor } from '@capacitor/core';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';
import { styled } from 'goober';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';
import { useNotifications } from '../contexts/NotificationsContext';
import { useProfilePageContext } from '../contexts/ProfilePageContext';

interface Props {}

const Container = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: calc(100% - 40px);
  padding: 20px;
  margin: 20px;
  background: #e7e8f6;
  border: 2px dashed #5d5fa8;
  border-radius: 10px;
  max-width: 600px;
  @media (max-width: 460px) {
    width: calc(100% - 24px);
    margin: 20px 12px;
    padding: 14px;
  }
`;

const StyledH4 = styled('h4')`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  margin-bottom: 4px;
`;

const DescriptionText = styled('p')`
  font-size: 15px;
`;

const ContentContainer = styled('div')``;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  button:not(:first-child) {
    margin-left: 10px;
  }
`;

export const PushNotificationsLocalStorageKey = 'ifancy_push_notifications_cta';

export type PushNotificationLocalStorageData = {
  lastShown: string;
  response: 'granted' | 'denied';
};

const reminderTime = 1000 * 60 * 60 * 24 * 7;

export const PushNotificationCTABox: React.FC<Props> = () => {
  const { isNonAuthedProfile } = useProfilePageContext();
  const { loggedInUser } = useLoggedInUser();
  const { permissionStatus, handleAllow } = useNotifications();

  const [showCTA, setShowCTA] = useState<boolean | null>(null);
  const [denied, setDenied] = useState(false);

  useEffect(() => {
    if (showCTA === null && Capacitor.isNativePlatform()) {
      const data = localStorage.getItem(PushNotificationsLocalStorageKey);
      if (!data) {
        setShowCTA(true);
      } else {
        const parsedData: PushNotificationLocalStorageData = JSON.parse(data);
        if (
          parsedData.response === 'denied' &&
          new Date().getTime() - new Date(parsedData.lastShown).getTime() >
            reminderTime
        ) {
          setShowCTA(true);
        }
      }
    }
  }, [showCTA]);

  if (
    isNonAuthedProfile ||
    !loggedInUser ||
    !showCTA ||
    !Capacitor.isPluginAvailable('PushNotifications') ||
    !permissionStatus ||
    permissionStatus.receive !== 'prompt'
  ) {
    return;
  }

  const handleDeny = () => {
    logEvent(analytics, 'push_notification_denied');
    setDenied(true);
    const data: PushNotificationLocalStorageData = {
      lastShown: new Date().toISOString(),
      response: 'denied',
    };
    localStorage.setItem(
      PushNotificationsLocalStorageKey,
      JSON.stringify(data)
    );
  };

  return (
    <Container>
      <ContentContainer>
        {denied ? (
          <>
            <StyledH4>Well, okay then...</StyledH4>
            <DescriptionText>
              If you change your mind, go to the settings page (Click the
              settings icon at the top right of your profile card)
            </DescriptionText>
            <ButtonContainer>
              <Button color='dark' onClick={() => setShowCTA(false)}>
                Okay
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <>
            <StyledH4>Allow push notifications?</StyledH4>
            <DescriptionText>
              This way we can notify you of things such as friend requests,
              wishlist suggestions or even when someone has purchased something
              for you!
            </DescriptionText>
            <ButtonContainer>
              <Button color='primary' onClick={handleAllow}>
                Allow
              </Button>
              <Button color='dark' clear onClick={handleDeny}>
                Deny
              </Button>
            </ButtonContainer>
          </>
        )}
      </ContentContainer>
    </Container>
  );
};
