import { IonSearchbar, useIonRouter } from '@ionic/react';
import React, { useState } from 'react';

import { Button } from './BaseUI/Button';
import { customPageAnimationHorizontally } from '../utils/pageTransition';
import { styled } from 'goober';

interface Props {}

const Container = styled('div')`
  max-width: 450px;
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 28vw;
  @media (min-width: 967px) {
    min-width: 300px;
  }
  ion-searchbar {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    min-height: 39px;
    .searchbar-input-container {
      min-height: 39px;
    }
  }
  > button {
    margin-left: 4px;
  }
`;

export const SearchInput: React.FC<Props> = () => {
  const [searchValue, setSearchValue] = useState<string>('');

  const ionRouter = useIonRouter();

  const goToSearchPage = () => {
    ionRouter.push(
      `/search`,
      'forward',
      'push',
      { query: searchValue } as any,
      customPageAnimationHorizontally
    );
    setSearchValue('');
  };

  return (
    <Container>
      <IonSearchbar
        disabled={ionRouter.routeInfo?.pathname === '/search'}
        value={searchValue}
        onIonInput={(e) => setSearchValue(e.detail.value || '')}
        placeholder={
          ionRouter.routeInfo?.pathname === '/search'
            ? 'Use the search input below'
            : 'Search for somebody'
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            goToSearchPage();
          }
        }}
      />
      {searchValue.length > 1 && (
        <Button color='primary' onClick={goToSearchPage}>
          Search
        </Button>
      )}
    </Container>
  );
};
