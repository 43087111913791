import { Capacitor } from '@capacitor/core';
import { RateApp } from 'capacitor-rate-app';
import { differenceInDays } from 'date-fns';
import { useEffect } from 'react';

export const useAskForAppReview = (isLoggedInUsersProfile: boolean, wishlistItems: number) => {
    useEffect(() => {
        if (
            isLoggedInUsersProfile &&
            wishlistItems > 4 &&
            Capacitor.isNativePlatform()
        ) {
            const lastFailedUrlString = localStorage.getItem('ifancy-failedurl');
            const lastAskedForReviewString = localStorage.getItem(
                'ifancy-askedforreview'
            );

            if (
                lastAskedForReviewString &&
                Math.abs(
                    differenceInDays(new Date(lastAskedForReviewString), new Date())
                ) < 30
            ) {
                return;
            }

            if (
                lastFailedUrlString &&
                Math.abs(differenceInDays(new Date(lastFailedUrlString), new Date())) <
                2
            ) {
                return;
            }

            RateApp.requestReview();

            localStorage.setItem('ifancy-askedforreview', new Date().toISOString());
        }
    }, [isLoggedInUsersProfile, wishlistItems]);

}