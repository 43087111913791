import { IonBadge, IonIcon } from '@ionic/react';
import React, { useContext, useEffect } from 'react';

import { CustomImage } from './BaseUI/CustomImage';
import MissingLinkIcon from '../assets/imgs/missing-link-icon.svg';
import { UserBankContext } from '../contexts/UserBank';
import { WishlistItem } from '../types/GlobalTypes';
import { eyeOffOutline } from 'ionicons/icons';
import { styled } from 'goober';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

interface Props {
  onClick?: () => void;
  wishlistItem: WishlistItem;
  className?: string;
  purchased?: string;
  isLoggedInUsersItem?: boolean;
  userId: string | undefined;
}

const WishListItemContainer = styled('div')<{ $purchased: boolean }>`
  border-radius: 12px;
  background: white;
  box-shadow: ${({ $purchased }) =>
    $purchased
      ? `var(--box-shadow-wishlist-item-muted)`
      : `var(--box-shadow-wishlist-item)`};
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: transform 0.15s ease;
  &:active {
    &.clickable {
      transform: scale(0.93);
    }
  }
`;

const StyledCustomImage = styled(CustomImage as any)`
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const WishListContent = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 7;
  padding: 6px;
  pointer-events: none;
  justify-content: space-between;
`;

const WishListHeader = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const MissingLink = styled('div')`
  margin: 0;
  padding: 1px 7px;
  padding-bottom: 0px;
  background: white;
  border-radius: 16px;
  box-shadow: var(--box-shadow-md);
  img {
    width: 15px;
  }
`;

const PriceContainer = styled('div')`
  border-radius: 50px;
  background: black;
  color: white;
  opacity: 0.7;
  padding: 1px 5px;
  font-size: 12px;
`;

const TitleContainer = styled('div')`
  border-radius: 8px;
  background: black;
  color: white;
  padding: 3px 7px;
  text-overflow: ellipsis;
  max-height: 57px;
  line-height: 110%;
`;

const HiddenBadge = styled(IonBadge)`
  width: 32px;
  display: flex;
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 4px;
`;

const Container = styled('div')<any>`
  padding: 8px;
  @media (max-width: 650px) {
    padding: 5px;
  }
  height: 260px;
  .if-custom-img {
    opacity: ${({ $purchased, $isNotVisible }) =>
      $isNotVisible ? 0.5 : $purchased ? 0.3 : 1};
  }
  .if-wishlist-header {
    opacity: ${({ $purchased, $isNotVisible }) =>
      $isNotVisible ? 0.5 : $purchased ? 0.3 : 1};
  }
  .if-title-container {
    opacity: ${({ $purchased, $isNotVisible }) =>
      $isNotVisible ? 0.5 : $purchased ? 0.3 : 0.7};
  }
`;

export const WishListItem: React.FC<Props> = ({
  onClick,
  purchased,
  isLoggedInUsersItem,
  wishlistItem,
  className,
  userId,
}) => {
  const { userBank, getUser } = useContext(UserBankContext);
  const { loggedInUser } = useLoggedInUser();

  useEffect(() => {
    if (purchased && loggedInUser) {
      getUser(purchased);
    }
  }, [purchased, getUser, loggedInUser]);

  return (
    <Container
      className={className}
      $isNotVisible={
        isLoggedInUsersItem && wishlistItem.visible_to !== undefined
      }
      $purchased={purchased}
    >
      <WishListItemContainer
        $purchased={Boolean(purchased)}
        onClick={onClick}
        className={onClick ? 'clickable' : ''}
      >
        <StyledCustomImage
          className='if-custom-img'
          userId={userId}
          src={wishlistItem.image}
        />
        <WishListContent>
          <WishListHeader className='if-wishlist-header'>
            <div style={{ display: 'flex' }}>
              {!wishlistItem.link && (
                <MissingLink>
                  <img src={MissingLinkIcon} alt='missing link' />
                </MissingLink>
              )}
            </div>
            <div>
              {wishlistItem.price && (
                <PriceContainer>${wishlistItem.price}</PriceContainer>
              )}
            </div>
          </WishListHeader>
          <div>
            {purchased && (
              <IonBadge className='full-opacity' color='primary'>
                Purchased
                {loggedInUser
                  ? ` by ${
                      purchased === loggedInUser?.id
                        ? 'You'
                        : userBank[purchased]?.user?.first_name || 'Unknown'
                    }`
                  : ''}
              </IonBadge>
            )}
            {isLoggedInUsersItem &&
              typeof wishlistItem.visible_to !== 'undefined' && (
                <HiddenBadge className='full-opacity' color='primary'>
                  <IonIcon icon={eyeOffOutline} />
                </HiddenBadge>
              )}
            {wishlistItem.title && (
              <TitleContainer className='if-title-container'>
                {wishlistItem.title.length > 40
                  ? wishlistItem.title.substring(0, 40) + '...'
                  : wishlistItem.title}
              </TitleContainer>
            )}
          </div>
        </WishListContent>
      </WishListItemContainer>
    </Container>
  );
};
