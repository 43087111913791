import React, { useContext } from 'react';

import { NotificationsContext } from '../contexts/NotificationsContext';
import { styled } from 'goober';

const NotificationBadgeContainer = styled('div')`
  padding: 2px 6px;
  padding-top: 3px;
  border-radius: 12px;
  background: var(--ion-color-danger);
  color: white;
  font-size: 13px;
  position: absolute;
  top: -7px;
  left: 50%;
  z-index: 10;
  text-align: center;
  display: flex;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  font-family: 'Nunito Sans', sans-serif;
  box-shadow: var(--box-shadow-md);
  min-width: 20px;
  pointer-events: none;
`;

type Props = {
  className?: string;
};

export const NotificationBadge: React.FC<Props> = ({ className }) => {
  const { notifications } = useContext(NotificationsContext);
  const notificationUnreadCount =
    notifications?.filter((notification) => !notification.read)?.length || 0;

  if (notificationUnreadCount < 1) return null;

  return (
    <NotificationBadgeContainer className={className}>
      {notificationUnreadCount > 19 ? '20+' : notificationUnreadCount}
    </NotificationBadgeContainer>
  );
};
