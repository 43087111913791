import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import {
  PushNotificationLocalStorageData,
  PushNotificationsLocalStorageKey,
} from '../components/PushNotificationCTABox';
import React, { useEffect, useRef, useState } from 'react';
import { db, functions } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';

import { Button } from '../components/BaseUI/Button';
import { Capacitor } from '@capacitor/core';
import { DeleteAccountButton } from '../components/DeleteAccountButton';
import LinkParsingInfoModal from '../modals/LinkParsingInfoModal';
import LogoutButton from '../components/authentication/LogoutButton';
import { NotificationEventName } from '../types/GlobalTypes';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StyledIonOuterToolbar } from '../components/layout/StyledOuterIonToolbar';
import { customPageAnimationHorizontally } from '../utils/pageTransition';
import { handleCatchError } from '../utils/handleCatchError';
import { httpsCallable } from 'firebase/functions';
import packageJson from '../../package.json';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';
import { useNotifications } from '../contexts/NotificationsContext';

const StyledIonTitle = styled(IonTitle)``;

const SettingTitle = styled('p')``;

const SettingDescription = styled('p')`
  font-size: 12px;
  opacity: 0.6;
  margin-top: -2px;
  line-height: 120%;
`;

const SettingsLabel = styled('div')`
  padding: 10px 0;
`;

const CloseButton = styled(Button as any)`
  padding: 6px 16px;
  .btn-text {
    font-size: 19px;
    font-family: 'Nunito Sans', sans-serif;
    letter-spacing: 0.3px;
  }
`;

const StyledIonListHeader = styled(IonListHeader)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.4px;
  font-family: 'Nunito Sans', sans-serif;
  margin-top: 24px;
  &:first-of-type {
    margin-top: 0;
  }
`;

const Settings: React.FC = () => {
  const { loggedInUser, pushToken, setPushToken } = useLoggedInUser();
  const [isLinkParsingInfoModalOpen, setIsLinkParsingInfoModalOpen] =
    useState(false);
  const ionRouter = useIonRouter();
  const pageRef = useRef<any>();
  const [showAllowNotifications, setShowAllowNotifications] = useState(false);
  const { handleAllow } = useNotifications();

  const togglePrivateProfile = async () => {
    try {
      if (!loggedInUser?.id) throw new Error('Unauthenticated');
      const newPrivateValue = !loggedInUser.private ? true : false;
      await updateDoc(doc(db, `users/${loggedInUser.id}`), {
        private: newPrivateValue,
      });

      toast.success(
        `Your profile is now ${newPrivateValue ? 'private' : 'public'}`
      );
    } catch (err) {
      handleCatchError(err);
    }
  };

  const toggleHideFromSearch = async () => {
    try {
      if (!loggedInUser?.id) throw new Error('Unauthenticated');
      const newValue = !loggedInUser.hideFromSearch ? true : false;
      await updateDoc(doc(db, `users/${loggedInUser.id}`), {
        hideFromSearch: newValue,
      });
      toast.success(
        `Your profile is now ${newValue ? 'non-searchable' : 'searchable'}`
      );
    } catch (err) {
      handleCatchError(err);
    }
  };

  const goBack = () => {
    ionRouter.goBack();
  };

  const toggleNotificationSetting:
    | React.MouseEventHandler<HTMLIonToggleElement>
    | undefined = async (e) => {
    try {
      if (!e) throw new Error('No event');
      const eventName = e.currentTarget.getAttribute(
        'name'
      ) as NotificationEventName;
      if (!eventName) throw new Error('No event name value');
      if (!loggedInUser?.id) throw new Error('Unauthenticated');
      if (!pushToken?.id) throw new Error('No Push Token Settings');
      if (!pushToken.ignoreNotificationTypes) {
        await updateDoc(
          doc(db, `users/${loggedInUser.id}/pnids/${pushToken.id}`),
          { ignoreNotificationTypes: [] }
        );
        throw new Error('Malformed token doc. Try Again');
      }

      if (eventName === 'global-notification') {
        const apiCall = httpsCallable(
          functions,
          pushToken.ignoreNotificationTypes.includes(eventName)
            ? 'subscribeToGlobalNotifications'
            : 'unsubscribeToGlobalNotifications'
        );
        await apiCall({ token: pushToken.id });
      }

      const newValue = pushToken.ignoreNotificationTypes.includes(eventName)
        ? [...pushToken.ignoreNotificationTypes.filter((i) => i !== eventName)]
        : [...pushToken.ignoreNotificationTypes, eventName];
      await updateDoc(
        doc(db, `users/${loggedInUser.id}/pnids/${pushToken.id}`),
        { ignoreNotificationTypes: newValue }
      );
      setPushToken({ ...pushToken, ignoreNotificationTypes: newValue });
    } catch (err) {
      handleCatchError(err);
    }
  };

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    const data = localStorage.getItem(PushNotificationsLocalStorageKey);
    if (!data) {
      setShowAllowNotifications(true);
    } else {
      const parsedData: PushNotificationLocalStorageData = JSON.parse(data);
      if (parsedData.response === 'denied') {
        setShowAllowNotifications(true);
      }
    }
  }, []);

  return (
    <IonPage ref={pageRef}>
      <IonHeader mode='ios' translucent={true}>
        <StyledIonOuterToolbar>
          <IonButtons slot='end'>
            <CloseButton color='dark' clear onClick={goBack}>
              Close
            </CloseButton>
          </IonButtons>
          <IonTitle>Settings</IonTitle>
        </StyledIonOuterToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <PageWrapper footerType='full'>
          <IonHeader mode='ios' collapse='condense'>
            <IonToolbar>
              <StyledIonTitle size='large'>Settings</StyledIonTitle>
            </IonToolbar>
          </IonHeader>
          <IonList>
            <StyledIonListHeader mode='ios'>
              Profile Settings
            </StyledIonListHeader>
            <IonItem disabled={!loggedInUser?.id}>
              <SettingsLabel>
                <SettingTitle>Private Profile</SettingTitle>
                <SettingDescription>
                  Users cannot see items on a private profile unless they add
                  you as a friend. This does not affect the searchability
                  setting.
                </SettingDescription>
              </SettingsLabel>

              <IonToggle
                slot='end'
                color='dark'
                onClick={togglePrivateProfile}
                checked={loggedInUser?.private}
              />
            </IonItem>

            <IonItem disabled={!loggedInUser?.id}>
              <SettingsLabel>
                <SettingTitle>Searchable Profile</SettingTitle>
                <SettingDescription>
                  When users search for friends, a non-searchable profile will
                  not be listed in the results.
                </SettingDescription>
              </SettingsLabel>
              <IonToggle
                slot='end'
                color='dark'
                onClick={toggleHideFromSearch}
                checked={!loggedInUser?.hideFromSearch}
              />
            </IonItem>

            {loggedInUser?.id && <LogoutButton />}

            {loggedInUser?.id && Capacitor.isNativePlatform() && (
              <>
                <StyledIonListHeader mode='ios'>
                  Push Notifications
                </StyledIonListHeader>
                {!pushToken && showAllowNotifications && (
                  <IonItem>
                    <SettingsLabel>
                      <SettingTitle>Allow Notifications</SettingTitle>
                    </SettingsLabel>
                    <Button
                      slot='end'
                      color='primary'
                      small
                      onClick={handleAllow}
                    >
                      Allow
                    </Button>
                  </IonItem>
                )}

                {pushToken && (
                  <>
                    <IonItem>
                      <SettingsLabel>
                        <SettingTitle>Friend Activity</SettingTitle>
                        <SettingDescription>
                          If a user modifies their wishlist, receive a push
                          notification - Limited to once every 3 days.
                        </SettingDescription>
                      </SettingsLabel>
                      <IonToggle
                        slot='end'
                        color='dark'
                        name={'friend-activity' as NotificationEventName}
                        onClick={toggleNotificationSetting}
                        checked={
                          !pushToken.ignoreNotificationTypes?.includes(
                            'friend-activity'
                          )
                        }
                      />
                    </IonItem>
                    <IonItem>
                      <SettingsLabel>
                        <SettingTitle>Friend Add</SettingTitle>
                        <SettingDescription>
                          When a user adds you as a friend, receive a push
                          notification.
                        </SettingDescription>
                      </SettingsLabel>
                      <IonToggle
                        slot='end'
                        color='dark'
                        name={'friend-add' as NotificationEventName}
                        onClick={toggleNotificationSetting}
                        checked={
                          !pushToken.ignoreNotificationTypes?.includes(
                            'friend-add'
                          )
                        }
                      />
                    </IonItem>
                    {/* <IonItem>
                  <SettingsLabel>
                    <SettingTitle>Item Suggestion</SettingTitle>
                    <SettingDescription>
                      When a user suggests an item for you to add to your wishlist, receive a push notification.
                    </SettingDescription>
                  </SettingsLabel>
                  <IonToggle
                    slot="end"
                    color="dark"
                   name={'item-suggested' as NotificationEventName}
                    onClick={toggleNotificationSetting}
                    checked={!pushToken.ignoreNotificationTypes?.includes('item-suggested')}
                 />
                </IonItem> */}
                    {/* <IonItem>
                  <SettingsLabel>
                    <SettingTitle>Item Archived</SettingTitle>
                    <SettingDescription>
                      When an item on your wishlist has been archived after being marked purchased for longer than 3
                      months, receive a push notification.
                    </SettingDescription>
                  </SettingsLabel>
                  <IonToggle
                    slot="end"
                    color="dark"
                    name={'item-archived' as NotificationEventName}
                    onClick={toggleNotificationSetting}
                    checked={!pushToken.ignoreNotificationTypes?.includes('item-archived')}
                />
                </IonItem> */}
                    <IonItem>
                      <SettingsLabel>
                        <SettingTitle>Item Purchased</SettingTitle>
                        <SettingDescription>
                          When a user marks an item on your wishlist as
                          purchased, receive a push notification.
                        </SettingDescription>
                      </SettingsLabel>
                      <IonToggle
                        slot='end'
                        color='dark'
                        name={'item-purchased' as NotificationEventName}
                        onClick={toggleNotificationSetting}
                        checked={
                          !pushToken.ignoreNotificationTypes?.includes(
                            'item-purchased'
                          )
                        }
                      />
                    </IonItem>
                    <IonItem>
                      <SettingsLabel>
                        <SettingTitle>General</SettingTitle>
                        <SettingDescription>
                          When a general notification is sent out from iFancy,
                          receive a push notification.
                        </SettingDescription>
                      </SettingsLabel>
                      <IonToggle
                        slot='end'
                        color='dark'
                        name={'global-notification' as NotificationEventName}
                        onClick={toggleNotificationSetting}
                        checked={
                          !pushToken.ignoreNotificationTypes?.includes(
                            'global-notification'
                          )
                        }
                      />
                    </IonItem>
                  </>
                )}
              </>
            )}
            <LinkParsingInfoModal
              pageRef={pageRef}
              isOpen={isLinkParsingInfoModalOpen}
              onDidDismiss={() => setIsLinkParsingInfoModalOpen(false)}
            />
            <StyledIonListHeader mode='ios'>Info</StyledIonListHeader>
            <IonItem
              button
              detail={true}
              onClick={() => setIsLinkParsingInfoModalOpen(true)}
            >
              Link Parsing
            </IonItem>
            <IonItem
              detail={true}
              routerLink='/terms-of-use'
              routerAnimation={customPageAnimationHorizontally}
            >
              Terms Of Use
            </IonItem>
            <IonItem
              detail={true}
              routerLink='/privacy-policy'
              routerAnimation={customPageAnimationHorizontally}
            >
              Privacy Policy
            </IonItem>
            <IonItem
              detail={true}
              routerLink='/affiliate-disclosure'
              routerAnimation={customPageAnimationHorizontally}
            >
              Affiliate Disclosure
            </IonItem>
            <IonItem>Version: {packageJson.version}</IonItem>

            {loggedInUser?.id && (
              <>
                <StyledIonListHeader mode='ios'>
                  Danger Zone
                </StyledIonListHeader>
                <DeleteAccountButton />
              </>
            )}
          </IonList>
        </PageWrapper>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
