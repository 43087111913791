import { ImageUploadOptions, ImageUploadSuccess } from '../contexts/ImageUploadContext';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

import { defaultAcceptedFileTypes } from '../constants/defaultAcceptedFileTypes';
import { getImageDimensions } from './getImageDimensions';
import { nanoid } from 'nanoid';
import { storage } from '../firebase';

type UploadImageFn = {
  image: string;
  acceptedFileTypes?: string[];
  uploadOptions?: ImageUploadOptions;
};

export const uploadImageToFirebase = async ({
  image,
  acceptedFileTypes = defaultAcceptedFileTypes,
  uploadOptions,
}: UploadImageFn) => {
  // for now don't do anything with url images
  if (image.startsWith('http')) {
    // just return the URL
    return {
      downloadUrl: image,
      fullPath: image,
    } as ImageUploadSuccess;
  }
  const res = await fetch(image);
  const blob = await res.blob();

  const dimensionMetadata = await getImageDimensions(image);

  if (!acceptedFileTypes.includes(blob.type)) {
    throw new Error('Cannot accept the file type: ' + blob.type);
  }

  const imageUUID = uploadOptions?.imageFileName
    ? uploadOptions?.imageFileName
    : nanoid();
  console.log(
    'Uploading Image: ',
    imageUUID,
    'Opts:',
    uploadOptions
  );


  const fileType = blob.type.split('/')[1];
  const fullPath =
    (uploadOptions?.pathPrefix ? uploadOptions.pathPrefix : '') +
    imageUUID +
    '.' +
    fileType;
  const firebaseImageRef = ref(storage, fullPath);
  const imageUploadObj = await uploadBytes(firebaseImageRef, blob, { customMetadata: dimensionMetadata })

  const imageURI: string = await getDownloadURL(imageUploadObj.ref);



  if (imageURI) {
    // force https for images
    if (imageURI.indexOf('http:') !== -1) {
      imageURI.replace('http:', 'https:');
    }

    return {
      downloadUrl: imageURI,
      imageUUID,
      fileType,
      ...dimensionMetadata,
      fullPath,
    } as ImageUploadSuccess;
  } else {
    throw new Error('There was an error uploading the image :/');
  }
};
