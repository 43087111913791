import { Capacitor } from '@capacitor/core';
import React from 'react';

interface Props {
  picture: {
    sources: any;
    img: { src: string; w: number; h: number };
  };
  alt: string;
}

export const Picture: React.FC<Props> = ({ picture, alt }) => {
  return (
    <>
      {Object.entries(picture.sources).map(([type, srcSet]: any) =>
        Capacitor.isNativePlatform() && type === 'avif' ? null : (
          <source key={type} srcSet={srcSet} type={'image/' + type} />
        )
      )}

      <img
        src={picture.img.src}
        width={picture.img.w}
        height={picture.img.h}
        alt={alt}
      />
    </>
  );
};
