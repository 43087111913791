import { Keyboard, KeyboardInfo, KeyboardResize } from '@capacitor/keyboard';
import { useEffect, useState } from 'react';
import { useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';

import { Capacitor } from '@capacitor/core';

export const useKeyboardResizeNonePaddingHeight = () => {
  const [padding, setPadding] = useState(0);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardWillShow', (info: KeyboardInfo) => {
        setPadding(info.keyboardHeight);
      });

      Keyboard.addListener('keyboardWillHide', () => {
        setPadding(0);
      });
    }

    return () => {
      if (Capacitor.isNativePlatform()) {
        Keyboard.removeAllListeners();
      }
    };
  }, []);

  useIonViewDidEnter(() => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
  });

  useIonViewWillLeave(() => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.Native });
    }
  });

  return padding;
};
