import { styled } from 'goober';

export const LiveButtonDot = styled('div')`
  width: 8px;
  height: 8px;
  margin-right: 6px;
  border-radius: 50%;
  background: var(--ion-color-danger);
  &.green {
    background: var(--ion-color-success);
  }
  animation: pulse 1.5s infinite;
`;
