import { Event, captureEvent } from '@sentry/react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { auth, db } from '../firebase';
import {
  deleteField,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';

import { Capacitor } from '@capacitor/core';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { User } from '../types/GlobalTypes';
import equal from 'fast-deep-equal/es6';
import { getFormattedName } from '../utils/transformers/getFormattedName';
import { log } from '../utils/log';
import { transformUser } from '../utils/transformers/transformUser';

type UserBankProps = {
  updateUser: (user_id: string, newFields: Partial<User>) => Promise<User>;
  getUser: (
    user_id: string,
    refresh?: boolean,
    ignoreDoesNotExistErrorReport?: boolean
  ) => Promise<User | null | undefined>;
  userBank: UserBank;
  watchUser: (
    userId: string
  ) => Promise<User | undefined | { doesNotExist: true }>;
  stopWatching: (userId: string) => void;
};

type UserBank = {
  [user_id: string]: {
    user?: User;
    doesNotExist?: true;
  };
};

export const UserBankContext = React.createContext<UserBankProps>(
  {} as UserBankProps
);

export const useUserBank = () => useContext(UserBankContext);

export const UserBankProvider = ({ children }: any) => {
  const [userBank, setUserBank] = useState<UserBank>({});

  const realTimeFetchList = useRef<string[]>([]);
  const userBankRef = useRef<UserBank>({});
  const documentListeners = useRef<{ userId: string; listener: any }[]>([]);
  const writeLimit = useRef(1000);

  const watchUser = useCallback(
    (userId: string) =>
      new Promise(
        (
          resolve: (user: User | undefined | { doesNotExist: true }) => void
        ) => {
          if (
            !documentListeners?.current?.find(
              ({ userId: listenerUserID }) => listenerUserID === userId
            )
          ) {
            log(
              'green',
              '[WATCH] User watch subscription created for:' + userId
            );
            const listener = onSnapshot(
              doc(db, `users/${userId}`),
              async (rawDoc) => {
                let user: User | undefined | { doesNotExist: true };
                if (!rawDoc.exists()) {
                  if (!userBankRef.current[userId]) {
                    userBankRef.current[userId] = { doesNotExist: true };
                    user = { doesNotExist: true };
                  }
                } else {
                  user = await transformUser(rawDoc);

                  if (!userBankRef.current[userId]) {
                    userBankRef.current[userId] = { user };
                  } else {
                    userBankRef.current[userId].user = user;
                  }

                  if (userBankRef.current[userId].doesNotExist) {
                    delete userBankRef.current[userId].doesNotExist;
                  }
                }

                log('white', 'Watched User updated: ' + userId);
                setUserBank({ ...userBankRef.current });
                resolve(user);
              },
              console.error
            );
            documentListeners.current.push({ userId, listener });
          }
        }
      ),
    []
  );

  const stopWatching = useCallback((userId: string) => {
    // if empty ID, remove all listeners
    if (!userId) {
      for (const listenerRef of documentListeners.current) {
        listenerRef.listener();
      }
      documentListeners.current = [];
      return;
    }

    const listenerRef = documentListeners?.current?.find(
      ({ userId: listenerUserID }) => listenerUserID === userId
    );
    if (!listenerRef) return;
    log('green', '[UN-WATCH] User doc listener unsubscribed for: ' + userId);
    listenerRef.listener();

    documentListeners.current = documentListeners?.current?.filter(
      ({ userId: listenerUserID }) => listenerUserID !== userId
    );
  }, []);

  const updateUser = useCallback(
    async (user_id: string, newFields: Partial<User>) => {
      if (!userBankRef.current[user_id]) {
        throw new Error(
          "Uhhhh we don't have that user in the bank... dats a prolem"
        );
      }

      const newUser = {
        ...userBankRef.current[user_id].user,
        ...newFields,
      } as User;
      if (newFields.first_name || newFields.last_name) {
        newUser.formattedName = getFormattedName(
          newFields.first_name || userBankRef.current[user_id].user?.first_name,
          newFields.last_name || userBankRef.current[user_id].user?.last_name
        );
      }

      if (equal(userBankRef.current[user_id].user, newUser)) {
        log(
          'render',
          `[${newUser.id}] User update is equal so WE MIGHT NEED TO IGNORE BUT WE ARE NOT`
        );
        console.warn(
          `[${newUser.id}] User update is equal so WE MIGHT NEED TO IGNORE BUT WE ARE NOT`
        );
        // return newUser;
      }

      userBankRef.current[user_id].user = newUser;

      log('write', '[Write] User: ' + user_id);

      writeLimit.current--;
      if (writeLimit.current < 1) {
        throw new Error('Write limit exceeded');
      }
      // if any field is undefined, the assumed intention is to remove it from DB
      for (const key in newFields) {
        if (newFields[key as keyof User] === undefined) {
          newFields[key as keyof User] = deleteField() as any;
        }
      }

      await updateDoc(doc(db, `users/${user_id}`), newFields);
      const listenerRef = documentListeners?.current?.find(
        ({ userId: listenerUserID }) => listenerUserID === user_id
      );
      if (!listenerRef) {
        // only force state update if its not being watched otherwise let the listener update the state
        setUserBank({ ...userBankRef.current });
      }
      return newUser;
    },
    []
  );

  const fetchUser = useCallback(
    async (user_id: string, ignoreDoesNotExistErrorReport?: boolean) => {
      const listenerRef = documentListeners?.current?.find(
        ({ userId: listenerUserID }) => listenerUserID === user_id
      );

      if (realTimeFetchList.current.includes(user_id) || listenerRef) {
        return null;
      } else {
        realTimeFetchList.current.push(user_id);
      }
      log('read', '[Fetch] User: ' + user_id);
      const rawDoc = await getDoc(doc(db, `users/${user_id}`));

      let user: User | undefined;
      if (!rawDoc.exists()) {
        if (!ignoreDoesNotExistErrorReport) {
          const event: Event = {
            message: `${user_id} does not exist`,
          };
          captureEvent(event);
          console.error(event.message);
        }
        // set to empty object to denote that the user has been queried but no user object exists
        if (!userBankRef.current[user_id]) {
          userBankRef.current[user_id] = { doesNotExist: true };
        }
      } else {
        user = await transformUser(rawDoc);

        if (!userBankRef.current[user_id]) {
          userBankRef.current[user_id] = { user };
        } else {
          userBankRef.current[user_id].user = user;
        }

        if (userBankRef.current[user_id].doesNotExist) {
          delete userBankRef.current[user_id].doesNotExist;
        }
      }

      //check for forced logout
      if (
        auth?.currentUser?.uid &&
        user?.id &&
        user?.id === auth?.currentUser?.uid &&
        user.forceLogout
      ) {
        await updateUser(user.id, { forceLogout: deleteField() as any });
        await auth.signOut();
        if (Capacitor.isNativePlatform()) {
          await FirebaseAuthentication.signOut();
        }
        localStorage.clear();
      }

      realTimeFetchList.current = realTimeFetchList.current.filter(
        (item) => item !== user_id
      );
      if (realTimeFetchList.current.length === 0) {
        setUserBank({ ...userBankRef.current });
      }
      return user;
    },
    [updateUser]
  );

  const getUser = useCallback(
    async (
      user_id: string,
      refresh?: boolean,
      ignoreDoesNotExistErrorReport?: boolean
    ) => {
      if (userBankRef.current[user_id] && userBankRef.current[user_id].user) {
        if (refresh) {
          return await fetchUser(user_id, ignoreDoesNotExistErrorReport);
        } else {
          return userBankRef.current[user_id].user;
        }
      } else {
        return await fetchUser(user_id, ignoreDoesNotExistErrorReport);
      }
    },
    [fetchUser]
  );

  useEffect(() => {
    return () => {
      for (const item of documentListeners.current) {
        item.listener();
      }
      documentListeners.current = [];
    };
  }, []);

  return (
    <UserBankContext.Provider
      value={{
        getUser,
        watchUser,
        stopWatching,
        userBank,
        updateUser,
      }}
    >
      {children}
    </UserBankContext.Provider>
  );
};
