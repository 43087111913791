import { IonPage, useIonRouter } from '@ionic/react';
import React, { useEffect } from 'react';

import { Capacitor } from '@capacitor/core';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';
import { useLocation } from 'react-router';

export const OpenInApp: React.FC = () => {
  const ionRouter = useIonRouter();
  const location = useLocation();
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      ionRouter.push(
        decodeURIComponent(location.search.replace('?pathname=', ''))
      );
    } else {
      logEvent(analytics, 'open_in_app');
      window.location.href =
        'https://apps.apple.com/us/app/ifancy-wishlist/id1536285199';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <IonPage></IonPage>;
};

export default OpenInApp;
