import {
  AlertButton,
  IonAlert,
  getPlatforms,
  useIonRouter,
} from '@ionic/react';
import React, { useState } from 'react';
import { notificationsOffOutline, notificationsOutline } from 'ionicons/icons';

import { Capacitor } from '@capacitor/core';
import { ProfileMenuActionButton } from './ProfileActionButtons';
import { analytics } from '../../firebase';
import { apiFunctionFetch } from '../../utils/apiFunctionFetch';
import { handleCatchError } from '../../utils/handleCatchError';
import { logEvent } from 'firebase/analytics';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';
import { useProfilePageContext } from '../../contexts/ProfilePageContext';
import { useUserBank } from '../../contexts/UserBank';

interface Props {
  onClick?: () => void;
}

const NewBadge = styled('p')`
  padding: 1px 6px;
  padding-top: 2px;
  border-radius: 10px;
  background-color: var(--ion-color-danger);
  color: white;
  font-size: 10px;
  font-weight: 900;
  margin: 0px 4px;
  display: inline-block;
`;

export const SubscribeToWishlistUpdatesButton: React.FC<Props> = ({
  onClick,
}) => {
  const [showSubscribeAlert, setShowSubscribeAlert] = useState(false);
  const [showNotOnMobileAlert, setShowNotOnMobileAlert] = useState(false);
  const { userId, selectedWishlist } = useProfilePageContext();
  const { loggedInUser } = useLoggedInUser();
  const { userBank } = useUserBank();

  const ionRouter = useIonRouter();

  if (!loggedInUser?.id || !userId || !selectedWishlist) return null;

  const user = userBank[userId].user;

  if (!user) return null;

  const subscribeToNotifications = () => {
    setShowSubscribeAlert(true);
  };

  const unsubscribeToNotifications = async () => {
    const toastId = toast('Unsubscribing...');
    let successToastId: string | undefined;
    setTimeout(() => {
      toast.dismiss(toastId);
      successToastId = toast.success('Unsubscribed');
      onClick?.();
    }, 1000);

    try {
      const response = await apiFunctionFetch(
        'wishlistNotificationSubscription',
        {
          userId: user.id,
          wishlistId: selectedWishlist.id,
          action: 'unsubscribe',
        }
      );

      if (response.error) {
        throw new Error(response.error);
      }
    } catch (error) {
      if (successToastId) toast.dismiss(successToastId);
      toast.error('Uh oh, wait... Failed to unsubscribe');
      handleCatchError(error);
    }
  };

  const handleSubscribe = async () => {
    if (!Capacitor.isNativePlatform()) {
      setShowNotOnMobileAlert(true);
    }
    setShowSubscribeAlert(false);

    const toastId = toast('Subscribing...');
    let successToastId: string | undefined;
    setTimeout(() => {
      toast.dismiss(toastId);
      successToastId = toast.success('Subscribed');
    }, 1000);

    try {
      const response = await apiFunctionFetch(
        'wishlistNotificationSubscription',
        {
          userId: user.id,
          wishlistId: selectedWishlist.id,
          action: 'subscribe',
        }
      );

      if (response.error) {
        throw new Error(response.error);
      }
    } catch (error) {
      if (successToastId) toast.dismiss(successToastId);
      toast.error('Uh oh, wait... Failed to subscribe');
      handleCatchError(error);
    }

    if (Capacitor.isNativePlatform()) {
      onClick?.();
    }
  };

  const notOnMobileButtons: AlertButton[] = [
    {
      text: 'I have an Android',
      handler: () => {
        logEvent(analytics, 'i_have_android_from_notify_me');
        toast.error(
          'Sorry, we are currently working on an Android version but it is not ready yet.'
        );
        onClick?.();
      },
    },
    {
      text: 'Okay',
      role: 'cancel',
      cssClass: 'secondary',
      handler: () => {
        onClick?.();
      },
    },
  ];

  if (
    !Capacitor.isNativePlatform() &&
    !getPlatforms().includes('android') &&
    (getPlatforms().includes('ios') || navigator.userAgent.indexOf('Mac') > -1)
  ) {
    notOnMobileButtons.unshift({
      text: 'Go To App Store',
      handler: () => {
        logEvent(analytics, 'open_in_app_from_notify_me');
        ionRouter.push('/open-in-app');
        onClick?.();
      },
    });
  }

  return (
    <>
      <IonAlert
        isOpen={showSubscribeAlert}
        onDidDismiss={() => setShowSubscribeAlert(false)}
        header={'Subscribe to Wishlist Updates'}
        mode='ios'
        message={`You will be notified anytime ${user.first_name} adds or modifies items in their wishlist called "${selectedWishlist.name}".`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {},
          },
          {
            text: 'Subscribe',
            handler: () => {
              handleSubscribe();
            },
          },
        ]}
      />

      <IonAlert
        isOpen={showNotOnMobileAlert}
        onDidDismiss={() => setShowNotOnMobileAlert(false)}
        header={'Uh Oh!'}
        mode='ios'
        message={`This feature will only work if you have the iOS (or Mac) app and push notifications turned on.`}
        buttons={notOnMobileButtons}
      />
      {selectedWishlist.notificationSubscribers?.includes(loggedInUser.id) ? (
        <ProfileMenuActionButton
          color='medium'
          clear
          iconLeft={notificationsOffOutline}
          onClick={unsubscribeToNotifications}
        >
          Stop Notifications
        </ProfileMenuActionButton>
      ) : (
        <ProfileMenuActionButton
          color='medium'
          clear
          iconLeft={notificationsOutline}
          onClick={subscribeToNotifications}
          iconRight={
            !loggedInUser?.featureAwareness?.subscribeToWishlistUpdates && (
              <NewBadge>NEW</NewBadge>
            )
          }
        >
          Notify Me
        </ProfileMenuActionButton>
      )}
    </>
  );
};
