// @ts-expect-error image import
import HappyGirlsImage from '../../assets/imgs/homepage-happy-girls.png?w=300;600;900;1200&format=avif;webp;png&as=picture';
import { Picture } from '../BaseUI/Picture';
import { PseudoH1 } from './HomepageStyledComponents';
import React from 'react';
import { motion } from 'framer-motion';
import { styled } from 'goober';

interface Props {}

const defaultViewPortOptions: any = {
  once: true,
};

const MainTitle = styled(PseudoH1)`
  &.primary {
    color: var(--ion-color-primary);
  }
`;

const Subheader = styled(motion.p)`
  font-size: 15px;
  font-family: 'Nunito', sans-serif;
  margin-bottom: 20px;
  color: #202020;
  max-width: 80%;
  span {
    font-weight: 700;
  }
  @media (min-width: 368px) {
    font-size: 17px;
  }
  @media (min-width: 468px) {
    margin-bottom: 20px;
    font-size: 20px;
  }
  @media (min-width: 768px) {
    font-size: 27px;
  }

  @media (min-width: 968px) {
    font-size: 34px;
  }
`;

const HappyGirlsPicture = styled('picture')`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
`;

const Container = styled('div')`
  width: 100%;
  padding-top: 100px;
  padding-left: 14px;
  padding-right: 14px;
  @media (min-width: 368px) {
    padding-left: 18px;
    padding-right: 18px;
  }
  @media (min-width: 468px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: 768px) {
    padding-top: 200px;
  }
`;

const MaxWidthContainer = styled('div')`
  margin: auto;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
`;

export const HappyGirlsSection: React.FC<Props> = () => {
  return (
    <Container>
      <MaxWidthContainer>
        <MainTitle
          initial={{
            opacity: 0,
            x: 24,
            filter: 'blur(14px)',
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            filter: 'blur(0px)',
            transition: {
              duration: 0.8,
              delay: 0.2,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          Give the right gift.
        </MainTitle>
        <MainTitle
          initial={{
            opacity: 0,
            x: 24,
            filter: 'blur(14px)',
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            filter: 'blur(0px)',
            transition: {
              duration: 0.8,
              delay: 0.4,
            },
          }}
          viewport={defaultViewPortOptions}
          className='primary'
        >
          Receive the gift you want.
        </MainTitle>
        <Subheader
          initial={{
            opacity: 0,
            x: 24,
            filter: 'blur(14px)',
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            filter: 'blur(0px)',
            transition: {
              duration: 0.8,
              delay: 0.6,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          Make it <span>easy for everyone</span> to choose the perfect gift.
        </Subheader>
        <HappyGirlsPicture>
          <Picture
            picture={HappyGirlsImage}
            alt='Girls smiling from presents and gifts holidays'
          />
        </HappyGirlsPicture>
      </MaxWidthContainer>
    </Container>
  );
};
