import {
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import {
  customPageAnimationHorizontally,
  customPageAnimationVertically,
} from '../utils/pageTransition';

import { Button } from '../components/BaseUI/Button';
import { DEFAULT_PAGE_TITLE } from '../constants/seo';
import { ErrorMessageText } from '../components/ErrorMessageText';
import { FriendsList } from '../components/FriendsList';
import { Helmet } from 'react-helmet-async';
import { PageWrapper } from '../components/layout/PageWrapper';
import React from 'react';
import { StyledIonOuterToolbar } from '../components/layout/StyledOuterIonToolbar';
import { styled } from 'goober';
import { useAuth } from '../contexts/AuthenticationContext';

const OrText = styled('p')`
  text-align: center;
  margin-top: 18px;
  color: var(--ion-color-medium);
  font-size: 80%;
`;

export const Friends: React.FC = () => {
  const { loggedInUserId } = useAuth();

  const ionRouter = useIonRouter();

  const goToSearchPage = () => {
    ionRouter.push(
      '/search',
      'forward',
      'push',
      {},
      customPageAnimationHorizontally
    );
  };

  const goToAuthPage = () => {
    ionRouter.push(
      '/auth',
      'forward',
      'push',
      {},
      customPageAnimationVertically
    );
  };

  return (
    <IonPage>
      <Helmet prioritizeSeoTags>
        <title>{DEFAULT_PAGE_TITLE}</title>
        <meta property='og:title' content={DEFAULT_PAGE_TITLE} />
      </Helmet>
      <IonHeader translucent={true}>
        <StyledIonOuterToolbar>
          <IonTitle>Friends</IonTitle>
        </StyledIonOuterToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <PageWrapper maxWidth footerType='minimal' hideDisclaimer>
          <IonHeader collapse='condense'>
            <IonToolbar>
              <IonTitle size='large'>Friends</IonTitle>
            </IonToolbar>
          </IonHeader>
          {loggedInUserId === '2MvvJEo6qrRZ7mNhG9xcU3Irz3K2' && (
            <>
              <IonItem routerLink='/amazon-admin-panel'>
                Amazon Admin Panel
              </IonItem>
              <IonItem routerLink='/birthday-sms-reminders'>
                Birthday SMS Reminders
              </IonItem>
            </>
          )}
          {loggedInUserId ? (
            <FriendsList
              showDictionary
              showFollowers
              showSearchForFriends
              userId={loggedInUserId}
            />
          ) : (
            <ErrorMessageText>
              You&apos;ll see a list of your friends here.
              <Button color='primary' onClick={goToAuthPage}>
                Login to see your friends
              </Button>
              <OrText>Or</OrText>
              <Button color='light' onClick={goToSearchPage}>
                Search for somebody
              </Button>
            </ErrorMessageText>
          )}
        </PageWrapper>
      </IonContent>
    </IonPage>
  );
};
