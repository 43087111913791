import React, { useContext, useEffect, useState } from 'react';

import { Button } from './BaseUI/Button';
import { NotificationsContext } from '../contexts/NotificationsContext';
import { User } from '../types/GlobalTypes';
import { alarmOutline } from 'ionicons/icons';
import { differenceInDays } from 'date-fns';
import { handleCatchError } from '../utils/handleCatchError';
import { isBlockedByUser } from '../utils/helper-functions/isBlockedByUser';
import { isUserAFollowingUserB } from '../utils/helper-functions/isUserAFollowingUserB';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

const SendReminderButton = styled(Button as any)`
  flex: 1;
  margin-top: 4px;
  ion-spinner {
    margin: -5px 0;
    height: 23px;
    width: 23px;
  }
`;

const Container = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: calc(100% - 40px);
  padding: 20px;
  margin: 20px;
  background: #fffcee;
  border: 2px dashed #ffe8ac;
  border-radius: 10px;
  max-width: 600px;
  @media (max-width: 460px) {
    width: calc(100% - 24px);
    margin: 20px 12px;
    padding: 14px;
  }
`;

const StyledH4 = styled('h4')`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
`;

const Message = styled('p')``;

interface Props {
  lastLogin: Date | undefined;
  user?: User;
}

const STALE_PROFILE_DAYS = 30;

export const StaleProfileReminderAlert: React.FC<Props> = ({
  lastLogin,
  user,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { loggedInUser } = useLoggedInUser();
  const { triggerNotification } = useContext(NotificationsContext);

  const isUserProfileStale = user
    ? lastLogin
      ? differenceInDays(new Date(), lastLogin) > STALE_PROFILE_DAYS
      : true
    : false;
  const showStaleProfileReminderAlert =
    show &&
    isUserProfileStale &&
    !isBlockedByUser(loggedInUser, user) &&
    (isUserAFollowingUserB(loggedInUser, user) ||
      isUserAFollowingUserB(user, loggedInUser));

  useEffect(() => {
    if (user?.id) {
      const lastClosed = localStorage.getItem('ifancy-utur-' + user?.id);

      if (lastClosed) {
        const lastClosedDate = new Date(lastClosed);

        if (Math.abs(differenceInDays(lastClosedDate, new Date())) < 5) {
          setShow(false);
        }
      } else {
        setShow(true);
      }
    }
  }, [user]);

  if (!isUserProfileStale || !showStaleProfileReminderAlert) return null;

  const sendReminder = async () => {
    try {
      setIsLoading(true);
      if (!loggedInUser?.id || !user?.id)
        throw new Error('Somethings not quite right...');
      await triggerNotification(loggedInUser.id, user.id, 'tidy-up-reminder');
      toast.success('Reminder sent!');
      localStorage.setItem('ifancy-utur-' + user.id, new Date().toISOString());
      setShow(false);
    } catch (err) {
      handleCatchError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <StyledH4>
        Looks like {user?.first_name} hasn&apos;t been on in a while...
      </StyledH4>
      <Message>
        Would you like to send them a reminder to tidy-up their wishlist?
      </Message>
      <SendReminderButton
        iconLeft={alarmOutline}
        onClick={sendReminder}
        color='dark'
        isLoading={isLoading}
      >
        Send a Reminder
      </SendReminderButton>
    </Container>
  );
};
