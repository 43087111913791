import { QuerySnapshot, collection, onSnapshot, query, where } from 'firebase/firestore';
import { UserWishlist, WishlistConfig, WishlistItem } from '../types/GlobalTypes';

import { db } from '../firebase';
import { log } from '../utils/log';

export const wishlistCollectionItemsObservable = (
  userId: string,
  selectedWishlist: WishlistConfig,
  setFn: (value: React.SetStateAction<UserWishlist | undefined>) => void
) => {
  const wishlistQuery = query(collection(db,
    selectedWishlist.id === 'original'
      ? `users/${userId}/wishlist`
      : `users/${userId}/wishlists/${selectedWishlist.id}/items`
  )
    , where('deletedAt', '==', null));

  return onSnapshot(wishlistQuery, (snapshot) => {
    const newWishlist: UserWishlist = {};
    (snapshot as QuerySnapshot<WishlistItem>).docs.map((item) => {
      newWishlist[item.id] = { ...item.data(), id: item.id };
      // @TODO does this refresh everytime an item updates?
      // YES IT DOES!
      // @TODO does it refresh when an item is deleted?
      log('read', `[READ from Server] Wishlist Item: ${userId}; ${item.id}`)
      return null;
    });

    setFn(newWishlist);
  });
};
