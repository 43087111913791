import { Timestamp, doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useEffect, useRef } from 'react';

import { log } from '../utils/log';

export const useSaveLoginTS = (authHasLoadedAndLoggedInUserLoaded: boolean, loggedInUserId: string | undefined) => {
    const hasSavedLoginTSRef = useRef(false);

    useEffect(() => {
        if (
            !hasSavedLoginTSRef.current &&
            authHasLoadedAndLoggedInUserLoaded &&
            loggedInUserId
        ) {
            hasSavedLoginTSRef.current = true;
            const updateLastLogin = async () => {
                try {
                    log('write', '[LoggedInUser] Last Login TS Save');

                    if (!auth.currentUser) throw new Error('No current user');

                    await setDoc(doc(db, `users/${loggedInUserId}`), { lastLogin: Timestamp.fromDate(new Date()) }, { merge: true });
                } catch (err) {
                    console.error(err);
                }
            }
            updateLastLogin();
        }
    }, [authHasLoadedAndLoggedInUserLoaded, loggedInUserId]);
}