import {
  DescriptionText,
  FeatureItemBoxContainer,
  H3,
  HalfFeatureBox,
  HalfFeatureBoxRowContainer,
  PseudoH1,
} from './HomepageStyledComponents';
import { FaRegListAlt, FaUserAstronaut } from 'react-icons/fa';
import { MdLock, MdOutlineSearch } from 'react-icons/md';

import { Button } from '../BaseUI/Button';
import { IoGiftOutline } from 'react-icons/io5';
import React from 'react';
import { WISHLIST_VISIBILITY_OPTIONS } from '../../constants/constants';
import { customPageAnimationHorizontally } from '../../utils/pageTransition';
import { giftOutline } from 'ionicons/icons';
import { motion } from 'framer-motion';
import { styled } from 'goober';
import { useIonRouter } from '@ionic/react';

interface Props {}

const defaultViewPortOptions: any = {
  once: true,
};

const PrivacyMainTitle = styled(PseudoH1 as any)`
  text-align: center;
`;

const PrivacySubheader = styled(motion.p)`
  text-align: center;
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
  margin-bottom: 20px;
  color: #676767;
  br {
    display: none;
  }

  @media (min-width: 468px) {
    margin-bottom: 30px;
    font-size: 16px;
  }
  @media (min-width: 768px) {
    br {
      display: inline;
    }
    margin-bottom: 40px;
    font-size: 22px;
  }
`;

const PrivacyOptionBox = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 120px;
  @media (min-width: 768px) {
    margin-bottom: 200px;
  }
`;

const PrivacyOption = styled(motion.p)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  background: #f5f5f5;
  margin: 4px;
  color: #676767;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
  white-space: nowrap;
  cursor: default;
  &:hover {
    border-color: #cacaca;
    color: #333;
  }

  border: 1px solid #e7e7e7;
  margin: 2px;
  border-radius: 9px;
  padding: 3px 9px;
  font-size: 12px;
  svg {
    flex-shrink: 0;
    font-size: 14px;
    margin-right: 4px;
  }

  @media (min-width: 468px) {
    border: 2px solid #e7e7e7;
    margin: 3px;
    border-radius: 10px;
    font-size: 15px;
    padding: 4px 10px;
    svg {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  @media (min-width: 768px) {
    border: 2px solid #e7e7e7;
    margin: 4px;
    border-radius: 14px;
    font-size: 22px;
    padding: 4px 12px;
    svg {
      font-size: 24px;
      margin-right: 8px;
    }
  }
`;

const Container = styled('section')`
  width: 100%;
  padding-top: 100px;
  padding-bottom: 60px;
  padding-left: 14px;
  padding-right: 14px;
  background: #f9f9f9;
  @media (min-width: 368px) {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 120px;
  }
  @media (min-width: 468px) {
    padding-top: 200px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 120px;
  }

  @media (min-width: 768px) {
    padding-top: 280px;
  }
`;

const MaxWidthContainer = styled('div')`
  margin: auto;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled(motion.div)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 40px;
  flex: 1;
  .main-icon {
    font-size: 100px;
    @media (min-width: 468px) {
      font-size: 180px;
    }
    color: #e7e7e7;
  }

  .lock-icon {
    position: absolute;
    font-size: 70px;
    left: 100%;
    transform: translateX(-60%);
    top: 20%;
    color: #2a2a2a;
    @media (min-width: 468px) {
      font-size: 110px;
    }
  }
`;

const GiftDivider = styled('div')`
  width: 100%;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  background: #ffffff;
  color: #eaeaea;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 18px;
  text-align: center;
  white-space: nowrap;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 60px;
  @media (min-width: 268px) and (max-width: 468px) {
    svg:nth-of-type(18) ~ svg {
      display: none;
    }
  }
  @media (min-width: 468px) and (max-width: 768px) {
    svg:nth-of-type(26) ~ svg {
      display: none;
    }
  }
  @media (min-width: 768px) and (max-width: 1068px) {
    svg:nth-of-type(34) ~ svg {
      display: none;
    }
  }
  @media (min-width: 1068px) and (max-width: 1268px) {
    svg:nth-of-type(42) ~ svg {
      display: none;
    }
  }
  @media (min-width: 1268px) and (max-width: 1468px) {
    svg:nth-of-type(52) ~ svg {
      display: none;
    }
  }
`;

const FinalCTAContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  margin-bottom: 60px;
  padding: 40px 40px;
  align-self: center;
  border: 2px dashed #e7e7e7;
  border-radius: 24px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.1)
  );
  @media (min-width: 768px) {
    padding: 60px;
  }
`;

const FinalCTA = styled('p')`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: var(--ion-color-primary);
  font-family: 'Nunito', sans-serif;
  margin-bottom: 20px;
  @media (min-width: 468px) {
    font-size: 28px;
  }
  @media (min-width: 768px) {
    font-size: 42px;
  }
`;

export const PrivacyFeaturesSection: React.FC<Props> = () => {
  const ionRouter = useIonRouter();

  const goToAuth = () => {
    ionRouter.push(
      `/auth`,
      'forward',
      'push',
      {},
      customPageAnimationHorizontally
    );
  };
  return (
    <>
      <Container id='privacy'>
        <MaxWidthContainer>
          <PrivacyMainTitle
            initial={{
              opacity: 0,
              y: 24,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.8,
                delay: 0.3,
              },
            }}
            viewport={defaultViewPortOptions}
          >
            Privacy Is A Big Deal
          </PrivacyMainTitle>
          <PrivacySubheader
            initial={{
              opacity: 0,
              y: 24,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.8,
                delay: 0.3,
              },
            }}
            viewport={defaultViewPortOptions}
          >
            We take privacy seriously. You can control exactly how your profile,
            <br />
            wishlists, and wishlist items appear to the outside world.
          </PrivacySubheader>

          <PrivacyOptionBox>
            {WISHLIST_VISIBILITY_OPTIONS.map((option, index) => (
              <PrivacyOption
                key={index}
                initial={{
                  opacity: 0,
                  y: 24,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.8,
                    delay: 0.3 + 0.2 * index,
                  },
                }}
                viewport={defaultViewPortOptions}
              >
                {option.icon}
                {option.name}
              </PrivacyOption>
            ))}
          </PrivacyOptionBox>

          <HalfFeatureBoxRowContainer className='snug'>
            <FeatureItemBoxContainer>
              <HalfFeatureBox>
                <H3
                  initial={{
                    opacity: 0,
                    x: 24,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.8,
                      delay: 0.2,
                    },
                  }}
                  viewport={defaultViewPortOptions}
                >
                  Profile
                </H3>
                <DescriptionText
                  initial={{
                    opacity: 0,
                    x: 24,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.8,
                      delay: 0.3,
                    },
                  }}
                  viewport={defaultViewPortOptions}
                >
                  You can make your profile private thus only allowing friends
                  to see anything related to your wishlists.
                </DescriptionText>
                <IconContainer
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                    transition: {
                      duration: 0.5,
                      delay: 0.5,
                    },
                  }}
                  viewport={defaultViewPortOptions}
                >
                  <FaUserAstronaut className='main-icon' />
                  <MdLock className='lock-icon' />
                </IconContainer>
              </HalfFeatureBox>
            </FeatureItemBoxContainer>
            <FeatureItemBoxContainer>
              <HalfFeatureBox>
                <H3
                  initial={{
                    opacity: 0,
                    x: 24,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.8,
                      delay: 0.4,
                    },
                  }}
                  viewport={defaultViewPortOptions}
                >
                  Wishlists
                </H3>

                <DescriptionText
                  initial={{
                    opacity: 0,
                    x: 24,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.8,
                      delay: 0.5,
                    },
                  }}
                  viewport={defaultViewPortOptions}
                >
                  Wishlists can be private, friends-only, or public.
                </DescriptionText>
                <IconContainer
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                    transition: {
                      duration: 0.5,
                      delay: 0.5,
                    },
                  }}
                  viewport={defaultViewPortOptions}
                >
                  <FaRegListAlt className='main-icon' />
                  <MdLock className='lock-icon list-icon' />
                </IconContainer>
              </HalfFeatureBox>
            </FeatureItemBoxContainer>
          </HalfFeatureBoxRowContainer>

          <HalfFeatureBoxRowContainer>
            <FeatureItemBoxContainer>
              <HalfFeatureBox>
                <H3
                  initial={{
                    opacity: 0,
                    x: 24,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.8,
                      delay: 0.2,
                    },
                  }}
                  viewport={defaultViewPortOptions}
                >
                  Items
                </H3>
                <DescriptionText
                  initial={{
                    opacity: 0,
                    x: 24,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.8,
                      delay: 0.3,
                    },
                  }}
                  viewport={defaultViewPortOptions}
                >
                  You can hide your wishlist items. You can hide it from
                  everyone OR select which friends are able to see them.
                </DescriptionText>
                <IconContainer
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                    transition: {
                      duration: 0.5,
                      delay: 0.5,
                    },
                  }}
                  viewport={defaultViewPortOptions}
                >
                  <IoGiftOutline className='main-icon' />
                  <MdLock className='lock-icon' />
                </IconContainer>
              </HalfFeatureBox>
            </FeatureItemBoxContainer>
            <FeatureItemBoxContainer>
              <HalfFeatureBox>
                <H3
                  initial={{
                    opacity: 0,
                    x: 24,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.8,
                      delay: 0.4,
                    },
                  }}
                  viewport={defaultViewPortOptions}
                >
                  Search Results
                </H3>

                <DescriptionText
                  initial={{
                    opacity: 0,
                    x: 24,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.8,
                      delay: 0.5,
                    },
                  }}
                  viewport={defaultViewPortOptions}
                >
                  You can select to not be searchable through iFancy. This means
                  for someone to find your profile you have to give them your
                  exact profile link.
                </DescriptionText>
                <IconContainer
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                    transition: {
                      duration: 0.5,
                      delay: 0.5,
                    },
                  }}
                  viewport={defaultViewPortOptions}
                >
                  <MdOutlineSearch className='main-icon' />
                  <MdLock className='lock-icon search-icon' />
                </IconContainer>
              </HalfFeatureBox>
            </FeatureItemBoxContainer>
          </HalfFeatureBoxRowContainer>
          <FinalCTAContainer>
            <FinalCTA>What are you waiting for?</FinalCTA>
            <Button color='primary' onClick={goToAuth} iconRight={giftOutline}>
              Create An Account
            </Button>
          </FinalCTAContainer>
        </MaxWidthContainer>
      </Container>
      <GiftDivider>
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
        <IoGiftOutline />
      </GiftDivider>
    </>
  );
};
