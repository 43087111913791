import { Animation, createAnimation } from '@ionic/core';

export const customPageAnimationVertically = (baseEl: HTMLElement, opts?: any) => {
  return customPageAnimation(baseEl, { ...opts, axis: 'Y' });
};

export const customPageAnimationHorizontally = (baseEl: HTMLElement, opts?: any) => {
  return customPageAnimation(baseEl, { ...opts, axis: 'X' });
};

export const customPageAnimationTabLeft = (baseEl: HTMLElement, opts?: any) => {
  return customPageAnimation(baseEl, { ...opts, tabDirection: 'back', axis: 'X' });
};

export const customPageAnimationTabRight = (baseEl: HTMLElement, opts?: any) => {
  return customPageAnimation(baseEl, { ...opts, tabDirection: 'forward', axis: 'X' });
};


export const customPageAnimation = (baseEl: HTMLElement, opts?: any): Animation => {
  const DURATION = 120;
  const DISTANCE = 15;

  const AXIS = opts.axis;
  const direction = opts.tabDirection ? opts.tabDirection : opts.direction;
  if (direction === 'forward') {
    // Fade in the next page
    const rootAnimation = createAnimation()
      .addElement(opts.enteringEl)
      .duration(DURATION)
      .easing('ease-in')
      .delay((DURATION / 4) * 3)
      .fromTo('opacity', 0, 1)
      .fromTo('transform', `translate${AXIS}(${DISTANCE}px)`, `translate${AXIS}(0px)`);

    const leavingAnim = createAnimation()
      .addElement(opts.leavingEl)
      .duration(DURATION)
      .easing('ease-in')
      .fromTo('opacity', 1, 0);

    if (AXIS !== 'Y') {
      leavingAnim.fromTo('transform', `translate${AXIS}(0px)`, `translate${AXIS}(-${DISTANCE}px)`);
    }

    return createAnimation().addAnimation([leavingAnim, rootAnimation]);
  } else if (direction === 'back') {
    // Fade in the previous page
    const rootAnimation = createAnimation()
      .addElement(opts.enteringEl)
      .duration(DURATION)
      .easing('ease-out')
      .delay((DURATION / 4) * 3)
      .fromTo('opacity', 0, 1);

    // Fade out the current top page
    const leavingAnim = createAnimation()
      .addElement(opts.leavingEl)
      .duration(DURATION)
      .easing('ease-out')
      .fromTo('opacity', 1, 0)
      .fromTo('transform', `translate${AXIS}(0px)`, `translate${AXIS}(${DISTANCE}px)`);

    if (AXIS !== 'Y') {
      rootAnimation.fromTo('transform', `translate${AXIS}(-${DISTANCE}px)`, `translate${AXIS}(0px)`);
    }

    return createAnimation().addAnimation([leavingAnim, rootAnimation]);
  }

  return {} as Animation;
};
