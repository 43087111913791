import { Timestamp, getFirestore } from 'firebase/firestore';
import { getApp, initializeApp } from 'firebase/app';
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth';

import { Capacitor } from '@capacitor/core';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const firebase = initializeApp({
  apiKey: 'AIzaSyCRky3o1HWhl1taONn0kCTD-LlRUtPiF5A',
  authDomain: 'auth.ifancy.app',
  databaseURL: 'https://wishroll-373cb.firebaseio.com',
  projectId: 'wishroll-373cb',
  storageBucket: 'wishroll-373cb.appspot.com',
  messagingSenderId: '919737169552',
  appId: '1:919737169552:web:dab54ee1b30ab09fa0ca15',
  measurementId: 'G-K4QN8NB71N',
});

const db = getFirestore(firebase);

if (Capacitor.isNativePlatform()) {
  initializeAuth(getApp(), {
    persistence: indexedDBLocalPersistence,
  });
}

const auth = getAuth(firebase);

const storage = getStorage(firebase);
const analytics = getAnalytics(firebase);

const functions = getFunctions(firebase);

export { db, functions, storage, auth, analytics, firebase, Timestamp };
