import { IonAlert, useIonRouter } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { WishlistConfig, WishlistItem } from '../types/GlobalTypes';
import { connectToSocketServer, socket } from '../utils/socket';

import WishlistItemModal from '../modals/WishlistItemModal';
import { analytics } from '../firebase';
import { customPageAnimationVertically } from '../utils/pageTransition';
import { logEvent } from 'firebase/analytics';
import { useSubscribeToSocketConnection } from '../hooks/useSubscribeToSocketEvents';

type WishlistItemModalContextProps = {
  openWishlistItemModal: (wishlistItemProps: WishlistModalProps) => void;
  handleNonAuthWishlistAddClick: () => void;
};

type WishlistModalProps = {
  isSuggestion: boolean;
  userId?: string;
  item: WishlistItem | undefined;
  readonly: boolean;
  pageRef: any;
  isViewingArchivedItem?: boolean;
  currentWishlist: WishlistConfig | string | undefined;
};

export const WishlistItemModalContext =
  React.createContext<WishlistItemModalContextProps>(
    {} as WishlistItemModalContextProps
  );

export const WishlistItemModalContextProvider = ({ children }: any) => {
  const [wishlistModalProps, setWishlistModalProps] =
    useState<WishlistModalProps>();
  const [isOpen, setIsOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const ionRouter = useIonRouter();

  useSubscribeToSocketConnection();

  const openWishlistItemModal = useCallback(
    (wishlistModalProps: WishlistModalProps) => {
      setWishlistModalProps(wishlistModalProps);
      if (wishlistModalProps.isSuggestion) {
        logEvent(analytics, 'wishlist_suggestion_item_clicked', {
          itemId: wishlistModalProps.item?.id,
        });
      } else {
        logEvent(analytics, 'wishlist_item_clicked', {
          itemId: wishlistModalProps.item?.id,
        });
      }
      if (!socket.connected && !wishlistModalProps.readonly) {
        connectToSocketServer();
      }

      setIsOpen(true);
    },
    []
  );

  const goToAuth = () => {
    ionRouter.push(
      `/auth`,
      'forward',
      'push',
      {},
      customPageAnimationVertically
    );
  };

  const handleNonAuthWishlistAddClick = useCallback(() => {
    setIsAlertOpen(true);
  }, []);

  return (
    <WishlistItemModalContext.Provider
      value={{
        openWishlistItemModal,
        handleNonAuthWishlistAddClick,
      }}
    >
      {children}
      <IonAlert
        mode='ios'
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setIsAlertOpen(false);
            },
          },
          {
            text: 'Login // Sign up',
            handler: () => {
              goToAuth();
            },
          },
        ]}
        header={"We don't know where to save your wishlist item!"}
        message={
          'In order to create a wishlist and save your items, please login or sign up.'
        }
        isOpen={isAlertOpen}
        onDidDismiss={() => setIsAlertOpen(false)}
      />
      <WishlistItemModal
        currentWishlist={wishlistModalProps?.currentWishlist}
        isSuggestion={Boolean(wishlistModalProps?.isSuggestion)}
        userId={wishlistModalProps?.userId}
        wishlistItem={wishlistModalProps?.item}
        readonly={Boolean(wishlistModalProps?.readonly)}
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        pageRef={wishlistModalProps?.pageRef}
        isViewingArchivedItem={Boolean(
          wishlistModalProps?.isViewingArchivedItem
        )}
      />
    </WishlistItemModalContext.Provider>
  );
};
