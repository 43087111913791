import { IonSkeletonText, IonSpinner } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { refreshOutline, searchOutline } from 'ionicons/icons';

import { Button } from './BaseUI/Button';
import { analytics } from '../firebase';
import { handleCatchError } from '../utils/handleCatchError';
import { log } from '../utils/log';
import { logEvent } from 'firebase/analytics';
import { styled } from 'goober';

interface Props {
  searchTerm: string;
  onSearchImageSelected: (image: string) => void;
  isLinkFetchLoading: boolean;
  image?: string;
  uniqueOpenID: string | undefined;
}

const ImageResultImagesContainer = styled('div')`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 4px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  @media (max-width: 969px) {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    width: auto;
  }
`;

const ImageResultImageButton = styled('img')<{ $used?: boolean }>`
  width: 66px;
  height: 66px;
  @media (max-width: 569px) {
    width: 52px;
    height: 52px;
  }
  object-fit: cover;
  border-radius: 12px;
  margin: 2px;
  cursor: pointer;
  border: 2px solid
    ${({ $used }) => ($used ? 'var(--ion-color-primary)' : 'transparent')};
  flex-shrink: 0;
`;

const StyledRefreshButton = styled(Button as any)`
  width: 66px;
  height: 66px;
  flex-shrink: 0;
  @media (max-width: 569px) {
    width: 52px;
    height: 52px;
  }
  margin: 2px;
  border: 2px solid var(--ion-color-light);
`;

const ImageResultErrorMessage = styled('p')`
  margin: 0;
  padding: 0;
  padding-left: 4px;
  margin-top: 4px;
  text-align: left;
  font-size: 10px;
  opacity: 0.5;
  color: black;
  font-weight: 700;
  width: 100%;
  letter-spacing: 0.02em;
  ion-spinner {
    margin-right: 6px;
    width: 12px;
    height: 12px;
    margin-top: -3px;
    margin-bottom: -2px;
  }
`;

const SkeletonImage = styled(IonSkeletonText)`
  width: 66px;
  height: 66px;
  @media (max-width: 569px) {
    width: 52px;
    height: 52px;
  }
  border-radius: 6px;
  margin: 2px;
  border: 2px solid transparent;
  flex-shrink: 0;
`;

const StyledSearchButton = styled(Button as any)`
  margin-top: 6px;
`;

export type ImageResult = {
  thumbnailUrl?: string;
};

export const SearchImageResults: React.FC<Props> = ({
  searchTerm,
  onSearchImageSelected,
  image,
  isLinkFetchLoading,
  uniqueOpenID,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSearchImage, setSelectedSearchImage] = useState<string>();
  const [imageSearchResults, setImageSearchResults] = useState<ImageResult[]>();
  const [eligibleForRefresh, setEligibleForRefresh] = useState(false);

  useEffect(() => {
    setSelectedSearchImage(image);
  }, [image]);

  useEffect(() => {
    setEligibleForRefresh(true);
  }, [searchTerm]);

  const handleSearchImage = async () => {
    try {
      if (searchTerm?.length <= 3)
        throw new Error('Search term must be at least 3 characters');
      setIsLoading(true);
      const reqOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': import.meta.env.VITE_BING_KEY || '',
        },
      };
      logEvent(analytics, 'search_image');
      log('red', 'search_image', searchTerm);
      const response = await fetch(
        `https://api.bing.microsoft.com/v7.0/images/search?q=${encodeURIComponent(
          searchTerm
        )}&cc=US&count=10&safeSearch=Strict`,
        reqOptions
      );

      const parsedResponse = await response.json();

      if (parsedResponse?.value) {
        setImageSearchResults(parsedResponse.value);
        setEligibleForRefresh(false);
      }
    } catch (err) {
      handleCatchError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (image || isLinkFetchLoading || !uniqueOpenID) {
      if (!selectedSearchImage) {
        setImageSearchResults([]);
      }
    }
  }, [isLinkFetchLoading, image, selectedSearchImage, uniqueOpenID]);

  const removeImageFromResults = (imageUrl: string) => {
    if (!imageSearchResults) {
      return;
    }
    setImageSearchResults([
      ...imageSearchResults.filter((i) => i.thumbnailUrl !== imageUrl),
    ]);
  };

  const handleImageSelected = (imageUrl?: string) => {
    if (!imageUrl) {
      return;
    }
    setSelectedSearchImage(imageUrl);
    onSearchImageSelected(imageUrl);
  };

  if (
    (image && image !== selectedSearchImage) ||
    isLinkFetchLoading ||
    !uniqueOpenID
  ) {
    return null;
  }

  return (
    <>
      {!image && !imageSearchResults?.length && searchTerm.length > 2 && (
        <StyledSearchButton
          small
          onClick={handleSearchImage}
          color='medium'
          clear
          iconLeft={searchOutline}
        >
          Search For Images
        </StyledSearchButton>
      )}

      {isLoading ? (
        <ImageResultErrorMessage>
          <IonSpinner name='crescent' color='dark' />
          Searching for Images
        </ImageResultErrorMessage>
      ) : imageSearchResults?.length ? (
        <ImageResultErrorMessage>Image Suggestions</ImageResultErrorMessage>
      ) : null}
      {isLoading || imageSearchResults?.length ? (
        <ImageResultImagesContainer>
          {eligibleForRefresh && !isLoading && (
            <StyledRefreshButton
              color='medium'
              clear
              onClick={handleSearchImage}
              iconLeft={refreshOutline}
            />
          )}
          {imageSearchResults?.length
            ? imageSearchResults.map((image) => (
                <ImageResultImageButton
                  onClick={() => handleImageSelected(image.thumbnailUrl)}
                  key={image.thumbnailUrl}
                  src={image.thumbnailUrl}
                  onError={() =>
                    removeImageFromResults(image.thumbnailUrl || '')
                  }
                  $used={selectedSearchImage === image.thumbnailUrl}
                />
              ))
            : null}
          {isLoading && (
            <>
              <SkeletonImage animated />
              <SkeletonImage animated />
              <SkeletonImage animated />
              <SkeletonImage animated />
              <SkeletonImage animated />
              <SkeletonImage animated />
              <SkeletonImage animated />
              <SkeletonImage animated />
              <SkeletonImage animated />
              <SkeletonImage animated />
              <SkeletonImage animated />
              <SkeletonImage animated />
              <SkeletonImage animated />
              <SkeletonImage animated />
            </>
          )}
        </ImageResultImagesContainer>
      ) : null}
    </>
  );
};
