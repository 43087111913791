import React, { useContext, useState } from 'react';
import { lockClosedOutline, lockOpenOutline } from 'ionicons/icons';

import { IonAlert } from '@ionic/react';
import { ProfileMenuActionButton } from './ProfileActionButtons';
import { User } from '../../types/GlobalTypes';
import { UserBankContext } from '../../contexts/UserBank';
import { analytics } from '../../firebase';
import { handleCatchError } from '../../utils/handleCatchError';
import { isBlockedByUser } from '../../utils/helper-functions/isBlockedByUser';
import { logEvent } from 'firebase/analytics';
import toast from 'react-hot-toast';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';

type Props = {
  user: User;
  onClick?: () => void;
};

export const ToggleBlockUserButtons: React.FC<Props> = ({ user, onClick }) => {
  const { loggedInUser } = useLoggedInUser();
  const { updateUser } = useContext(UserBankContext);
  const [showConfirmBlockAlert, setShowConfirmBlockAlert] = useState(false);

  if (!loggedInUser?.id) return null;
  if (loggedInUser.id === user.id) return null;

  const isUserBlockedByLoggedInUser = isBlockedByUser(user, loggedInUser);

  const blockUser = async () => {
    logEvent(analytics, 'user_blocked', { userId: user.id });
    try {
      setShowConfirmBlockAlert(false);
      if (!loggedInUser?.id || !user.id) throw new Error('User not found');
      await updateUser(loggedInUser?.id, {
        blocked: [...(loggedInUser?.blocked || []), user.id],
        friends: [
          ...(loggedInUser?.friends || []).filter((id) => id !== user.id),
        ],
      });
      toast.success('User Blocked');
    } catch (err) {
      handleCatchError(err);
    }
    onClick && onClick();
  };

  const unblockUser = async () => {
    logEvent(analytics, 'user_unblocked', { userId: user.id });
    try {
      setShowConfirmBlockAlert(false);
      if (!loggedInUser?.id || !user.id) throw new Error('User not found');
      await updateUser(loggedInUser?.id, {
        blocked: [
          ...(loggedInUser?.blocked || []).filter((uid) => uid !== user.id),
        ],
      });
      toast.success('User Un-Blocked');
    } catch (err) {
      handleCatchError(err);
    }
    onClick && onClick();
  };

  return (
    <>
      <IonAlert
        isOpen={showConfirmBlockAlert}
        onDidDismiss={() => setShowConfirmBlockAlert(false)}
        header={'Are you sure?'}
        mode='ios'
        message={`This will ${
          isUserBlockedByLoggedInUser ? 'un-' : ''
        }block this user from your profile.`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {},
          },
          {
            text: isUserBlockedByLoggedInUser ? 'Un-Block' : 'Block',
            handler: () => {
              if (isUserBlockedByLoggedInUser) {
                unblockUser();
              } else {
                blockUser();
              }
            },
          },
        ]}
      />

      {isUserBlockedByLoggedInUser ? (
        <ProfileMenuActionButton
          clear
          color='medium'
          iconLeft={lockOpenOutline}
          onClick={() => setShowConfirmBlockAlert(true)}
        >
          Un-Block User
        </ProfileMenuActionButton>
      ) : (
        <ProfileMenuActionButton
          clear
          color='medium'
          iconLeft={lockClosedOutline}
          onClick={() => setShowConfirmBlockAlert(true)}
        >
          Block User
        </ProfileMenuActionButton>
      )}
    </>
  );
};
