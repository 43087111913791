import { analytics } from '../firebase';
import { log } from '../utils/log';
import { logEvent } from 'firebase/analytics';
import { socket } from '../utils/socket';
import { useEffect } from 'react';

export const useSubscribeToSocketConnection = () => {
    useEffect(() => {
        socket.on('connect', () => {
            log('socket', 'Connected to socket server');
            logEvent(analytics, 'socket_connected');
        }
        );
        socket.on('disconnect', () => {
            log('socket', 'Disconnected from socket server');
            logEvent(analytics, 'socket_disconnected');
        }
        );

        return () => {
            socket.off('connect');
            socket.off('disconnect');
        }
    }, []);
};

