import { IonAlert, useIonRouter } from '@ionic/react';
import React, { useState } from 'react';
import { personAddOutline, personRemoveOutline } from 'ionicons/icons';

import { Button } from '../BaseUI/Button';
import { ProfileMenuActionButton } from './ProfileActionButtons';
import { User } from '../../types/GlobalTypes';
import { analytics } from '../../firebase';
import { apiFunctionFetch } from '../../utils/apiFunctionFetch';
import { customPageAnimationVertically } from '../../utils/pageTransition';
import { handleCatchError } from '../../utils/handleCatchError';
import { logEvent } from 'firebase/analytics';
import toast from 'react-hot-toast';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';
import { useProfilePageContext } from '../../contexts/ProfilePageContext';

interface Props {
  user: User;
  showOnly: 'follow' | 'unfollow';
  onClick?: () => void;
}

export const ToggleFriendButton: React.FC<Props> = ({
  user,
  showOnly,
  onClick,
}) => {
  const { loggedInUser } = useLoggedInUser();
  const {
    setForceWishlistRefresh,
    isLoggedInUserFollowing,
    isLoggedInUserBlockedByUser,
    wishlists,
  } = useProfilePageContext();
  const [showRemoveFriendAlert, setShowRemoveFriendAlert] = useState(false);
  const ionRouter = useIonRouter();

  const isOnLoggedInUserProfile =
    loggedInUser?.id && loggedInUser.id === user.id;
  if (isOnLoggedInUserProfile) return null;
  if (isLoggedInUserBlockedByUser) return null;

  const goToAuth = () => {
    ionRouter.push(
      `/auth`,
      'forward',
      'push',
      {},
      customPageAnimationVertically
    );
  };

  const toggleFriend = async () => {
    try {
      if (!user?.id) return;
      if (!loggedInUser?.id) {
        goToAuth();
        return;
      }

      const action = loggedInUser.friends?.includes(user.id) ? 'remove' : 'add';

      const doAction = async () => {
        logEvent(
          analytics,
          action === 'remove' ? 'user_unfollowed' : 'user_followed',
          { userId: user.id }
        );

        const wishlistsSubscribedTo = wishlists
          ?.filter((wishlist) =>
            wishlist.notificationSubscribers?.includes(loggedInUser.id!)
          )
          .map((wishlist) => wishlist.id);

        const response = await apiFunctionFetch('toggleFriend', {
          userIdToToggle: user.id,
          wishlistsSubscribedTo,
        });
        const { success, msg } = response;

        if (!success) throw new Error(msg);

        return true;
      };

      await toast.promise<boolean>(doAction(), {
        loading: 'Requesting...',
        success: `${user.first_name} ${
          action === 'add' ? 'added' : 'removed'
        } as a friend`,
        error: (err) => err.message,
      });
      await new Promise((r) => setTimeout(r, 1000));
      setForceWishlistRefresh(
        (forceWishlistRefresh: number) => forceWishlistRefresh + 1
      );
    } catch (err) {
      handleCatchError(err);
    }
    onClick && onClick();
  };

  if (
    !loggedInUser?.id ||
    (!isOnLoggedInUserProfile && !isLoggedInUserFollowing)
  ) {
    if (showOnly === 'unfollow') return null;
    return (
      <>
        <Button
          color='primary'
          iconLeft={personAddOutline}
          onClick={toggleFriend}
        >
          Add Friend
        </Button>
      </>
    );
  }

  if (loggedInUser?.id && !isOnLoggedInUserProfile && isLoggedInUserFollowing) {
    if (showOnly === 'follow') return null;
    return (
      <>
        <ProfileMenuActionButton
          clear
          color='medium'
          iconLeft={personRemoveOutline}
          onClick={() => setShowRemoveFriendAlert(true)}
        >
          Remove Friend
        </ProfileMenuActionButton>
        <IonAlert
          isOpen={showRemoveFriendAlert}
          onDidDismiss={() => setShowRemoveFriendAlert(false)}
          header={'Are you sure?'}
          mode='ios'
          message='This will remove this user from your friends list.'
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {},
            },
            {
              text: 'Remove',
              handler: () => {
                toggleFriend();
              },
            },
          ]}
        />
      </>
    );
  }

  return null;
};
