import { Haptics, ImpactStyle } from '@capacitor/haptics';
import React, { useCallback, useContext, useRef } from 'react';

import { Capacitor } from '@capacitor/core';
import Confetti from 'react-canvas-confetti';
import mobile from 'is-mobile';

type ConfettiProps = {
  triggerConfetti: (colors: string[]) => void;
};

export const ConfettiContext = React.createContext<ConfettiProps>(
  {} as ConfettiProps
);

export const useConfetti = () => useContext(ConfettiContext);

export const ConfettiProvider = ({ children }: any) => {
  const confettiRef = useRef<any>();

  const getInstance = useCallback((instance: any) => {
    confettiRef.current = instance;
  }, []);

  const triggerConfetti = useCallback((colors: string[]) => {
    const confettiColors = ['#fff', ...colors];

    function getAnimationSettings(angle: number, originX: number) {
      return {
        particleCount: mobile() ? 7 : 10,
        angle,
        startVelocity: 90,
        spread: 45,
        origin: { x: originX, y: 1 },
        colors: confettiColors,
      };
    }

    const intervalMSLength = 16;
    const intervalMSMax = 700;
    let intervalCursor = 0;

    const nextTickAnimation = () => {
      if (confettiRef.current) {
        if (Capacitor.isNativePlatform() && intervalCursor % 48 === 0) {
          Haptics.impact({ style: ImpactStyle.Light });
        }
        confettiRef.current(getAnimationSettings(60, 0));
        confettiRef.current(getAnimationSettings(120, 1));
      }
    };

    const intervalId = setInterval(() => {
      intervalCursor += intervalMSLength;
      if (intervalCursor > intervalMSMax) {
        clearInterval(intervalId);
      }
      nextTickAnimation();
    }, intervalMSLength);
  }, []);

  return (
    <ConfettiContext.Provider
      value={{
        triggerConfetti,
      }}
    >
      <Confetti
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 40000,
          pointerEvents: 'none',
        }}
        refConfetti={getInstance}
      />
      {children}
    </ConfettiContext.Provider>
  );
};
