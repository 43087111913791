/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import './theme/font.scss';
import './theme/ionic.scss';
import './theme/shadows.scss';
import './theme/global.scss';
import './theme/modals.scss';

import { IonApp, getPlatforms, setupIonicReact } from '@ionic/react';
import React, { useEffect } from 'react';
import { StatusBar, Style } from '@capacitor/status-bar';

import { AuthenticationProvider } from './contexts/AuthenticationContext';
import { Capacitor } from '@capacitor/core';
import { ConfettiProvider } from './contexts/ConfettiContext';
import { HelmetProvider } from 'react-helmet-async';
import { ImageUploadContextProvider } from './contexts/ImageUploadContext';
import { IonReactRouter } from '@ionic/react-router';
import { LoggedInUserProvider } from './contexts/LoggedInUserContext';
import { NotificationsProvider } from './contexts/NotificationsContext';
import { RecaptchaElement } from './components/RecaptchaElement';
import { RoutedAppContextProvider } from './navigation/RoutedAppContext';
import { Toaster } from 'react-hot-toast';
import { UserBankProvider } from './contexts/UserBank';
import { WishlistItemModalContextProvider } from './contexts/WishlistItemModalContext';
import data from '@emoji-mart/data';
import { init } from 'emoji-mart';
import { inject } from '@vercel/analytics';
import { injectSpeedInsights } from '@vercel/speed-insights';
import { prefix } from 'goober/prefixer';
import { setup } from 'goober';
import { shouldForwardProp } from 'goober/should-forward-prop';

init({ data });
inject();
injectSpeedInsights();

setup(
  React.createElement,
  prefix,
  undefined,
  shouldForwardProp((prop) => {
    // Do NOT forward props that start with `$` symbol
    return prop['0'] !== '$';
  })
);

setupIonicReact({
  mode: 'ios',
  spinner: 'crescent',
});

const App: React.FC = () => {
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      const setStatusBarStyleDark = async () => {
        await StatusBar.setStyle({ style: Style.Dark });
      };

      setStatusBarStyleDark();
    }
  }, []);

  useEffect(() => {
    console.log('Native:', Boolean(Capacitor.isNativePlatform()));
    console.log('Platform:', Capacitor.getPlatform());
    console.log('Platforms:', getPlatforms());
  }, []);

  return (
    <HelmetProvider>
      <IonApp>
        <RecaptchaElement />
        <IonReactRouter>
          <ConfettiProvider>
            <UserBankProvider>
              <AuthenticationProvider>
                <LoggedInUserProvider>
                  <ImageUploadContextProvider>
                    <NotificationsProvider>
                      <WishlistItemModalContextProvider>
                        <RoutedAppContextProvider />
                      </WishlistItemModalContextProvider>
                    </NotificationsProvider>
                  </ImageUploadContextProvider>
                </LoggedInUserProvider>
              </AuthenticationProvider>
            </UserBankProvider>
          </ConfettiProvider>
        </IonReactRouter>
        <Toaster
          containerStyle={{
            zIndex: 99999,
            marginTop: 'var(--ion-safe-area-top)',
          }}
          toastOptions={{
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          }}
        />
      </IonApp>
    </HelmetProvider>
  );
};

export default App;
