import { BaseAvatar } from './BaseUI/BaseAvatar';
import React from 'react';
import { styled } from 'goober';

interface Props {
  image?: string;
  isOnLoggedInUsersProfile: boolean;
}

const StyledBaseAvatar = styled(BaseAvatar as any)`
  --image-border: 2px;
  --image-size: 36px;
  --border-radius: 90px;
  margin-left: 8px;
  margin-right: 8px;
`;

export const UserListAvatar: React.FC<Props> = ({
  image,
  isOnLoggedInUsersProfile,
}) => {
  return (
    <StyledBaseAvatar
      image={image}
      isOnLoggedInUsersProfile={isOnLoggedInUsersProfile}
    />
  );
};
