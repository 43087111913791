import { Button } from './BaseUI/Button';
import React from 'react';
import { customPageAnimationVertically } from '../utils/pageTransition';
import { styled } from 'goober';
import { useIonRouter } from '@ionic/react';
import { useProfilePageContext } from '../contexts/ProfilePageContext';

interface Props {}

const Container = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: calc(100% - 40px);
  padding: 20px;
  margin: 20px;
  padding-top: 10px;
  background: #ffdada;
  border: 2px dashed #d95656;
  border-radius: 10px;
  max-width: 600px;
  @media (max-width: 460px) {
    width: calc(100% - 24px);
    margin: 20px 12px;
    padding: 14px;
  }
`;

const StyledH4 = styled('h4')`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
`;

const ContentContainer = styled('div')``;

export const LoginCTABox: React.FC<Props> = () => {
  const { isNonAuthedProfile } = useProfilePageContext();

  const ionRouter = useIonRouter();

  const goToAuth = () => {
    ionRouter.push(
      `/auth`,
      'forward',
      'push',
      {},
      customPageAnimationVertically
    );
  };

  if (!isNonAuthedProfile) {
    return;
  }

  return (
    <Container>
      <ContentContainer>
        <StyledH4>Login or Sign up to create your profile</StyledH4>
        <Button color='dark' onClick={goToAuth}>
          Login // Sign up
        </Button>
      </ContentContainer>
    </Container>
  );
};
