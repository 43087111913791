import React, { useState } from 'react';
import {
  WishlistOptionButton,
  WishlistOptionButtonSmall,
} from './ProfileActionButtons';

import { CreateOrEditWishlistModal } from '../../modals/CreateOrEditWishlistModal';
import { WishlistConfig } from '../../types/GlobalTypes';
import { addOutline } from 'ionicons/icons';
import { handleCreateOrEditWishlistModalDismiss } from './EditWishlistButton';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { useIonRouter } from '@ionic/react';
import { useProfilePageContext } from '../../contexts/ProfilePageContext';

const StyledWishlistOptionButton = styled(WishlistOptionButton)`
  opacity: 0.5;
  background: var(--ion-color-light-100-shade);
  &:hover {
    opacity: 1;
    background: var(--ion-color-light-100);
    .btn-text,
    ion-icon {
      color: var(--ion-color-primary);
    }
  }
`;

const StyledWishlistOptionButtonSmall = styled(WishlistOptionButtonSmall)`
  opacity: 0.5;
  background: var(--ion-color-light-100-shade);
  &:hover {
    opacity: 1;
    background: var(--ion-color-light-100);
    .btn-text,
    ion-icon {
      color: var(--ion-color-primary);
    }
  }
`;

interface Props {
  large: boolean;
  isDemo?: boolean;
}

export const CreateNewWishlistButton: React.FC<Props> = ({ large, isDemo }) => {
  const [showModal, setShowModal] = useState(false);
  const { userId } = useProfilePageContext();
  const ionRouter = useIonRouter();

  const handleCreateNewClick = () => {
    setShowModal(true);
  };

  const handleCreateNewWishlistModalDismiss = (
    action?: 'deleted' | 'created' | 'demo',
    newWishlist?: WishlistConfig
  ) => {
    setShowModal(false);
    if (action === 'demo') {
      toast.error('You have to be logged in to create a wishlist!');
      return;
    } else if (isDemo) {
      return;
    }

    if (!userId) throw new Error('No user ID');
    handleCreateOrEditWishlistModalDismiss({
      userId,
      action,
      newWishlist,
      ionRouter,
    });
  };

  const Component = large
    ? StyledWishlistOptionButton
    : StyledWishlistOptionButtonSmall;

  if (!userId && !isDemo) return null;

  return (
    <>
      <CreateOrEditWishlistModal
        isOpen={showModal}
        isDemo={isDemo}
        onDidDismiss={handleCreateNewWishlistModalDismiss}
      />
      <Component
        color='light-100'
        iconLeft={addOutline}
        onClick={handleCreateNewClick}
      >
        Create New
      </Component>
    </>
  );
};
