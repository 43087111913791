import {
  IonDatetime,
  IonFooter,
  IonImg,
  IonModal,
  IonSpinner,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { addYears, format } from 'date-fns';

import { Button } from '../components/BaseUI/Button';
import SilenceImagSrc from '../assets/imgs/silence.png';
import { TransparentIonContent } from './StyledModalComponents';
import { UserBankContext } from '../contexts/UserBank';
import { getDefaultDiscloseAt } from '../utils/helper-functions/getDefaultDiscloseAt';
import { handleCatchError } from '../utils/handleCatchError';
import { styled } from 'goober';

const FooterButtonsContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 20px 20px;
  @media (max-width: 440px) {
    padding: 10px;
  }
  button:first-child {
    margin-right: 10px;
    @media (max-width: 440px) {
      margin-right: 5px;
    }
  }
  button:last-child {
    margin-left: 10px;
    @media (max-width: 440px) {
      margin-left: 5px;
    }
  }
`;

const StyledIonFooter = styled(IonFooter)`
  margin: 0;
`;

const MarkPurchasedButton = styled(Button as any)`
  flex: 1;
  ion-spinner {
    margin: -5px 0;
    height: 23px;
    width: 23px;
  }
`;

const CancelButton = styled(Button as any)`
  flex: 1;
`;

const StyledIonModal = styled(IonModal)`
  --width: calc(100vw - 20px);
  --max-width: 640px;
  --height: 100%;
  --max-height: 500px;
  --border-radius: 20px;
  @media (max-width: 460px) {
    --height: calc(
      100% - 20px - var(--ion-safe-area-top) - var(--ion-safe-area-bottom)
    );
    --max-height: 530px;
  }
  @media (max-width: 380px) {
    --max-height: 566px;
  }
  --background: rgba(255, 255, 255, 0.9);
`;

const StyledDatetimeIonModal = styled(IonModal)`
  --width: 350px;
  --max-width: 350px;
  --height: 402px;
  --max-height: 402px;
  --background: rgba(255, 255, 255, 0.9);
  ion-datetime {
    padding-top: 10px;
  }
`;

const Container = styled('div')`
  width: 100%;
  padding: 40px 40px;
  @media (max-width: 580px) {
    padding: 30px;
  }
  @media (max-width: 440px) {
    padding: 20px;
  }
`;

const StyledH2 = styled('h1')`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  align-self: flex-start;
  margin: 0;
  margin-bottom: 5px;
`;

const SilenceImage = styled(IonImg)`
  width: 100px;
`;

const DescriptionText = styled('p')`
  margin-bottom: 20px;
`;

interface Props {
  isOpen: boolean;
  onDidDismiss: () => void;
  userId?: string;
  togglePurchased: (discloseAt: string) => Promise<void>;
}

export const MarkPurchasedModal: React.FC<Props> = ({
  isOpen,
  userId,
  onDidDismiss,
  togglePurchased,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDatetimeModalOpen, setIsDatetimeModalOpen] = useState(false);
  const [discloseAt, setDiscloseAt] = useState<string>(getDefaultDiscloseAt());

  const { getUser, userBank } = useContext(UserBankContext);

  const user = userId ? userBank[userId]?.user : undefined;

  useEffect(() => {
    if (userId) {
      getUser(userId);
    }
  }, [userId, getUser]);

  const submit = async () => {
    setIsLoading(true);
    try {
      togglePurchased(discloseAt);
      closeModal();
    } catch (err) {
      handleCatchError(err);
    }

    setIsLoading(false);
  };

  const closeModal = () => {
    setDiscloseAt(getDefaultDiscloseAt());
    onDidDismiss();
  };

  return (
    <StyledIonModal
      onDidDismiss={onDidDismiss}
      className='custom-modal'
      isOpen={isOpen}
    >
      <TransparentIonContent>
        <Container>
          <SilenceImage src={SilenceImagSrc} />
          <StyledH2>
            When can {user?.first_name} see that this item has been purchased by
            you?
          </StyledH2>
          <DescriptionText>
            On the date you select, {user?.first_name} will be able to see that
            you have purchased this item. Before then, they will have no idea
            that it has been purchased{' '}
            <span role='img' aria-label='smiley face'>
              😃
            </span>
          </DescriptionText>
          <Button
            color='light-100'
            onClick={() => setIsDatetimeModalOpen(true)}
          >
            <strong>Disclose On: </strong>&nbsp;
            {format(new Date(discloseAt), 'P')}
          </Button>

          <StyledDatetimeIonModal
            className='custom-modal'
            isOpen={isDatetimeModalOpen}
            onDidDismiss={() => setIsDatetimeModalOpen(false)}
            mode='md'
          >
            <IonDatetime
              min={new Date().toISOString()}
              max={addYears(new Date(), 2).toISOString()}
              value={discloseAt}
              showDefaultButtons={true}
              onIonChange={(e) => setDiscloseAt(e.detail.value as string)}
              presentation='date'
              id='datetime'
            ></IonDatetime>
          </StyledDatetimeIonModal>
        </Container>
      </TransparentIonContent>
      <StyledIonFooter mode='ios'>
        <FooterButtonsContainer>
          <CancelButton onClick={closeModal} color='medium'>
            Cancel
          </CancelButton>
          <MarkPurchasedButton onClick={submit} color='primary'>
            {isLoading ? <IonSpinner name='crescent' /> : 'Mark Purchased'}
          </MarkPurchasedButton>
        </FooterButtonsContainer>
      </StyledIonFooter>
    </StyledIonModal>
  );
};
