import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect } from 'react';

import { DEFAULT_PAGE_TITLE } from '../constants/seo';
import { Footer } from '../components/layout/Footer';
import { HappyGirlsSection } from '../components/homepage/HappyGirlsSection';
import { HeaderSection } from '../components/homepage/HeaderSection';
import { Helmet } from 'react-helmet-async';
import { HomepageFeaturesSection } from '../components/homepage/FeaturesSection';
import { PrivacyFeaturesSection } from '../components/homepage/PrivacySection';
import { ReviewsSection } from '../components/homepage/ReviewsSection';
import { useLocation } from 'react-router-dom';

const Home: React.FC = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      try {
        setTimeout(() => {
          const element = document.getElementById(
            hash.substring(1).split('?')[0]
          );
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1000);
      } catch (error) {
        console.error(error);
      }
    }
  }, [hash]);

  return (
    <IonPage>
      <Helmet prioritizeSeoTags>
        <title>{DEFAULT_PAGE_TITLE}</title>
        <meta property='og:title' content={DEFAULT_PAGE_TITLE} />
        <meta property='og:image' content='/assets/images/ogimage.png' />
        <meta
          property='og:description'
          content='iFancy is the ultimate gift wishlist app for family and friends, helping you easily create, share, and track gift ideas for birthdays, holidays, and special occasions. Simplify gift-giving with personalized wishlists, ensuring the perfect present every time.'
        />
        <meta
          name='description'
          property='description'
          content='iFancy is the ultimate gift wishlist app for family and friends, helping you easily create, share, and track gift ideas for birthdays, holidays, and special occasions. Simplify gift-giving with personalized wishlists, ensuring the perfect present every time.'
        />
      </Helmet>
      <IonContent fullscreen>
        <HeaderSection />
        <ReviewsSection />
        <HappyGirlsSection />
        <HomepageFeaturesSection />
        <PrivacyFeaturesSection />
        <Footer type='full' />
      </IonContent>
    </IonPage>
  );
};

export default Home;
