import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { bulbOutline, checkmark, close } from 'ionicons/icons';

import { Button } from '../components/BaseUI/Button';
import { FriendsList } from '../components/FriendsList';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

const AcceptButton = styled(Button as any)`
  padding: 0px 12px;
  margin-right: 4px;
  border-radius: 16px;
  .btn-icon {
    font-size: 35px;
  }
`;

const CloseButton = styled(Button as any)`
  padding: 4px;
  .btn-icon {
    font-size: 38px;
  }
`;

const StyledIonModal = styled(IonModal)`
  @media (min-width: 769px) {
    --min-height: 90vh;
    --min-width: 92vw;
  }
`;

const TipBox = styled('div')`
  margin: 16px 24px;
  padding: 16px;
  display: flex;
  align-items: center;
  background: #ebebeb;
  border-radius: 12px;
  p {
    margin-top: 3px;
    font-weight: 700;
    opacity: 0.7;
  }
  .icon {
    display: flex;
    font-size: 24px;
    padding-left: 6px;
    padding-right: 12px;
    opacity: 0.6;
  }
`;

type Props = {
  isOpen: boolean;
  pageRef?: any;
  onDidDismiss: (selectedUsers: string[] | undefined) => void;
  selectedFriends?: string[];
  limit?: number;
  required?: number;
  showSelf?: boolean;
  showFollowers: boolean;
  tipBoxText: string;
};

const FriendsModal: React.FC<Props> = ({
  isOpen,
  pageRef,
  onDidDismiss,
  selectedFriends: _selectedFriends = [],
  limit,
  required,
  showSelf,
  showFollowers,
  tipBoxText,
}) => {
  const [selectedFriends, setSelectedFriends] = useState<string[]>([]);
  const { loggedInUser } = useLoggedInUser();

  useEffect(() => {
    if (isOpen) {
      setSelectedFriends(_selectedFriends);
    }
  }, [isOpen, _selectedFriends, setSelectedFriends]);

  const _onDidDismiss = () => {
    onDidDismiss(undefined);
  };

  const save = async () => {
    onDidDismiss(selectedFriends);
  };

  const handleFriendToggle = (userId: string) => {
    if (selectedFriends.includes(userId)) {
      if (required && selectedFriends.length <= required) {
        if (required > 1) {
          toast.error(
            `At least ${required} user${
              required === 1 ? ' is' : 's are'
            } required`
          );
        }
      } else {
        setSelectedFriends(selectedFriends.filter((i) => i !== userId));
      }
    } else {
      if (limit && selectedFriends.length >= limit) {
        const newSelectedFriends = selectedFriends.slice(1);
        setSelectedFriends([...newSelectedFriends, userId]);
      } else {
        setSelectedFriends([...selectedFriends, userId]);
      }
    }
  };

  return (
    <StyledIonModal
      mode='ios'
      presentingElement={pageRef.current}
      onDidDismiss={_onDidDismiss}
      isOpen={isOpen}
    >
      <IonHeader className='ion-no-border' mode='ios' translucent={true}>
        <IonToolbar>
          <IonButtons slot='start'>
            <CloseButton
              clear
              color='dark'
              iconLeft={close}
              onClick={_onDidDismiss}
            />
          </IonButtons>
          <IonButtons slot='end'>
            <AcceptButton iconLeft={checkmark} color='success' onClick={save} />
          </IonButtons>
          <IonTitle>Friends</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <TipBox>
          <div className='icon'>
            <IonIcon icon={bulbOutline} />
          </div>
          <p>{tipBoxText}</p>
        </TipBox>
        <FriendsList
          showSelf={showSelf}
          showFollowers={showFollowers}
          userId={loggedInUser?.id}
          onSelect={handleFriendToggle}
          selected={selectedFriends}
        />
      </IonContent>
    </StyledIonModal>
  );
};

export default FriendsModal;
