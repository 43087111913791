import { BaseAvatar } from './BaseUI/BaseAvatar';
import React from 'react';
import { styled } from 'goober';

interface Props {
  image?: string;
  isOnLoggedInUsersProfile: boolean;
}

const StyledBaseAvatar = styled(BaseAvatar as any)`
  --image-border: 8px;
  --image-size: 124px;
  --border-radius: 23px;
  margin-top: -70px;

  @media (max-width: 650px) {
    --image-border: 6px;
    --image-size: 78px;
    margin-top: -45px;
  }
`;

export const ProfileAvatar: React.FC<Props> = ({
  image,
  isOnLoggedInUsersProfile,
}) => {
  return (
    <StyledBaseAvatar
      image={image}
      isOnLoggedInUsersProfile={isOnLoggedInUsersProfile}
    />
  );
};
