const knownAmazonImagesToIgnore = [
    'short-bullet-point-support.',
    'short-bullet-point-coverage.',
    'short-bullet-point-claim.',
    'toggle_off.',
    'common/transparent-pixel.'
]

export const cleanImagesFn = (imgUrl: string) => {
    if (!imgUrl) return '';
    const i = imgUrl;

    if (i.startsWith("data:image")) return '';
    if (knownAmazonImagesToIgnore.some((known) => i.includes(known))) return '';
    return i.trim();
}

export const isImageValid = (imgUrl: string): Promise<boolean> => {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            if (img.naturalWidth > 400 && img.naturalHeight > 400) {
                resolve(true);
            } else {
                resolve(false);
            }
        };
        img.onerror = () => {
            resolve(false);
        };
        img.src = imgUrl;
    });
};