import React, { useContext, useEffect, useState } from 'react';
import { UserWishlist, WishlistConfig } from '../types/GlobalTypes';
import { createAnimation, useIonRouter } from '@ionic/react';

import { DEFAULT_WISHLIST_CONFIG } from '../constants/constants';
import { Timestamp } from 'firebase/firestore';
import { useGetWishlistItems } from '../hooks/useGetWishlistItems';
import { useGetWishlistSuggestions } from '../hooks/useGetWishlistSuggestions';
import { useGetWishlists } from '../hooks/useGetWishlists';
import { useParams } from 'react-router';

type ProviderProps = {
  children: React.ReactNode;
  userId?: string;
  isLoggedInUsersProfile: boolean;
  isLoggedInUserFollowing: boolean;
  isThisAPrivateProfileAndHidden: boolean;
  isLoggedInUserBlockedByUser: boolean;
  pageRef: any;
  isNonAuthedProfile?: boolean;
};

type Props = {
  wishlists: WishlistConfig[];
  setWishlists: React.Dispatch<React.SetStateAction<WishlistConfig[]>>;
  selectedWishlist?: WishlistConfig;
  updateSelectedWishlist: React.Dispatch<
    React.SetStateAction<WishlistConfig | undefined>
  >;
  wishlistItems: UserWishlist | undefined;
  suggestionList: UserWishlist | undefined;
  setForceWishlistRefresh: React.Dispatch<React.SetStateAction<number>>;
  profilePageRef?: any;
  isLoggedInUserFollowing: boolean;
  isLoggedInUsersProfile: boolean;
  userId?: string;
  isNonAuthedProfile?: boolean;
  isLoggedInUserBlockedByUser: boolean;
};

const ProfilePageContextContext = React.createContext<Props>({} as Props);

export const useProfilePageContext = () =>
  useContext(ProfilePageContextContext);

export const ProfilePageContextProvider = ({
  children,
  userId,
  isLoggedInUsersProfile,
  isLoggedInUserFollowing,
  isThisAPrivateProfileAndHidden,
  isLoggedInUserBlockedByUser,
  pageRef,
  isNonAuthedProfile,
}: ProviderProps) => {
  const [selectedWishlist, setSelectedWishlist] = useState<WishlistConfig>();
  const [forceWishlistRefresh, setForceWishlistRefresh] = useState<number>(0);
  const ionRouter = useIonRouter();

  const { wishlists, setWishlists } = useGetWishlists({
    userId,
    isLoggedInUsersProfile,
  });

  const wishlistItems = useGetWishlistItems({
    userId,
    forceWishlistRefresh,
    isLoggedInUserBlockedByUser,
    isLoggedInUserFollowing,
    isLoggedInUsersProfile,
    isThisAPrivateProfileAndHidden,
    wishlist: selectedWishlist,
  });

  const suggestionList = useGetWishlistSuggestions({
    userId,
    isLoggedInUsersProfile,
  });

  const params = useParams<{
    userId?: string;
    wishlistId: string | undefined;
  }>();

  useEffect(() => {
    const newSelectedWishlist = wishlists.find((wishlist) =>
      !params.wishlistId
        ? wishlist.id === 'original'
        : wishlist.id === params.wishlistId
    );
    if (
      params.wishlistId &&
      !newSelectedWishlist &&
      wishlists.length > 0 &&
      userId
    ) {
      ionRouter.push(`/user/${userId}`, 'none', 'replace', {}, () =>
        createAnimation()
      );
    } else {
      if (!newSelectedWishlist) {
        setSelectedWishlist(
          wishlists.find((wishlist) => wishlist.id === 'original')
        );
      } else {
        setSelectedWishlist(newSelectedWishlist);
      }
    }
  }, [params, wishlists, isLoggedInUsersProfile, ionRouter, userId]);

  useEffect(() => {
    if (isNonAuthedProfile) {
      const defaultWishlist = {
        ...DEFAULT_WISHLIST_CONFIG,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
      };
      setSelectedWishlist(defaultWishlist);
      setWishlists([defaultWishlist]);
    } else {
      setSelectedWishlist(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, isNonAuthedProfile]);

  return (
    <ProfilePageContextContext.Provider
      value={{
        wishlists,
        setWishlists,
        selectedWishlist,
        wishlistItems,
        suggestionList,
        setForceWishlistRefresh,
        profilePageRef: pageRef,
        updateSelectedWishlist: setSelectedWishlist,
        isLoggedInUserFollowing,
        isLoggedInUsersProfile,
        userId,
        isNonAuthedProfile,
        isLoggedInUserBlockedByUser,
      }}
    >
      {children}
    </ProfilePageContextContext.Provider>
  );
};
