import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { bulbOutline, close } from 'ionicons/icons';

import { Button } from '../components/BaseUI/Button';
import React from 'react';
import { styled } from 'goober';

const CloseButton = styled(Button as any)`
  padding: 4px;
  .btn-icon {
    font-size: 38px;
  }
`;

const StyledIonModal = styled(IonModal)`
  @media (min-width: 769px) {
    --min-height: 90vh;
    --min-width: 92vw;
  }
`;

const TipBox = styled('div')`
  margin: 16px 24px;
  padding: 16px;
  display: flex;
  align-items: center;
  background: #ebebeb;
  border-radius: 12px;
  p {
    margin-top: 3px;
    font-weight: 700;
    opacity: 0.7;
    margin-bottom: 0;
  }
  .icon {
    display: flex;
    font-size: 24px;
    padding-left: 6px;
    padding-right: 12px;
    opacity: 0.6;
  }
`;

const ArticleContent = styled('div')`
  padding-bottom: 100px;
  h1 {
    font-size: 32px;
    margin-bottom: 0;
  }
  h2 {
    font-size: 25px;
    font-family: 'Nunito';
    line-height: 1;
  }
  h1,
  h2,
  h3,
  h4 {
    font-weight: 700;
  }
  h1,
  h2,
  h3,
  h4,
  p {
    margin-right: 24px;
    margin-left: 24px;
  }
  h4 {
    margin-top: 24px;
  }
  p {
    margin-top: 18px;
    margin-bottom: 18px;
  }
`;

type Props = {
  isOpen: boolean;
  pageRef?: any;
  onDidDismiss: (selectedUsers: string[] | undefined) => void;
};

const LinkParsingInfoModal: React.FC<Props> = ({
  isOpen,
  pageRef,
  onDidDismiss,
}) => {
  const _onDidDismiss = () => {
    onDidDismiss(undefined);
  };

  return (
    <StyledIonModal
      mode='ios'
      canDismiss={true}
      presentingElement={pageRef.current}
      onDidDismiss={_onDidDismiss}
      isOpen={isOpen}
    >
      <IonHeader className='ion-no-border' mode='ios' translucent={true}>
        <IonToolbar>
          <IonButtons slot='end'>
            <CloseButton
              clear
              color='dark'
              iconLeft={close}
              onClick={_onDidDismiss}
            />
          </IonButtons>
          <IonTitle>Link Parsing</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <ArticleContent>
          <h1>Link Parsing</h1>
          <h2>a.k.a Link Unfurling</h2>
          <h2>a.k.a OpenGraph</h2>

          <h4>What is it (briefly)?</h4>
          <p>
            This is what makes those &quot;previews&quot; available on
            iMessages, slack, or other social platforms when you paste a link.
            It can be called various things but for iFancy we will call it link
            parsing because that seems like the easiest non-technical term to
            comprehend. Essentially, link parsing is simply reaching out to a
            URL and when it returns the webpage code (HTML, CSS, etc) you search
            through the code for tips on what&apos;s on the page.
          </p>
          <h4>Why is it not working?</h4>
          <strong>
            <p>There are 2 common reasons why link parsing is not working:</p>
          </strong>
          <p>
            1. The website isn&apos;t setup for it. The important thing to
            remember about link parsing is that it depends on the setup of the
            website being requested. So if that website doesn&apos;t use any
            standardized format to help link parsing understand what product is
            on the page, then it becomes very difficult to figure out any
            details about a specific product on the page. Every website design
            is vastly different so if there are no coded helpers for link
            parsing its pretty much a crapshoot to try and figure out where the
            &quot;main&quot; product is on the page.
          </p>
          <p>
            2. The website intentionally blocks link parsing. This happens
            usually with bigger retailers in an attempt to obfuscate price and
            product changes. Interestingly, there are bots that scan the
            internet and report back price changes to retailers so that they can
            make sure that their pricing is competitive. So in some cases it
            would be advantageous to NOT reveal any info to link parsing bots.
            The problem is, it also blocks us from link parsing.
          </p>
          <h4>Are there web standards?</h4>
          <p>
            There are a few different standardized protocols for how to define
            whats on a website for link parsing. The most well-known is the
            probably the{' '}
            <a href='https://ogp.me/' rel='noopener noreferrer' target='_blank'>
              Open Graph Protocol
            </a>
            . It is very common for websites to include data in what are called
            OG meta tags that describe whats on the page such as product price,
            image, twitter handles etc. This is what is used to display previews
            on social applications.
          </p>
          <TipBox>
            <div className='icon'>
              <IonIcon icon={bulbOutline} />
            </div>
            <p>
              Did you know that Facebook actually invented the Open Graph
              Protocol?
            </p>
          </TipBox>

          <h4>Resources</h4>
          <IonItem
            detail={true}
            href='https://ogp.me/'
            rel='noopener noreferrer'
            target='_blank'
          >
            Open Graph Protocol
          </IonItem>
          <IonItem
            detail={true}
            href='https://medium.com/slack-developer-blog/everything-you-ever-wanted-to-know-about-unfurling-but-were-afraid-to-ask-or-how-to-make-your-e64b4bb9254'
            rel='noopener noreferrer'
            target='_blank'
          >
            Everything you ever wanted to know about unfurling but were afraid
            to ask /or/ How to make your site previews look amazing in Slack
          </IonItem>
          <IonItem
            detail={true}
            href='https://support.sendible.com/hc/en-us/articles/115000159366-How-are-link-previews-handled-by-the-different-social-sites-'
            rel='noopener noreferrer'
            target='_blank'
          >
            How are link previews handled by the different social sites?
          </IonItem>
          <IonItem
            detail={true}
            href='https://ahrefs.com/blog/open-graph-meta-tags/'
            rel='noopener noreferrer'
            target='_blank'
          >
            Open Graph Meta Tags: Everything You Need to Know
          </IonItem>
        </ArticleContent>
      </IonContent>
    </StyledIonModal>
  );
};

export default LinkParsingInfoModal;
