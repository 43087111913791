
const amazonLinkVariations: { url: string; isShortened?: boolean; isAlreadyTagged?: boolean; }[] = [];

const constructListOfAmazonDomains = () => {
    const amazonDomainVariations = [
        { url: 'amzn', isAlreadyTagged: true },
        { url: 'a', isShortened: true },
        { url: 'amazon' },
    ];

    const subdomainVariations = ['www.', ''];

    const httpVariations = ['https://', 'http://'];

    for (const domainObj of amazonDomainVariations) {
        const domain = domainObj.url;
        const isShortened = Boolean(domainObj.isShortened);
        const isAlreadyTagged = Boolean(domainObj.isAlreadyTagged);
        for (const subdomain of subdomainVariations) {
            for (const http of httpVariations) {
                amazonLinkVariations.push({
                    url: `${http}${subdomain}${domain}.`,
                    isShortened,
                    isAlreadyTagged
                });
            }
        }
    }
};

constructListOfAmazonDomains();

export const isKnownAmazonLink = (link: string): typeof amazonLinkVariations[number] | undefined => {
    return amazonLinkVariations.find(
        (amazonDomain) => link.indexOf(amazonDomain.url) > -1
    );
}