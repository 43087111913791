import {
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonRouter,
  useIonViewDidEnter,
} from '@ionic/react';
import React, { useContext } from 'react';
import { checkmarkDoneCircleOutline, refreshOutline } from 'ionicons/icons';

import { Button } from '../components/BaseUI/Button';
import { Capacitor } from '@capacitor/core';
import { DEFAULT_PAGE_TITLE } from '../constants/seo';
import { ErrorMessageText } from '../components/ErrorMessageText';
import { FeedbackInlineBox } from '../components/FeedbackInlineBox';
import { Helmet } from 'react-helmet-async';
import { NotificationItem } from '../components/NotificationItem';
import { NotificationsContext } from '../contexts/NotificationsContext';
import { PageWrapper } from '../components/layout/PageWrapper';
import { PushNotifications } from '@capacitor/push-notifications';
import { StyledIonOuterToolbar } from '../components/layout/StyledOuterIonToolbar';
import { customPageAnimationVertically } from '../utils/pageTransition';
import { styled } from 'goober';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

const MoreContentContainer = styled('div')`
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ion-spinner {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
  p {
    color: var(--ion-color-dark);
    font-size: 13px;
  }
`;

const ButtonsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--ion-color-light);
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
`;

const ContentContainer = styled('div')`
  width: 100%;
  margin: 0 auto;
`;

export const NotificationsPage: React.FC = () => {
  const {
    notifications,
    markRead,
    isLoading,
    reachedEndOfNotifications,
    fetchNotifications,
  } = useContext(NotificationsContext);

  const { loggedInUser } = useLoggedInUser();

  const ionRouter = useIonRouter();

  const dismissAll = async () => {
    if (!notifications) return;
    for (const notification of notifications) {
      markRead(notification);
    }
  };

  const goToAuth = () => {
    ionRouter.push(
      `/auth`,
      'forward',
      'push',
      {},
      customPageAnimationVertically
    );
  };

  useIonViewDidEnter(() => {
    fetchNotifications(true, false);
    if (!Capacitor.isNativePlatform()) return;
    PushNotifications.removeAllDeliveredNotifications();
  }, []);

  return (
    <IonPage>
      <Helmet prioritizeSeoTags>
        <title>{DEFAULT_PAGE_TITLE}</title>
        <meta property='og:title' content={DEFAULT_PAGE_TITLE} />
      </Helmet>
      <IonHeader translucent={true}>
        <StyledIonOuterToolbar>
          <IonTitle>Notifications</IonTitle>
        </StyledIonOuterToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <PageWrapper maxWidth footerType='minimal' hideDisclaimer>
          <IonHeader collapse='condense'>
            <IonToolbar>
              <IonTitle size='large'>Notifications</IonTitle>
            </IonToolbar>
          </IonHeader>
          <ContentContainer>
            <FeedbackInlineBox />

            <ButtonsContainer>
              <Button
                isLoading={isLoading}
                iconLeft={refreshOutline}
                color='dark'
                clear
                onClick={() => fetchNotifications(true, true)}
              >
                Refresh
              </Button>
              {notifications && (
                <Button
                  iconLeft={checkmarkDoneCircleOutline}
                  color='dark'
                  clear
                  onClick={dismissAll}
                >
                  Dismiss All
                </Button>
              )}
            </ButtonsContainer>
            {notifications?.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
            <MoreContentContainer>
              {isLoading ? (
                <>
                  <IonSpinner name='crescent' color='medium' /> <p>Loading</p>
                </>
              ) : reachedEndOfNotifications ? (
                <p>
                  No more notifications to load{' '}
                  <span role='img' aria-label='monkey'>
                    🙉
                  </span>
                </p>
              ) : loggedInUser ? (
                <Button
                  clear
                  color='dark'
                  onClick={() => fetchNotifications(false, false)}
                >
                  View More
                </Button>
              ) : (
                <ErrorMessageText>
                  <Button color='primary' onClick={goToAuth}>
                    Login // Sign up
                  </Button>
                </ErrorMessageText>
              )}
            </MoreContentContainer>
          </ContentContainer>
        </PageWrapper>
      </IonContent>
    </IonPage>
  );
};
