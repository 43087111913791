import { Input, InputContainer } from './BaseUI/InputContainer';
import { IonSkeletonText, IonSpinner, IonText } from '@ionic/react';
import { LinkResultResponse, MergedProductResults } from '../types/GlobalTypes';
import { MdThumbDown, MdThumbUp } from 'react-icons/md';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  clipboardOutline,
  closeCircle,
  helpCircleOutline,
} from 'ionicons/icons';

import { Button } from './BaseUI/Button';
import { Capacitor } from '@capacitor/core';
import { Clipboard } from '@capacitor/clipboard';
import { PiMagicWandDuotone } from 'react-icons/pi';
import { analytics } from '../firebase';
import { captureMessage } from '@sentry/react';
import { extractURLFromString } from '../utils/helper-functions/extractURLFromString';
import { fetchLinkWithCapacitorHttps } from '../utils/OGScraping/fetchLinkWithCapacitorHttps';
import { handleCatchError } from '../utils/handleCatchError';
import { isImageValid } from '../utils/OGScraping/cleanImagesFn';
import { isValidURL } from '../utils/helper-functions/isValidURL';
import { log } from '../utils/log';
import { logEvent } from 'firebase/analytics';
import { socket } from '../utils/socket';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { truncateString } from '../utils/helper-functions/truncateString';

interface Props {
  link: string;
  setLink: (link: Props['link']) => void;
  linkDomain: string;
  price: string;
  setPrice: (price: Props['price']) => void;
  title: string;
  setTitle: (title: Props['title']) => void;
  image: string;
  setImage: (image: Props['image']) => void;
  readonly: boolean;
  itemId: string | undefined;
  onClickOpenLinkInfoModal: () => void;
  adminCheck?: boolean;
  uniqueOpenId: string | undefined;
  isLinkFetchLoading: boolean;
  setIsLinkFetchLoading: (loading: boolean) => void;
}

const LearnMoreButton = styled(Button as any)`
  margin-right: auto;
  margin-left: auto;
  margin-top: 8px;
  .btn-text {
    font-size: 10px;
  }
  .btn-icon {
    padding-left: 1px;
  }
  ion-icon {
    font-size: 12px;
  }
`;

const LinkResultContainer = styled('div')`
  display: flex;
  width: calc(100% + 32px);
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: 8px;
  margin-bottom: 4px;
  padding-bottom: 3px;
  padding-top: 4px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 24px;
  border-radius: 8px;
  background: #efefef;
  border: 1px dashed #dbdbdb;
  &:not(.invisible) .if-input-container {
    background: white;
    input {
      background: white;
    }
    margin-top: 2px;
    margin-bottom: 8px;
  }
  &.invisible {
    .if-input-container {
      margin-bottom: 12px;
    }
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 4px;
    background: transparent;
    border-color: transparent;
  }
`;

const LinkResultImagesContainer = styled('div')`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 4px;
  flex-wrap: wrap;
`;

const LinkResultButton = styled(Button as any)<{ $used?: boolean }>`
  margin-top: 4px;
  opacity: ${({ $used }) => ($used ? 0.5 : 1)};
`;

const LinkResultImageButton = styled('img')<{ $used?: boolean; size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  object-fit: cover;
  border-radius: 6px;
  margin: 3px;
  cursor: pointer;
  border: 2px solid
    ${({ $used }) => ($used ? 'var(--ion-color-primary)' : 'transparent')};
`;

const SoftErrorIonText = styled(IonText)`
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  padding-bottom: 4px;
  letter-spacing: 0.02em;
`;

const AmazonNote = styled(SoftErrorIonText)`
  text-align: left;
  padding-bottom: 0;
  margin-bottom: -6px;
  padding-top: 6px;
`;

const SiteBlockedText = styled('p')`
  font-size: 16px;
  color: var(--ion-color-primary);
  font-weight: 900;
`;

const BetterResultsText = styled('p')`
  font-size: 14px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 9px;
  background: var(--ion-color-light);
  border: 2px dashed var(--ion-color-primary);
`;

const LinkResultErrorMessage = styled('p')`
  margin: 0;
  padding: 0;
  padding-left: 4px;
  margin-top: 4px;
  font-size: 10px;
  opacity: 0.5;
  color: black;
`;

const LinkLoadingText = styled('div')`
  margin: 0;
  color: var(--ion-color-dark);
  opacity: 0.7;
  font-size: 12px;
  padding-bottom: 2px;
  position: relative;
  ion-spinner {
    margin-right: 6px;
    width: 12px;
    height: 12px;
    margin-top: 2px;
  }
  p {
    position: absolute;
    top: -3px;
    left: 16px;
    white-space: nowrap;
    transition: transform 0.42s ease, opacity 0.42s ease;
  }
  p.visible {
    opacity: 1;
    transform: translateY(0px);
  }
  p.invisible-out {
    opacity: 0;
    transform: translateY(-17px);
  }
  p.invisible-in {
    opacity: 0;
    transform: translateY(17px);
    transition-delay: 0.42s;
  }
`;

const PasteButton = styled(Button as any)`
  && {
    margin-right: -9px;
    border-radius: 7px;
    padding: 6px 8px;
  }
`;

const RefreshButton = styled(Button as any)`
  margin-right: 0px;
  border-radius: 7px;
  color: var(--ion-color-primary);
  padding: 5px;
  position: relative;
  z-index: 1;
  background: white;
  .magic-wand {
    width: 19px;
    height: 19px;
    color: var(--ion-color-primary);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 7px;
    z-index: 10;
    border: 1px solid #d4d4d4;
  }
  &:hover {
    background: white;
    > svg {
      transform: scale(1.1);
    }
    &:after {
      border-color: var(--ion-color-primary);
    }
  }
`;

const ClearButton = styled(Button as any)`
  margin-right: -9px;
  border-radius: 7px;
  padding: 5px;
`;

const SkeletonLinkResult = styled(IonSkeletonText)`
  width: 136px;
  height: 28px;
  border-radius: 10px;
  margin-top: 4px;
`;

const SkeletonImage = styled(IonSkeletonText)`
  width: 76px;
  height: 76px;
  border-radius: 6px;
  margin: 3px;
  border: 2px solid transparent;
`;

const FetchButton = styled(Button as any)`
  width: 100%;
  color: white;
  margin-top: -4px;
  margin-bottom: 8px;
  height: 32px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  .btn-icon {
    margin-top: -2px;
    margin-bottom: -2px;
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
`;

const HelpfulButtonsContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  button {
    border: 1px dashed #bfbfbf;
    flex: 1;
    max-width: 200px;
    margin: 6px;
    svg {
      margin-right: 4px;
      width: 20px;
      height: 20px;
    }
  }
  button:first-of-type svg {
    color: var(--ion-color-success);
  }

  button:last-of-type svg {
    color: var(--ion-color-danger);
  }
`;

type ImageListItem = { url: string; approved: boolean };

type FetchRequest = {
  link: string;
  time: number;
  triggered: boolean;
};

type LinkStatusTexts =
  | ''
  | '❗️Invalid URL'
  | '😢 URL did not return any helpful data'
  | '🥳 Hooray! The URL returned some helpful data';

export const LinkInput: React.FC<Props> = ({
  link,
  linkDomain,
  setLink,
  readonly,
  image,
  setImage,
  title,
  setTitle,
  price,
  setPrice,
  uniqueOpenId,
  onClickOpenLinkInfoModal,
  adminCheck,
  isLinkFetchLoading,
  setIsLinkFetchLoading,
}) => {
  const linkInputRef = useRef<any>();

  const [socketEvents, setSocketEvents] = useState<LinkResultResponse[]>([]);
  const [showLoadingState, setShowLoadingState] = useState(false);
  const [linkStatusText, setLinkStatusText] = useState<LinkStatusTexts>('');
  const [initialLink, setInitialLink] = useState(link);
  const [imageList, setImageList] = useState<ImageListItem[]>([]);
  const [linkResults, setLinkResults] = useState<MergedProductResults>();
  const [imagesLoading, setImagesLoading] = useState(false);
  const [minimumLoadDone, setMinimumLoadDone] = useState(true);
  const [hideHelpfulButtons, setHideHelpfulButtons] = useState(false);

  const fetchLink = useRef<string>('');

  const fetchRequests = useRef<FetchRequest[]>([]);

  useEffect(() => {
    log('socket', 'Subscribing to socket link results...');

    socket.on('link-result', (data) => {
      const newData = data as LinkResultResponse;
      newData.results.prices = newData.results.prices?.map((price) => ({
        ...price,
        value: String(price.value),
      }));
      setSocketEvents((prev) => [...prev, newData]);
      log('socket', 'Link result received', data);
      logEvent(analytics, 'link_result_received');
    });

    return () => {
      log('socket', 'Unsubscribing to socket link results');

      socket.off('link-result');
    };
  }, []);

  useEffect(() => {
    socket.on('link-scrape-complete', (data: LinkResultResponse) => {
      if (fetchLink.current === data.link) {
        setIsLinkFetchLoading(false);
        setHideHelpfulButtons(false);
      }
      log('socket', 'Link scrape completed', data);
      logEvent(analytics, 'link_result_completed');
    });

    return () => {
      socket.off('link-scrape-complete');
    };
  }, [setIsLinkFetchLoading]);

  const imagesLoadingRef = useRef(false);
  const imagesLoadingRefChangedToFalse = useRef(false);

  useEffect(() => {
    if (!uniqueOpenId && !showLoadingState && linkResults) {
      logEvent(analytics, 'never_clicked_fetch_button');
    }

    setSocketEvents([]);
    setShowLoadingState(false);
    fetchLink.current = '';
    setInitialLink(link);
    setImageList([]);
    fetchRequests.current = [];
    setHideHelpfulButtons(false);
    setIsLinkFetchLoading(false);
    imagesLoadingRefChangedToFalse.current = false;
    imagesLoadingRef.current = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueOpenId]);

  useEffect(() => {
    const mostRecentSocketEventForLink = socketEvents.findLast(
      (e) => e.link === link
    );

    setLinkResults(mostRecentSocketEventForLink?.results);
  }, [socketEvents, link]);

  useEffect(() => {
    // Set title and price if we have one that comes back.
    if (linkResults && showLoadingState) {
      if (linkResults.titles && !title && linkResults.titles.length) {
        setTitle(linkResults.titles[0].value);
      }
      if (linkResults.prices && !price && linkResults.prices.length) {
        setPrice(linkResults.prices[0].value);
      }
    }
  }, [linkResults, price, title, setTitle, setPrice, showLoadingState]);

  useEffect(() => {
    if (imagesLoadingRef.current !== imagesLoading) {
      // there is a change in the imagesLoading.
      imagesLoadingRef.current = imagesLoading;
      if (imagesLoading === false) {
        imagesLoadingRefChangedToFalse.current = true;
      }
    }

    if (!imagesLoadingRefChangedToFalse.current) {
      return;
    }
    // Set title and price if we have one that comes back.
    if (linkResults && showLoadingState && imageList.length && !imagesLoading) {
      if (
        linkResults.images &&
        !image &&
        linkResults.images.length &&
        imageList.length
      ) {
        imagesLoadingRefChangedToFalse.current = false;
        const firstApprovedImage = imageList.find((i) => i.approved);
        if (firstApprovedImage) {
          setImage(firstApprovedImage.url);
        }
      }
    }
  }, [
    linkResults,
    imageList,
    image,
    setImage,
    showLoadingState,
    imagesLoading,
  ]);

  useEffect(() => {
    let abort = false;
    const checkImages = async () => {
      const finalImages: ImageListItem[] = [];
      if (!linkResults) {
        setImagesLoading(false);
        return;
      }
      for (const image of linkResults.images) {
        if (abort) return;
        if (imageList.some((i) => i.url === image.value)) continue;
        setImagesLoading(true);
        const isValid = await isImageValid(image.value);
        finalImages.push({ url: image.value, approved: isValid });
      }
      if (abort) {
        setImagesLoading(false);
        return;
      }

      if (!finalImages.length) {
        setImagesLoading(false);
        return;
      }

      const newImageList = [...imageList, ...finalImages];
      setImageList(newImageList);
      setImagesLoading(false);
    };

    checkImages();

    return () => {
      abort = true;
    };
  }, [linkResults, imageList]);

  useEffect(() => {
    if (!link) {
      setLinkStatusText('');
    } else {
      if (!isLinkFetchLoading && fetchLink.current === link) {
        if (
          !linkResults ||
          (!linkResults.titles?.length &&
            !linkResults.images?.length &&
            !linkResults.prices?.length)
        ) {
          setLinkStatusText('😢 URL did not return any helpful data');
          localStorage.setItem('ifancy-failedurl', new Date().toISOString());
        }
      } else {
        if (
          linkResults &&
          (linkResults.titles?.length ||
            linkResults.images?.length ||
            linkResults.prices?.length)
        ) {
          setLinkStatusText('🥳 Hooray! The URL returned some helpful data');
        }
      }

      if (!isValidURL(link)) {
        setLinkStatusText('❗️Invalid URL');
      }
    }
  }, [isLinkFetchLoading, linkResults, link]);

  const handlePasteLink = async (e: any) => {
    try {
      e.preventDefault();
      let str = '';

      if (
        Capacitor.getPlatform() !== 'web' &&
        Capacitor.isPluginAvailable('Clipboard')
      ) {
        const { value } = await Clipboard.read();
        str = value;
      } else {
        str = await navigator.clipboard.readText();
      }

      if (str.trim()) {
        setLink(str.trim());
        validateLinkInput(str.trim());
      }
    } catch (err) {
      handleCatchError(err);
    }
  };

  const validateLinkInput = (value: string) => {
    const extractedURL = extractURLFromString(value);

    const newValue = extractedURL || value;
    setShowLoadingState(false);

    setLink(newValue);
  };

  const requestLinkFetch = useCallback(
    (link: string) => {
      const now = Date.now();
      const lastRequest =
        fetchRequests.current.length > 0
          ? fetchRequests.current[fetchRequests.current.length - 1]
          : null;
      let isMounted = true;

      // Remove requests older than 2 minutes
      const triggeredRequests = fetchRequests.current.filter(
        (request) => request.triggered
      );

      if (triggeredRequests.length >= 20) {
        // If there have been 10 or more requests in the last 2 minutes, do not allow more requests
        toast.error('Too many requests.');
        captureMessage('Too many requests for link fetch - MAX 20');
        return;
      }

      // Remove requests older than 2 minutes
      const requestsWithin2Min = triggeredRequests.filter(
        (request) => now - request.time < 120000
      );

      if (requestsWithin2Min.length >= 10) {
        // If there have been 10 or more requests in the last 2 minutes, do not allow more requests
        toast.error('Too many requests. Please wait before trying again.');
        captureMessage('Too many requests for link fetch - 10 in 2 minutes');
        return;
      }

      if (lastRequest && now - lastRequest.time < 1000) {
        // If a request was made in the last 1000ms, schedule the new request
        fetchRequests.current.push({ link, time: now, triggered: false });
        setTimeout(() => {
          if (!isMounted) return;
          // Ensure only the most recent request is executed
          const latestRequest =
            fetchRequests.current[fetchRequests.current.length - 1];
          if (latestRequest.link === link) {
            if (!socket.connected) {
              toast.error('Socket connection lost. Try again.');
              captureMessage('Socket connection lost. Try again.');
              socket.connect();
              setShowLoadingState(false);
              return;
            }

            fetchLink.current = link;
            socket.emit('link-input', {
              link,
              waitForCapacitorResult: Capacitor.isNativePlatform(),
            });
            fetchLinkWithCapacitorHttps(link);
            setIsLinkFetchLoading(true);
            setMinimumLoadDone(false);
            setTimeout(() => {
              if (isMounted) setMinimumLoadDone(true);
            }, 1000);
            latestRequest.triggered = true;
          }
        }, 1000 - (now - lastRequest.time));
      } else {
        // If no recent request, execute immediately
        if (!socket.connected) {
          toast.error('Socket connection lost. Try again.');
          captureMessage('Socket connection lost. Try again.');
          socket.connect();
          setShowLoadingState(false);
          return;
        }

        fetchRequests.current.push({ link, time: now, triggered: true });
        fetchLink.current = link;

        socket.emit('link-input', {
          link,
          waitForCapacitorResult: Capacitor.isNativePlatform(),
        });
        fetchLinkWithCapacitorHttps(link);
        setIsLinkFetchLoading(true);
        setMinimumLoadDone(false);
        setTimeout(() => {
          if (isMounted) setMinimumLoadDone(true);
        }, 1000);
      }

      return () => {
        isMounted = false;
      };
    },
    [setIsLinkFetchLoading]
  );

  const handleRefresh = (e: any) => {
    e?.preventDefault();
    logEvent(analytics, 'link_fetch_refresh_button_clicked');
    setShowLoadingState(true);
    requestLinkFetch(link);
  };

  useEffect(() => {
    if (link !== initialLink && link && isValidURL(link)) {
      requestLinkFetch(link);
    }
  }, [link, initialLink, requestLinkFetch]);

  const handleClear = () => {
    setLink('');
    setIsLinkFetchLoading(false);
    fetchLink.current = '';
    setShowLoadingState(false);
  };

  const handleFetchClick = () => {
    logEvent(analytics, 'link_fetch_button_clicked');
    setShowLoadingState(true);
    setMinimumLoadDone(false);
    setTimeout(() => {
      setMinimumLoadDone(true);
    }, 1000);
  };

  const handleHelpfulRating = (helpful: boolean) => {
    if (!socket.connected) {
      toast.error('Socket connection lost. Try again.');
      captureMessage('Socket connection lost. Try again.');
      socket.connect();
      return;
    }

    if (helpful) {
      toast.success('Thank you for your feedback!');
    } else {
      toast.success(
        'Sorry it was not helpful. We will dig into this particular link to make improvements.',
        {
          duration: 6000,
        }
      );
      localStorage.setItem('ifancy-failedurl', new Date().toISOString());
    }

    socket.emit('link-helpful-rating', { link, helpful });
    setHideHelpfulButtons(true);
  };

  if (readonly) return null;

  const imagesToShow = linkResults?.images.filter((i) =>
    imageList.some((il) => il.url === i.value && il.approved)
  );

  return (
    <>
      <LinkResultContainer
        className={
          showLoadingState && (isLinkFetchLoading || linkResults)
            ? ''
            : 'invisible'
        }
      >
        {linkStatusText &&
          (showLoadingState || linkStatusText === '❗️Invalid URL') && (
            <SoftErrorIonText color='medium'>{linkStatusText}</SoftErrorIonText>
          )}
        <InputContainer
          className='if-input-container'
          onClick={() => {
            linkInputRef.current?.focus();
          }}
        >
          {link && initialLink === link && !showLoadingState ? (
            <RefreshButton
              color='medium'
              small
              clear
              iconLeft={<PiMagicWandDuotone className='magic-wand' />}
              onClick={handleRefresh}
              sparkles
            ></RefreshButton>
          ) : (
            <p>URL</p>
          )}
          <Input
            readonly={readonly}
            ref={linkInputRef}
            onIonInput={(e: any) => validateLinkInput(e.detail.value)}
            value={link}
          />
          {link ? (
            <ClearButton
              color='medium'
              small
              clear
              iconLeft={closeCircle}
              onClick={handleClear}
            />
          ) : (
            <PasteButton
              color='light'
              small
              iconLeft={clipboardOutline}
              onClick={handlePasteLink}
            >
              Paste Link
            </PasteButton>
          )}
        </InputContainer>
        {initialLink !== link &&
          link &&
          isValidURL(link) &&
          !showLoadingState && (
            <FetchButton
              color='primary'
              iconLeft={<PiMagicWandDuotone className='btn-icon' />}
              onClick={handleFetchClick}
              sparkles
              maxStars={8}
            >
              Fetch Data
            </FetchButton>
          )}
        {showLoadingState && (isLinkFetchLoading || !minimumLoadDone) && (
          <LinkLoadingText>
            <IonSpinner name='crescent' color='dark' />
            <p
              className={
                !linkResults?.images?.length &&
                !linkResults?.titles?.length &&
                !linkResults?.prices?.length
                  ? 'visible'
                  : 'invisible-out'
              }
            >
              Searching Link For Image, Title, & Price...
            </p>
            <p
              className={
                !linkResults?.images?.length &&
                !linkResults?.titles?.length &&
                !linkResults?.prices?.length
                  ? 'invisible-in'
                  : 'visible'
              }
            >
              Checking for more, hang tight...
            </p>
          </LinkLoadingText>
        )}
        {adminCheck && (
          <a href={link} target='_blank' rel='noreferrer'>
            Open Link
          </a>
        )}
        {showLoadingState && (linkResults || isLinkFetchLoading) && (
          <>
            {linkResults?.titles?.length ? (
              linkResults.titles?.map((titleItem) => (
                <LinkResultButton
                  onClick={() => setTitle(titleItem.value || '')}
                  small
                  color='primary'
                  key={titleItem.value}
                  $used={title === titleItem.value}
                >
                  Use Title: {truncateString(titleItem.value, 20)}
                </LinkResultButton>
              ))
            ) : isLinkFetchLoading ? null : (
              <LinkResultErrorMessage>Title not given</LinkResultErrorMessage>
            )}
            {linkResults?.prices?.length ? (
              linkResults.prices?.map((priceItem) => (
                <LinkResultButton
                  onClick={() => setPrice(priceItem.value || '')}
                  small
                  color='primary'
                  key={priceItem.value}
                  $used={price === priceItem.value}
                >
                  Use Price: {priceItem.value}
                </LinkResultButton>
              ))
            ) : isLinkFetchLoading ? null : (
              <LinkResultErrorMessage>Price not given</LinkResultErrorMessage>
            )}
            {isLinkFetchLoading && <SkeletonLinkResult animated />}
            <LinkResultImagesContainer>
              {imagesToShow?.length ? (
                imagesToShow?.map((imageUrl) => {
                  if (
                    !imageList.some(
                      (i) => i.url === imageUrl.value && i.approved
                    )
                  )
                    return null;
                  return (
                    <LinkResultImageButton
                      onClick={() => setImage(imageUrl.value)}
                      key={imageUrl.value}
                      src={imageUrl.value}
                      size={
                        !imagesToShow || imagesToShow.length < 5
                          ? 84
                          : imagesToShow.length < 10
                          ? 50
                          : 38
                      }
                      $used={image === imageUrl.value}
                    />
                  );
                })
              ) : isLinkFetchLoading || imagesLoading ? (
                <>
                  <SkeletonImage animated />
                  <SkeletonImage animated />
                  <SkeletonImage animated />
                </>
              ) : (
                <LinkResultErrorMessage>No images given</LinkResultErrorMessage>
              )}

              {showLoadingState &&
                (isLinkFetchLoading || imagesLoading) &&
                imagesToShow &&
                imagesToShow.length < 5 && <SkeletonImage animated />}
            </LinkResultImagesContainer>
            {linkStatusText === '😢 URL did not return any helpful data' ? (
              link.indexOf('amazon.com') !== -1 ||
              link.indexOf('/a.co') !== -1 ? (
                <AmazonNote>
                  Unfortunately, Amazon doesn&apos;t always return product
                  information. Typically this is because Amazon intends to
                  obfuscate price or product changes from link parsing.
                  You&apos;ll have to manually fill in the details. Click below
                  to read more about link parsing.
                </AmazonNote>
              ) : (
                <>
                  <SiteBlockedText>
                    {linkDomain} is blocking access to product information.
                  </SiteBlockedText>
                  {!Capacitor.isNativePlatform() && (
                    <BetterResultsText>
                      Use the mobile iOS app for better results
                    </BetterResultsText>
                  )}

                  <AmazonNote>
                    If you&apos;re sure the URL correctly links to a product...
                    Unfortunately, some websites don&apos;t return product
                    information. Typically it&apos;s either because their
                    website isn&apos;t setup for modern web OR the website
                    intends to obfuscate price or product changes from link
                    parsing. You&apos;ll have to manually fill in the details.
                    Click below to read more about link parsing.
                  </AmazonNote>
                </>
              )
            ) : null}

            {!isLinkFetchLoading && !hideHelpfulButtons && (
              <HelpfulButtonsContainer>
                <Button
                  small
                  color='light'
                  onClick={() => handleHelpfulRating(true)}
                  iconLeft={<MdThumbUp />}
                >
                  Helpful
                </Button>

                <Button
                  small
                  color='light'
                  onClick={() => handleHelpfulRating(false)}
                  iconLeft={<MdThumbDown />}
                >
                  Not Helpful
                </Button>
              </HelpfulButtonsContainer>
            )}

            <LearnMoreButton
              small
              clear
              color='medium'
              onClick={onClickOpenLinkInfoModal}
              iconRight={helpCircleOutline}
            >
              Learn More About AutoMagic Link Parsing
            </LearnMoreButton>
          </>
        )}
      </LinkResultContainer>
    </>
  );
};
