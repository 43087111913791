import { IonText, IonToggle } from '@ionic/react';
import React, { useContext, useState } from 'react';

import { Button } from './BaseUI/Button';
import FriendsModal from '../modals/FriendsModal';
import { InputContainer } from './BaseUI/InputContainer';
import { UserBankContext } from '../contexts/UserBank';
import { oxfordJoinArray } from '../utils/oxfordJoinArray';
import { styled } from 'goober';

interface Props {
  visibleTo: string[] | undefined;
  setVisibleTo: (visibleTo: Props['visibleTo']) => void;
  modalRef: any;
}

const SelectFriendsButton = styled(Button as any)`
  margin-top: -4px;
  flex-shrink: 0;
  margin-right: -10px;
  margin-bottom: -4px;
`;

const VisibleToContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  ion-text {
    font-size: 13px;
    padding-right: 8px;
    font-weight: 700;
    letter-spacing: 0.3px;
  }
`;

export const VisibleToInput: React.FC<Props> = ({
  visibleTo,
  setVisibleTo,
  modalRef,
}) => {
  const { userBank } = useContext(UserBankContext);
  const [isFriendsModalOpen, setIsFriendsModalOpen] = useState(false);

  const toggleHidden = () => {
    setVisibleTo(visibleTo ? undefined : []);
  };

  const handleVisibleToModalDismiss = (selectedUsers: string[] | undefined) => {
    if (selectedUsers) {
      setVisibleTo(selectedUsers);
    }
    setIsFriendsModalOpen(false);
  };

  return (
    <>
      <InputContainer>
        <p>Hidden</p>
        <IonToggle
          checked={Boolean(visibleTo)}
          onClick={toggleHidden}
          mode='ios'
          color='dark'
        />
        {Boolean(visibleTo) && (
          <VisibleToContainer>
            <IonText color='medium'>
              Hidden from everyone{' '}
              {visibleTo?.length
                ? 'except ' +
                  oxfordJoinArray(
                    visibleTo
                      .map((userId) => userBank[userId]?.user?.formattedName)
                      .filter(Boolean) as string[],
                    '&',
                    'Unknown'
                  )
                : null}
            </IonText>
            <SelectFriendsButton
              color='medium'
              clear
              small
              onClick={() => setIsFriendsModalOpen(true)}
            >
              Select Friends
            </SelectFriendsButton>
          </VisibleToContainer>
        )}
      </InputContainer>
      <FriendsModal
        tipBoxText='Select friends who will be able to see the item'
        selectedFriends={visibleTo}
        isOpen={isFriendsModalOpen}
        onDidDismiss={handleVisibleToModalDismiss}
        pageRef={modalRef}
        showFollowers
      />
    </>
  );
};
