import { QuerySnapshot, Timestamp, collection, doc, onSnapshot, query, setDoc, where } from 'firebase/firestore';

import { DEFAULT_WISHLIST_CONFIG } from '../constants/constants';
import { WishlistConfig } from '../types/GlobalTypes';
import { db } from '../firebase';
import { log } from '../utils/log';

const wishlistFilterAndSortFn = (wishlists: WishlistConfig[]): WishlistConfig[] => {
  return wishlists.filter(i => !i.deletedAt).sort((a, b) => {
    if (a.createdAt.toMillis() > b.createdAt.toMillis()) {
      return 1;
    } else if (a.createdAt.toMillis() < b.createdAt.toMillis()) {
      return -1;
    } else {
      return 0;
    }
  })
}

export const wishlistCollectionObservable = (
  userId: string,
  isLoggedInUsersProfile: boolean,
  setFn: (value: React.SetStateAction<WishlistConfig[]>) => void
) => {
  const wishlistsQuery = query(collection(db,
    `users/${userId}/wishlists`
  )
    , where('deletedAt', '==', null));

  return onSnapshot(wishlistsQuery, async (snapshot) => {
    const wishlists = wishlistFilterAndSortFn((snapshot as QuerySnapshot<WishlistConfig>).docs.map((doc) => {
      const wishlistConfig = doc.data() as WishlistConfig;
      log('read', `[READ from Server] wishlistCollectionObservable: ${userId}; ${wishlistConfig.name}`)
      return wishlistConfig;
    }));

    if (wishlists.length === 0) {
      const newOriginalWishlist = {
        ...DEFAULT_WISHLIST_CONFIG,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
      };

      if (isLoggedInUsersProfile) {
        log('write', `[WRITE to Server] wishlistCollectionObservable: ${userId}; ${newOriginalWishlist.name}`);
        await setDoc(doc(db, `users/${userId}/wishlists/original`), newOriginalWishlist);
        return;
      } else {
        wishlists.push(newOriginalWishlist);
      }
    }

    setFn(wishlists);
  });
};
