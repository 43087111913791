import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import { Footer } from '../components/layout/Footer';
import { Helmet } from 'react-helmet-async';
import React from 'react';
import { StyledIonOuterToolbar } from '../components/layout/StyledOuterIonToolbar';
import { customPageAnimationHorizontally } from '../utils/pageTransition';
import { styled } from 'goober';

const TermsContainer = styled('div')`
  padding: 32px;
  max-width: 800px;
  margin: 0 auto;
  h4,
  h3,
  p {
    margin-top: 16px;
  }
`;

export const AffiliateDisclosure: React.FC = () => {
  return (
    <IonPage>
      <Helmet prioritizeSeoTags>
        <title>iFancy | Affiliate Disclosure</title>
        <meta
          property='twitter:title'
          content={'iFancy | Affiliate Disclosure'}
        />

        <meta property='og:title' content={'iFancy | Affiliate Disclosure'} />
      </Helmet>
      <IonHeader translucent={true}>
        <StyledIonOuterToolbar>
          <IonButtons slot='start'>
            <IonBackButton
              defaultHref='/'
              routerAnimation={customPageAnimationHorizontally}
              color='dark'
            />
          </IonButtons>
          <IonTitle>Affiliate Disclosure</IonTitle>
        </StyledIonOuterToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <IonHeader collapse='condense'>
          <IonToolbar>
            <IonTitle size='large'></IonTitle>
          </IonToolbar>
        </IonHeader>
        <TermsContainer>
          <h2>Affiliate Disclosure</h2>
          <p>
            iFancy is a free to use application, however, there are costs to
            maintain, host, and serve the application and website. We are able
            to pay for these costs by utilizing affiliate links to generate a
            small percentage of revenue from each purchase made through iFancy.
          </p>
          <h3>Monetization of Web Links</h3>
          <p>
            Some links from iFancy to third-party e-commerce sites are monetized
            by third-party services. iFancy receives “credit” for its part in
            helping match the buyer with the right item from the right seller.
            This monetization is automatic and transparent and does not affect
            the item price. This disclosure is provided in accordance with the
            United States Federal Trade Commission’s 16 CFR § 255.5
          </p>
          <h3>Associate Disclosure</h3>
          <p>
            iFancy is a participant in the Amazon Services LLC Associates
            Program, an affiliate advertising program designed to provide a
            means for sites to earn advertising fees by advertising and linking
            to amazon.com, amazon.co.uk, amazon.ca, amazon.it, amazon.de,
            amazon.fr, amazon.co.au. As an Amazon Associate we earn from
            qualifying purchases.
          </p>
          <p>
            CERTAIN CONTENT THAT APPEARS ON THIS SITE COMES FROM AMAZON SERVICES
            LLC. THIS CONTENT IS PROVIDED ‘AS IS’ AND IS SUBJECT TO CHANGE OR
            REMOVAL AT ANY TIME.
          </p>
        </TermsContainer>
        <Footer type='full' />
      </IonContent>
    </IonPage>
  );
};
