import { analytics, auth } from '../firebase';

import { Capacitor } from '@capacitor/core';
import { FeedbackType } from '../modals/FeedbackModal';
import { getPlatforms } from '@ionic/react';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

declare global {
    interface Window {
        tidioChatApi?: {
            hide: () => void;
            on: (event: string, callback: (e?: any) => void) => void;
            show: () => void;
            open: () => void;
            setVisitorData: (data: {
                email?: string | undefined;
                phone?: string | undefined;
                name: string | undefined;
                distinct_id: string | undefined;
                tags: string[];
            }) => void;
            setContactProperties: (data: {
                userid: string;
            }) => void;
            messageFromOperator: (text: string) => void;
            messageFromVisitor: (text: string) => void;
        };
    }
}

export const useTidioControls = () => {
    const { loggedInUser } = useLoggedInUser();

    useEffect(() => {
        function onTidioChatApiReady() {
            window.tidioChatApi?.on('messageFromOperator', () => {
                window.tidioChatApi?.show();
                window.tidioChatApi?.open();
                localStorage.setItem(
                    'ifancy-support-chat-last-triggered',
                    new Date().toISOString()
                );
            });
            window?.tidioChatApi?.setVisitorData({
                distinct_id: loggedInUser?.id,
                name: loggedInUser ? loggedInUser?.first_name + ' ' + loggedInUser?.last_name : undefined,
                tags: [(Capacitor.isNativePlatform() ? 'native' : null), ...getPlatforms(), Capacitor.getPlatform()].filter(i => typeof i === 'string'),
                email: auth.currentUser?.email || undefined,
                phone: auth.currentUser?.phoneNumber || undefined,
            });

            window?.tidioChatApi?.setContactProperties({
                userid: loggedInUser?.id || 'Not logged in',
            });

        }

        if (window.tidioChatApi?.setVisitorData) {
            onTidioChatApiReady();
        } else {
            document.addEventListener('tidioChat-ready', onTidioChatApiReady);
        }

        return () => {
            document.removeEventListener('tidioChat-ready', onTidioChatApiReady);
        }
    }, [loggedInUser]);

    useEffect(() => {
        function onTidioChatApiReady() {
            window.tidioChatApi?.hide();
            window.tidioChatApi?.on('close', function () {
                window.tidioChatApi?.hide();
            });
        }

        if (window?.tidioChatApi) {
            window.tidioChatApi.on('ready', onTidioChatApiReady);
        } else {
            document.addEventListener('tidioChat-ready', onTidioChatApiReady);
        }

        return () => {
            document.removeEventListener('tidioChat-ready', onTidioChatApiReady);
        }
    }, [])

};



export const getDaysSinceLastChat = () => {
    const lastTriggered = localStorage.getItem(
        'ifancy-support-chat-last-triggered'
    );

    if (lastTriggered) {
        const lastTriggeredDate = new Date(lastTriggered);
        const now = new Date();
        const diff = now.getTime() - lastTriggeredDate.getTime();
        const diffInDays = diff / (1000 * 60 * 60 * 24);

        return diffInDays;
    }
}


export const handleOpenTidioChat = async (type: FeedbackType | 'auth-help') => {

    const lastOpenedInDays = getDaysSinceLastChat();

    logEvent(analytics, 'chat_init', { type });
    if (type === 'auth-help') {
        logEvent(analytics, 'chat_init_auth_help');
    }
    if (!window.tidioChatApi) {
        logEvent(analytics, 'chat_unavailable_old_form_opened');
        throw new Error('Tidio chat not available');
    }
    localStorage.setItem(
        'ifancy-support-chat-last-triggered',
        new Date().toISOString()
    );
    window.tidioChatApi.show();
    window.tidioChatApi.open();

    if ((lastOpenedInDays || 100) * 24 * 60 > 5) {
        if (type === 'bug') {
            window.tidioChatApi.messageFromVisitor('I found a bug');
            await new Promise((resolve) => setTimeout(resolve, 2000));
            window.tidioChatApi.messageFromOperator(
                'Oh no! Please describe the bug you found'
            );
        } else if (type === 'feature') {
            window.tidioChatApi.messageFromVisitor('I have a feature request');
            await new Promise((resolve) => setTimeout(resolve, 2000));
            window.tidioChatApi.messageFromOperator(
                'Awesome! What feature would you like to see?'
            );
        } else {
            window.tidioChatApi.messageFromVisitor('I need help with something');
            await new Promise((resolve) => setTimeout(resolve, 2000));
            window.tidioChatApi.messageFromOperator('What can we help you with?');
        }
    }

}

