import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  useIonRouter,
} from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';

import { AuthenticationContext } from '../contexts/AuthenticationContext';
import { Button } from './BaseUI/Button';
import { ErrorMessageText } from './ErrorMessageText';
import { RELATIONSHIP_DEFINITIONS } from '../constants/relationshipDefinitions';
import { User } from '../types/GlobalTypes';
import { UserBankContext } from '../contexts/UserBank';
import { UserListItem } from './UserListItem';
import { customPageAnimationHorizontally } from '../utils/pageTransition';
import { db } from '../firebase';
import { differenceInMilliseconds } from 'date-fns';
import { log } from '../utils/log';
import { search } from 'ionicons/icons';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';

const RelationshipDictionary = styled('div')`
  margin: 18px;
  padding: 18px;
  background: var(--ion-color-light);
  border-radius: 12px;
  p {
    font-size: 14px;
    letter-spacing: 0.2px;
    text-indent: -36px;
    margin-left: 36px;
  }
`;

const StyledIonList = styled(IonList)`
  contain: none;
`;

interface Props {
  selected?: string[] | undefined;
  onSelect?: (userId: string) => void;
  userId: string | undefined;
  showFollowers?: boolean;
  isViewingUserFriends?: boolean;
  showDictionary?: boolean;
  showSelf?: boolean;
  showSearchForFriends?: boolean;
}

export const FriendsList: React.FC<Props> = ({
  isViewingUserFriends,
  onSelect,
  selected,
  showDictionary,
  showFollowers,
  userId,
  showSelf,
  showSearchForFriends,
}) => {
  const { userBank } = useContext(UserBankContext);
  const { loggedInUserId } = useContext(AuthenticationContext);
  const { loggedInUser } = useLoggedInUser();
  const ionRouter = useIonRouter();
  const [followers, setFollowers] = useState<string[]>();

  const hasLoaded = useRef<boolean>(false);
  const loadingRef = useRef<{ toastId: string; ts: Date } | undefined>();
  const user = userId
    ? userId === loggedInUserId
      ? loggedInUser
      : userBank[userId]?.user
    : undefined;

  useEffect(() => {
    let cancelLoadingTimeout: any;
    // check that all users have loaded
    let hasAllUsersLoaded = true;
    const allUsersToLoad = [...(user?.friends || []), ...(followers || [])];
    for (const friendId of allUsersToLoad) {
      if (!userBank[friendId]) {
        hasAllUsersLoaded = false;
        break;
      }
    }

    if (!hasAllUsersLoaded) {
      if (hasLoaded.current) return;
      hasLoaded.current = true;
      loadingRef.current = {
        toastId: toast.loading('Loading Friends', { duration: 3000 }),
        ts: new Date(),
      };
    } else {
      if (loadingRef?.current?.toastId) {
        const minimumLoadingTimeout = 1000;
        const timeElapsed = Math.abs(
          differenceInMilliseconds(new Date(), loadingRef.current.ts)
        );
        if (timeElapsed < minimumLoadingTimeout) {
          cancelLoadingTimeout = setTimeout(() => {
            toast.dismiss(loadingRef?.current?.toastId);
            loadingRef.current = undefined;
          }, minimumLoadingTimeout - timeElapsed);
        }
      }
    }

    return () => {
      clearTimeout(cancelLoadingTimeout);
    };
  }, [userBank, user, followers]);

  const mountedRef = useRef<boolean>(true);
  const alreadyRunningFollowersQuery = useRef<boolean>(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const user = userId
      ? userId === loggedInUserId
        ? loggedInUser
        : userBank[userId]?.user
      : undefined;
    if (user && showFollowers && !alreadyRunningFollowersQuery.current) {
      alreadyRunningFollowersQuery.current = true;
      const getFollowers = async () => {
        log('read', '[Query]', 'getFollowers');
        const querySnap = await getDocs(
          query(
            collection(db, 'users'),
            where('friends', 'array-contains', userId)
          )
        );

        if (!mountedRef.current) return;

        setFollowers(querySnap.docs.map((item) => item.id));
      };

      getFollowers();
    }
  }, [showFollowers, userBank, userId, loggedInUserId, loggedInUser]);

  useEffect(() => {
    alreadyRunningFollowersQuery.current = false;
  }, [userId, loggedInUserId]);

  if (!user) return null;

  const goToSearchPage = () => {
    ionRouter.push(
      '/search',
      'forward',
      'push',
      {},
      customPageAnimationHorizontally
    );
  };

  if (
    (!user?.friends || user?.friends.length === 0) &&
    (!followers || followers.length === 0)
  ) {
    return (
      <ErrorMessageText>
        <p>
          No friends found{' '}
          <span role='img' aria-label='sad face'>
            😕
          </span>
        </p>
        <Button onClick={goToSearchPage} color='primary' iconRight={search}>
          Search for a friend
        </Button>
      </ErrorMessageText>
    );
  }

  const handleClick = (friend: User) => {
    if (!friend.id) return;
    if (selected) {
      if (onSelect) {
        onSelect(friend.id);
      }
    } else {
      ionRouter.push(
        `/user/${friend.id}`,
        'forward',
        'push',
        {
          prevPage: isViewingUserFriends
            ? `${user.first_name}'s Friends`
            : 'Friends',
        } as any,
        customPageAnimationHorizontally
      );
    }
  };

  return (
    <StyledIonList>
      {showSearchForFriends && (
        <IonItem
          style={{
            borderRadius: 20,
            '--inner-border-width': 0,
            marginLeft: 4,
            marginRight: 4,
          }}
          color='light'
          onClick={goToSearchPage}
          button
        >
          <IonLabel>Search for Friends</IonLabel>
          <IonIcon icon={search} slot='start' />
        </IonItem>
      )}
      {showSelf && loggedInUser?.id && (
        <UserListItem
          key={loggedInUser.id}
          showRelationship={false}
          userOrUserId={{ ...loggedInUser, formattedName: 'My Profile' }}
          onClick={handleClick}
          selected={selected}
        />
      )}
      {user?.friends
        ?.sort((a, b) => {
          const userA = userBank[a]?.user;
          const userB = userBank[b]?.user;
          if (!userA?.formattedName || !userB?.formattedName) return 0;
          if (
            userA.formattedName.toLowerCase() <
            userB.formattedName.toLowerCase()
          )
            return -1;
          if (
            userA.formattedName.toLowerCase() >
            userB.formattedName.toLowerCase()
          )
            return 1;
          return 0;
        })
        .map((userId) => (
          <UserListItem
            key={userId}
            showRelationship={user?.id === loggedInUserId}
            userOrUserId={userId}
            onClick={handleClick}
            selected={selected}
          />
        ))}
      {showFollowers && (
        <>
          {followers
            ?.filter(
              (followerId) =>
                !user?.friends?.length || !user?.friends?.includes(followerId)
            )
            .map((userId) => (
              <UserListItem
                key={userId}
                userOrUserId={userId}
                showRelationship={user?.id === loggedInUserId}
                onClick={handleClick}
                selected={selected}
              />
            ))}
          {showDictionary && (
            <RelationshipDictionary>
              {Object.entries(RELATIONSHIP_DEFINITIONS).map(([key, item]) => (
                <p key={key}>
                  <span role='img' aria-label={item.alt}>
                    {item.emoji}
                  </span>{' '}
                  = {item.definition}
                </p>
              ))}
            </RelationshipDictionary>
          )}
        </>
      )}
    </StyledIonList>
  );
};
