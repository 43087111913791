import React, { useState } from 'react';

import { Button } from '../BaseUI/Button';
import Color from 'color';
import { DEFAULT_PROFILE_COLOR } from '../../constants/constants';
import { IonPopover } from '@ionic/react';
import { ProfileMenuActionButton } from './ProfileActionButtons';
import { SketchPicker } from 'react-color';
import { User } from '../../types/GlobalTypes';
import { analytics } from '../../firebase';
import { handleCatchError } from '../../utils/handleCatchError';
import { logEvent } from 'firebase/analytics';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';
import { useProfilePageContext } from '../../contexts/ProfilePageContext';

interface Props {
  user: User | undefined;
  profileColorValue: string;
  setProfileColorValue: (value: string) => void;
}

const PopoverContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .sketch-picker {
    box-shadow: none !important;
    input {
      border-radius: 0;
    }
  }
  button {
    align-self: stretch;
    margin: 10px;
    margin-top: 0;
  }
`;

const ProfileColorSwatch = styled('div')<{ color: string }>`
  border-radius: 4px;
  border: 1px solid #8f8f8f;
  width: 21px;
  height: 21px;
  margin-right: 10px;
  background: ${({ color }) => color};
`;

export const EditProfileColorButton: React.FC<Props> = ({
  user,
  profileColorValue,
  setProfileColorValue,
}) => {
  const [isProfileColorLoading, setIsProfileColorLoading] = useState(false);
  const [profileColorPopupEvent, setProfileColorPopupEvent] = useState<any>();
  const { loggedInUser, updateLoggedInUser } = useLoggedInUser();
  const { isLoggedInUsersProfile, isNonAuthedProfile } =
    useProfilePageContext();
  const profileColor = Color(profileColorValue);

  if (!loggedInUser?.id && !isNonAuthedProfile) return null;

  if (!isLoggedInUsersProfile) return null;

  const saveColor = async () => {
    try {
      logEvent(analytics, 'change_profile_color');
      setIsProfileColorLoading(true);
      if (!user?.id) throw new Error('User not found');
      if (user.id !== loggedInUser?.id) throw new Error('Not Allowed');
      await updateLoggedInUser({ profileColor: profileColorValue });
      setProfileColorPopupEvent(undefined);
      toast.success('Profile Updated');
    } catch (err) {
      handleCatchError(err);
    } finally {
      setIsProfileColorLoading(false);
    }
  };

  const handleOpenColorPicker = (e: any) => {
    e.persist();
    setProfileColorPopupEvent(e);
  };

  const handleProfileColorPopoverDismiss = () => {
    setProfileColorPopupEvent(undefined);
    setProfileColorValue(user?.profileColor || DEFAULT_PROFILE_COLOR);
  };

  return (
    <>
      <IonPopover
        showBackdrop={false}
        mode='md'
        className='color-picker-popover'
        event={profileColorPopupEvent}
        isOpen={profileColorPopupEvent ? true : false}
        onDidDismiss={handleProfileColorPopoverDismiss}
      >
        <PopoverContainer>
          <SketchPicker
            color={profileColorValue}
            onChangeComplete={(color) => setProfileColorValue(color.hex)}
          />
          <Button
            isLoading={isProfileColorLoading}
            disabled={isProfileColorLoading}
            color='dark'
            onClick={saveColor}
          >
            Save
          </Button>
        </PopoverContainer>
      </IonPopover>
      <ProfileMenuActionButton
        iconLeft={<ProfileColorSwatch color={profileColor.hex()} />}
        color='medium'
        clear
        disabled={!user}
        onClick={handleOpenColorPicker}
      >
        Profile Color
      </ProfileMenuActionButton>
    </>
  );
};
