import {
  SelectOption,
  SelectPopoverButton,
} from '../BaseUI/SelectPopoverButton';
import {
  WishlistOptionButton,
  WishlistOptionButtonSmall,
} from './ProfileActionButtons';

import { Button } from '../BaseUI/Button';
import { CreateNewWishlistButton } from './CreateNewWishlistButton';
import { Emoji } from '../Emoji';
import { MdOutlineErrorOutline } from 'react-icons/md';
import React from 'react';
import { WISHLIST_VISIBILITY_OPTIONS } from '../../constants/constants';
import { WishlistConfig } from '../../types/GlobalTypes';
import { styled } from 'goober';
import useMediaQuery from '../../hooks/useMediaQuery';

interface Props {
  onSelect: (wishlist: WishlistConfig) => void;
  showAddNewButton?: boolean;
  selectedWishlist?: WishlistConfig;
  large?: boolean;
  className?: string;
  wishlists: WishlistConfig[];
  isDemo?: boolean;
}

const SelectedWishlistButtonBase = styled(Button as any)`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
  max-width: 580px;
  margin-left: 0;
  .btn-text {
    flex: 1;
    text-align: left;
    font-weight: 900;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 17px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow-x: clip;
    margin-left: -2px;
    line-height: 100%;
    max-width: calc(100% - 54px);
  }

  &.no-wishlists {
    .btn-text {
      color: var(--ion-color-medium);
    }
    > svg {
      color: var(--ion-color-danger);
      width: 26px;
      height: 26px;
      margin-right: 8px;
    }
  }

  @media (max-width: 768px) {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 10px;
    padding-right: 8px;
    max-width: calc(100% - 110px);
    .btn-text {
      font-size: 16px;
    }
  }
  @media (max-width: 368px) {
    .btn-text {
      font-size: 15px;
    }
  }
  > span:last-of-type {
    font-size: 17px;
    margin-left: 8px;
    opacity: 0.3;
    margin-top: -4px;
    margin-bottom: -5px;
    pointer-events: none;
    @media (max-width: 468px) {
      margin-left: 8px;
      font-size: 18px;
    }
  }
  &:hover {
    > span:last-of-type {
      opacity: 0.8;
    }
  }
`;

const SelectedWishlistButtonLarge = styled(SelectedWishlistButtonBase)`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 10px;
  .btn-text {
    font-size: 26px;
  }

  @media (max-width: 768px) {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 10px;
    padding-right: 8px;
    max-width: calc(100% - 110px);
    .btn-text {
      font-size: 20px;
    }
  }
  @media (max-width: 368px) {
    .btn-text {
      font-size: 18px;
    }
  }
  > span:last-of-type {
    font-size: 22px;
    margin-left: 10px;
    margin-top: -5px;
    margin-bottom: -5px;
    @media (max-width: 468px) {
      margin-left: 8px;
      font-size: 18px;
    }
  }
`;

const EmojiIcon = styled('span')`
  margin-right: 8px;
  margin-top: -13px;
  margin-bottom: -19px;
  pointer-events: none;
  @media (max-width: 468px) {
    margin-right: 8px;
  }
`;

export const WishlistsSelectButton: React.FC<Props> = ({
  onSelect,
  showAddNewButton,
  selectedWishlist,
  large,
  wishlists,
  className,
  isDemo,
}) => {
  const screenLessThan468 = useMediaQuery('(max-width: 468px)');

  const handleSelectWishlist = (newValue: SelectOption) => {
    const wishlist = wishlists.find((i) => i.id === newValue.value);
    if (wishlist) {
      onSelect(wishlist);
    }
  };

  if (!wishlists || wishlists.length === 0) {
    const Component = large
      ? SelectedWishlistButtonLarge
      : SelectedWishlistButtonBase;
    return (
      <Component
        className={'no-wishlists ' + className}
        disabled
        iconLeft={<MdOutlineErrorOutline />}
        color='light-100'
      >
        No Wishlists Found
      </Component>
    );
  }

  return (
    <SelectPopoverButton
      className={className}
      CustomStyledSelectButton={
        large ? SelectedWishlistButtonLarge : SelectedWishlistButtonBase
      }
      CustomStyledSelectOptionButton={
        large ? WishlistOptionButton : WishlistOptionButtonSmall
      }
      showBackdrop
      value={selectedWishlist?.id}
      onChange={handleSelectWishlist}
      options={wishlists.map((wishlist) => ({
        value: wishlist.id,
        name: wishlist.name,
        iconRight: WISHLIST_VISIBILITY_OPTIONS.find(
          (i) => i.value === wishlist.visibility
        )?.icon,
        icon: (
          <EmojiIcon>
            <Emoji
              emoji={wishlist.emoji}
              size={
                large
                  ? screenLessThan468
                    ? 22
                    : 28
                  : screenLessThan468
                  ? 18
                  : 20
              }
            />
          </EmojiIcon>
        ),
      }))}
      AddNewButton={
        showAddNewButton ? (
          <CreateNewWishlistButton isDemo={isDemo} large={Boolean(large)} />
        ) : undefined
      }
    />
  );
};
