import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonRouter,
  useIonViewDidEnter,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';

import { AuthenticationContext } from '../contexts/AuthenticationContext';
import { Capacitor } from '@capacitor/core';
import { DEFAULT_PAGE_TITLE } from '../constants/seo';
import { Helmet } from 'react-helmet-async';
import { Keyboard } from '@capacitor/keyboard';
import { PageWrapper } from '../components/layout/PageWrapper';
import { StyledIonOuterToolbar } from '../components/layout/StyledOuterIonToolbar';
import { User } from '../types/GlobalTypes';
import { UserListItem } from '../components/UserListItem';
import algoliasearch from 'algoliasearch';
import { analytics } from '../firebase';
import { customPageAnimationHorizontally } from '../utils/pageTransition';
import { getFormattedName } from '../utils/transformers/getFormattedName';
import { handleCatchError } from '../utils/handleCatchError';
import { logEvent } from 'firebase/analytics';
import { styled } from 'goober';
import { useKeyboardResizeNonePaddingHeight } from '../hooks/useKeyboardOpenHeight';

const StyledIonTitle = styled(IonTitle)``;

const StyledPageWrapper = styled(PageWrapper as any)<{ paddingBottom: number }>`
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
  .footer {
    ${({ paddingBottom }) =>
      paddingBottom !== 0 ? `padding-bottom: 13px !important;` : ''}
  }
`;

const ShowingResultsText = styled('p')`
  padding: 8px 18px;
  color: var(--ion-color-medium);
`;

const searchClient = algoliasearch(
  import.meta.env.VITE_ALGOLIA_APP_ID || '',
  import.meta.env.VITE_ALGOLIA_SEARCH_KEY || ''
);

const index = searchClient.initIndex('users');

export const Search: React.FC = () => {
  const { loggedInUserId } = useContext(AuthenticationContext);

  const [users, setUsers] = useState<User[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const ionRouter = useIonRouter();

  const searchbarRef = React.useRef<HTMLIonSearchbarElement>(null);

  const keyboardBottomPadding = useKeyboardResizeNonePaddingHeight();

  useIonViewDidEnter(() => {
    if (searchbarRef.current) {
      searchbarRef.current.setFocus();
    }
  });

  useEffect(() => {
    let abort = false;
    const search = async (str: string) => {
      try {
        if (!str || str.length < 2) {
          setUsers([]);
          return;
        }
        logEvent(analytics, 'search', { search_term: str });
        const results = await index.search(str, {
          facetFilters: 'hideFromSearch:-true',
        });

        if (abort) return;
        setUsers(
          results.hits
            .map((item: any) => ({
              ...item,
              id: item.objectID,
              formattedName: getFormattedName(item.first_name, item.last_name),
            }))
            .filter((i) => i.id !== loggedInUserId) as User[]
        );
      } catch (err) {
        handleCatchError(err);
      }
    };
    search(searchValue);

    return () => {
      abort = true;
    };
  }, [loggedInUserId, searchValue]);

  const handleClick = (user: User) => {
    if (!user.id) return;

    ionRouter.push(
      `/user/${user.id}`,
      'forward',
      'push',
      { prevPage: `Search` } as any,
      customPageAnimationHorizontally
    );
  };

  useIonViewDidEnter(() => {
    if (ionRouter.routeInfo.routeOptions?.query) {
      if (typeof ionRouter.routeInfo.routeOptions.query === 'string') {
        setSearchValue(ionRouter.routeInfo.routeOptions.query);
      }
    }
  }, [ionRouter]);

  return (
    <IonPage>
      <Helmet prioritizeSeoTags>
        <title>{DEFAULT_PAGE_TITLE}</title>
        <meta property='og:title' content={DEFAULT_PAGE_TITLE} />
      </Helmet>
      <IonHeader translucent={true}>
        <StyledIonOuterToolbar>
          <IonTitle>Search</IonTitle>
        </StyledIonOuterToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <StyledPageWrapper
          paddingBottom={keyboardBottomPadding}
          footerType='minimal'
          hideDisclaimer
          maxWidth
        >
          <IonHeader mode='ios' collapse='condense'>
            <IonToolbar>
              <StyledIonTitle size='large'>Search</StyledIonTitle>
            </IonToolbar>
            <IonToolbar mode='ios'>
              <IonSearchbar
                value={searchValue}
                ref={searchbarRef}
                debounce={400}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    Capacitor.isNativePlatform() && Keyboard.hide();
                  }
                }}
                onIonInput={(e) => setSearchValue(e.detail.value || '')}
              />
            </IonToolbar>
          </IonHeader>
          {searchValue && searchValue.length > 1 && (
            <ShowingResultsText>
              Showing search results for &quot;{searchValue}&quot;
            </ShowingResultsText>
          )}
          <IonList>
            {users.map((user) => (
              <UserListItem
                key={user.id}
                userOrUserId={user}
                onClick={handleClick}
              />
            ))}
          </IonList>
        </StyledPageWrapper>
      </IonContent>
    </IonPage>
  );
};
