import { FaUserAstronaut, FaUsers } from 'react-icons/fa';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { IoNotifications, IoSearch } from 'react-icons/io5';
import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { add, home } from 'ionicons/icons';
import {
  customPageAnimationTabLeft,
  customPageAnimationTabRight,
} from '../../utils/pageTransition';
import { matchPath, useLocation } from 'react-router-dom';

import { BaseAvatar } from '../BaseUI/BaseAvatar';
import { Button } from '../BaseUI/Button';
import { Capacitor } from '@capacitor/core';
import Logo from '../../assets/imgs/logo_new.svg';
import { NotificationBadge } from '../NotificationBadge';
import { SearchInput } from '../SearchInput';
import { SwitchToAppButton } from '../SwitchToAppButton';
import { WishlistItemModalContext } from '../../contexts/WishlistItemModalContext';
import { styled } from 'goober';
import { useIonRouter } from '@ionic/react';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';

interface Props {}

const StyledBaseAvatar = styled(BaseAvatar as any)`
  --image-border: 2px;
  --image-size: 30px;
  --border-radius: 90px;
  margin-top: -10px;
  margin-bottom: -10px;
`;

const TabbarWrapper = styled('div')`
  position: fixed;
  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom);
  left: 0;
  display: flex;
  flex-direction: column;
  right: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999;
  transition: transform 0.35s ease;
  @media (min-width: 769px) {
    bottom: auto;
    top: 0;
    padding-bottom: 0;
    padding-top: env(safe-area-inset-top);
    &.auth {
      transform: translateY(-200px);
    }
  }
`;

const TabbarContainer = styled('div')`
  background: rgba(255, 255, 255, 0.7);
  box-shadow: var(--box-shadow-md);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  width: 100%;
  max-width: 1200px;
  margin-bottom: 10px;
  border-radius: 16px;
  display: flex;
  padding-right: 46px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 4px;
  position: relative;
  transition: transform 0.15s ease;
  @media (max-width: 768px) {
    &.auth {
      transform: translateY(300px);
    }
  }
  @media (min-width: 408px) {
    padding-right: 50px;
  }
  @media (min-width: 768px) {
    padding-right: 6px;
    padding-left: 6px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .tab-line::after {
    content: '';
    position: absolute;
    top: 3px;
    left: -3px;
    bottom: 3px;
    width: 1px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.03);
  }

  .tab-line:first-of-type::after {
    opacity: 0;
  }

  .tab-line.active + .tab-line::after {
    opacity: 0;
  }
  .tab-line.active::after {
    opacity: 0;
  }
`;

const TextContainer = styled('div', forwardRef)`
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  transition: all 0.15s 0s;
  transform: translateX(-10px);
  max-width: 0;

  @media (min-width: 1090px) {
    max-width: 300px;
    opacity: 1;
    margin-left: 8px;
    transform: translateX(0);
  }
  &.active {
    margin-left: 4px;
    @media (min-width: 408px) {
      margin-left: 8px;
    }
    opacity: 1;
    transition: all 0.15s 0.05s;
    transform: translateX(0);
    max-width: 300px;
  }
`;

const TabbarButton = styled('button')`
  flex: 1;
  display: flex;
  margin-right: 2px;
  margin-left: 2px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 4px;
  padding-left: 4px;
  max-width: 200px;
  @media (min-width: 368px) {
    padding-left: 12px;
    padding-right: 12px;
  }
  @media (min-width: 408px) {
    padding-right: 14px;
    padding-left: 14px;
  }
  @media (min-width: 768px) {
    &.no-auth {
      display: none;
    }
  }
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid transparent;
  transition: all 0.1s;
  position: relative;
  color: #2e2e2e;
  background: transparent;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: rgba(255, 255, 255, 1);
    }
  }

  &.active {
    transition: all 0.1s;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #e3e3e3;
    color: #151515;
  }
`;

const AddItemButton = styled(Button as any)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 6px;
  top: 6px;
  .btn-text {
    display: none;
    white-space: nowrap;
  }
  .btn-icon {
    padding-right: 0;
    font-size: 26px;
  }
  &.no-auth {
    .btn-icon {
      font-size: 17px;
    }
  }
  @media (min-width: 768px) {
    .btn-text {
      display: flex;
    }
    .btn-icon {
      padding-right: 6px;
      font-size: 20px;
    }
    border-radius: 14px;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    position: relative;
    width: auto;
    height: auto;
    margin-left: 8px;
    &.no-auth {
      display: none;
    }
  }
`;

const IconContainer = styled('div')`
  font-size: 22px;
  color: inherit;
  position: relative;
  display: flex;
`;

const LogoContainer = styled('a')`
  display: none;
  width: 75px;
  margin-top: -4px;
  margin-bottom: -4px;
  margin-right: 10px;
  margin-left: 10px;
  align-self: center;
  transform: translateY(3px);
  aspect-ratio: 800/329;
  flex-shrink: 0;
  cursor: pointer;
  img {
    width: auto;
    height: auto;
  }
  @media (min-width: 768px) {
    display: flex;
  }
  @media (min-width: 968px) {
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const SearchbarContainer = styled('div')`
  display: none;
  margin-right: 10px;
  margin-left: 10px;
  flex: 1;
  justify-content: flex-start;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const AuthButtonsContainer = styled('div')`
  display: none;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  button {
    margin-left: 4px;
    margin-right: 4px;
  }
  button:last-of-type {
    margin-right: 0;
  }
  @media (min-width: 768px) {
    &.no-auth {
      display: flex;
    }
  }
`;

const TabOrder = ['/profile', '/friends', '/search', '/notifications'];

const StyledSwitchToAppButton = styled(SwitchToAppButton)`
  margin-bottom: 6px;
  max-width: 310px;
  width: 100%;
  pointer-events: auto;
  .btn-text {
    font-size: 12px;
  }
  .btn-icon {
    font-size: 18px;
  }
`;

export const isUserProfileRoot = (pathname: string) => {
  const profileTabs = [`/user/:userId`, `/user/:userId/wishlists/:wishlistId`];
  const matchingPath = profileTabs.find((tab) =>
    matchPath(pathname, { path: tab, exact: true, strict: true })
  );
  return matchingPath;
};

const isMyProfileTabRoot = (pathname: string, loggedInUserId: string) => {
  const profileTabs = [
    '/empty-profile',
    `/user/${loggedInUserId}`,
    `/user/${loggedInUserId}/wishlists/:wishlistId`,
    `/user/${loggedInUserId}/archive`,
    `/user/${loggedInUserId}/wishlists/:wishlistId/archive`,
  ];
  const matchingPath = profileTabs.find((tab) =>
    matchPath(pathname, { path: tab, exact: true, strict: true })
  );
  return matchingPath;
};

const Tabbar: React.FC<Props> = () => {
  const [tabStackActivated, setTabStackActivated] = useState<
    undefined | 'search' | 'friends'
  >(undefined);
  const [searchTabLastKnownPage, setSearchTabLastKnownPage] = useState('');
  const [friendsTabLastKnownPage, setFriendsTabLastKnownPage] = useState('');

  const { loggedInUser } = useLoggedInUser();
  const { openWishlistItemModal } = useContext(WishlistItemModalContext);

  const location = useLocation();
  const ionRouter = useIonRouter();

  useEffect(() => {
    if (ionRouter.routeInfo.routeOptions?.tabStack === 'friends') {
      setTabStackActivated(ionRouter.routeInfo.routeOptions.tabStack);
      setFriendsTabLastKnownPage(ionRouter.routeInfo.pathname);
    } else if (ionRouter.routeInfo.routeOptions?.tabStack === 'search') {
      setTabStackActivated(ionRouter.routeInfo.routeOptions.tabStack);
      setSearchTabLastKnownPage(ionRouter.routeInfo.pathname);
    }
    if (tabStackActivated) {
      if (
        isMyProfileTabRoot(
          ionRouter.routeInfo.pathname,
          loggedInUser?.id || ''
        ) ||
        ionRouter.routeInfo.pathname === '/notifications' ||
        ionRouter.routeInfo.pathname === '/settings'
      ) {
        setTabStackActivated(undefined);
      } else if (isUserProfileRoot(ionRouter.routeInfo.pathname)) {
        if (tabStackActivated === 'search') {
          setSearchTabLastKnownPage(ionRouter.routeInfo.pathname);
        } else if (tabStackActivated === 'friends') {
          setFriendsTabLastKnownPage(ionRouter.routeInfo.pathname);
        }
      }
    }

    if (ionRouter.routeInfo.pathname === '/search') {
      setSearchTabLastKnownPage('');
      if (tabStackActivated !== 'search') {
        setTabStackActivated('search');
      }
    }
    if (ionRouter.routeInfo.pathname === '/friends') {
      setFriendsTabLastKnownPage('');
      if (tabStackActivated !== 'friends') {
        setTabStackActivated('friends');
      }
    }
  }, [ionRouter, tabStackActivated, loggedInUser]);

  const goToHomepage = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    ionRouter.push('/home', 'forward', 'push', {}, customPageAnimationTabLeft);
  };

  const handleTabClick = (tabUrl: (typeof TabOrder)[number]) => {
    if (tabUrl === location.pathname) return;
    if (Capacitor.isNativePlatform()) {
      Haptics.impact({ style: ImpactStyle.Light });
    }

    const comingFromUrl =
      tabStackActivated === 'search'
        ? '/search'
        : tabStackActivated === 'friends'
        ? '/friends'
        : isMyProfileTabRoot(location.pathname, loggedInUser?.id || '')
        ? '/profile'
        : location.pathname;

    const indexOfComingFrom = TabOrder.indexOf(comingFromUrl);
    const indexOfTabUrl = TabOrder.indexOf(tabUrl);

    const customAnimationToUse =
      indexOfComingFrom === -1 || indexOfComingFrom === indexOfTabUrl
        ? customPageAnimationTabLeft
        : indexOfComingFrom < indexOfTabUrl
        ? customPageAnimationTabRight
        : customPageAnimationTabLeft;

    if (Capacitor.isNativePlatform()) {
      const nowActiveTab =
        tabUrl === '/search'
          ? 'search'
          : tabUrl === '/friends'
          ? 'friends'
          : undefined;
      setTabStackActivated(nowActiveTab);
      let modifiedTabUrl =
        nowActiveTab === 'search' && searchTabLastKnownPage
          ? searchTabLastKnownPage
          : nowActiveTab === 'friends' && friendsTabLastKnownPage
          ? friendsTabLastKnownPage
          : tabUrl;

      if (modifiedTabUrl === location.pathname) {
        // user is clicking on tab that is already active
        modifiedTabUrl = tabUrl;
      }

      ionRouter.push(
        modifiedTabUrl,
        'forward',
        'push',
        (nowActiveTab === 'search'
          ? { prevPage: `Search` }
          : nowActiveTab === 'friends'
          ? { prevPage: `Friends` }
          : {}) as any,
        customAnimationToUse
      );
    } else {
      ionRouter.push(tabUrl, 'forward', 'push', {}, customAnimationToUse);
    }
  };

  const openAddItemModal = () => {
    if (!loggedInUser) {
      ionRouter.push(
        '/home',
        'forward',
        'push',
        {},
        customPageAnimationTabRight
      );
    } else {
      openWishlistItemModal({
        currentWishlist: undefined,
        isSuggestion: false,
        userId: loggedInUser?.id,
        item: undefined,
        readonly: false,
        pageRef: undefined,
      });
    }
  };

  const gettingOGImage = new URLSearchParams(window?.location?.search).has(
    'ogimage'
  );

  if (gettingOGImage) return null;

  return (
    <TabbarWrapper
      className={ionRouter.routeInfo.pathname.startsWith('/auth') ? 'auth' : ''}
    >
      <StyledSwitchToAppButton />
      <TabbarContainer
        className={
          ionRouter.routeInfo.pathname.startsWith('/auth') ? 'auth' : ''
        }
      >
        <LogoContainer href='/home' onClick={goToHomepage}>
          <img src={Logo} alt='iFancy Logo' width={75} height={31} />
        </LogoContainer>
        <SearchbarContainer>
          <SearchInput />
        </SearchbarContainer>
        <AuthButtonsContainer className={loggedInUser ? '' : 'no-auth'}>
          <Button color='dark' clear onClick={() => handleTabClick('/auth')}>
            Login
          </Button>
          <Button color='dark' onClick={() => handleTabClick('/auth')}>
            Create Account
          </Button>
        </AuthButtonsContainer>

        <TabbarButton
          onClick={() =>
            handleTabClick(
              loggedInUser ? '/user/' + loggedInUser.id : '/empty-profile'
            )
          }
          className={
            'tab-line' +
            (ionRouter.routeInfo.pathname === '/empty-profile' ||
            (loggedInUser &&
              ionRouter.routeInfo.pathname === '/user/' + loggedInUser.id)
              ? ' active'
              : '') +
            (loggedInUser ? '' : ' no-auth')
          }
        >
          <IconContainer>
            {loggedInUser?.avatarImg ? (
              <StyledBaseAvatar
                image={loggedInUser.avatarImg}
                isOnLoggedInUsersProfile={false}
              />
            ) : (
              <FaUserAstronaut />
            )}
          </IconContainer>

          <TextContainer
            className={
              ionRouter.routeInfo.pathname === '/empty-profile' ||
              (loggedInUser &&
                ionRouter.routeInfo.pathname === '/user/' + loggedInUser.id)
                ? 'active'
                : ''
            }
          >
            My Profile
          </TextContainer>
        </TabbarButton>

        <TabbarButton
          onClick={() => {
            handleTabClick('/friends');
          }}
          className={
            'tab-line' +
            ((Capacitor.isNativePlatform() &&
              tabStackActivated === 'friends') ||
            ionRouter.routeInfo.pathname === '/friends'
              ? ' active'
              : '') +
            (loggedInUser ? '' : ' no-auth')
          }
        >
          <IconContainer>
            <FaUsers />
          </IconContainer>
          <TextContainer
            className={
              (Capacitor.isNativePlatform() &&
                tabStackActivated === 'friends') ||
              ionRouter.routeInfo.pathname === '/friends'
                ? 'active'
                : ''
            }
          >
            Friends
          </TextContainer>
        </TabbarButton>

        <TabbarButton
          onClick={() => {
            handleTabClick('/search');
          }}
          className={
            'tab-line' +
            ((Capacitor.isNativePlatform() && tabStackActivated === 'search') ||
            ionRouter.routeInfo.pathname === '/search'
              ? ' active'
              : '') +
            (loggedInUser ? '' : ' no-auth')
          }
        >
          <IconContainer>
            <IoSearch />
          </IconContainer>
          <TextContainer
            className={
              (Capacitor.isNativePlatform() &&
                tabStackActivated === 'search') ||
              ionRouter.routeInfo.pathname === '/search'
                ? 'active'
                : ''
            }
          >
            Search
          </TextContainer>
        </TabbarButton>

        <TabbarButton
          onClick={() => handleTabClick('/notifications')}
          className={
            'tab-line' +
            (ionRouter.routeInfo.pathname === '/notifications'
              ? ' active'
              : '') +
            (loggedInUser ? '' : ' no-auth')
          }
        >
          <IconContainer>
            <IoNotifications />
            <NotificationBadge />
          </IconContainer>
          <TextContainer
            className={
              ionRouter.routeInfo.pathname === '/notifications' ? 'active' : ''
            }
          >
            Notifications
          </TextContainer>
        </TabbarButton>

        <AddItemButton
          className={loggedInUser ? '' : 'no-auth'}
          color='dark'
          iconLeft={loggedInUser ? add : home}
          onClick={openAddItemModal}
          aria-label='Add Item'
        >
          Add Item
        </AddItemButton>
      </TabbarContainer>
    </TabbarWrapper>
  );
};

export default Tabbar;
