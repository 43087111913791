import { IonAlert, IonItem } from '@ionic/react';
import React, { useState } from 'react';

import { apiFunctionFetch } from '../utils/apiFunctionFetch';
import { useAuth } from '../contexts/AuthenticationContext';
import { useHistory } from 'react-router';

interface Props {}

export const DeleteAccountButton: React.FC<Props> = () => {
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);

  const { logoutUser, loggedInUserId } = useAuth();

  const deleteUserAccount = async () => {
    localStorage.removeItem('ifancy-nuc');
    apiFunctionFetch('requestDeleteAccount', {
      userId: loggedInUserId,
    });
    await logoutUser();
    history.replace('/auth');
  };

  return (
    <IonItem button onClick={() => setShowAlert(true)}>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Permanently Delete Account?'}
        message='This action is irreversible, be careful'
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {},
          },
          {
            text: 'Delete Account',
            role: 'destructive',
            handler: () => {
              deleteUserAccount();
            },
          },
        ]}
      />
      Delete Account
    </IonItem>
  );
};
