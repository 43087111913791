import React, { useEffect, useRef, useState } from 'react';

import { styled } from 'goober';

const SparkleContainer = styled('div', React.forwardRef)`
  position: absolute;

  pointer-events: none;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100px;
  max-height: 40px;

  .star {
    --star-size: 50px;
    --star-life: 5s;

    --start-left: 0px;
    --start-top: 0px;

    --end-left: 0px;
    --end-top: 0px;

    --star-color: #f1c40f;

    width: var(--star-size);
    height: var(--star-size);
    left: var(--end-left);
    top: var(--end-top);
    background: var(--star-color);
    position: absolute;
    mix-blend-mode: lighten;
    animation: slide var(--star-life) ease-in forwards;
    z-index: -1;
  }

  .star:after {
    display: block;
    content: '';
    width: var(--star-size);
    height: var(--star-size);
    background-color: var(--sparkle-bg);
    border-radius: 100%;
    position: relative;
    top: calc(var(--star-size) / 2 * -1);
    left: calc(var(--star-size) / 2 * -1);
    box-shadow: var(--star-size) var(--star-size) var(--sparkle-bg),
      var(--star-size) 0px var(--sparkle-bg),
      0px var(--star-size) var(--sparkle-bg);
  }

  @keyframes slide {
    0% {
      left: var(--start-left);
      top: var(--start-top);
      transform: rotate(0deg);
      opacity: 0;
    }
    100% {
      left: var(--end-left);
      top: var(--end-top);
      transform: rotate(calc(180deg * var(--star-life-num))) scale(0.5);
      opacity: 1;
    }
  }
`;

const STAR_INTERVAL = 46;

const MAX_STAR_LIFE = 6;
const MIN_STAR_LIFE = 2;

const MAX_STAR_SIZE = 20;
const MIN_STAR_SIZE = 4;

const MIN_STAR_TRAVEL_X = 22;
const MIN_STAR_TRAVEL_Y = 22;

const Star = class {
  constructor(sparkle: HTMLElement) {
    this.sparkle = sparkle;
    this.size = this.random(MAX_STAR_SIZE, MIN_STAR_SIZE);

    this.x = this.random(
      sparkle.offsetWidth * 0.75,
      sparkle.offsetWidth * 0.25
    );
    this.y = sparkle.offsetHeight / 2 - this.size / 2;

    this.x_dir = this.randomMinus();
    this.y_dir = this.randomMinus();

    this.x_max_travel =
      this.x_dir === -1 ? this.x : sparkle.offsetWidth - this.x - this.size;
    this.y_max_travel = sparkle.offsetHeight / 2 - this.size;

    this.x_travel_dist = this.random(this.x_max_travel, MIN_STAR_TRAVEL_X);
    this.y_travel_dist = this.random(this.y_max_travel, MIN_STAR_TRAVEL_Y);

    this.x_end = this.x + this.x_travel_dist * this.x_dir;
    this.y_end = this.y + this.y_travel_dist * this.y_dir;

    this.life = this.random(MAX_STAR_LIFE, MIN_STAR_LIFE);

    this.star = document.createElement('div');
    this.star.classList.add('star');

    this.star.style.setProperty('--start-left', this.x + 'px');
    this.star.style.setProperty('--start-top', this.y + 'px');

    this.star.style.setProperty('--end-left', this.x_end + 'px');
    this.star.style.setProperty('--end-top', this.y_end + 'px');

    this.star.style.setProperty('--star-life', this.life + 's');
    this.star.style.setProperty('--star-life-num', String(this.life));

    this.star.style.setProperty('--star-size', this.size + 'px');
    this.star.style.setProperty('--star-color', this.randomRainbowColor());
  }

  size: number;
  x: number;
  y: number;
  x_dir: number;
  y_dir: number;
  x_max_travel: number;
  y_max_travel: number;
  x_travel_dist: number;
  y_travel_dist: number;
  x_end: number;
  y_end: number;
  life: number;
  star: HTMLDivElement;
  sparkle: HTMLElement;

  draw() {
    this.sparkle.appendChild(this.star);
  }

  pop() {
    this.sparkle.removeChild(this.star);
  }

  random(max: number, min: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  randomRainbowColor() {
    return 'hsla(' + this.random(360, 0) + ', 100%, 50%, 1)';
  }

  randomMinus() {
    return Math.random() > 0.5 ? 1 : -1;
  }
};

type Props = {
  maxStars?: number;
};

export const Sparkles: React.FC<Props> = ({ maxStars = 3 }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    let current_star_count = 0;
    if (mounted) {
      interval = setInterval(() => {
        if (current_star_count >= maxStars) {
          return;
        }

        current_star_count++;
        const sparkle = ref.current;
        if (!sparkle) return;
        const newStar = new Star(sparkle);

        newStar.draw();

        setTimeout(() => {
          current_star_count--;

          newStar.pop();
        }, newStar.life * 1000);
      }, STAR_INTERVAL);
    }

    return () => {
      interval && clearInterval(interval);
    };
  }, [mounted, maxStars]);

  return <SparkleContainer ref={ref} />;
};
