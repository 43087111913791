import { BsChevronExpand, BsCursorFill, BsPatchCheck } from 'react-icons/bs';
import {
  DescriptionText,
  FeatureItemBoxContainer,
  H2,
  H3,
  H4,
  HalfFeatureBox,
  HalfFeatureBoxRowContainer,
  SmallFeatureBox,
  SmallFeatureBoxRowContainer,
} from './HomepageStyledComponents';
import { IoLockClosedOutline, IoShareOutline } from 'react-icons/io5';

import { Button } from '../BaseUI/Button';
// @ts-expect-error image import
import ChromeLogo from '../../assets/imgs/chrome_128x128.png?w=100&format=avif;webp;png&as=picture';
import DownloadOnAppStore from '../../assets/imgs/download_on_app_store.svg';
// @ts-expect-error image import
import EdgeLogo from '../../assets/imgs/edge_128x128.png?w=100&format=avif;webp;png&as=picture';
import { Emoji } from '../Emoji';
// @ts-expect-error image import
import FirefoxLogo from '../../assets/imgs/firefox_128x128.png?w=100&format=avif;webp;png&as=picture';
// @ts-expect-error image import
import FriendSuggestion from '../../assets/imgs/friend-suggestion.png?w=400&format=avif;webp;png&as=picture';
// @ts-expect-error image import
import KeepTheSurpriseImage1 from '../../assets/imgs/keep-the-surprise-image-1.png?w=600&format=avif;webp;png&as=picture';
// @ts-expect-error image import
import KeepTheSurpriseImage2 from '../../assets/imgs/keep-the-surprise-image-2.png?w=600&format=avif;webp;png&as=picture';
import LinkParsingInfoModal from '../../modals/LinkParsingInfoModal';
import LinkParsingVideo from '/assets/LinkParsingVideo.mp4';
import { MdTvOff } from 'react-icons/md';
// @ts-expect-error image import
import OrderedList from '../../assets/imgs/ordered-list.png?w=400&format=avif;webp;png&as=picture';
import { Picture } from '../BaseUI/Picture';
import PinkArrow from '../../assets/imgs/PinkArrow.svg';
import PurpleArrow from '../../assets/imgs/PurpleArrow.svg';
import React from 'react';
import RedArrow from '../../assets/imgs/RedArrow.svg';
import RedUnderline from '../../assets/imgs/RedUnderline.svg';
// @ts-expect-error image import
import SafariLogo from '../../assets/imgs/safari_128x128.png?w=100&format=avif;webp;png&as=picture';
import { Timestamp } from 'firebase/firestore';
// @ts-expect-error image import
import TimmyImage from '../../assets/imgs/timmy.png?w=400&format=avif;webp;png&as=picture';
import { WishlistConfig } from '../../types/GlobalTypes';
import { WishlistsSelectButton } from '../ProfileActionButtons/WishlistsSelectButton';
import { helpCircleOutline } from 'ionicons/icons';
import { motion } from 'framer-motion';
import { styled } from 'goober';
import { useInView } from 'react-intersection-observer';

interface Props {}

const defaultViewPortOptions: any = {
  once: true,
};

const ScreenshotContainer2 = styled(motion.div)`
  position: relative;
  margin-top: 20px;
  width: 70%;
  max-width: 250px;
  align-self: center;

  margin-bottom: -48px;
`;

const Screenshot = styled('picture')`
  width: 100%;
`;

const ScreenshotContainer = styled(motion.div)`
  position: relative;
  margin-bottom: 20px;
  width: 60%;
  @media (min-width: 768px) {
    width: 32%;
  }
`;

const StyledPurpleArrow = styled(motion.img)`
  position: absolute;
  top: 37%;
  left: 86%;
  width: 14vw;
  max-width: 98px;

  @media (min-width: 768px) {
    top: 33.5%;
  }
  @media (min-width: 868px) {
    top: 34.5%;
  }
  @media (min-width: 968px) {
    top: 36.5%;
  }
`;

const StyledPurpleArrow2 = styled(motion.img)`
  position: absolute;
  bottom: 80%;
  left: 55%;
  width: 60px;

  @media (min-width: 768px) {
    width: 98px;
  }
`;

const ClickMe = styled(motion.p)`
  position: absolute;

  font-weight: 900;
  font-family: 'Nunito', sans-serif;

  color: var(--ion-color-primary);
  letter-spacing: 0px;
  white-space: nowrap;

  bottom: calc(80% + 30px);
  left: calc(55% - 95px);
  font-size: 22px;
  @media (min-width: 768px) {
    font-size: 24px;
    left: calc(55% - 100px);
    bottom: calc(80% + 50px);
  }
  @media (min-width: 968px) {
    font-size: 29px;
    left: calc(55% - 130px);
  }
  @media (min-width: 1126px) {
  }
`;

const YouSeeThis = styled(motion.p)`
  position: absolute;

  font-weight: 900;
  font-family: 'Nunito', sans-serif;

  color: var(--ion-color-primary);
  letter-spacing: 0px;
  white-space: nowrap;

  top: 28%;
  left: 105%;
  font-size: calc(6px + 3.5vw);
  @media (min-width: 468px) {
    top: 29%;
  }
  @media (min-width: 768px) {
    top: 25%;
    font-size: 24px;
  }
  @media (min-width: 968px) {
    top: 27%;
    font-size: 29px;
  }
  @media (min-width: 1126px) {
    top: 29%;
    right: -180px;
  }
`;

const StyledRedArrow = styled(motion.img)`
  position: absolute;
  top: 41%;
  right: 44%;
  width: 36vw;
  max-width: 228px;
`;

const StyledRedUnderline = styled(motion.img)`
  position: absolute;
  top: 90%;
  right: -4%;
  width: 51%;
`;

const TheySeeNothing = styled(motion.p)`
  position: absolute;

  font-weight: 900;
  font-family: 'Nunito', sans-serif;
  font-size: 29px;
  color: #d75d82;
  letter-spacing: 0px;
  white-space: nowrap;

  top: 54%;
  right: 90%;
  font-size: calc(6px + 3.5vw);
  @media (min-width: 468px) {
    top: 55%;
  }
  @media (min-width: 668px) {
    top: 53%;
  }
  @media (min-width: 700px) {
    top: 51%;
  }
  @media (min-width: 768px) {
    top: 63%;
    right: 101%;
    font-size: 24px;
  }
  @media (min-width: 928px) {
    top: 59%;
  }
  @media (min-width: 968px) {
    top: 57%;
    font-size: 29px;
  }
  @media (min-width: 1068px) {
    top: 54%;
  }
  @media (min-width: 1126px) {
    top: 53%;
  }
`;

const BothScreenshotsContainer = styled('div')`
  padding-top: 30px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: column;
  > div:last-of-type {
    align-self: flex-end;
    margin-top: -10%;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    padding-top: 80px;
    > div:first-of-type {
      margin-right: 80px;
    }
    > div:last-of-type {
      margin-left: 80px;
      margin-top: 0%;
    }
  }
`;

const KeepSurpriseBadge = styled(motion.p)`
  color: white;
  font-weight: 900;
  background-color: var(--ion-color-primary);
  font-family: 'Nunito Sans', sans-serif;

  display: flex;
  align-self: flex-start;
  margin-bottom: 10px;

  margin-top: 20px;
  border-radius: 8px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  @media (min-width: 468px) {
    margin-top: 40px;

    border-radius: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 17px;
  }
  @media (min-width: 668px) {
    margin-top: 80px;
    border-radius: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 22px;
  }
`;

const ComingSoonBadge = styled(motion.p)`
  color: white;
  font-weight: 900;
  background-color: var(--ion-color-medium);
  font-family: 'Nunito Sans', sans-serif;

  display: flex;
  align-self: flex-start;

  border-radius: 6px;
  font-size: 11px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  @media (min-width: 468px) {
    border-radius: 7px;
    font-size: 12px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  @media (min-width: 668px) {
    border-radius: 8px;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  @media (min-width: 768px) {
    margin-top: -23px;
  }
`;

const FeaturesTitle = styled(H2 as any)`
  margin-bottom: 20px;

  @media (min-width: 468px) {
    margin-bottom: 40px;
  }
  @media (min-width: 668px) {
    margin-bottom: 80px;
  }
`;

const DownloadOnAppStoreLink = styled('a')`
  width: 80%;
  max-width: 140px;
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 0;
  display: flex;
  outline: none;

  max-width: 119px;
  @media (min-width: 468px) {
    margin-top: 50px;
    max-width: 140px;
  }

  &:hover {
    opacity: 0.6;
  }
`;

const DownloadOnAppStoreImg = styled('img')`
  width: 100%;
`;

const BrowserList = styled('div')`
  display: flex;
  width: 100%;
  margin-right: -8px;
  align-items: center;
  justify-content: flex-start;
  p {
    margin: 0;
    margin-left: 8px;
  }
`;

const BrowserLogo = styled('picture')`
  width: 34px;
  margin: 4px;

  @media (min-width: 468px) {
    width: 44px;
    margin: 8px;
  }
`;

const TimmyPicture = styled('picture')`
  display: flex;
  width: 58%;
  position: absolute;
  bottom: 0;
  right: 5%;
  min-width: 230px;

  @media (min-width: 568px) {
    width: 50%;
  }

  @media (min-width: 768px) {
    width: 59%;
  }
`;

const FakeWishlistSelect = styled('p')`
  background: var(--ion-color-light-100);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 900;
  font-size: 17px;
  border-radius: 12px;
  margin-top: 20px;
  align-self: center;
  white-space: nowrap;
  min-width: 80%;
  position: relative;
  padding-right: 40px;
  margin-bottom: 130px;
  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
  svg {
    position: absolute;
    top: 12px;
    right: 12px;
    color: var(--ion-color-medium);
  }
`;

const DadCursorContainer = styled(motion.div)`
  position: absolute;
  animation: float 6s ease-in-out infinite;
  top: 66%;
  right: 48%;
  display: flex;
  align-items: flex-start;
  p {
    padding: 1px 4px;
    border: 2px solid #3a9af2;
    border-radius: 8px;
    font-weight: 900;
  }
  svg {
    color: #3a9af2;
    width: 26px;
    height: 26px;
    margin-top: -18px;
    margin-left: -5px;
  }
`;

const MomCursorContainer = styled(motion.div)`
  position: absolute;
  animation: float 6s ease-in-out infinite;
  animation-delay: 2s;
  top: 70%;
  right: 69%;
  display: flex;
  align-items: flex-start;
  p {
    padding: 1px 4px;
    border: 2px solid #f23a3a;
    border-radius: 8px;
    font-weight: 900;
  }
  svg {
    color: #f23a3a;
    width: 26px;
    height: 26px;
    margin-top: -18px;
    margin-left: -5px;
  }
`;

const WishlistSelectButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 110px;
  margin-bottom: 40px;
  width: 100%;
  @media (min-width: 768px) {
    margin-top: 110px;
    margin-bottom: 80px;
  }
  @media (min-width: 968px) {
    margin-top: 160px;
    margin-bottom: 160px;
  }
`;

const StyledWishlistSelect = styled(WishlistsSelectButton as any)`
  max-width: 100%;
  @media (min-width: 468px) {
    max-width: 600px;
  }
`;

const Container = styled('div', React.forwardRef)`
  width: 100%;
  padding-top: 60px;
  padding-bottom: 80px;
  padding-left: 14px;
  padding-right: 14px;
  position: relative;
  z-index: 10;
  box-shadow: 0px 0px 68px rgba(25, 27, 29, 0.15),
    0px 0px 12px rgba(25, 27, 29, 0.07), 0px 0px 400px rgba(25, 27, 29, 0.07);
  @media (min-width: 368px) {
    padding-left: 18px;
    padding-right: 18px;
  }
  @media (min-width: 468px) {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
`;

const VideoContainerOuter = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 0vw;
  align-self: center;
  margin-top: 40px;
  @media (min-width: 668px) {
    padding-left: 30vw;
  }
  @media (min-width: 768px) {
    margin-top: 80px;
    padding-left: 28vw;
  }
  @media (min-width: 1140px) {
    padding-left: 0;
  }
`;

const VideoContainer = styled('div')`
  width: 100%;
  max-width: 400px;
  margin: auto;
  border: 1px dashed #dbdbdb;
  border-radius: 14px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  video {
    width: 100%;
  }
`;

const MagicText = styled('p')`
  color: #ff69b4;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  font-family: 'Nunito', sans-serif;
  @media (min-width: 668px) {
    font-size: 36px;
  }
`;

const NotReallyMagicText = styled('p')`
  font-size: 12px;
  font-weight: 700;
  color: var(--ion-color-dark);
  text-align: center;
  font-family: 'Nunito', sans-serif;
`;

const LearnMoreButton = styled(Button as any)`
  margin-right: auto;
  margin-left: auto;
  .btn-text {
    font-size: 10px;
  }
  .btn-icon {
    padding-left: 1px;
  }
  ion-icon {
    font-size: 12px;
  }
`;

const StyledHotPinkArrow = styled(motion.img)`
  position: absolute;
  top: -2%;
  right: 90%;
  width: 20vw;
  max-width: 120px;
  display: none;
  @media (min-width: 668px) {
    display: initial;
  }
  @media (min-width: 768px) {
    max-width: 150px;
  }
`;

const PasteLinkText = styled(motion.p)`
  position: absolute;
  top: -15px;
  right: auto;
  left: 3px;
  font-weight: 900;
  font-family: 'Nunito', sans-serif;
  color: #ff69b4;
  font-size: 27px;
  white-space: nowrap;
  @media (min-width: 668px) {
    left: auto;
    font-size: 22px;
    top: -5px;
    right: 120%;
  }
  @media (min-width: 768px) {
    font-size: 29px;
    top: -5px;
    right: 128%;
  }
`;

const MaxWidthContainer = styled('div')`
  margin: auto;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
`;

const defaultWishlists: WishlistConfig[] = [
  {
    id: '1',
    name: 'Birthday Wishlist',
    visibility: 'public',
    emoji: ':birthday:',
    deletedAt: null,
    createdAt: Timestamp.fromDate(new Date()),
    updatedAt: Timestamp.fromDate(new Date()),
  },
  {
    id: '4',
    name: 'Christmas ' + new Date().getFullYear(),
    visibility: 'friends-only',
    emoji: ':christmas_tree:',
    deletedAt: null,
    createdAt: Timestamp.fromDate(new Date()),
    updatedAt: Timestamp.fromDate(new Date()),
  },
  {
    id: '2',
    name: 'Baby Joe',
    visibility: 'friends-only',
    emoji: ':baby:',
    deletedAt: null,
    createdAt: Timestamp.fromDate(new Date()),
    updatedAt: Timestamp.fromDate(new Date()),
  },
  {
    id: '8',
    name: 'Stella',
    visibility: 'friends-only',
    emoji: ':dog:',
    deletedAt: null,
    createdAt: Timestamp.fromDate(new Date()),
    updatedAt: Timestamp.fromDate(new Date()),
  },
  {
    id: '3',
    name: 'My Secret Wishlist',
    visibility: 'private',
    emoji: ':lock:',
    deletedAt: null,
    createdAt: Timestamp.fromDate(new Date()),
    updatedAt: Timestamp.fromDate(new Date()),
  },
];

export const HomepageFeaturesSection: React.FC<Props> = () => {
  const [isLinkParsingInfoModalOpen, setIsLinkParsingInfoModalOpen] =
    React.useState(false);

  const { ref, inView } = useInView({
    /* Optional options */
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <Container id='features' ref={ref}>
      <MaxWidthContainer>
        <FeaturesTitle
          initial={{
            opacity: 0,
            x: 24,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
              delay: 0.3,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          Features
        </FeaturesTitle>
        <SmallFeatureBoxRowContainer>
          <FeatureItemBoxContainer>
            <SmallFeatureBox
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
                transition: {
                  duration: 1,
                  delay: 0.2,
                },
              }}
              viewport={defaultViewPortOptions}
            >
              <BsPatchCheck />
              <H4>Free</H4>
            </SmallFeatureBox>
          </FeatureItemBoxContainer>

          <FeatureItemBoxContainer>
            <SmallFeatureBox
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
                transition: {
                  duration: 1,
                  delay: 0.3,
                },
              }}
              viewport={defaultViewPortOptions}
            >
              <MdTvOff />
              <H4>No Ads</H4>
            </SmallFeatureBox>
          </FeatureItemBoxContainer>

          <FeatureItemBoxContainer>
            <SmallFeatureBox
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
                transition: {
                  duration: 1,
                  delay: 0.4,
                },
              }}
              viewport={defaultViewPortOptions}
            >
              <IoLockClosedOutline />
              <H4>Secure</H4>
            </SmallFeatureBox>
          </FeatureItemBoxContainer>

          <FeatureItemBoxContainer>
            <SmallFeatureBox
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
                transition: {
                  duration: 1,
                  delay: 0.5,
                },
              }}
              viewport={defaultViewPortOptions}
            >
              <IoShareOutline />
              <H4>Shareable</H4>
            </SmallFeatureBox>
          </FeatureItemBoxContainer>
        </SmallFeatureBoxRowContainer>
        <KeepSurpriseBadge
          initial={{
            opacity: 0,
            x: 12,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
              delay: 0.1,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          Keep the surprise
        </KeepSurpriseBadge>
        <H3
          initial={{
            opacity: 0,
            x: 24,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
              delay: 0.2,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          You pick when to reveal your purchase
        </H3>
        <DescriptionText
          initial={{
            opacity: 0,
            x: 24,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
              delay: 0.3,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          <span>Unlike other gift registries,</span> iFancy aims to keep your
          gifts a surprise. Users won’t know what has been purchased (or by who)
          until the date you select.
        </DescriptionText>
        <BothScreenshotsContainer>
          <ScreenshotContainer
            initial={{
              opacity: 0,
              y: 24,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.8,
                delay: 0.2,
              },
            }}
            viewport={{
              ...defaultViewPortOptions,
              margin: '-300px 0px 300px 0px',
            }}
          >
            <Screenshot>
              <Picture
                picture={KeepTheSurpriseImage1}
                alt='Keep the surprise feature of iFancy wishlist app'
              />
            </Screenshot>
            <StyledPurpleArrow
              initial={{
                opacity: 0,
                y: -24,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  duration: 1,
                  delay: 0.8,
                  type: 'spring',
                  bounce: 0.7,
                },
              }}
              viewport={defaultViewPortOptions}
              src={PurpleArrow}
              alt='purple arrow'
            />
            <YouSeeThis
              initial={{
                opacity: 0,
                y: -34,
                rotate: -9.46,
              }}
              whileInView={{
                opacity: 1,
                rotate: -9.46,
                y: 0,
                transition: {
                  duration: 1,
                  delay: 1,
                  type: 'spring',
                  bounce: 0.7,
                },
              }}
              viewport={defaultViewPortOptions}
            >
              You see this
            </YouSeeThis>
          </ScreenshotContainer>
          <ScreenshotContainer
            initial={{
              opacity: 0,
              y: 24,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.8,
                delay: 0.4,
              },
            }}
            viewport={{
              ...defaultViewPortOptions,
              margin: '-300px 0px 300px 0px',
            }}
          >
            <Screenshot>
              <Picture
                picture={KeepTheSurpriseImage2}
                alt='gift registry wishlist app screenshot'
              />
            </Screenshot>
            <StyledRedArrow
              initial={{
                opacity: 0,
                y: 24,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  duration: 1,
                  delay: 0.7,
                  type: 'spring',
                  bounce: 0.7,
                },
              }}
              viewport={defaultViewPortOptions}
              src={RedArrow}
              alt='red arrow'
            />
            <TheySeeNothing
              initial={{
                opacity: 0,
                y: 34,
                rotate: 15.67,
              }}
              whileInView={{
                opacity: 1,
                rotate: 15.67,
                y: 0,
                transition: {
                  duration: 1,
                  delay: 1,
                  type: 'spring',
                  bounce: 0.7,
                },
              }}
              viewport={defaultViewPortOptions}
            >
              They see nothing
              <StyledRedUnderline
                initial={{
                  opacity: 0,
                  y: 14,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.5,
                    delay: 1.4,
                    type: 'spring',
                    bounce: 0.7,
                  },
                }}
                viewport={defaultViewPortOptions}
                src={RedUnderline}
                alt='red underline'
              />
            </TheySeeNothing>
          </ScreenshotContainer>
        </BothScreenshotsContainer>
        <HalfFeatureBoxRowContainer>
          <FeatureItemBoxContainer>
            <HalfFeatureBox>
              <H3
                initial={{
                  opacity: 0,
                  x: 24,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.8,
                    delay: 0.2,
                  },
                }}
                viewport={defaultViewPortOptions}
              >
                Web & iOS
              </H3>
              <DescriptionText
                initial={{
                  opacity: 0,
                  x: 24,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.8,
                    delay: 0.3,
                  },
                }}
                viewport={defaultViewPortOptions}
              >
                Access our app on the <span>iOS App Store</span> or any{' '}
                <span>web browser</span>, making it easy to manage your wishlist
                from any device!
              </DescriptionText>
              <DownloadOnAppStoreLink
                href='https://apps.apple.com/us/app/ifancy-wishlist/id1536285199'
                rel='noreferrer noopener'
                target='_blank'
              >
                <DownloadOnAppStoreImg
                  src={DownloadOnAppStore}
                  alt='Download on iOS App Store'
                />
              </DownloadOnAppStoreLink>
              <BrowserList>
                <BrowserLogo>
                  <Picture picture={ChromeLogo} alt='chrome browser logo' />
                </BrowserLogo>

                <BrowserLogo>
                  <Picture picture={SafariLogo} alt='safari browser logo' />
                </BrowserLogo>

                <BrowserLogo>
                  <Picture picture={FirefoxLogo} alt='firefox browser logo' />
                </BrowserLogo>

                <BrowserLogo>
                  <Picture picture={EdgeLogo} alt='edge browser logo' />
                </BrowserLogo>
                <DescriptionText>& More</DescriptionText>
              </BrowserList>
            </HalfFeatureBox>
          </FeatureItemBoxContainer>
          <FeatureItemBoxContainer>
            <HalfFeatureBox>
              <ComingSoonBadge
                initial={{
                  opacity: 0,
                  x: 24,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.8,
                    delay: 0.5,
                  },
                }}
                viewport={defaultViewPortOptions}
              >
                Coming Soon
              </ComingSoonBadge>
              <H3
                initial={{
                  opacity: 0,
                  x: 24,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.8,
                    delay: 0.4,
                  },
                }}
                viewport={defaultViewPortOptions}
              >
                Collaborate
              </H3>

              <DescriptionText
                initial={{
                  opacity: 0,
                  x: 24,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.8,
                    delay: 0.5,
                  },
                }}
                viewport={defaultViewPortOptions}
              >
                Collaborate to create the ultimate wishlist together! (i.e. For
                your child or pet that can&apos;t make their own account)
              </DescriptionText>
              <FakeWishlistSelect>
                <Emoji emoji=':birthday:' /> Timmy&apos;s 1st Birthday{' '}
                <BsChevronExpand />
              </FakeWishlistSelect>
              <TimmyPicture>
                <Picture
                  picture={TimmyImage}
                  alt='happy baby from gift list app'
                />
              </TimmyPicture>
              <DadCursorContainer>
                <p>Dad</p>
                <BsCursorFill />
              </DadCursorContainer>

              <MomCursorContainer>
                <p>Mom</p>
                <BsCursorFill />
              </MomCursorContainer>
            </HalfFeatureBox>
          </FeatureItemBoxContainer>
        </HalfFeatureBoxRowContainer>

        <H3
          initial={{
            opacity: 0,
            x: 24,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
              delay: 0.2,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          Multiple Wishlists
        </H3>
        <DescriptionText
          initial={{
            opacity: 0,
            x: 24,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
              delay: 0.3,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          Create <span>more than just one wishlist.</span> Perfect for a
          fur-baby wishlist, child, or special occasion!
        </DescriptionText>
        <WishlistSelectButtonContainer>
          <StyledWishlistSelect
            onSelect={() => {}}
            wishlists={defaultWishlists}
            selectedWishlist={defaultWishlists[0]}
            showAddNewButton
            isDemo={true}
          />

          <StyledPurpleArrow2
            initial={{
              opacity: 0,
              y: -24,
              rotate: -109.46,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              rotate: -119.46,
              transition: {
                duration: 1,
                delay: 0.8,
                type: 'spring',
                bounce: 0.7,
              },
            }}
            viewport={defaultViewPortOptions}
            src={PurpleArrow}
            alt='purple arrow'
          />
          <ClickMe
            initial={{
              opacity: 0,
              y: -34,
              rotate: 9.46,
            }}
            whileInView={{
              opacity: 1,
              rotate: 9.46,
              y: 0,
              transition: {
                duration: 1,
                delay: 1,
                type: 'spring',
                bounce: 0.7,
              },
            }}
            viewport={defaultViewPortOptions}
          >
            Click Me
          </ClickMe>
        </WishlistSelectButtonContainer>

        <HalfFeatureBoxRowContainer>
          <FeatureItemBoxContainer>
            <HalfFeatureBox>
              <H3
                initial={{
                  opacity: 0,
                  x: 24,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.8,
                    delay: 0.2,
                  },
                }}
                viewport={defaultViewPortOptions}
              >
                Suggestions
              </H3>
              <DescriptionText
                initial={{
                  opacity: 0,
                  x: 24,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.8,
                    delay: 0.3,
                  },
                }}
                viewport={defaultViewPortOptions}
              >
                Add suggestions to other peoples wishlists and let them decide
                if they want to add it or not.
              </DescriptionText>
              <ScreenshotContainer2
                initial={{
                  opacity: 0,
                  y: 24,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.8,
                    delay: 0.4,
                  },
                }}
                viewport={{
                  ...defaultViewPortOptions,
                  margin: '-300px 0px 300px 0px',
                }}
              >
                <Screenshot>
                  <Picture
                    picture={FriendSuggestion}
                    alt='gift registry app screenshot'
                  />
                </Screenshot>
              </ScreenshotContainer2>
            </HalfFeatureBox>
          </FeatureItemBoxContainer>
          <FeatureItemBoxContainer>
            <HalfFeatureBox>
              <H3
                initial={{
                  opacity: 0,
                  x: 24,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.8,
                    delay: 0.4,
                  },
                }}
                viewport={defaultViewPortOptions}
              >
                Ordered List
              </H3>

              <DescriptionText
                initial={{
                  opacity: 0,
                  x: 24,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.8,
                    delay: 0.5,
                  },
                }}
                viewport={defaultViewPortOptions}
              >
                Order your wishlist to show your most wanted items at the top!
              </DescriptionText>
              <ScreenshotContainer2
                className='last'
                initial={{
                  opacity: 0,
                  y: 24,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.8,
                    delay: 0.6,
                  },
                }}
                viewport={{
                  ...defaultViewPortOptions,
                  margin: '-300px 0px 300px 0px',
                }}
              >
                <Screenshot>
                  <Picture
                    picture={OrderedList}
                    alt='ifancy wishlist app feature for family and friends'
                  />
                </Screenshot>
              </ScreenshotContainer2>
            </HalfFeatureBox>
          </FeatureItemBoxContainer>
        </HalfFeatureBoxRowContainer>

        <H3
          initial={{
            opacity: 0,
            x: 24,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
              delay: 0.2,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          AutoMagic Link Parsing
        </H3>
        <DescriptionText
          initial={{
            opacity: 0,
            x: 24,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
              delay: 0.3,
            },
          }}
          viewport={defaultViewPortOptions}
        >
          Paste a link and viola! iFancy will check the link for price
          information, images and product titles to auto-fill your wishlist.
        </DescriptionText>
        <VideoContainerOuter>
          <VideoContainer>
            <PasteLinkText
              initial={{
                opacity: 0,
                y: -24,
                rotate: -9.46,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
                rotate: -9.46,
                transition: {
                  duration: 1,
                  delay: 0.5,
                  type: 'spring',
                  bounce: 0.7,
                },
              }}
              viewport={defaultViewPortOptions}
            >
              Paste link
            </PasteLinkText>
            <StyledHotPinkArrow
              initial={{
                opacity: 0,
                y: -24,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  duration: 1,
                  delay: 0.8,
                  type: 'spring',
                  bounce: 0.7,
                },
              }}
              viewport={defaultViewPortOptions}
              src={PinkArrow}
              alt='pink arrow'
            />
            {inView && (
              <video
                loop
                muted
                autoPlay
                preload='none'
                controls={false}
                playsInline
              >
                <source src={LinkParsingVideo} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            )}

            <MagicText>It&apos;s Magic!</MagicText>
            <NotReallyMagicText>
              Not really though.{' '}
              <LearnMoreButton
                small
                clear
                color='medium'
                onClick={() => setIsLinkParsingInfoModalOpen(true)}
                iconRight={helpCircleOutline}
              >
                Learn More About AutoMagic Link Parsing
              </LearnMoreButton>
              <LinkParsingInfoModal
                pageRef={{ current: null }}
                isOpen={isLinkParsingInfoModalOpen}
                onDidDismiss={() => setIsLinkParsingInfoModalOpen(false)}
              />
            </NotReallyMagicText>
          </VideoContainer>
        </VideoContainerOuter>
      </MaxWidthContainer>
    </Container>
  );
};
