import { ProfileMenuActionButton } from './ProfileActionButtons';
import React from 'react';
import { customPageAnimationHorizontally } from '../../utils/pageTransition';
import { folderOpenSharp } from 'ionicons/icons';
import { isAdmin } from '../../utils/helper-functions/isAdmin';
import { useIonRouter } from '@ionic/react';
import { useLoggedInUser } from '../../contexts/LoggedInUserContext';

interface Props {
  userId: string;
  onClick?: () => void;
}

export const ViewArchivedItems: React.FC<Props> = ({ userId, onClick }) => {
  const { loggedInUser } = useLoggedInUser();
  const ionRouter = useIonRouter();

  if (
    !loggedInUser?.id ||
    (loggedInUser?.id !== userId && !isAdmin(loggedInUser))
  )
    return null;

  const handleClick = () => {
    onClick?.();
    ionRouter.push(
      `${ionRouter.routeInfo.pathname}/archive`,
      'forward',
      'push',
      {},
      customPageAnimationHorizontally
    );
  };

  return (
    <ProfileMenuActionButton
      clear
      color='medium'
      iconLeft={folderOpenSharp}
      onClick={handleClick}
    >
      View Archive
    </ProfileMenuActionButton>
  );
};
