import {
  IonItem,
  IonLabel,
  IonReorder,
  IonReorderGroup,
  IonSkeletonText,
  useIonRouter,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { Timestamp, analytics, db } from '../firebase';
import {
  UserWishlist,
  WishlistConfig,
  WishlistItem,
} from '../types/GlobalTypes';
import { arrowUndoOutline, checkmark } from 'ionicons/icons';
import { doc, writeBatch } from 'firebase/firestore';
import { formatDistanceToNow, isPast } from 'date-fns';

import { AddWishlistItemButton } from './AddWishlistItemButton';
import { Button } from './BaseUI/Button';
import { CustomImage } from './BaseUI/CustomImage';
import { ItemReorderEventDetail } from '@ionic/core';
import { WISHLIST_VISIBILITY_OPTIONS } from '../constants/constants';
import { WishListItem } from './WishListItem';
import { WishlistActionsSection } from './ProfileActionButtons/WishlistActionsSection';
import { WishlistItemModalContext } from '../contexts/WishlistItemModalContext';
import { WishlistsSelectButton } from './ProfileActionButtons/WishlistsSelectButton';
import { filterWishlistsInSelectButton } from '../utils/helper-functions/filterWishlistsInSelectButton';
import { handleCatchError } from '../utils/handleCatchError';
import { logEvent } from 'firebase/analytics';
import { mapWishlistToSortedArray } from '../utils/transformers/mapWishlistToSortedArray';
import { restoreItem } from '../utils/restoreItem';
import { styled } from 'goober';
import toast from 'react-hot-toast';
import { useAskForAppReview } from '../hooks/useAskForAppReview';
import { useLoggedInUser } from '../contexts/LoggedInUserContext';
import { useProfilePageContext } from '../contexts/ProfilePageContext';

const SkeletonWishlistItem = styled(IonSkeletonText)`
  border-radius: 12px;
  width: 100%;
  height: 100%;
  margin: 0;
`;

const ActionPanel = styled('div')`
  padding: 4px 12px;
  @media (min-width: 651px) {
    padding: 4px 20px;
  }
  display: flex;
  justify-content: space-between;
`;

const SkeletonWishlistItemContainer = styled('div')`
  padding: 8px;
  width: 100%;
  height: 100%;
  min-height: 260px;
`;

const NoItemsText = styled('div')<{ $addItemButtonShowing: boolean }>`
  height: 250px;
  width: 100%;
  justify-content: center;
  align-items: center;
  opacity: 1;
  color: #6d6d6d;
  display: flex;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  @media (max-width: 880px) {
    ${({ $addItemButtonShowing }) =>
      $addItemButtonShowing
        ? `position: relative;
    width: auto;`
        : ''}
  }
`;

const NoArchivedItemsText = styled('div')`
  height: 250px;
  width: 100%;
  justify-content: center;
  align-items: center;
  opacity: 1;
  color: #6d6d6d;
  display: flex;
`;

const PrivateListAlert = styled('div')`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  color: #6d6d6d;
  display: flex;
  margin-top: 92px;
  margin-bottom: 62px;

  > svg {
    width: 80px;
    height: 80px;
    color: var(--ion-color-light-100-tint);
  }
`;

const StyledIonItem = styled(IonItem)`
  --background: transparent;
  --padding-start: 10px;
`;

const WishlistContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 0px;
  align-items: start;
  padding: 12px;
  position: relative;
  @media (max-width: 650px) {
    padding: 4px;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
`;

const StyledCustomImage = styled(CustomImage as any)<{ slot: any }>`
  width: 48px;
  height: 48px;
  box-shadow: var(--box-shadow-md);
  margin: 6px 4px;
  margin-right: 12px;
`;

const SelectOtherListContainer = styled('div')`
  width: 100%;
  max-width: 450px;
  margin-top: 60px;
  padding: 0px 20px;
  @media (max-width: 650px) {
    margin-top: 80px;
  }
  p {
    font-weight: 900;
    letter-spacing: 0;
  }
  button {
    width: 100%;
    max-width: 100%;
  }
`;

interface Props {
  isLoggedInUsersProfile: boolean;
  userId: string | undefined;
  showingArchivedItems?: boolean;
  wishlist?: UserWishlist;
  onSelectWishlistItem?: (item: WishlistItem) => void;
  selectedWishlist?: WishlistConfig;
}

const shouldShowWishlistItem = (
  item: WishlistItem,
  isLoggedInUsersProfile: boolean,
  currentUserId: string | undefined
): boolean => {
  if (isLoggedInUsersProfile) return true;

  if (item.visible_to) {
    if (!currentUserId || !item.visible_to.includes(currentUserId))
      return false;
  }

  return true;
};

export const WishList: React.FC<Props> = ({
  onSelectWishlistItem: _onSelectWishlistItem,
  wishlist: _wishlist,
  isLoggedInUsersProfile,
  userId,
  showingArchivedItems = false,
  selectedWishlist: _selectedWishlist,
}) => {
  const {
    wishlistItems: __wishlist,
    selectedWishlist: __selectedWishlist,
    profilePageRef,
    wishlists,
    isLoggedInUserFollowing,
    isNonAuthedProfile,
  } = useProfilePageContext();
  const { loggedInUser } = useLoggedInUser();
  const [wishlist, setWishlist] = useState<undefined | WishlistItem[]>(
    mapWishlistToSortedArray(
      _wishlist || __wishlist,
      isLoggedInUsersProfile,
      showingArchivedItems
    )
  );
  const [showListView, setShowListView] = useState(false);
  const { openWishlistItemModal, handleNonAuthWishlistAddClick } = useContext(
    WishlistItemModalContext
  );
  const ionRouter = useIonRouter();
  const hideAddItemButton = isNonAuthedProfile
    ? false
    : !loggedInUser ||
      (!isLoggedInUsersProfile &&
        (!userId || !loggedInUser?.friends?.includes(userId)));

  const selectedWishlist = _selectedWishlist || __selectedWishlist;

  useAskForAppReview(isLoggedInUsersProfile, wishlist?.length || 0);

  useEffect(() => {
    setWishlist(
      isNonAuthedProfile
        ? []
        : mapWishlistToSortedArray(
            _wishlist || __wishlist,
            isLoggedInUsersProfile,
            showingArchivedItems
          )
    );
  }, [
    _wishlist,
    isLoggedInUsersProfile,
    loggedInUser,
    __wishlist,
    showingArchivedItems,
    isNonAuthedProfile,
  ]);

  const handleWishlistSelected = (wishlist: WishlistConfig) => {
    // ionRouter.routeInfo.routeDirection = 'none';
    ionRouter.push(
      `/user/${userId}${
        wishlist.id === 'original' ? '' : `/wishlists/${wishlist?.id}`
      }`,
      'none',
      'push'
    );
  };

  if (selectedWishlist && !isLoggedInUsersProfile) {
    if (
      selectedWishlist.visibility === 'private' ||
      (selectedWishlist.visibility === 'friends-only' &&
        !isLoggedInUserFollowing)
    ) {
      return (
        <PrivateListAlert>
          {
            WISHLIST_VISIBILITY_OPTIONS.find(
              (i) => i.value === selectedWishlist.visibility
            )?.icon
          }
          <p>
            This wishlist is{' '}
            {selectedWishlist.visibility === 'private'
              ? 'private'
              : 'for friends only'}
            .
          </p>
          <SelectOtherListContainer>
            <p>Select a different list:</p>
            <WishlistsSelectButton
              large
              wishlists={filterWishlistsInSelectButton(
                wishlists,
                isLoggedInUsersProfile,
                isLoggedInUserFollowing
              )}
              showAddNewButton={isLoggedInUsersProfile}
              onSelect={handleWishlistSelected}
              selectedWishlist={selectedWishlist}
            />
          </SelectOtherListContainer>
        </PrivateListAlert>
      );
    }
  }

  if (!wishlist) {
    return (
      <WishlistContainer>
        {[1, 2, 3, 4, 5, 6].map((a, i) => (
          <SkeletonWishlistItemContainer key={i + '-skeleton'}>
            <SkeletonWishlistItem animated={true} />
          </SkeletonWishlistItemContainer>
        ))}
      </WishlistContainer>
    );
  }

  const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    const newOrderWishlist = event.detail.complete(wishlist);
    setWishlist(newOrderWishlist);
  };

  const cancelReorder = () => {
    setWishlist(
      mapWishlistToSortedArray(
        _wishlist || __wishlist,
        isLoggedInUsersProfile,
        showingArchivedItems
      )
    );
    setShowListView(false);
  };

  const saveOrder = async () => {
    try {
      const batch = writeBatch(db);

      if (!selectedWishlist?.id)
        throw new Error('Unable to determine which wishlist to save');
      const collectionPath =
        selectedWishlist.id === 'original'
          ? 'wishlist'
          : `wishlists/${selectedWishlist.id}/items`;
      const updatedAt = Timestamp.fromDate(new Date());
      wishlist.map((item, i) => {
        const ref = doc(db, `users/${userId}/${collectionPath}/${item.id}`);

        if (item.order === i) return undefined;
        return batch.update(ref, { order: i, updatedAt });
      });

      await batch.commit();
      logEvent(analytics, 'wishlist_order_saved');
      toast.success('Arrangement saved!');
      setShowListView(false);
    } catch (err) {
      handleCatchError(err);
    }
  };

  const unArchiveItem = async (wishlistItem: WishlistItem, e: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (!selectedWishlist?.id)
      return toast.error('Unable to determine which wishlist to save');
    await restoreItem(
      loggedInUser?.id || '',
      wishlistItem?.id || '',
      selectedWishlist.id
    );
  };

  const onAddNewItemClick = () => {
    if (isNonAuthedProfile) {
      handleNonAuthWishlistAddClick();
    } else {
      openWishlistItemModal({
        currentWishlist: selectedWishlist,
        isSuggestion: !isLoggedInUsersProfile,
        pageRef: profilePageRef,
        readonly: false,
        userId: userId,
        item: undefined,
      });
    }
  };

  const onSelectWishlistItem = (item: WishlistItem) => {
    if (_onSelectWishlistItem) {
      _onSelectWishlistItem(item);
    } else {
      openWishlistItemModal({
        currentWishlist: selectedWishlist,
        isSuggestion: false,
        pageRef: profilePageRef,
        readonly: !isLoggedInUsersProfile,
        userId: userId,
        item,
      });
    }
  };

  return (
    <>
      {(userId || isNonAuthedProfile) && !showingArchivedItems && (
        <ActionPanel>
          {isLoggedInUsersProfile && showListView ? (
            <>
              <Button clear onClick={cancelReorder} color='medium'>
                Cancel
              </Button>
              <Button iconLeft={checkmark} onClick={saveOrder} color='success'>
                Save
              </Button>
            </>
          ) : selectedWishlist || isNonAuthedProfile ? (
            <WishlistActionsSection
              handleShowListViewClick={() => setShowListView(true)}
            />
          ) : null}
        </ActionPanel>
      )}

      {showingArchivedItems ? (
        wishlist.length === 0 ? (
          <NoArchivedItemsText>
            <p>
              No Archived Items{' '}
              <span role='img' aria-label='empty box'>
                🗃
              </span>
            </p>
          </NoArchivedItemsText>
        ) : (
          wishlist.map((item) => (
            <StyledIonItem
              button
              detail={false}
              onClick={() => onSelectWishlistItem(item)}
              key={item.id}
            >
              <StyledCustomImage
                userId={userId}
                slot='start'
                src={item.image}
              />
              <IonLabel>
                {item.title}
                <p>
                  {item.deletedAt &&
                    formatDistanceToNow(new Date(item.deletedAt.toDate()), {
                      addSuffix: true,
                    })}
                </p>
              </IonLabel>
              <Button
                onClick={(e) => unArchiveItem(item, e)}
                color='medium'
                slot='end'
                iconLeft={arrowUndoOutline}
              >
                Restore
              </Button>
            </StyledIonItem>
          ))
        )
      ) : showListView ? (
        <IonReorderGroup onIonItemReorder={doReorder} disabled={false}>
          {wishlist?.map((item) => (
            <StyledIonItem key={item.id}>
              <StyledCustomImage
                userId={userId}
                slot='start'
                src={item.image}
              />
              <IonLabel>{item.title}</IonLabel>
              <IonReorder slot='end' />
            </StyledIonItem>
          ))}
        </IonReorderGroup>
      ) : (
        <WishlistContainer>
          {!hideAddItemButton ? (
            <AddWishlistItemButton
              isLoggedInUsersProfile={isLoggedInUsersProfile}
              onClick={onAddNewItemClick}
            />
          ) : null}
          {wishlist.length === 0 ? (
            <NoItemsText $addItemButtonShowing={!hideAddItemButton}>
              <p>
                No Items{' '}
                <span role='img' aria-label='cry face'>
                  😢
                </span>
              </p>
            </NoItemsText>
          ) : (
            wishlist
              .filter((item) =>
                shouldShowWishlistItem(
                  item,
                  isLoggedInUsersProfile,
                  loggedInUser?.id
                )
              )
              .map((item) => (
                <WishListItem
                  key={item.id}
                  userId={userId}
                  isLoggedInUsersItem={isLoggedInUsersProfile}
                  purchased={
                    loggedInUser &&
                    (!isLoggedInUsersProfile ||
                      (item?.purchased?.disclose_at?.toDate &&
                        isPast(item.purchased.disclose_at.toDate())))
                      ? item?.purchased?.user_id
                      : undefined
                  }
                  wishlistItem={item}
                  onClick={() => onSelectWishlistItem(item)}
                />
              ))
          )}
        </WishlistContainer>
      )}
    </>
  );
};
